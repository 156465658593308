import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type HoverNoType = {
  mediumDelete?: string;

  /** Style props */
  hoverNoCursor?: CSSProperties["cursor"];
  hoverNoBorderW?: CSSProperties["borderWidth"];
  hoverNoBorderS?: CSSProperties["borderStyle"];
  hoverNoBorderC?: CSSProperties["borderColor"];
  hoverNoBackgroundColor?: CSSProperties["backgroundColor"];

  /** Action props */
  onButtonDelete24x24Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const MediumdeleteIcon = styled.img`
  position: relative;
  --side: min(1.3888888888888888vw,2.604166666666667vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const HovernoRoot = styled.div<{
  hoverNoCursor?: Property.Cursor;
  hoverNoBorderW?: Property.BorderWidth;
  hoverNoBorderC?: Property.BorderColor;
  hoverNoBorderS?: Property.BorderStyle;
  hoverNoBackgroundColor?: Property.BackgroundColor;
}>`border-radius: var(--corner-radius-m);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xsH) var(--padding-11xsW);
  cursor: ${(p) => p.hoverNoCursor};
  border-width: ${(p) => p.hoverNoBorderW};
  border-color: ${(p) => p.hoverNoBorderC};
  border-style: ${(p) => p.hoverNoBorderS};
  background-color: ${(p) => p.hoverNoBackgroundColor};
  & img{ filter: var(--filters-purple-light);}
  &:hover {
    background-color: var(--colors-red);
    & img { filter: var(--filters-btn-txt);}
  }
`;

const HoverNo: FunctionComponent<HoverNoType> = ({
  mediumDelete,
  hoverNoCursor,
  hoverNoBorderW,
  hoverNoBorderC,
  hoverNoBorderS,
  hoverNoBackgroundColor,
  onButtonDelete24x24Click,
}) => {
  return (
    <HovernoRoot
      hoverNoCursor={hoverNoCursor}
      hoverNoBorderW={hoverNoBorderW}
      hoverNoBorderC={hoverNoBorderC}
      hoverNoBorderS={hoverNoBorderS}
      hoverNoBackgroundColor={hoverNoBackgroundColor}
      onClick={onButtonDelete24x24Click}
    >
      <MediumdeleteIcon alt="" src={mediumDelete} />
    </HovernoRoot>
  );
};

export default HoverNo;
