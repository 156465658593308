import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1UserType = {
  /** Style props */
  property1UserFlexShrink?: CSSProperties["flexShrink"];
  property1UserBackgroundColor?: CSSProperties["backgroundColor"];
  porFavorMargin?: CSSProperties["margin"];
  porFavorFontWeight?: CSSProperties["fontWeight"];
  porFavorColor?: CSSProperties["color"];
  text: string;
};

const PorFavor = styled.div<{
  porFavorMargin?: Property.Margin;
  porFavorFontWeight?: Property.FontWeight;
  porFavorColor?: Property.Color;
}>`flex: 1;
  position: relative;
  line-height: 133%;
  margin: ${(p) => p.porFavorMargin};
  font-weight: ${(p) => p.porFavorFontWeight};
  color: ${(p) => p.porFavorColor};
`;
const Property1userRoot = styled.div<{
  property1UserFlexShrink?: Property.FlexShrink;
  property1UserBackgroundColor?: Property.BackgroundColor;
}>`border-radius: var(--corner-radius-xl);
  background-color: var(--colors-grey-stroke);
  width: 31.944444444444443vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-baseH) var(--padding-baseW);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--body-message);
  flex-shrink: ${(p) => p.property1UserFlexShrink};
  background-color: ${(p) => p.property1UserBackgroundColor};
`;

const Property1User: FunctionComponent<Property1UserType> = ({
  property1UserFlexShrink,
  property1UserBackgroundColor,
  porFavorMargin,
  porFavorFontWeight,
  porFavorColor,
  text,
}) => {
  return (
    <Property1userRoot
      property1UserFlexShrink={property1UserFlexShrink}
      property1UserBackgroundColor={property1UserBackgroundColor}
    >
      <PorFavor
        porFavorMargin={porFavorMargin}
        porFavorFontWeight={porFavorFontWeight}
        porFavorColor={porFavorColor}
      >
        {text}
      </PorFavor>
    </Property1userRoot>
  );
};

export default Property1User;
