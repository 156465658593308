import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import Property1Default3 from "./Property1Default3";
import StateDefaultEmptyno from "./StateDefaultEmptyno";
import { Socket } from 'socket.io-client';

type StateSmallTextType = {
  /** Style props */
  frameDivOverflow?: CSSProperties["overflow"];
  pauseA:() => void;
  playA:() => void;
  stopA:() => void;
  isPushToTalk:boolean;
  isEditBeforeSend:boolean;
  LL: string;
  NL: string;

  /** Action props */
  onButtonDownloadClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onClickSend?: (e: React.MouseEvent<HTMLElement>) => void;
  onClickVoice1?: (e: React.MouseEvent<HTMLElement>) => void;
  onClickVoice2?: (e: React.MouseEvent<HTMLElement>) => void;
  clearAudioQueue: () => void;
  socket: Socket;
};

const StatesmalltextChild = styled.div`
  align-self: stretch;
  position: relative;
  height: 0.0vh;
`;
const ButtondownloadParent = styled.div<{
  frameDivOverflow?: Property.Overflow;
}>`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-5xs);
  overflow: ${(p) => p.frameDivOverflow};
`;
const StatesmalltextRoot = styled.div`
  height: 8.333333333333332vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-5xs);
  align-self: stretch;
`;

const StateSmallText: FunctionComponent<StateSmallTextType> = ({
  frameDivOverflow,
  onButtonDownloadClick,
  onClickSend,
  onClickVoice1,
  onClickVoice2,
  clearAudioQueue,
  pauseA,
  playA,
  stopA,
  isPushToTalk,
  isEditBeforeSend,
  socket,
  LL,
  NL,
}) => {
  return (
    <StatesmalltextRoot>
      <StatesmalltextChild />
      <ButtondownloadParent frameDivOverflow={frameDivOverflow}>
        <Property1Default3
          property1DefaultCursor="pointer"
          property1DefaultBorderW="unset"
          property1DefaultBorderC="unset"
          property1DefaultBorderS="unset"
          property1DefaultBackgroundColor="var(--colors-darkerblue)"
          onButtonDownloadClick={onButtonDownloadClick}
        />
        <StateDefaultEmptyno
          stateDefaultEmptynoGap="0.5555555555555556vw"
          stateDefaultEmptynoOutline="unset"
          stateDefaultEmptynoFontFamily="unset"
          stateDefaultEmptynoFontSize="unset"
          stateDefaultEmptynoColor="unset"
          stateDefaultEmptynoBackgroundColor="var(--colors-white)"
          stateDefaultEmptynoBorderW="0.130208333vh 0.0694444444vw"
          stateDefaultEmptynoBorderC="var(--colors-grey-stroke)"
          stateDefaultEmptynoBorderS="solid"
          onClickSend={onClickSend}
          onClickVoice1={onClickVoice1}
          onClickVoice2={onClickVoice2}
          clearAudioQueue={clearAudioQueue}
          socket={socket}
          pauseA={pauseA}
          playA={playA}
          stopA={stopA}
          isPushToTalk={isPushToTalk}
          isEditBeforeSend={isEditBeforeSend}
          LL={LL}
          NL={NL}
        />
      </ButtondownloadParent>
    </StatesmalltextRoot>
  );
};

export default StateSmallText;
