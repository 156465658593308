import { FunctionComponent, useState, useCallback, forwardRef } from "react";
import styled from "styled-components";
import StateDefaultEmptyYesChec from "./StateDefaultEmptyYesChec";
import StateemptyScrollno from "./StateemptyScrollno";
import ContainerWithButtons from "./ContainerWithButtons";
import Property1Default6 from "./Property1Default6";
import StateDefaultEmptyYesChec1 from "./StateDefaultEmptyYesChec1";
import StateHoverIconno from "./StateHoverIconno";
import StateDefaultIconyes from "./StateDefaultIconyes";
import HoverNo from "./HoverNo";
import Property1Default9 from "./Property1Default9";
import Property1Default10 from "./Property1Default10";

const PleaseAddYour = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
`;
const PleaseAddYourDisplayNameFParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
`;
const TypeYourBioexperienceVisiParent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const FrameGroup = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const Language = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 0.0vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 9.027777777777777vw;
  opacity: 0.8;
`;
const Level = styled.div`
  position: absolute;
  top: -0.13020833333333331vh;
  left: 13.61111111111111vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 6.944444444444445vw;
  opacity: 0.8;
`;
const PrivateLessonsStatus = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 23.47222222222222vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 11.11111111111111vw;
  opacity: 0.8;
`;
const HourlyRateUsd = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 39.861111111111114vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8.333333333333332vw;
  opacity: 0.8;
`;
const HourlyRateUsd1 = styled.div`
  position: absolute;
  top: 0.13020833333333331vh;
  left: 51.52777777777777vw;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 9.722222222222223vw;
  opacity: 0;
`;
const UploadCredentials = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 64.44444444444444vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 12.36111111111111vw;
  opacity: 0.8;
`;
const Tableheadera = styled.div`
  align-self: stretch;
  position: relative;
  height: 2.604166666666667vh;
`;
const FrameChild = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  height: 0.13020833333333331vh;
`;
const TableheaderaParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
`;
const EnglishUs = styled.div`
  position: absolute;
  top: 0.78125vh;
  left: 0.0vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 9.027777777777777vw;
`;
const Conversational = styled.div`
  position: absolute;
  top: 0.78125vh;
  left: 13.61111111111111vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 6.944444444444445vw;
`;
const RightArea = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 64.44444444444444vw;
  width: 13.055555555555557vw;
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const LanguageBlock = styled.div`
  align-self: stretch;
  position: relative;
  height: 4.166666666666666vh;
`;
const LanguageBlocks = styled.div`
  align-self: stretch;
  border-radius: var(--br-base);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-baseH) var(--padding-baseW);
  gap: var(--gap-base);
  z-index: 1;
`;
const LanguageParent = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
`;
const CreateNewTab = styled.div`
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const LanguageOperations = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  z-index: 0;
  font-size: var(--caption-cap-12-size);
`;
const LanguageBlocksParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--body-main-size);
`;
const Student = styled.h3`
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: inherit;
`;
const Rate = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 0;
`;
const Radiobutton = styled.input`
  margin: 0 !important;
  position: absolute;
  top: 0.71614583333vh;
  left: 4.54861111111vw;
  border-radius: var(--corner-radius-s);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-purple-light);
  box-sizing: border-box;
  width: 1.1805555555555556vw;
  height: 2.213541666666667vh;
  cursor: pointer;
  z-index: 1;
`;
const Sortingitem = styled.div`
  width: 5.694444444444445vw;
  height: 3.6458333333333335vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-5xs);
`;
const SortingfiltersChild = styled.div`
  position: relative;
  border-right: 0.06944444444444445vw solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 0.06944444444444445vw;
  height: 2.473958333333333vh;
`;
const Sortingfilters = styled.div`
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-base);
  font-size: var(--body-main-size);
  color: var(--colors-purple-dark);
`;
const StudentParent = styled.div`
  align-self: stretch;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.0vh 0.0vw 0.0vh var(--padding-9xlW);
`;
const AlexanderVachovsky = styled.div`
  position: relative;
  font-size: var(--buttons-btn-input-size);
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: var(--buttons-btn-input);
  color: var(--colors-dark-1);
  text-align: left;
`;
const MediumdropdownarrowIcon = styled.img<{IsRotated: boolean}>`
  position: relative;
  --side: min(1.3888888888888888vw,2.604166666666667vh);
  width: var(--side);
  height: var(--side);
  object-fit: cover;
  transform: ${({ IsRotated }) => IsRotated ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease;
  filter: var(--filters-purple-dark);
`;
const AlexanderVachovskyParent = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const TabsInner = styled.div`
  background-color: var(--colors-white);
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 80.27777777777779vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-9xlW);
`;
const CreateNewTabInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;
const Language2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
`;
const RightArea2 = styled.div`
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: var(--gap-xs);
`;
const CreateNewTab1 = styled.div`
  align-self: stretch;
  height: 6.770833333333333vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
const CreateNewTabWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Language4 = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 0.0vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 9.166666666666666vw;
  opacity: 0.8;
`;
const SetupBy = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 12.5vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 9.86111111111111vw;
  opacity: 0.8;
`;
const Target = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 28.888888888888886vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 34.44444444444444vw;
  opacity: 0.8;
`;
const Deadline = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 69.86111111111111vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 5.555555555555555vw;
  opacity: 0.8;
`;
const EnglishUk = styled.div`
  position: absolute;
  top: 1.3020833333333335vh;
  left: 0.0vw;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 9.166666666666666vw;
`;
const DanielCraig = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  font-weight: 500;
`;
const Name1 = styled.div`
  position: absolute;
  top: 1.0416666666666665vh;
  left: 12.5vw;
  width: 9.86111111111111vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--colors-darkerblue);
`;
const NonSet = styled.div`
  position: absolute;
  top: 1.3020833333333335vh;
  left: 28.888888888888886vw;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 34.44444444444444vw;
`;
const Div = styled.div`
  position: absolute;
  top: 1.3020833333333335vh;
  left: 69.86111111111111vw;
  line-height: 2.604166666666667vh;
  color: var(--colors-purple-dark);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 5.555555555555555vw;
`;
const TableElement = styled.div`
  align-self: stretch;
  position: relative;
  height: 5.208333333333334vh;
`;
const ActiveEnglishUs = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 28.888888888888886vw;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 34.44444444444444vw;
`;
const LanguageBlocks1 = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--colors-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-baseH) var(--padding-baseW);
  gap: var(--gap-base);
  font-size: var(--body-main-size);
`;
const FrameDiv = styled.div<{IsHidden?: boolean}>`
  align-self: stretch;
  background-color: var(--colors-grey-bg);
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-baseH) var(--padding-baseW);
  gap: var(--gap-5xl);
  display: ${(p) => p.IsHidden?"none":"flex"};
`;
const Tabs = styled.div`
  border-radius: var(--br-xs);
  background-color: var(--colors-grey-bg);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 80.41666666666667vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--caption-cap-12-size);
  overflow: hidden;
`;
const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--body-message-size);
`;
const TabTutorRoot = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  z-index: 1;
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  max-height: 84.5vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

type GoalT = {
  Language: string;
  SetUpBy: string;
  Target: string;
  Deadline: string;
}

type StudentT = {
  tableData: GoalT[];
  Name: string;
  Nick: string;
}

type langRowT = {
  id: number;
  language: string;
  level: string;
  status: string;
}

type InType = {
  Students: StudentT[];
  languageData: langRowT[];
  Code: string;
  rec: () => Promise<void>;
}

type SBT = {
  Student: StudentT;
  sindex: number;
  InputData: InType;
  onButtonSecondary33Click: (e:React.MouseEvent<HTMLElement>)=>void;
}
const StudBlock: FunctionComponent<SBT> = ({Student, sindex,InputData,onButtonSecondary33Click,}) => {
  const [IsHidden, setIsHidden] = useState(true);
  const [IsRotated, setIsRotated] = useState(false);
  const ChangeHideState = () => {
    setIsHidden(!IsHidden);
    setIsRotated(!IsRotated)
    InputData.rec()
  };
  return (
  <>
    <TabsInner>
      <AlexanderVachovskyParent onClick={ChangeHideState}>
        <AlexanderVachovsky>{Student.Name}</AlexanderVachovsky>
        <MediumdropdownarrowIcon
          alt=""
          src="/DropdownLocerArrow.svg"
          IsRotated={IsRotated}
        />
      </AlexanderVachovskyParent>
    </TabsInner>
    <FrameDiv IsHidden={IsHidden}>
      <CreateNewTabWrapper>
        <CreateNewTab1>
          <CreateNewTabInner>
            <LanguageParent>
              <PleaseAddYour>Name</PleaseAddYour>
              <StateDefaultEmptyYesChec
                text="Name"
                value={Student.Nick}
                stateDefaultEmptyYesChecBoxSizing="border-box"
                stateDefaultEmptyYesChecPosition="unset"
                stateDefaultEmptyYesChecGap="unset"
                stateDefaultEmptyYesChecOutline="none"
                stateDefaultEmptyYesChecFontFamily="Onest"
                stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-5xsW)"
                stateDefaultEmptyYesChecAlignSelf="unset"
                stateDefaultEmptyYesChecWidth="17.36111111111111vw"
                stateDefaultEmptyYesChecHeight="3.7760416666666665vh"
                stateDefaultEmptyYesChecFontWeight="500"
                stateDefaultEmptyYesChecFlex="unset"
                stateDefaultEmptyYesChecZIndex="unset"
                stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                stateDefaultEmptyYesChecBorderS="solid"
                stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                stateDefaultEmptyTextWidth="90%"
              />
            </LanguageParent>
          </CreateNewTabInner>
          <RightArea2>
            <Language2>
              <PleaseAddYour>Language</PleaseAddYour>
              <Property1Default6
                property1DefaultWidth="11.180555555555555vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="unset"
                property1DefaultLeft="unset"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="Onest"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="1.8229166666666667vh"
                property1DefaultColor="var(--colors-purple-light)"
                property1DefaultAlignSelf="unset"
                options={["English","Russian"]}
                value={"English"}
              />
            </Language2>
            <StateHoverIconno
              saveSession="Initiate Goal for Student Account"
              stateHoverIconnoJustifyContent="flex-start"
              stateHoverIconnoWidth="unset"
              stateHoverIconnoZIndex="unset"
              stateHoverIconnoBackgroundColor="transparent"
              stateHoverIconnoCursor="pointer"
              stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
              stateHoverIconnoBorderC="var(--colors-darkerblue)"
              stateHoverIconnoBorderS="solid"
              stateHoverIconnoHeight="4.166666666666666vh"
              stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
              stateHoverIconnoBoxSizing="border-box"
              stateHoverIconnoPosition="unset"
              stateHoverIconnoTop="unset"
              stateHoverIconnoLeft="unset"
              saveSessionColor="var(--colors-darkerblue)"
              saveSessionDisplay="inline-block"
              onButtonSecondaryClick={onButtonSecondary33Click}
            />
          </RightArea2>
        </CreateNewTab1>
      </CreateNewTabWrapper>
      <LanguageBlocks1>
        <TableheaderaParent>
          <Tableheadera>
            <Language4>Language</Language4>
            <SetupBy>Setup by</SetupBy>
            <Target>Target</Target>
            <Deadline>Deadline</Deadline>
          </Tableheadera>
          <FrameChild />
        </TableheaderaParent>
        {Student.tableData.map((item, index) => (
          <TableElement key={index}>
            <EnglishUk>{item.Language}</EnglishUk>
            <Name1>
              <Property1Default10
                smallCall="/Call.svg"
                property1DefaultCursor="pointer"
                property1DefaultBorderW="none"
                property1DefaultBorderC="none"
                property1DefaultBorderS="none"
                property1DefaultPadding="0"
                property1DefaultPosition="unset"
                property1DefaultTop="unset"
                property1DefaultLeft="unset"
                property1DefaultBackgroundColor="var(--colors-green-light)"
              />
              <DanielCraig>{item.SetUpBy}</DanielCraig>
            </Name1>
            {item.Target ? (
              <ActiveEnglishUs>{item.Target}</ActiveEnglishUs>
            ) : (
              <NonSet>non set</NonSet>
            )}
            <Div>{item.Deadline || ""}</Div>
          </TableElement>
        ))}
      </LanguageBlocks1>
    </FrameDiv>
  </>)
}

type SBTS = {
  Students: StudentT[];
  InputData: InType;
  onButtonSecondary33Click: (e:React.MouseEvent<HTMLElement>)=>void;
}

const StudBlocks: FunctionComponent<SBTS> = ({Students,InputData, onButtonSecondary33Click}) => {
  return <>{Students.map((Student, sindex) => (
    <StudBlock Student={Student} sindex={sindex} InputData={InputData} onButtonSecondary33Click={onButtonSecondary33Click}/>
  ))}</>
}

const FormContainer2 = forwardRef<HTMLDivElement, InType>((InputData,ref) => {
  const onButtonSecondary26Click1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary27Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary28Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary31Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary32Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onSortingItem2Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary33Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onRadioButton1Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const [items, setFCListItems] = useState(InputData.languageData);
  const [Students, setStudents] = useState(InputData.Students)

  const handleRemoveItem = useCallback((itemId: number) => {
    setFCListItems(items => items.filter(item => item.id !== itemId));
  }, []);

  return (
    <TabTutorRoot id="TutorSection" ref={ref}>
      <FrameParent>
        <FrameGroup>
          <PleaseAddYourDisplayNameFParent>
            <PleaseAddYour>
              Please add your Display Name for it to be visible to potential
              students.
            </PleaseAddYour>
            <StateDefaultEmptyYesChec
              stateDefaultEmptyYesChecBoxSizing="border-box"
              stateDefaultEmptyYesChecPosition="unset"
              stateDefaultEmptyYesChecGap="unset"
              stateDefaultEmptyYesChecOutline="none"
              stateDefaultEmptyYesChecFontFamily="Onest"
              stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
              stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
              stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-5xsW)"
              stateDefaultEmptyYesChecAlignSelf="unset"
              stateDefaultEmptyYesChecWidth="17.36111111111111vw"
              stateDefaultEmptyYesChecHeight="3.7760416666666665vh"
              stateDefaultEmptyYesChecFontWeight="unset"
              stateDefaultEmptyYesChecFlex="unset"
              stateDefaultEmptyYesChecZIndex="unset"
              stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
              stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
              stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
              stateDefaultEmptyYesChecBorderS="solid"
              stateDefaultEmptyTextWidth="90%"
            />
          </PleaseAddYourDisplayNameFParent>
          <TypeYourBioexperienceVisiParent>
            <PleaseAddYour>
              Type your Bio/Experience (visible to others) here...
            </PleaseAddYour>
            <StateemptyScrollno
              stateemptyScrollnoBoxSizing="border-box"
              stateemptyScrollnoFontFamily="Onest"
              stateemptyScrollnoFontSize="1.8229166666666667vh"
              stateemptyScrollnoOutline="none"
              stateemptyScrollnoColor="var(--colors-purple-light)"
              stateemptyScrollnoFlex="1"
              stateemptyScrollnoHeight="unset"
              stateemptyScrollnoFlexShrink="unset"
              stateemptyScrollnoBackgroundColor="var(--colors-white)"
              stateemptyScrollnoBorderW="0.130208333vh 0.0694444444vw"
              stateemptyScrollnoBorderC="var(--colors-grey-stroke)"
              stateemptyScrollnoBorderS="solid"
            />
          </TypeYourBioexperienceVisiParent>
        </FrameGroup>
        <ContainerWithButtons
          tutorCodeInstructions="Get Tutor Code and your clients should add it to their Goal (in My Goals section), for which you are a Tutor. With this code your client link you with their goal in your subject, which allows you to keep track of the client's progress. If a new client is introduced to the platform with your code, both he and you benefit: your client receives a discount and you get enrolled into our Awards Program (see Terms and Conditions)."
          tutorCodeDescription="Get Tutor Code"
          clientGoalCode={InputData.Code}
          onButtonSecondary26Click={onButtonSecondary26Click1}
        />
      </FrameParent>
      <LanguageBlocksParent>
        <LanguageBlocks>
          <TableheaderaParent>
            <Tableheadera>
              <Language>Language</Language>
              <Level>Level</Level>
              <PrivateLessonsStatus>
                Private Lessons Status
              </PrivateLessonsStatus>
              <HourlyRateUsd>Hourly Rate, USD</HourlyRateUsd>
              <HourlyRateUsd1>Hourly Rate, USD</HourlyRateUsd1>
              <UploadCredentials>Upload credentials</UploadCredentials>
            </Tableheadera>
            <FrameChild />
          </TableheaderaParent>
          {items.map((item, index) => (
            <LanguageBlock key={index}>
              <EnglishUs>{item.language}</EnglishUs>
              <Conversational>{item.level}</Conversational>
              <Property1Default6
                property1DefaultWidth="13.541666666666666vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="0.1953125vh"
                property1DefaultLeft="23.4375vw"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="Onest"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="1.8229166666666667vh"
                property1DefaultColor="var(--colors-purple-light)"
                property1DefaultAlignSelf="unset"
                options={["1","2"]}
                value={item.status}
              />
              <StateDefaultEmptyYesChec1
                text="1-150"
                stateDefaultEmptyYesChecBoxSizing="border-box"
                stateDefaultEmptyYesChecOutline="none"
                stateDefaultEmptyYesChecFontFamily="Onest"
                stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                stateDefaultEmptyYesChecFlexShrink="0"
                stateDefaultEmptyYesChecColor="var(--colors-dark-1)"
                stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-5xsW)"
                stateDefaultEmptyYesChecFlex="unset"
                stateDefaultEmptyYesChecFontWeight="500"
                stateDefaultEmptyYesChecPosition="absolute"
                stateDefaultEmptyYesChecTop="0.1953125vh"
                stateDefaultEmptyYesChecLeft="39.8263888889vw"
                stateDefaultEmptyYesChecWidth="6.597222222222222vw"
                stateDefaultEmptyYesChecAlignSelf="unset"
                stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                stateDefaultEmptyYesChecBorderS="solid"
                IsInput={true}
              />
              <StateHoverIconno
                saveSession="Test Knowledge"
                stateHoverIconnoJustifyContent="center"
                stateHoverIconnoWidth="13.472222222222221vw"
                stateHoverIconnoZIndex="unset"
                stateHoverIconnoBackgroundColor="transparent"
                stateHoverIconnoCursor="pointer"
                stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                stateHoverIconnoBorderC="var(--colors-darkerblue)"
                stateHoverIconnoBorderS="solid"
                stateHoverIconnoHeight="4.166666666666666vh"
                stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                stateHoverIconnoBoxSizing="border-box"
                stateHoverIconnoPosition="absolute"
                stateHoverIconnoTop="0.0vh"
                stateHoverIconnoLeft="48.05555555555556vw"
                saveSessionColor="var(--colors-darkerblue)"
                saveSessionDisplay="inline-block"
                onButtonSecondaryClick={onButtonSecondary27Click}
              />
              <RightArea>
                <StateDefaultIconyes
                  stateDefaultIconyesJustifyContent="center"
                  stateDefaultIconyesGap="0.5555555555555556vw"
                  stateDefaultIconyesFlex="1"
                  stateDefaultIconyesCursor="pointer"
                  stateDefaultIconyesWidth="9.722222222222223vw"
                  stateDefaultIconyesBorderW="0.130208333vh 0.0694444444vw"
                  stateDefaultIconyesBorderC="var(--colors-darkerblue)"
                  stateDefaultIconyesBorderS="solid"
                  onButtonSecondary28Click={onButtonSecondary28Click}
                />
                <HoverNo
                  mediumDelete="/DeleteGoal&TutoringLang.svg"
                  hoverNoCursor="pointer"
                  hoverNoBorderW="none"
                  hoverNoBorderS="none"
                  hoverNoBorderC="none"
                  hoverNoBackgroundColor="transparent"
                  onButtonDelete24x24Click={() => handleRemoveItem(item.id)}
                />
              </RightArea>
            </LanguageBlock>
          ))}
        </LanguageBlocks>
        <LanguageOperations>
          <CreateNewTab>
            <LanguageParent>
              <PleaseAddYour>Language</PleaseAddYour>
              <Property1Default6
                property1DefaultWidth="11.180555555555555vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="unset"
                property1DefaultLeft="unset"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="Onest"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="1.8229166666666667vh"
                property1DefaultColor="var(--colors-purple-light)"
                property1DefaultAlignSelf="unset"
                options={["English","Russian"]}
                value={"English"}
              />
            </LanguageParent>
            <StateHoverIconno
              saveSession="Create New"
              stateHoverIconnoJustifyContent="flex-start"
              stateHoverIconnoWidth="unset"
              stateHoverIconnoZIndex="unset"
              stateHoverIconnoBackgroundColor="transparent"
              stateHoverIconnoCursor="pointer"
              stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
              stateHoverIconnoBorderS="solid"
              stateHoverIconnoBorderC="var(--colors-darkerblue)"
              stateHoverIconnoHeight="4.166666666666666vh"
              stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
              stateHoverIconnoBoxSizing="border-box"
              stateHoverIconnoPosition="unset"
              stateHoverIconnoTop="unset"
              stateHoverIconnoLeft="unset"
              saveSessionColor="var(--colors-darkerblue)"
              saveSessionDisplay="inline-block"
              onButtonSecondaryClick={onButtonSecondary31Click}
            />
          </CreateNewTab>
          <StateHoverIconno
            saveSession="Confirm and Save"
            stateHoverIconnoJustifyContent="flex-start"
            stateHoverIconnoWidth="unset"
            stateHoverIconnoZIndex="unset"
            stateHoverIconnoBackgroundColor="var(--colors-darkerblue)"
            stateHoverIconnoCursor="pointer"
            stateHoverIconnoBorderW="none"
            stateHoverIconnoBorderC="none"
            stateHoverIconnoBorderS="none"
            stateHoverIconnoHeight="4.166666666666666vh"
            stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
            stateHoverIconnoBoxSizing="border-box"
            stateHoverIconnoPosition="unset"
            stateHoverIconnoTop="unset"
            stateHoverIconnoLeft="unset"
            saveSessionColor="var(--colors-btn-txt)"
            saveSessionDisplay="inline-block"
            onButtonSecondaryClick={onButtonSecondary32Click}
          />
        </LanguageOperations>
      </LanguageBlocksParent>
      <FrameContainer>
        <StudentParent>
          <Student>Student</Student>
          <Sortingfilters>
            <Sortingitem>
              <Rate>Available</Rate>
              <Radiobutton type="checkbox" onClick={onRadioButton1Click} />
            </Sortingitem>
            <SortingfiltersChild />
            <Property1Default9
              text="Rate"
              smallArrows="/NoSort.svg"
              property1DefaultCursor="pointer"
              property1DefaultBorderW="none"
              property1DefaultBorderS="none"
              property1DefaultBorderC="none"
              property1DefaultPadding="0"
              property1DefaultBackgroundColor="transparent"
              rateColor="var(--colors-purple-dark)"
              rateDisplay="inline-block"
              onSortingItemClick1={onSortingItem2Click}
            />
            <SortingfiltersChild />
            <Property1Default6
                property1DefaultWidth="9.791666666666666vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="unset"
                property1DefaultLeft="unset"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="var(--buttons-btn-input)"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="var(--body-main-size)"
                property1DefaultColor="var(--colors-dark-1)"
                property1DefaultAlignSelf="unset"
                options={["All Languages", "English","Russian"]}
                value={"All Languages"}
              />
          </Sortingfilters>
        </StudentParent>
        <Tabs>
          <StudBlocks Students={Students} InputData={InputData} onButtonSecondary33Click={onButtonSecondary33Click}/>
        </Tabs>
      </FrameContainer>
    </TabTutorRoot>
  );
});

export default FormContainer2;
