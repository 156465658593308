import { FunctionComponent } from "react";
import styled from "styled-components";
import StateHoverIconno from "./StateHoverIconno";

type ContainerWithButtonsType = {
  tutorCodeInstructions?: string;
  tutorCodeDescription?: string;
  clientGoalCode?: string;

  /** Action props */
  onButtonSecondary26Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const GetTutorCode = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 2.604166666666667vh;
`;
const A3d6337m78htpy = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
`;
const ButtonsecondaryParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--buttons-btn-input-size);
`;
const Code = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const CodeWrapperRoot = styled.div`
  border-radius: var(--br-base);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 31.11111111111111vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xlH) var(--padding-5xlW);
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
`;

const ContainerWithButtons: FunctionComponent<ContainerWithButtonsType> = ({
  tutorCodeInstructions,
  tutorCodeDescription,
  clientGoalCode,
  onButtonSecondary26Click,
}) => {
  return (
    <CodeWrapperRoot>
      <Code>
        <GetTutorCode>{tutorCodeInstructions}</GetTutorCode>
        <ButtonsecondaryParent>
          <StateHoverIconno
            saveSession="Get Tutor Code"
            stateHoverIconnoJustifyContent="flex-start"
            stateHoverIconnoWidth="unset"
            stateHoverIconnoZIndex="unset"
            stateHoverIconnoBackgroundColor="transparent"
            stateHoverIconnoCursor="pointer"
            stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
            stateHoverIconnoBorderC="var(--colors-darkerblue)"
            stateHoverIconnoBorderS="solid"
            stateHoverIconnoHeight="4.166666666666666vh"
            stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
            stateHoverIconnoBoxSizing="border-box"
            stateHoverIconnoPosition="unset"
            stateHoverIconnoTop="unset"
            stateHoverIconnoLeft="unset"
            saveSessionColor="var(--colors-darkerblue)"
            saveSessionDisplay="inline-block"
            onButtonSecondaryClick={onButtonSecondary26Click}
          />
          <A3d6337m78htpy>{clientGoalCode}</A3d6337m78htpy>
        </ButtonsecondaryParent>
      </Code>
    </CodeWrapperRoot>
  );
};

export default ContainerWithButtons;
