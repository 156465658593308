import { FunctionComponent, type CSSProperties, forwardRef } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import Property1User from "./Property1User";
type inT ={from:'tutor'|'student';text:string}
type Property1usermessageType = {
  /** Style props */
  aquTienesLasContainerMargin?: CSSProperties["margin"];
  aquTienesLasContainerFontWeight?: CSSProperties["fontWeight"];
  aquTienesLasContainerColor?: CSSProperties["color"];
  messageTextBackgroundColor?: CSSProperties["backgroundColor"];
  porFavorMargin?: CSSProperties["margin"];
  porFavorFontWeight?: CSSProperties["fontWeight"];
  porFavorColor?: CSSProperties["color"];
  punto1EnfoqueContainerMargin?: CSSProperties["margin"];
  punto1EnfoqueContainerFontWeight?: CSSProperties["fontWeight"];
  punto1EnfoqueContainerColor?: CSSProperties["color"];
  inputs: inT[]
};

const AquTienesLasContainer = styled.div<{
  aquTienesLasContainerMargin?: Property.Margin;
  aquTienesLasContainerFontWeight?: Property.FontWeight;
  aquTienesLasContainerColor?: Property.Color;
}>`position: relative;
  line-height: 133%;
  display: inline-block;
  width: 37.5vw;
  flex-shrink: 0;
  margin: ${(p) => p.aquTienesLasContainerMargin};
  font-weight: ${(p) => p.aquTienesLasContainerFontWeight};
  color: ${(p) => p.aquTienesLasContainerColor};
`;
const AquTienesLasPalabrasYSusWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const MessageTextWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const Property1usermessageRoot = styled.div`
  height: 72.13541666666666vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--body-message);
  align-self: stretch;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Property1usermessage = forwardRef<HTMLDivElement, Property1usermessageType>(({
  aquTienesLasContainerMargin,
  aquTienesLasContainerFontWeight,
  aquTienesLasContainerColor,
  messageTextBackgroundColor,
  porFavorMargin,
  porFavorFontWeight,
  porFavorColor,
  punto1EnfoqueContainerMargin,
  punto1EnfoqueContainerFontWeight,
  punto1EnfoqueContainerColor,
  inputs,
}, ref) => {
  return (
    <Property1usermessageRoot ref={ref}>
      {
        inputs.map((val,i) => {
          return (
            val.from==='tutor'?
            <AquTienesLasPalabrasYSusWrapper key={String(i)}>
              <AquTienesLasContainer
                aquTienesLasContainerMargin={aquTienesLasContainerMargin}
                aquTienesLasContainerFontWeight={aquTienesLasContainerFontWeight}
                aquTienesLasContainerColor={aquTienesLasContainerColor}
              >
                {val.text}
              </AquTienesLasContainer>
            </AquTienesLasPalabrasYSusWrapper>
            :
            <MessageTextWrapper key={String(i)}>
              <Property1User
                property1UserFlexShrink="0"
                property1UserBackgroundColor="var(--colors-grey-stroke)"
                porFavorMargin="unset"
                porFavorFontWeight="unset"
                porFavorColor="var(--colors-dark-1)"
                text={val.text}
              />
            </MessageTextWrapper>
          )
        })
      }
    </Property1usermessageRoot>
  );
});

export default Property1usermessage;
