import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type StateDefaultType = {
  letsIconschartAlt?: string;

  /** Style props */
  stateDefaultCursor?: CSSProperties["cursor"];
  stateDefaultBorderW?: CSSProperties["borderWidth"];
  stateDefaultBorderS?: CSSProperties["borderStyle"];
  stateDefaultBorderC?: CSSProperties["borderColor"];
  stateDefaultPadding?: CSSProperties["padding"];
  stateDefaultBackgroundColor?: CSSProperties["backgroundColor"];

  /** Action props */
  onButttonChartButtonV12424Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const LetsIconschartAlt = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.8;
`;
const StatedefaultRoot = styled.div<{
  stateDefaultCursor?: Property.Cursor;
  stateDefaultBorderW?: Property.BorderWidth;
  stateDefaultBorderC?: Property.BorderColor;
  stateDefaultBorderS?: Property.BorderStyle;
  stateDefaultPadding?: Property.Padding;
  stateDefaultBackgroundColor?: Property.BackgroundColor;
}>`border-radius: var(--corner-radius-round);
  background-color: var(--colors-blue-1);
  --side: min(1.6666666666666667vw,3.125vh);
  width: var(--side);
  height: var(--side);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: ${(p) => p.stateDefaultCursor};
  border-width: ${(p) => p.stateDefaultBorderW};
  border-color: ${(p) => p.stateDefaultBorderC};
  border-style: ${(p) => p.stateDefaultBorderS};
  padding: ${(p) => p.stateDefaultPadding};
  background-color: ${(p) => p.stateDefaultBackgroundColor};
  & img {
    filter: var(--filters-dark-1);
    opacity: 0.8;
  }
  &:hover {
    background-color: var(--colors-darkerblue);
    & img {
      filter: var(--filters-btn-txt);
      opacity: 1;
    }
  }
`;

const StateDefault: FunctionComponent<StateDefaultType> = ({
  letsIconschartAlt,
  stateDefaultCursor,
  stateDefaultBorderW,
  stateDefaultBorderC,
  stateDefaultBorderS,
  stateDefaultPadding,
  stateDefaultBackgroundColor,
  onButttonChartButtonV12424Click,
}) => {
  return (
    <StatedefaultRoot
      stateDefaultCursor={stateDefaultCursor}
      stateDefaultBorderW={stateDefaultBorderW}
      stateDefaultBorderC={stateDefaultBorderC}
      stateDefaultBorderS={stateDefaultBorderS}
      stateDefaultPadding={stateDefaultPadding}
      stateDefaultBackgroundColor={stateDefaultBackgroundColor}
      onClick={onButttonChartButtonV12424Click}
    >
      <LetsIconschartAlt alt="" src={letsIconschartAlt} />
    </StatedefaultRoot>
  );
};

export default StateDefault;
