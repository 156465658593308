import { FunctionComponent, type CSSProperties, forwardRef } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import Property1DefaultImage from "./Property1DefaultImage";
import Property1DefaultVideo from "./Property1DefaultVideo";
import Property1NavigationPropert from "./Property1NavigationPropert";

type Property1TextProperty2DeType = {
  graphicsHolder?: string;
  graphicsHolderV?: string;
  largeBack?: string;
  src: string;
  url: string;
  descr?: string;
  publicationDate?: string;
  language: string;
  title: string;
  text: string;

  /** Style props */
  property1TextProperty2DeWidth?: CSSProperties["width"];
  publicationDateColor?: CSSProperties["color"];
  divColor?: CSSProperties["color"];
  spainColor?: CSSProperties["color"];
  cuidadoHaciendaEchaMargin?: CSSProperties["margin"];
  cuidadoHaciendaEchaColor?: CSSProperties["color"];
  aunqueBizumCuentaColor?: CSSProperties["color"];
  aunqueCuentaConContainerColor?: CSSProperties["color"];
  sourceColor?: CSSProperties["color"];
  diariodemallorcaColor?: CSSProperties["color"];
  sourceLinkColor?: CSSProperties["color"];
  httpswwwdiariodemallorcaeColor?: CSSProperties["color"];
  backButtonCursor?: CSSProperties["cursor"];
  backButtonPadding?: CSSProperties["padding"];
  backButtonBackgroundColor?: CSSProperties["backgroundColor"];
  newsColor?: CSSProperties["color"];

  /** Action props */
  onBackButtonClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const PublicationDate = styled.div<{ publicationDateColor?: Property.Color }>`
  position: relative;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  opacity: 0.8;
  color: ${(p) => p.publicationDateColor};
`;
const Div = styled.div<{ divColor?: Property.Color }>`
  position: relative;
  color: var(--colors-purple-dark);
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.divColor};
`;
const PublicationDateParent = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const Div1 = styled.div<{ spainColor?: Property.Color }>`
  position: relative;
  color: var(--colors-purple-dark);
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.spainColor};
`;
const FrameGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const CuidadoHaciendaEcha = styled.div<{
  cuidadoHaciendaEchaMargin?: Property.Margin;
  cuidadoHaciendaEchaColor?: Property.Color;
}>`flex: 1;
  position: relative;
  line-height: 3.6458333333333335vh;
  font-weight: 600;
  margin: ${(p) => p.cuidadoHaciendaEchaMargin};
  color: ${(p) => p.cuidadoHaciendaEchaColor};
`;
const Header = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--body-message-size);
`;
const AunqueCuentaCon = styled.p`
  margin-block-start: 0;
  margin-block-end: 1.5625vh;
`;
const AunqueBizumCuenta1 = styled.div<{
  aunqueCuentaConContainerColor?: Property.Color;
}>`
  align-self: stretch;
  position: relative;
  font-size: var(--buttons-btn-input-size);
  line-height: 133%;
  color: ${(p) => p.aunqueCuentaConContainerColor};
`;
const Source = styled.div<{ sourceColor?: Property.Color }>`
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.sourceColor};
`;
const Source1 = styled.div<{ diariodemallorcaColor?: Property.Color }>`
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.diariodemallorcaColor};
`;
const SourceParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const Source2 = styled.div<{ sourceLinkColor?: Property.Color }>`
  position: relative;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.sourceLinkColor};
`;
const Httpswwwdiariodemallorcae = styled.a<{
  httpswwwdiariodemallorcaeColor?: Property.Color;
}>`
  flex: 1;
  position: relative;
  text-decoration: underline;
  color: var(--colors-darkerblue);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 2.34375vh;
  color: ${(p) => p.httpswwwdiariodemallorcaeColor};
`;
const FrameContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const Property1textProperty2deRoot = styled.div<{
  property1TextProperty2DeWidth?: Property.Width;
}>`
  width: 44.72222222222222vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-21xlW) 0.0vh var(--padding-xlW);
  box-sizing: border-box;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  width: ${(p) => p.property1TextProperty2DeWidth};
`;

const Property1TextProperty2De= forwardRef<HTMLDivElement, Property1TextProperty2DeType>(({
  graphicsHolder,
  graphicsHolderV,
  src,
  url,
  descr,
  largeBack,
  publicationDate,
  language,
  title,
  text,
  property1TextProperty2DeWidth,
  publicationDateColor,
  divColor,
  spainColor,
  cuidadoHaciendaEchaMargin,
  cuidadoHaciendaEchaColor,
  aunqueBizumCuentaColor,
  aunqueCuentaConContainerColor,
  sourceColor,
  diariodemallorcaColor,
  sourceLinkColor,
  httpswwwdiariodemallorcaeColor,
  backButtonCursor,
  backButtonPadding,
  backButtonBackgroundColor,
  newsColor,
  onBackButtonClick,
}, ref) => {
  return (
    <Property1textProperty2deRoot
      property1TextProperty2DeWidth={property1TextProperty2DeWidth}
      ref={ref}
    >
      <FrameParent>
        <FrameGroup>
          <PublicationDateParent>
            <PublicationDate publicationDateColor={publicationDateColor}>
              Publication Date:
            </PublicationDate>
            <Div divColor={divColor}>{publicationDate}</Div>
          </PublicationDateParent>
          <Div1 spainColor={spainColor}>{language}</Div1>
        </FrameGroup>
        <Header>
          <CuidadoHaciendaEcha
            cuidadoHaciendaEchaMargin={cuidadoHaciendaEchaMargin}
            cuidadoHaciendaEchaColor={cuidadoHaciendaEchaColor}
          >
            {title}
          </CuidadoHaciendaEcha>
        </Header>
        {
          graphicsHolder && <Property1DefaultImage
            property1DefaultImageProp={graphicsHolder}
            property1DefaultIconWidth="40.27777777777778vw"
            property1DefaultIconHeight="42.2916666667vh"
            property1DefaultIconPosition="relative"
            property1DefaultIconFlexShrink="0"
            property1DefaultIconTop="unset"
            property1DefaultIconLeft="unset"
          />
        }
        {
          graphicsHolderV && <Property1DefaultVideo
            property1DefaultImageProp={graphicsHolderV}
            property1DefaultIconWidth="40.27777777777778vw"
            property1DefaultIconHeight="42.2916666667vh"
            property1DefaultIconPosition="relative"
            property1DefaultIconFlexShrink="0"
            property1DefaultIconTop="unset"
            property1DefaultIconLeft="unset"
          />
        }
        {
          descr && <AunqueBizumCuenta1
            aunqueCuentaConContainerColor={aunqueCuentaConContainerColor}
          >
            <AunqueCuentaCon>
              {descr}
            </AunqueCuentaCon>
          </AunqueBizumCuenta1>
        }
        <AunqueBizumCuenta1
          aunqueCuentaConContainerColor={aunqueCuentaConContainerColor}
        >
          <AunqueCuentaCon>
            {text}
          </AunqueCuentaCon>
        </AunqueBizumCuenta1>
        <FrameContainer>
          <SourceParent>
            <Source sourceColor={sourceColor}>{`Source: `}</Source>
            <Source1 diariodemallorcaColor={diariodemallorcaColor}>
              {src}
            </Source1>
          </SourceParent>
          <SourceParent>
            <Source2
              sourceLinkColor={sourceLinkColor}
            >{`Source link: `}</Source2>
            <Httpswwwdiariodemallorcae
              href={url}
              target="_blank"
              httpswwwdiariodemallorcaeColor={httpswwwdiariodemallorcaeColor}
            >
              {url}
            </Httpswwwdiariodemallorcae>
          </SourceParent>
        </FrameContainer>
      </FrameParent>
      <Property1NavigationPropert
        property1NavigationProperWidth="40.27777777777778vw"
        property1NavigationProperPosition="unset"
        property1NavigationProperTop="unset"
        property1NavigationProperLeft="unset"
        property1NavigationProperFlexShrink="unset"
        property1NavigationProperPadding="unset"
        property1NavigationProperBoxSizing="unset"
        backButtonCursor="unset"
        backButtonPadding="unset"
        backButtonBackgroundColor="var(--colors-blue-1)"
        newsColor="var(--colors-purple-dark)"
        onBackButton1Click={onBackButtonClick}
      />
    </Property1textProperty2deRoot>
  );
});

export default Property1TextProperty2De;
