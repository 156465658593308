import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type StateDefaultEmptyYesChec1Type = {
  text?: string;

  /** Style props */
  stateDefaultEmptyYesChecBoxSizing?: CSSProperties["boxSizing"];
  stateDefaultEmptyYesChecOutline?: CSSProperties["outline"];
  stateDefaultEmptyYesChecFontFamily?: CSSProperties["fontFamily"];
  stateDefaultEmptyYesChecFontSize?: CSSProperties["fontSize"];
  stateDefaultEmptyYesChecFlexShrink?: CSSProperties["flexShrink"];
  stateDefaultEmptyYesChecColor?: CSSProperties["color"];
  stateDefaultEmptyYesChecPadding?: CSSProperties["padding"];
  stateDefaultEmptyYesChecFlex?: CSSProperties["flex"];
  stateDefaultEmptyYesChecFontWeight?: CSSProperties["fontWeight"];
  stateDefaultEmptyYesChecPosition?: CSSProperties["position"];
  stateDefaultEmptyYesChecTop?: CSSProperties["top"];
  stateDefaultEmptyYesChecLeft?: CSSProperties["left"];
  stateDefaultEmptyYesChecWidth?: CSSProperties["width"];
  stateDefaultEmptyYesChecAlignSelf?: CSSProperties["alignSelf"];
  stateDefaultEmptyYesChecBackgroundColor?: CSSProperties["backgroundColor"];
  stateDefaultEmptyYesChecBorderW?: CSSProperties["borderWidth"];
  stateDefaultEmptyYesChecBorderS?: CSSProperties["borderStyle"];
  stateDefaultEmptyYesChecBorderC?: CSSProperties["borderColor"];
  textFlex?: CSSProperties["flex"];
  tutorCodeColor?: CSSProperties["color"];
  tutorCodeFlex?: CSSProperties["flex"];
  IsInput: boolean;
};

const TutorCode = styled.div<{
  tutorCodeColor?: Property.Color;
  tutorCodeFlex?: Property.Flex;
}>`position: relative;
  line-height: 2.604166666666667vh;
  color: ${(p) => p.tutorCodeColor};
  flex: ${(p) => p.tutorCodeFlex};
`;
const Text1 = styled.div<{ textFlex?: Property.Flex }>`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex: ${(p) => p.textFlex};
`;
const Text1In = styled.input<{ textFlex?: Property.Flex }>`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Onest;
  flex: ${(p) => p.textFlex};
  width: 99%;
  border-width: 0;
  background-color: var(--colors-white);
  color: var(--colors-dark-1);
  &:focus {
    outline: none;
  }
`;
const StatedefaultEmptyyesChecRoot = styled.div<{
  stateDefaultEmptyYesChecBoxSizing?: Property.BoxSizing;
  stateDefaultEmptyYesChecOutline?: Property.Outline;
  stateDefaultEmptyYesChecFontFamily?: Property.FontFamily;
  stateDefaultEmptyYesChecFontSize?: Property.FontSize;
  stateDefaultEmptyYesChecFlexShrink?: Property.FlexShrink;
  stateDefaultEmptyYesChecColor?: Property.Color;
  stateDefaultEmptyYesChecPadding?: Property.Padding;
  stateDefaultEmptyYesChecFlex?: Property.Flex;
  stateDefaultEmptyYesChecFontWeight?: Property.FontWeight;
  stateDefaultEmptyYesChecPosition?: Property.Position;
  stateDefaultEmptyYesChecTop?: Property.Top;
  stateDefaultEmptyYesChecLeft?: Property.Left;
  stateDefaultEmptyYesChecWidth?: Property.Width;
  stateDefaultEmptyYesChecAlignSelf?: Property.AlignSelf;
  stateDefaultEmptyYesChecBackgroundColor?: Property.BackgroundColor;
  stateDefaultEmptyYesChecBorderW?: Property.BorderWidth;
  stateDefaultEmptyYesChecBorderC?: Property.BorderColor;
  stateDefaultEmptyYesChecBorderS?: Property.BorderStyle;
}>`border-radius: var(--corner-radius-m);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-purple-light);
  font-family: var(--buttons-btn-input);
  flex: 1;
  box-sizing: ${(p) => p.stateDefaultEmptyYesChecBoxSizing};
  outline: ${(p) => p.stateDefaultEmptyYesChecOutline};
  font-family: ${(p) => p.stateDefaultEmptyYesChecFontFamily};
  font-size: ${(p) => p.stateDefaultEmptyYesChecFontSize};
  flex-shrink: ${(p) => p.stateDefaultEmptyYesChecFlexShrink};
  color: ${(p) => p.stateDefaultEmptyYesChecColor};
  padding: ${(p) => p.stateDefaultEmptyYesChecPadding};
  flex: ${(p) => p.stateDefaultEmptyYesChecFlex};
  font-weight: ${(p) => p.stateDefaultEmptyYesChecFontWeight};
  position: ${(p) => p.stateDefaultEmptyYesChecPosition};
  top: ${(p) => p.stateDefaultEmptyYesChecTop};
  left: ${(p) => p.stateDefaultEmptyYesChecLeft};
  width: ${(p) => p.stateDefaultEmptyYesChecWidth};
  align-self: ${(p) => p.stateDefaultEmptyYesChecAlignSelf};
  background-color: ${(p) => p.stateDefaultEmptyYesChecBackgroundColor};
  border-width: ${(p) => p.stateDefaultEmptyYesChecBorderW};
  border-color: ${(p) => p.stateDefaultEmptyYesChecBorderC};
  border-style: ${(p) => p.stateDefaultEmptyYesChecBorderS};
`;

const StateDefaultEmptyYesChec1: FunctionComponent<
  StateDefaultEmptyYesChec1Type
> = ({
  text = "Tutor Code ",
  stateDefaultEmptyYesChecBoxSizing,
  stateDefaultEmptyYesChecOutline,
  stateDefaultEmptyYesChecFontFamily,
  stateDefaultEmptyYesChecFontSize,
  stateDefaultEmptyYesChecFlexShrink,
  stateDefaultEmptyYesChecColor,
  stateDefaultEmptyYesChecPadding,
  stateDefaultEmptyYesChecFlex,
  stateDefaultEmptyYesChecFontWeight,
  stateDefaultEmptyYesChecPosition,
  stateDefaultEmptyYesChecTop,
  stateDefaultEmptyYesChecLeft,
  stateDefaultEmptyYesChecWidth,
  stateDefaultEmptyYesChecAlignSelf,
  stateDefaultEmptyYesChecBackgroundColor,
  stateDefaultEmptyYesChecBorderW,
  stateDefaultEmptyYesChecBorderC,
  stateDefaultEmptyYesChecBorderS,
  textFlex,
  tutorCodeColor,
  tutorCodeFlex,
  IsInput,
}) => {
  type AaType = {IsInput: boolean}
  const { t } = useTranslation();
  const Aa: FunctionComponent<AaType> = ({IsInput}) => {
    return IsInput===false ? 
    <Text1 textFlex={textFlex}>
      <TutorCode
        tutorCodeColor={tutorCodeColor}
        tutorCodeFlex={tutorCodeFlex}
      >
        {t(text)}
      </TutorCode>
    </Text1>
    :
    <Text1In textFlex={textFlex} placeholder={t(text)} id={text+"In"}/>
  }
  return (
    <StatedefaultEmptyyesChecRoot
      stateDefaultEmptyYesChecBoxSizing={stateDefaultEmptyYesChecBoxSizing}
      stateDefaultEmptyYesChecOutline={stateDefaultEmptyYesChecOutline}
      stateDefaultEmptyYesChecFontFamily={stateDefaultEmptyYesChecFontFamily}
      stateDefaultEmptyYesChecFontSize={stateDefaultEmptyYesChecFontSize}
      stateDefaultEmptyYesChecFlexShrink={stateDefaultEmptyYesChecFlexShrink}
      stateDefaultEmptyYesChecColor={stateDefaultEmptyYesChecColor}
      stateDefaultEmptyYesChecPadding={stateDefaultEmptyYesChecPadding}
      stateDefaultEmptyYesChecFlex={stateDefaultEmptyYesChecFlex}
      stateDefaultEmptyYesChecFontWeight={stateDefaultEmptyYesChecFontWeight}
      stateDefaultEmptyYesChecPosition={stateDefaultEmptyYesChecPosition}
      stateDefaultEmptyYesChecTop={stateDefaultEmptyYesChecTop}
      stateDefaultEmptyYesChecLeft={stateDefaultEmptyYesChecLeft}
      stateDefaultEmptyYesChecWidth={stateDefaultEmptyYesChecWidth}
      stateDefaultEmptyYesChecAlignSelf={stateDefaultEmptyYesChecAlignSelf}
      stateDefaultEmptyYesChecBackgroundColor={
        stateDefaultEmptyYesChecBackgroundColor
      }
      stateDefaultEmptyYesChecBorderW={stateDefaultEmptyYesChecBorderW}
      stateDefaultEmptyYesChecBorderC={stateDefaultEmptyYesChecBorderC}
      stateDefaultEmptyYesChecBorderS={stateDefaultEmptyYesChecBorderS}
    >
    <Aa IsInput={IsInput}/>
    </StatedefaultEmptyyesChecRoot>
  );
};

export default StateDefaultEmptyYesChec1;
