import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import StateDefaultEmptyYesChec1 from "./StateDefaultEmptyYesChec1";
import StateDefault2 from "./StateDefault2";
import styled from "styled-components";

type EditdefaultType = {
  word?: string;
  mean?: string;
  smallDelete?: string;

  /** Style props */
  editdefaultBorderBottom?: CSSProperties["borderBottom"];
  tutorCodeColor?: CSSProperties["color"];
  tutorCodeColor1?: CSSProperties["color"];
  butttonDelete40x28Cursor?: CSSProperties["cursor"];
  butttonDelete40x28BorderW?: CSSProperties["borderWidth"];
  butttonDelete40x28BorderS?: CSSProperties["borderStyle"];
  butttonDelete40x28BorderC?: CSSProperties["borderColor"];
  butttonDelete40x28BackgroundColor?: CSSProperties["backgroundColor"];

  /** Action props */
  onButttonDelete40x283Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const EditdefaultRoot = styled.div<{
  editdefaultBorderBottom?: Property.BorderBottom;
}>`
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) 0.0vw;
  gap: var(--gap-5xs);
  align-self: stretch;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: min-content;
  border-bottom: ${(p) => p.editdefaultBorderBottom};
`;

const Editdefault: FunctionComponent<EditdefaultType> = ({
  word,
  mean,
  smallDelete,
  editdefaultBorderBottom,
  butttonDelete40x28Cursor,
  butttonDelete40x28BorderW,
  butttonDelete40x28BorderC,
  butttonDelete40x28BorderS,
  butttonDelete40x28BackgroundColor,
  onButttonDelete40x283Click,
}) => {
  return (
    <EditdefaultRoot editdefaultBorderBottom={editdefaultBorderBottom}>
      <StateDefaultEmptyYesChec1
        text={word}
        stateDefaultEmptyYesChecBoxSizing="border-box"
        stateDefaultEmptyYesChecOutline="unset"
        stateDefaultEmptyYesChecFontFamily="unset"
        stateDefaultEmptyYesChecFontSize="unset"
        stateDefaultEmptyYesChecFlexShrink="0"
        stateDefaultEmptyYesChecColor="unset"
        stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
        stateDefaultEmptyYesChecFlex="1"
        stateDefaultEmptyYesChecFontWeight="unset"
        stateDefaultEmptyYesChecPosition="unset"
        stateDefaultEmptyYesChecTop="unset"
        stateDefaultEmptyYesChecLeft="unset"
        stateDefaultEmptyYesChecWidth="unset"
        stateDefaultEmptyYesChecAlignSelf="unset"
        stateDefaultEmptyYesChecBackgroundColor="unset"
        stateDefaultEmptyYesChecBorderW="unset"
        stateDefaultEmptyYesChecBorderC="unset"
        stateDefaultEmptyYesChecBorderS="unset"
        textFlex="unset"
        tutorCodeColor="var(--colors-dark-1)"
        tutorCodeFlex="unset"
        IsInput={false}
      />
      <StateDefaultEmptyYesChec1
        text={mean}
        stateDefaultEmptyYesChecBoxSizing="border-box"
        stateDefaultEmptyYesChecOutline="unset"
        stateDefaultEmptyYesChecFontFamily="unset"
        stateDefaultEmptyYesChecFontSize="unset"
        stateDefaultEmptyYesChecFlexShrink="0"
        stateDefaultEmptyYesChecColor="unset"
        stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
        stateDefaultEmptyYesChecFlex="1"
        stateDefaultEmptyYesChecFontWeight="unset"
        stateDefaultEmptyYesChecPosition="unset"
        stateDefaultEmptyYesChecTop="unset"
        stateDefaultEmptyYesChecLeft="unset"
        stateDefaultEmptyYesChecWidth="unset"
        stateDefaultEmptyYesChecAlignSelf="unset"
        stateDefaultEmptyYesChecBackgroundColor="unset"
        stateDefaultEmptyYesChecBorderW="unset"
        stateDefaultEmptyYesChecBorderC="unset"
        stateDefaultEmptyYesChecBorderS="unset"
        textFlex="unset"
        tutorCodeColor="var(--colors-dark-1)"
        tutorCodeFlex="unset"
        IsInput={false}
      />
      <StateDefault2
        smallDelete={smallDelete}
        stateDefaultFlexShrink="0"
        stateDefaultCursor={butttonDelete40x28Cursor}
        stateDefaultBorderW={butttonDelete40x28BorderW}
        stateDefaultBorderC={butttonDelete40x28BorderC}
        stateDefaultBorderS={butttonDelete40x28BorderS}
        stateDefaultBackgroundColor={butttonDelete40x28BackgroundColor}
        onButttonDelete40x28Click={onButttonDelete40x283Click}
        ChangeBGOnHover={true}
      />
    </EditdefaultRoot>
  );
};

export default Editdefault;
