import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1Default3Type = {
  /** Style props */
  property1DefaultCursor?: CSSProperties["cursor"];
  property1DefaultBorderW?: CSSProperties["borderWidth"];
  property1DefaultBorderS?: CSSProperties["borderStyle"];
  property1DefaultBorderC?: CSSProperties["borderColor"];
  property1DefaultBackgroundColor?: CSSProperties["backgroundColor"];

  /** Action props */
  onButtonDownloadClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const LargedownloadIcon = styled.img`
  position: relative;
  --side: min(1.6666666666666667vw,3.125vh);
  width: var(--side);
  height: var(--side);
  object-fit: cover;
`;
const Property1defaultRoot = styled.div<{
  property1DefaultCursor?: Property.Cursor;
  property1DefaultBorderW?: Property.BorderWidth;
  property1DefaultBorderC?: Property.BorderColor;
  property1DefaultBorderS?: Property.BorderStyle;
  property1DefaultBackgroundColor?: Property.BackgroundColor;
}>`border-radius: var(--corner-radius-l);
  background-color: var(--colors-darkerblue);
  width: 3.3333333333333335vw;
  height: 6.25vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xsH) var(--padding-11xsW);
  box-sizing: border-box;
  cursor: ${(p) => p.property1DefaultCursor};
  border-width: ${(p) => p.property1DefaultBorderW};
  border-color: ${(p) => p.property1DefaultBorderC};
  border-style: ${(p) => p.property1DefaultBorderS};
  background-color: ${(p) => p.property1DefaultBackgroundColor};
  & img {
    filter: var(--filters-btn-txt);
  }
`;

const Property1Default3: FunctionComponent<Property1Default3Type> = ({
  property1DefaultCursor,
  property1DefaultBorderW,
  property1DefaultBorderC,
  property1DefaultBorderS,
  property1DefaultBackgroundColor,
  onButtonDownloadClick,
}) => {
  return (
    <Property1defaultRoot
      property1DefaultCursor={property1DefaultCursor}
      property1DefaultBorderW={property1DefaultBorderW}
      property1DefaultBorderC={property1DefaultBorderC}
      property1DefaultBorderS={property1DefaultBorderS}
      property1DefaultBackgroundColor={property1DefaultBackgroundColor}
      onClick={onButtonDownloadClick}
    >
      <LargedownloadIcon alt="" src="/LargeDownload.svg" />
    </Property1defaultRoot>
  );
};

export default Property1Default3;
