import { FunctionComponent, useCallback } from "react";
import styled from "styled-components";
import StudyFormContainerQ from "./StudyFormContainerQ";
import { useTranslation } from "react-i18next";

const Layer1Icon = styled.img`
  position: relative;
  width: 10.555555555555555vw;
  height: 4.817708333333334vh;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
`;
const Layer1Wrapper = styled.div`
  height: 4.817708333333334vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.0vh var(--padding-xlW);
  box-sizing: border-box;
`;
const FrameChild = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  opacity: 0.8;
`;
const Dot = styled.div<{darkMode: boolean}>`
  position: absolute;
  --side: min(0.8333333333333334vw,1.5625vh);
  top: calc(50% - calc(var(--side) / 2));
  ${(p)=>p.darkMode?"right":"left"}: 0.16666666666vw;
  border-radius: 50%;
  background-color: var(--colors-btn-txt);
  width: var(--side);
  height: var(--side);
`;
const Toggle = styled.div<{darkMode: boolean}>`
  position: relative;
  border-radius: var(--corner-radius-round);
  background-color: ${(p)=>p.darkMode?"var(--colors-darkerblue)":"var(--colors-purple-light)"};
  width: 1.9444444444444444vw;
  height: min(1.11111111vw,2.08333333vh);
  cursor: pointer;
`;
const DarkMode = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  width: max-content;
  height: 2.083333333333333vh;
  flex-shrink: 0;
  color: var(--colors-btn-txt);
`;
const Additional = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xsH) 0.0vw;
  gap: var(--gap-9xs);
`;
const VectorParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-9xsW);
  gap: var(--gap-5xs);
  opacity: 0.8;
`;
const Balancesettings = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0.0vh var(--padding-5xlW);
  gap: var(--gap-base);
`;
const FrameParentRoot = styled.div`
  align-self: stretch;
  height: 93.75vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-white);
  font-family: var(--buttons-btn-input);
`;

const StudyContainer1: FunctionComponent<{
  switchDarkMode: () => void;
  darkMode: boolean;
  onSideNavigationBClick1: (e: React.MouseEvent<HTMLElement>) => void;
}> = ({switchDarkMode,darkMode,onSideNavigationBClick1}) => {

  const onToggleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    switchDarkMode()
  }, [switchDarkMode]);
  const { t } = useTranslation();

  return (
    <FrameParentRoot>
      <Layer1Wrapper>
        <Layer1Icon alt="" src="/layer-1@2x.png" />
      </Layer1Wrapper>
      <StudyFormContainerQ
        onSideNavigationBClick={onSideNavigationBClick1}
      />
      <Balancesettings>
        <VectorParent>
          <FrameChild alt="" src="/line-8@2x.png" />
          <Additional>
            <Toggle onClick={onToggleClick} darkMode={darkMode===true}>
              <Dot darkMode={darkMode===true} />
            </Toggle>
            <DarkMode>{t("DarkMode")}</DarkMode>
          </Additional>
        </VectorParent>
      </Balancesettings>
    </FrameParentRoot>
  );
};

export default StudyContainer1;
