import { FunctionComponent, type CSSProperties, useEffect, useRef, useState } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import Property1Default9 from "./Property1Default9";
import Property1LargeImage from "./Property1LargeImage";
import TeacherPageContainer from "./TeacherPageContainer";
import Property1Default6 from "../components/Property1Default6";
import ScrollHolder from "../components/ScrollHolder";
import Property1Default12 from "../components/Property1Default12";

type TutorsContainerType = {
  tutorCardsData: TCTOther[];
  ScrollObj: {
    scrollRef: React.RefObject<HTMLDivElement>;
    siblingRef: React.RefObject<HTMLDivElement>;
    scrollIndicatorHeight: string;
    scrollIndicatorTop: string;
    recalculateScrollIndicator: () => Promise<void>;
  };
  TutPageScroll: {
    scrollRef: React.RefObject<HTMLDivElement>;
    siblingRef: React.RefObject<HTMLDivElement>;
    scrollIndicatorHeight: string;
    scrollIndicatorTop: string;
    recalculateScrollIndicator: () => Promise<void>;
  };

  /** Style props */
  propColor?: CSSProperties["color"];
  propBorderW?: CSSProperties["borderWidth"];
  propBorderS?: CSSProperties["borderStyle"];
  propBorderC?: CSSProperties["borderColor"];
  propCursor?: CSSProperties["cursor"];
  propBorderRight?: CSSProperties["borderRight"];
  propBorderRight1?: CSSProperties["borderRight"];
  propBorderRight2?: CSSProperties["borderRight"];
  triggerTL: boolean;

  /** Action props */
  onRadioButtonClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onSortingItemClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onSortingItem1Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Tutors = styled.h3<{ propColor?: Property.Color }>`
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: inherit;
  color: ${(p) => p.propColor};
`;
const Rate = styled.div<{ propColor?: Property.Color }>`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 0;
  color: ${(p) => p.propColor};
`;
const Radiobutton = styled.input<{
  propBorderW?: Property.BorderWidth;
  propBorderC?: Property.BorderColor;
  propBorderS?: Property.BorderStyle;
  propCursor?: Property.Cursor;
}>`margin: 0 !important;
  position: absolute;
  top: 0.71614583333vh;
  left: 4.54861111111vw;
  border-radius: var(--corner-radius-s);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-purple-light);
  box-sizing: border-box;
  width: 1.1805555555555556vw;
  height: 2.213541666666667vh;
  z-index: 1;
  border-width: ${(p) => p.propBorderW};
  border-color: ${(p) => p.propBorderC};
  border-style: ${(p) => p.propBorderS};
  cursor: ${(p) => p.propCursor};
`;
const Sortingitem = styled.div`
  width: 5.694444444444445vw;
  height: 3.6458333333333335vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-5xs);
`;
const SortingfiltersChild = styled.div<{
  propBorderRight?: Property.BorderRight;
}>`
  position: relative;
  border-right: 0.06944444444444445vw solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 0.06944444444444445vw;
  height: 2.473958333333333vh;
  border-right: ${(p) => p.propBorderRight};
`;
const SortingfiltersChild1 = styled.div<{
  propBorderRight1?: Property.BorderRight;
}>`
  position: relative;
  border-right: 0.06944444444444445vw solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 0.06944444444444445vw;
  height: 2.473958333333333vh;
  border-right: ${(p) => p.propBorderRight1};
`;
const SortingfiltersChild2 = styled.div<{
  propBorderRight2?: Property.BorderRight;
}>`
  position: relative;
  border-right: 0.06944444444444445vw solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 0.06944444444444445vw;
  height: 2.473958333333333vh;
  border-right: ${(p) => p.propBorderRight2};
`;
const Sortingfilters = styled.div`
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-base);
  font-size: var(--body-main-size);
  color: var(--colors-purple-dark);
`;
const TutorsParent = styled.div<{isVisible: boolean,}>`
  width: 80.27777777777779vw;
  display: ${(p)=>(p.isVisible?'flex':'none')};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.0vh 0.0vw 0.0vh var(--padding-9xlW);
  box-sizing: border-box;
`;

const AnikaFourie = styled.div<{ propColor?: Property.Color }>`
  position: relative;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.propColor};
`;
const Available = styled.div`
  flex: 1;
  position: relative;
  line-height: 2.34375vh;
`;
const Text1 = styled.div`
  width: 4.027777777777778vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--body-main-size);
  color: var(--colors-blue2);
`;
const AnikaFourieParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Div = styled.div<{ propColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.propColor};
`;
const SmallstarIcon = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.8;
  filter: var(--filters-dark-1);
`;
const Parent1 = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: flex-start;
  max-height: 1vh;
`;
const Parent2 = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const SortingfiltersChild3 = styled.div<{
  propBorderRight3?: Property.BorderRight;
}>`
  position: relative;
  border-right: 0.06944444444444445vw solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 0.06944444444444445vw;
  height: 2.473958333333333vh;
  border-right: ${(p) => p.propBorderRight3};
`;
const Div1: FunctionComponent<ET2> = ({ propColor, text }) => {
  return (
    <div
      style={{
        position: 'relative',
        lineHeight: '2.604166666666667vh',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: propColor,
      }}
    >
      {text}
    </div>
  );
};
const TutorcardinfoInner = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const SortingfiltersChild4 = styled.div<{
  propBorderRight4?: Property.BorderRight;
}>`
  position: relative;
  border-right: 0.06944444444444445vw solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 0.06944444444444445vw;
  height: 2.473958333333333vh;
  border-right: ${(p) => p.propBorderRight4};
`;
type ET2 = {
  propColor: CSSProperties["color"];
  text: string;
}
type ET = {
  langMax: CSSProperties["maxWidth"];
  propColor: CSSProperties["color"];
  text: string;
  triggerTL: boolean;
}

const English: FunctionComponent<ET> = ({ langMax, propColor, text,triggerTL }) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!triggerTL){
      return
    }
    // Check if the content height exceeds maxHeight
    if (contentRef.current && 100 * contentRef.current.offsetHeight / 768 > 8) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [triggerTL]); // Depend on text, so it updates when text changes

  const scrollContent = (direction: 'up' | 'down') => {
    if (contentRef.current) {
      const step = 2.604166666666667 * window.innerHeight / 100; // Line height as scroll step
      contentRef.current.scrollTop += direction === 'down' ? step : -step;
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {isOverflow && (
        <Property1Default12
          largeBack="/ArrowHead.svg"
          property1DefaultCursor="pointer"
          property1DefaultBorderW="none"
          property1DefaultBorderS="none"
          property1DefaultBorderC="none"
          property1DefaultPadding="0"
          property1DefaultTransform={`translate(${langMax},0)`}
          property1DefaultBackgroundColor="var(--colors-blue-1)"
          onBackButton2Click1={(e: React.MouseEvent<HTMLElement>) => {e.stopPropagation(); scrollContent('up')}}
          largeBackIconTransform="rotate(90deg)"
          property1DefaultPosition="absolute"
        />
      )}
      <div
        ref={contentRef}
        style={{
          lineHeight: '2.604166666666667vh',
          maxWidth: langMax,
          color: propColor,
          overflow: 'hidden',
          maxHeight: "8vh",
        }}
      >
        {text}
      </div>
      {isOverflow && (
        <Property1Default12
        largeBack="/ArrowHead.svg"
        property1DefaultCursor="pointer"
        property1DefaultBorderW="none"
        property1DefaultBorderS="none"
        property1DefaultBorderC="none"
        property1DefaultPadding="0"
        property1DefaultTransform={`translate(${langMax},-2.604166666666667vh)`}
        property1DefaultBackgroundColor="var(--colors-blue-1)"
        onBackButton2Click1={(e: React.MouseEvent<HTMLElement>) => {e.stopPropagation(); scrollContent('down')}}
        largeBackIconTransform="rotate(-90deg)"
        property1DefaultPosition="absolute"
      />
      )}
    </div>
  );
};
const Tutorcardinfo = styled.div<{ G: Property.Gap; LP: Property.PaddingLeft; RP: Property.PaddingRight}>`
  width: 18.055555555555554vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 ${(props) => (props.RP)} 0 ${(props) => (props.LP)};
  box-sizing: border-box;
  gap: ${(props) => (props.G)};
  font-size: var(--body-main-size);
`;
const FrameGroup = styled.div<{ availible: boolean }>`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  filter: ${(p) => p.availible?"none":"var(--filters-dark-1)"};
  opacity: ${(p) => p.availible?"1":"0.3"};
`;
const Tutorcard = styled.div<{
  propBackgroundColor?: Property.BackgroundColor;
  propBorder2W?: Property.BorderWidth;
  propBorder2C?: Property.BorderColor;
  propBorder2S?: Property.BorderStyle;
  propCursor1?: Property.Cursor;
  availible: boolean;
}>`border-radius: var(--corner-radius-l);
  background-color: ${(p) => p.availible?"var(--colors-white)":"F3F3F8"};
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 25.972222222222225vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-5xsW);
  gap: var(--gap-xs);
  cursor: ${(p) => p.propCursor1};
  &:hover {
    background-color: ${(p) => p.availible?"var(--colors-blue-bg)":"var(--colors-white)"};
    border-color: ${(p) => p.availible?"var(--colors-darkerblue)":"var(--colors-purple-dark)"};
    &>div {opacity: ${(p) => p.availible?"1":"0.8"};}
  }
`;
const TutorcardParent = styled.div`
  width: 80.2777778vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;

const FrameParent = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  height: 77vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const TabTutorsInner = styled.div<{isVisible: boolean,}>`
  display: ${(p)=>(p.isVisible?'flex':'none')};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--buttons-btn-input-size);
  height: 77vh;
  overflow: hidden;
`;
const TabTutorsRoot = styled.section`
  width: 80.2777778vw;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-white);
  font-family: var(--buttons-btn-input);
`;
type comment = {
  name:string;
  date:string;
  rate:number;
  comment:string;
}
type TCTOther = {
  id:number;
  photo:string;
  name:string;
  available:boolean;
  rating:number;
  price:number;
  language:string;
  comments:comment[];
}

type TCT = {
  data: TCTOther;
  propCursor:CSSProperties["cursor"];
  triggerTL: boolean;
  onTutorCardContainerClick?:(e: React.MouseEvent<HTMLElement>) => void;
}

const TutorCardCont: FunctionComponent<TCT> = ({
  data,
  propCursor,
  onTutorCardContainerClick,
  triggerTL,
}) => {
  return (
    <Tutorcard
    //key={"Tutor"+String(data.id)}
    availible={data.available}
    propCursor1={propCursor}
    onClick={onTutorCardContainerClick}
    >
      <Property1LargeImage
        property1LargeImageProper={data.photo}
        property1LargeIconPosition="relative"
        property1LargeIconBorderRadius="var(--corner-radius-s)"
        property1LargeIconWidth="5.555555555555555vw"
        property1LargeIconHeight="10.416666666666668vh"
      />
      <FrameGroup availible={data.available}>
        <AnikaFourieParent>
          <AnikaFourie propColor="var(--colors-dark-1)">
            {data.name}
          </AnikaFourie>
          <Text1>
            <Available>{data.available ? 'Available' : 'Unavailable'}</Available>
          </Text1>
        </AnikaFourieParent>
        <Tutorcardinfo LP="0.555555556vw" RP="0.555555556vw" G="1.3vw">
          <Parent2>
            <Div propColor="var(--colors-dark-1)">{data.rating}</Div>
            <SmallstarIcon alt="" src="/SmallStar.svg" />
          </Parent2>
          <SortingfiltersChild3 />
          <TutorcardinfoInner>
            <Parent2>
              <Div1 propColor="var(--colors-dark-1)" text={"$"+data.price}></Div1>
            </Parent2>
          </TutorcardinfoInner>
          <SortingfiltersChild4 />
          <Parent1>
            <English triggerTL={triggerTL} langMax="7.36111111vw" propColor="var(--colors-dark-1)" text={data.language} />
          </Parent1>
        </Tutorcardinfo>
      </FrameGroup>
    </Tutorcard>
  );
};

const TutorsContainer: FunctionComponent<TutorsContainerType> = ({
  tutorCardsData,
  propColor,
  propBorderW,
  propBorderC,
  propBorderS,
  propCursor,
  propBorderRight,
  propBorderRight1,
  propBorderRight2,
  ScrollObj,
  TutPageScroll,
  onRadioButtonClick,
  onSortingItemClick,
  onSortingItem1Click,
  triggerTL,
}) => {
  const getChunks = (array: TCTOther[], size: number) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const [dataArr, setDataArr] = useState(tutorCardsData);
  const tutorCardChunks = getChunks(dataArr, 3);

  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(true);
  const [dataIndex, setDataIndex] = useState(0);

  const CreateShowPage = (index: number) => {
    const ShowPage = () => {
      setDataIndex(index);
      setIsVisible(true)
      setIsVisible2(false)
      TutPageScroll.recalculateScrollIndicator()
    };
    return ShowPage
  }

  const HidePage = () => {
    setIsVisible(false)
    setIsVisible2(true)
  };

  return (
    <TabTutorsRoot id="TutorsListSection">
      <TutorsParent isVisible={isVisible2}>
        <Tutors propColor={propColor}>Tutors</Tutors>
        <Sortingfilters>
          <Sortingitem>
            <Rate propColor={propColor}>Available</Rate>
            <Radiobutton
              type="checkbox"
              propBorderW={propBorderW}
              propBorderC={propBorderC}
              propBorderS={propBorderS}
              propCursor={propCursor}
              onClick={onRadioButtonClick}
            />
          </Sortingitem>
          <SortingfiltersChild propBorderRight={propBorderRight} />
          <Property1Default9
            text="Rate"
            smallArrows="/NoSort.svg"
            property1DefaultCursor="pointer"
            property1DefaultBorderW="none"
            property1DefaultBorderC="none"
            property1DefaultBorderS="none"
            property1DefaultPadding="0"
            property1DefaultBackgroundColor="transparent"
            rateColor="var(--colors-purple-dark)"
            rateDisplay="inline-block"
            onSortingItemClick1={onSortingItemClick}
          />
          <SortingfiltersChild1 propBorderRight1={propBorderRight1} />
          <Property1Default9
            text="Price"
            smallArrows="/NoSort.svg"
            property1DefaultCursor="pointer"
            property1DefaultBorderW="none"
            property1DefaultBorderC="none"
            property1DefaultBorderS="none"
            property1DefaultPadding="0"
            property1DefaultBackgroundColor="transparent"
            rateColor="var(--colors-purple-dark)"
            rateDisplay="inline-block"
            onSortingItemClick1={onSortingItem1Click}
          />
          <SortingfiltersChild2 propBorderRight2={propBorderRight2} />
          <Property1Default6
              property1DefaultWidth="9.791666666666666vw"
              property1DefaultJustifyContent="space-between"
              property1DefaultPadding="var(--padding-6xsH) var(--padding-xsW)"
              property1DefaultGap="unset"
              property1DefaultPosition="relative"
              property1DefaultTop="unset"
              property1DefaultLeft="unset"
              property1DefaultBackgroundColor="transparent"
              property1DefaultFontFamily="Onest"
              property1DefaultFontWeight="500"
              property1DefaultFontSize="1.8229166666666667vh"
              property1DefaultColor="var(--colors-purple-light)"
              property1DefaultAlignSelf="stretch"
              options={["English", "Russian"]}
              value={"English"}
            />
        </Sortingfilters>
      </TutorsParent>
      <TabTutorsInner isVisible={isVisible2}>
        <FrameParent ref={ScrollObj.siblingRef}>
          {tutorCardChunks.map((chunk, index) => (
            <TutorcardParent key={index}>
              {chunk.map((cardData, index2) => (
                <TutorCardCont
                triggerTL={triggerTL}
                key={"TutorObj"+String(index*3+index2)}
                data={cardData}
                propCursor={propCursor}
                onTutorCardContainerClick={CreateShowPage(index*3+index2)} />
              ))}
            </TutorcardParent>
          ))}
        </FrameParent>
        <ScrollHolder
          scrollHeight={ScrollObj.scrollIndicatorHeight}
          scrollIndicatorTop={ScrollObj.scrollIndicatorTop}
          ref={ScrollObj.scrollRef}
          scrollHolderHeight="77vh"
          scrollHolderAlignItems="unset"
          scrollHolderPosition="absolute"
          scrollHolderTop="22.5vh"
          scrollHolderLeft="98vw"
          scrollHolderMargin="unset"
          scrollHolderRight="unset"
          scrollHolderFlexShrink="unset"
          scrollHolderZIndex="unset"
          scrollHolderBackgroundColor="var(--colors-grey-stroke)"
          scrollBackgroundColor="var(--colors-purple-light)"
        />
      </TabTutorsInner>
      <TeacherPageContainer
        ToClose={HidePage}
        isVisible={isVisible}
        data={dataArr}
        dataIndex={dataIndex}
        largeStar="/LargeStar.svg"
        largeStar1="/LargeStar.svg"
        largeStar2="/LargeStar.svg"
        largeStar3="/LargeStar.svg"
        frame126="/frame-126@2x.png"
        smallStar="/smallstar@2x.png"
        smallStar1="/smallstar@2x.png"
        smallStar2="/smallstar@2x.png"
        smallStar3="/smallstar@2x.png"
        interfaceStar="/interface--star@2x.png"
        smallStar4="/smallstar@2x.png"
        smallStar5="/smallstar@2x.png"
        smallStar6="/smallstar@2x.png"
        smallStar7="/smallstar@2x.png"
        interfaceStar1="/interface--star@2x.png"
        smallStar8="/smallstar@2x.png"
        smallStar9="/smallstar@2x.png"
        smallStar10="/smallstar@2x.png"
        smallStar11="/smallstar@2x.png"
        interfaceStar2="/interface--star@2x.png"
        smallStar12="/smallstar@2x.png"
        smallStar13="/smallstar@2x.png"
        smallStar14="/smallstar@2x.png"
        smallStar15="/smallstar@2x.png"
        interfaceStar3="/interface--star@2x.png"
        smallStar16="/smallstar@2x.png"
        smallStar17="/smallstar@2x.png"
        smallStar18="/smallstar@2x.png"
        smallStar19="/smallstar@2x.png"
        interfaceStar4="/interface--star@2x.png"
        smallStar20="/smallstar@2x.png"
        smallStar21="/smallstar@2x.png"
        smallStar22="/smallstar@2x.png"
        smallStar23="/smallstar@2x.png"
        interfaceStar5="/interface--star@2x.png"
        propColor="var(--colors-white)"
        propColor1="var(--colors-purple-dark)"
        propColor2="var(--colors-purple-dark)"
        propColor3="var(--colors-purple-dark)"
        propColor4="var(--colors-white)"
        propColor5="var(--colors-purple-dark)"
        propColor6="var(--colors-white)"
        propColor7="var(--colors-darkerblue)"
        propColor8="var(--colors-darkerblue)"
        propBackgroundColor="var(--colors-darkerblue)"
        propColor9="var(--colors-white)"
        propColor10="var(--colors-white)"
        propColor11="var(--colors-white)"
        propColor12="var(--colors-darkerblue)"
        propColor13="var(--colors-white)"
        propColor14="var(--colors-purple-light)"
        propColor15="var(--colors-white)"
        propColor16="var(--colors-purple-dark)"
        propColor17="var(--colors-white)"
        propColor18="var(--colors-white)"
        propColor19="var(--colors-purple-dark)"
        propColor20="var(--colors-white)"
        propColor21="var(--colors-white)"
        propColor22="var(--colors-purple-dark)"
        propColor23="var(--colors-white)"
        propColor24="var(--colors-white)"
        propColor25="var(--colors-purple-dark)"
        propColor26="var(--colors-white)"
        propColor27="var(--colors-white)"
        propColor28="var(--colors-purple-dark)"
        propColor29="var(--colors-white)"
        propColor30="var(--colors-purple-dark)"
        propColor31="var(--colors-purple-dark)"
        propColor32="var(--colors-purple-dark)"
        ref={TutPageScroll.siblingRef}
      />
      <ScrollHolder
          scrollHeight={TutPageScroll.scrollIndicatorHeight}
          scrollIndicatorTop={TutPageScroll.scrollIndicatorTop}
          ref={TutPageScroll.scrollRef}
          scrollHolderHeight="84.5vh"
          scrollHolderAlignItems="unset"
          scrollHolderPosition="absolute"
          scrollHolderTop="15vh"
          scrollHolderLeft="98vw"
          scrollHolderMargin="unset"
          scrollHolderRight="unset"
          scrollHolderFlexShrink="unset"
          scrollHolderZIndex="unset"
          scrollHolderBackgroundColor="var(--colors-grey-stroke)"
          scrollBackgroundColor="var(--colors-purple-light)"
          hidden={!isVisible}
        />
    </TabTutorsRoot>
  );
};

export default TutorsContainer;
