import { FunctionComponent, type CSSProperties, forwardRef } from "react";
import { Property } from "csstype";
import Property1LeftMultiLineNo from "./Property1LeftMultiLineNo";
import styled from "styled-components";

type TitleT = {
  datetime: string;
  language: string;
  categories: string[];
  title: string;
  content?: string;
  image?: string;
  video?: string;
}
type Property1ListType = {
  News: {item:TitleT,index:number}[];
  /** Style props */
  property1ListOverflow?: CSSProperties["overflow"];
  property1ListFlexShrink?: CSSProperties["flexShrink"];
  property1ListZIndex?: CSSProperties["zIndex"];

  /** Action props */
  GenShowTitle: (index:number) => () => void;
};

const Property1listRoot = styled.div<{
  property1ListOverflow?: Property.Overflow;
  property1ListFlexShrink?: Property.FlexShrink;
  property1ListZIndex?: Property.ZIndex;
}>`width: 46.38888888888889vw;
  height: 78.5156vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.0vh var(--padding-xsW);
  box-sizing: border-box;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow: ${(p) => p.property1ListOverflow};
  flex-shrink: ${(p) => p.property1ListFlexShrink};
  z-index: ${(p) => p.property1ListZIndex};
`;

const Property1List = forwardRef<HTMLDivElement, Property1ListType>(({
  News,
  property1ListOverflow,
  property1ListFlexShrink,
  property1ListZIndex,
  GenShowTitle,
}, ref) => {
  return (
    <Property1listRoot
      ref={ref}
      property1ListOverflow={property1ListOverflow}
      property1ListFlexShrink={property1ListFlexShrink}
      property1ListZIndex={property1ListZIndex}
    >
      {
        News.map((Title)=>{
          return (
            <Property1LeftMultiLineNo
              prop={Title.item.datetime}
              spain={Title.item.language}
              title={Title.item.title}
              text={Title.item.content?Title.item.content.slice(0, 100):""}
              property1LeftMultiLineNoFlexShrink="unset"
              property1LeftMultiLineNoCursor="pointer"
              property1LeftMultiLineNoBackgroundColor="var(--colors-white)"
              property1LeftMultiLineNoBorderW="0.130208333vh 0.0694444444vw"
              property1LeftMultiLineNoBorderS="solid"
              property1LeftMultiLineNoBorderC="var(--colors-grey-stroke)"
              cuidadoHaciendaEchaColor="var(--colors-dark-1)"
              aunqueBizumCuentaColor="var(--colors-dark-1)"
              frameDivBorderLeft="0.06944444444444445vw solid var(--colors-grey-stroke)"
              divColor="var(--colors-purple-dark)"
              spainPosition="relative"
              spainFontSize="1.5625vh"
              spainLineHeight="2.604166666666667vh"
              spainFontFamily="Onest"
              spainColor="var(--colors-purple-dark)"
              spainTextAlign="left"
              spainDisplay="inline-block"
              spainOverflow="hidden"
              spainTextOverflow="ellipsis"
              spainWhiteSpace="nowrap"
              spainWidth="2.569444444444444vw"
              spainHeight="2.604166666666667vh"
              spainFlexShrink="0"
              spainMargin="unset"
              onNewsCardListContainerClick={GenShowTitle(Title.index)}
            />
          )
        })
      }
    </Property1listRoot>
  );
});

export default Property1List;
