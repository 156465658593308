import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import StateHoverIconno from "./StateHoverIconno";

type Property1Default8Type = {
  largeStar?: string;
  largeStar1?: string;
  largeStar2?: string;
  largeStar3?: string;
  frame126?: string;

  /** Style props */
  anikaFourieColor?: CSSProperties["color"];
  anikaFourieMargin?: CSSProperties["margin"];
  rateColor?: CSSProperties["color"];
  divColor?: CSSProperties["color"];
  languageColor?: CSSProperties["color"];
  afrikaansColor?: CSSProperties["color"];
  priceColor?: CSSProperties["color"];
  hourColor?: CSSProperties["color"];
  buttonSecondaryCursor?: CSSProperties["cursor"];
  buttonSecondaryBackgroundColor?: CSSProperties["backgroundColor"];
  saveSessionColor?: CSSProperties["color"];
  saveSessionDisplay?: CSSProperties["display"];
  buttonSecondaryCursor1?: CSSProperties["cursor"];
  buttonSecondaryBackgroundColor1?: CSSProperties["backgroundColor"];
  saveSessionColor1?: CSSProperties["color"];
  saveSessionDisplay1?: CSSProperties["display"];
  buttonSecondaryCursor2?: CSSProperties["cursor"];
  buttonSecondaryBackgroundColor2?: CSSProperties["backgroundColor"];
  saveSessionDisplay2?: CSSProperties["display"];
  data: TCTOther[];
  dataIndex: number;

  /** Action props */
  onButtonSecondary8Click1?: () => void;
  onButtonSecondary9Click1?: () => void;
  onButtonSecondary10Click1?: () => void;
};

const AnikaFourie = styled.div<{
  anikaFourieColor?: Property.Color;
  anikaFourieMargin?: Property.Margin;
}>`position: relative;
  line-height: 3.125vh;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.anikaFourieColor};
  margin: ${(p) => p.anikaFourieMargin};
`;
const Available = styled.div`
  position: relative;
  font-size: var(--body-main-size);
  line-height: 2.34375vh;
  color: var(--colors-blue2);
  display: inline-block;
  width: 4.027777777777778vw;
  height: 2.34375vh;
  flex-shrink: 0;
`;
const Name1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
  font-size: var(--body-message-size);
  color: var(--colors-dark-3);
`;
const Rate1 = styled.div<{ rateColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 2.361111111111111vw;
  height: 3.125vh;
  flex-shrink: 0;
  color: ${(p) => p.rateColor};
`;
const LargestarIcon = styled.div<{percent: string,}>`
  position: relative;
  --side: min(1.6666666666666667vw,3.125vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  background: linear-gradient(
    to right,
    var(--colors-darkerblue) 0%,
    var(--colors-darkerblue) ${(p) => p.percent},
    var(--colors-purple-light) ${(p) => p.percent},
    var(--colors-purple-light) 100%
  );
  -webkit-mask: url('LargeStar.svg') no-repeat center / contain;
  mask: url('LargeStar.svg') no-repeat center / contain;
  display: inline-block;
  mask-composite: exclude;
`;
const Stars = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const Div = styled.div<{ divColor?: Property.Color }>`
  align-self: stretch;
  position: relative;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 1.5277777777777777vw;
  flex-shrink: 0;
  color: ${(p) => p.divColor};
`;
const Rate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Language = styled.div<{ languageColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.languageColor};
`;
const Afrikaans = styled.div<{ afrikaansColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  color: var(--colors-dark-1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.afrikaansColor};
`;
const Languages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Language1 = styled.div<{ priceColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.priceColor};
`;
const Afrikaans1 = styled.div<{ hourColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  color: var(--colors-dark-1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.hourColor};
`;
const Buttons = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const NameParent = styled.div`
  flex: 1;
  height: 27.604166666666668vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
const Property1defaultRoot = styled.div`
  width: 29.930555555555554vw;
  height: 27.604166666666668vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-purple-dark);
  font-family: var(--buttons-btn-input);
`;

type TCTOther = {
  id:number;
  photo:string;
  name:string;
  available:boolean;
  rating:number;
  price:number;
  language:string;
}

const Property1Default8: FunctionComponent<Property1Default8Type> = ({
  anikaFourieColor,
  anikaFourieMargin,
  rateColor,
  divColor,
  languageColor,
  afrikaansColor,
  priceColor,
  hourColor,
  data,
  dataIndex,
}) => {
  let ratingPool = data[dataIndex].rating * 100;
  let S1 = ratingPool>=100?100:ratingPool;
  ratingPool = ratingPool>=100?ratingPool-100:0;
  let S2 = ratingPool>=100?100:ratingPool;
  ratingPool = ratingPool>=100?ratingPool-100:0;
  let S3 = ratingPool>=100?100:ratingPool;
  ratingPool = ratingPool>=100?ratingPool-100:0;
  let S4 = ratingPool>=100?100:ratingPool;
  ratingPool = ratingPool>=100?ratingPool-100:0;
  let S5 = ratingPool>=100?100:ratingPool;
  ratingPool = ratingPool>=100?ratingPool-100:0;
  return (
    <Property1defaultRoot>
      <NameParent>
        <Name1>
          <AnikaFourie
            anikaFourieColor={anikaFourieColor}
            anikaFourieMargin={anikaFourieMargin}
          >
            {data[dataIndex].name}
          </AnikaFourie>
          <Available>{data[dataIndex].available?'Available':'Unavailable'}</Available>
        </Name1>
        <Rate>
          <Rate1 rateColor={rateColor}>Rate:</Rate1>
          <Stars>
            <LargestarIcon percent={String(S1)+"%"} />
            <LargestarIcon percent={String(S2)+"%"} />
            <LargestarIcon percent={String(S3)+"%"} />
            <LargestarIcon percent={String(S4)+"%"} />
            <LargestarIcon percent={String(S5)+"%"} />
          </Stars>
          <Div divColor={divColor}>{data[dataIndex].rating}</Div>
        </Rate>
        <Languages>
          <Language languageColor={languageColor}>Language:</Language>
          <Afrikaans afrikaansColor={afrikaansColor}>{data[dataIndex].language}</Afrikaans>
        </Languages>
        <Languages>
          <Language1 priceColor={priceColor}>Price:</Language1>
          <Afrikaans1 hourColor={hourColor}>${data[dataIndex].price}/hour</Afrikaans1>
        </Languages>
        <Buttons>
          <StateHoverIconno
            saveSession="Any secondary action"
            stateHoverIconnoJustifyContent="flex-start"
            stateHoverIconnoWidth="unset"
            stateHoverIconnoZIndex="unset"
            stateHoverIconnoBackgroundColor="unset"
            stateHoverIconnoCursor="pointer"
            stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
            stateHoverIconnoBorderC="var(--colors-darkerblue)"
            stateHoverIconnoBorderS="solid"
            stateHoverIconnoHeight="4.166666666666666vh"
            stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
            stateHoverIconnoBoxSizing="border-box"
            stateHoverIconnoPosition="unset"
            stateHoverIconnoTop="unset"
            stateHoverIconnoLeft="unset"
            saveSessionColor="var(--colors-darkerblue)"
            saveSessionDisplay="inline-block"
          />
          <StateHoverIconno
            saveSession="Contact tutor"
            stateHoverIconnoJustifyContent="flex-start"
            stateHoverIconnoWidth="unset"
            stateHoverIconnoZIndex="unset"
            stateHoverIconnoBackgroundColor="unset"
            stateHoverIconnoCursor="pointer"
            stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
            stateHoverIconnoBorderC="var(--colors-darkerblue)"
            stateHoverIconnoBorderS="solid"
            stateHoverIconnoHeight="4.166666666666666vh"
            stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
            stateHoverIconnoBoxSizing="border-box"
            stateHoverIconnoPosition="unset"
            stateHoverIconnoTop="unset"
            stateHoverIconnoLeft="unset"
            saveSessionColor="var(--colors-darkerblue)"
            saveSessionDisplay="inline-block"
          />
          <StateHoverIconno
            saveSession="Schedule lesson"
            stateHoverIconnoJustifyContent="flex-start"
            stateHoverIconnoWidth="unset"
            stateHoverIconnoZIndex="unset"
            stateHoverIconnoBackgroundColor="var(--colors-darkerblue)"
            stateHoverIconnoCursor="pointer"
            stateHoverIconnoBorderW="unset"
            stateHoverIconnoBorderC="unset"
            stateHoverIconnoBorderS="unset"
            stateHoverIconnoHeight="4.166666666666666vh"
            stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
            stateHoverIconnoBoxSizing="border-box"
            stateHoverIconnoPosition="unset"
            stateHoverIconnoTop="unset"
            stateHoverIconnoLeft="unset"
            saveSessionColor="var(--colors-btn-txt)"
            saveSessionDisplay="inline-block"
          />
        </Buttons>
      </NameParent>
    </Property1defaultRoot>
  );
};

export default Property1Default8;
