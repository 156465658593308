import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import Property1Default from "./Property1Default";

type Property1TextResolvednoType = {
  text: string;
  date: string;
  weekday: string;
  timestart: string;
  tags: string[];
  /** Style props */
  property1TextResolvednoBackgroundColor?: CSSProperties["backgroundColor"];
  monday20NovemberColor?: CSSProperties["color"];
  chipBackgroundColor?: CSSProperties["backgroundColor"];
  allColor?: CSSProperties["color"];
  chipBackgroundColor1?: CSSProperties["backgroundColor"];
  allColor1?: CSSProperties["color"];
  divColor?: CSSProperties["color"];
  youAcceptedFirstColor?: CSSProperties["color"];
};

const Monday20November = styled.div<{ monday20NovemberColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10.0vw;
  height: 2.604166666666667vh;
  flex-shrink: 0;
  color: ${(p) => p.monday20NovemberColor};
`;
const ChipParent = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Monday20NovemberParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const Div = styled.div<{ divColor?: Property.Color }>`
  align-self: stretch;
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 2.604166666666667vh;
  flex-shrink: 0;
  color: ${(p) => p.divColor};
`;
const Wrapper = styled.div`
  align-self: stretch;
  width: 10.0vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const YouAcceptedFirst = styled.div<{ youAcceptedFirstColor?: Property.Color }>`
  align-self: stretch;
  position: relative;
  line-height: 2.604166666666667vh;
  color: ${(p) => p.youAcceptedFirstColor};
`;
const YouAcceptedFirstLessonWithWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.0vh var(--padding-5xlW);
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const Property1textResolvednoInner = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--buttons-btn-input-size);
`;
const Property1textResolvednoRoot = styled.div<{
  property1TextResolvednoBackgroundColor?: Property.BackgroundColor;
}>`
  border-radius: var(--corner-radius-l);
  background-color: var(--colors-blue-bg);
  width: 63.888888888888886vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-baseH) var(--padding-baseW);
  box-sizing: border-box;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  background-color: ${(p) => p.property1TextResolvednoBackgroundColor};
`;

const Property1TextResolvedno: FunctionComponent<
  Property1TextResolvednoType
> = ({
  property1TextResolvednoBackgroundColor,
  monday20NovemberColor,
  chipBackgroundColor,
  allColor,
  chipBackgroundColor1,
  allColor1,
  divColor,
  youAcceptedFirstColor,
  text,
  date,
  weekday,
  timestart,
  tags,
}) => {
  return (
    <Property1textResolvednoRoot
      property1TextResolvednoBackgroundColor={
        property1TextResolvednoBackgroundColor
      }
    >
      <Monday20NovemberParent>
        <Monday20November monday20NovemberColor={monday20NovemberColor}>
          {weekday}, {date}
        </Monday20November>
        <ChipParent>
          {
            tags.map((tag)=>(
              <Property1Default
                text={tag}
                property1DefaultFlexShrink="0"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultBoxSizing="border-box"
                property1DefaultCursor="unset"
                property1DefaultBorderW="unset"
                property1DefaultBorderC="unset"
                property1DefaultBorderS="unset"
                property1DefaultBackgroundColor="var(--colors-blue-1)"
                allDisplay="inline-block"
                allAlignItems="unset"
                allJustifyContent="unset"
                allWidth="max-content"
                allHeight="1.5625"
                allFlexShrink="unset"
                allColor="var(--colors-dark-1)"
              />
            ))
          }
        </ChipParent>
      </Monday20NovemberParent>
      <Property1textResolvednoInner>
        <FrameParent>
          <Wrapper>
            <Div divColor={divColor}>{timestart}</Div>
          </Wrapper>
          <YouAcceptedFirstLessonWithWrapper>
            <YouAcceptedFirst youAcceptedFirstColor={youAcceptedFirstColor}>
              {text}
            </YouAcceptedFirst>
          </YouAcceptedFirstLessonWithWrapper>
        </FrameParent>
      </Property1textResolvednoInner>
    </Property1textResolvednoRoot>
  );
};

export default Property1TextResolvedno;
