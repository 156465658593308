import { FunctionComponent, useCallback } from "react";
import StateDefaultNotificationno from "./StateDefaultNotificationno";
import styled from "styled-components";

const TabnavigationRoot = styled.nav`
  margin: 0;
  align-self: stretch;
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const AlertsTabNavigation: FunctionComponent<{
  TLScrollRec: () => Promise<void>;
  TLTrig: ()=> void;
}> = ({TLScrollRec,TLTrig,}) => {
  const onTabPrimaryB3Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      showElement("LearnSection");
      hideElement("TutorsListSection");
      hideElement("AlertSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );

  const onTabPrimaryB4Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      hideElement("LearnSection");
      showElement("TutorsListSection");
      hideElement("AlertSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
      TLScrollRec();
      TLTrig();
    },
    [TLScrollRec,TLTrig]
  );

  const onTabPrimaryB5Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      hideElement("LearnSection");
      hideElement("TutorsListSection");
      showElement("AlertSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );

  return (
    <TabnavigationRoot>
      <StateDefaultNotificationno
        smallFlashCards="/MyLearnAct.svg"
        text="My Learning Activities"
        stateDefaultNotificationnCursor="pointer"
        stateDefaultNotificationnBorderW="none"
        stateDefaultNotificationnBorderS="none"
        stateDefaultNotificationnBorderC="none"
        stateDefaultNotificationnBackgroundColor="transparent"
        stateDefaultNotificationnWidth="max-content"
        stateDefaultNotificationnFlexShrink="0"
        stateDefaultNotificationnBoxSizing="border-box"
        stateDefaultNotificationnBorderBottom="unset"
        smallFlashCardsIconOverflow="unset"
        smallFlashCardsIconFlexShrink="unset"
        smallFlashCardsIconOpacity="unset"
        flashCardsDisplay="inline-block"
        flashCardsColor="var(--colors-dark-1)"
        HasClass="selected"
        onTabPrimaryB3Click={onTabPrimaryB3Click}
      />
      {false && <StateDefaultNotificationno
        smallFlashCards="/Tutors.svg"
        text="Tutors"
        stateDefaultNotificationnCursor="pointer"
        stateDefaultNotificationnBorderW="none"
        stateDefaultNotificationnBorderC="none"
        stateDefaultNotificationnBorderS="none"
        stateDefaultNotificationnBackgroundColor="transparent"
        stateDefaultNotificationnWidth="max-content"
        stateDefaultNotificationnFlexShrink="0"
        stateDefaultNotificationnBoxSizing="border-box"
        stateDefaultNotificationnBorderBottom="unset"
        smallFlashCardsIconOverflow="hidden"
        smallFlashCardsIconFlexShrink="0"
        smallFlashCardsIconOpacity="unset"
        flashCardsDisplay="inline-block"
        flashCardsColor="var(--colors-dark-1)"
        onTabPrimaryB3Click={onTabPrimaryB4Click}
      />}
      {false && <StateDefaultNotificationno
        smallFlashCards="/MyAlerts.svg"
        text="My Alerts"
        stateDefaultNotificationnCursor="pointer"
        stateDefaultNotificationnBorderW="none"
        stateDefaultNotificationnBorderC="none"
        stateDefaultNotificationnBorderS="none"
        stateDefaultNotificationnBackgroundColor="transparent"
        stateDefaultNotificationnWidth="max-content"
        stateDefaultNotificationnFlexShrink="0"
        stateDefaultNotificationnBoxSizing="border-box"
        stateDefaultNotificationnBorderBottom="unset"
        smallFlashCardsIconOverflow="hidden"
        smallFlashCardsIconFlexShrink="0"
        smallFlashCardsIconOpacity="unset"
        flashCardsDisplay="inline-block"
        flashCardsColor="var(--colors-dark-1)"
        onTabPrimaryB3Click={onTabPrimaryB5Click}
      />}
    </TabnavigationRoot>
  );
};

export default AlertsTabNavigation;
