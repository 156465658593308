import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type HoverDisabledType = {
  smallCheck?: string;

  /** Style props */
  hoverDisabledMargin?: CSSProperties["margin"];
  hoverDisabledPosition?: CSSProperties["position"];
  hoverDisabledTop?: CSSProperties["top"];
  hoverDisabledRight?: CSSProperties["right"];
  hoverDisabledFlexShrink?: CSSProperties["flexShrink"];
  hoverDisabledZIndex?: CSSProperties["zIndex"];
  hoverDisabledBackgroundColor?: CSSProperties["backgroundColor"];

  onCheckClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const SmallcheckIcon = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const HoverdisabledRoot = styled.div<{
  hoverDisabledMargin?: Property.Margin;
  hoverDisabledPosition?: Property.Position;
  hoverDisabledTop?: Property.Top;
  hoverDisabledRight?: Property.Right;
  hoverDisabledFlexShrink?: Property.FlexShrink;
  hoverDisabledZIndex?: Property.ZIndex;
  hoverDisabledBackgroundColor?: Property.BackgroundColor;
}>`border-radius: var(--corner-radius-m);
  background-color: var(--colors-blue-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xsH) var(--padding-9xsW);
  cursor: pointer;
  margin: ${(p) => p.hoverDisabledMargin};
  position: ${(p) => p.hoverDisabledPosition};
  top: ${(p) => p.hoverDisabledTop};
  right: ${(p) => p.hoverDisabledRight};
  flex-shrink: ${(p) => p.hoverDisabledFlexShrink};
  z-index: ${(p) => p.hoverDisabledZIndex};
  background-color: ${(p) => p.hoverDisabledBackgroundColor};
  & img{filter: var(--filters-purple-light);}
  &:hover{
    & img{filter: var(--filters-dark-1);}
  }
  `;

const HoverDisabled: FunctionComponent<HoverDisabledType> = ({
  smallCheck,
  hoverDisabledMargin,
  hoverDisabledPosition,
  hoverDisabledTop,
  hoverDisabledRight,
  hoverDisabledFlexShrink,
  hoverDisabledZIndex,
  hoverDisabledBackgroundColor,
  onCheckClick,
}) => {
  return (
    <HoverdisabledRoot
      hoverDisabledMargin={hoverDisabledMargin}
      hoverDisabledPosition={hoverDisabledPosition}
      hoverDisabledTop={hoverDisabledTop}
      hoverDisabledRight={hoverDisabledRight}
      hoverDisabledFlexShrink={hoverDisabledFlexShrink}
      hoverDisabledZIndex={hoverDisabledZIndex}
      hoverDisabledBackgroundColor={hoverDisabledBackgroundColor}
      onClick={onCheckClick}
    >
      <SmallcheckIcon alt="" src={smallCheck} />
    </HoverdisabledRoot>
  );
};

export default HoverDisabled;
