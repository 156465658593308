import { FunctionComponent, useState, useCallback } from "react";
import StudyContainer1Quiz from "../components/StudyContainer1Quiz";
import styled from "styled-components";
import QuizSection from '../components/QuizSection';
import QuizSection2 from '../components/QuizSection2';

const Navigationmainb = styled.nav`
  margin: 0;
  background-color: var(--colors-dark-sidepanel);
  width: 16.38888888888889vw;
  height: 100.0vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) 0.0vw;
  box-sizing: border-box;
`;
const Page1Study = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const BlocksOfPages = styled.main`
  width: 83.61111111111111vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const LayoutBlocksLightRoot = styled.div`
  position: relative;
  background-color: var(--colors-grey-bg);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  & ::selection {
    background: var(--colors-green);
  }
`;

type LBL = {
  darkMode: boolean;
  switchDarkMode: ()=>void;
  QT: "None" | "Initial" | "GoalCreate" | "GoalChoose" | "TestFinal";
}

const LayoutBlocksLightQuiz: FunctionComponent<LBL> = (InputData) => {
  const onSideNavigationBClick1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      showElement("StudySection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );


  
  return (
    <LayoutBlocksLightRoot>
      <Navigationmainb>
        <StudyContainer1Quiz
          switchDarkMode={InputData.switchDarkMode}
          darkMode={InputData.darkMode}
          onSideNavigationBClick1={onSideNavigationBClick1}
        />
      </Navigationmainb>
      <BlocksOfPages>
        <Page1Study id="TestSection" style={{paddingTop:"5vh"}}>
          {
            (()=>{switch(InputData.QT) {
              case "None":
                return <></>
              case "Initial":
                return <QuizSection2 />
              case "GoalCreate":
                return <QuizSection2 />
              case "GoalChoose":
                return <QuizSection2 />
              case "TestFinal":
                return <QuizSection />
              default:
                return <></>
            }})()
          }
        </Page1Study>
      </BlocksOfPages>
    </LayoutBlocksLightRoot>
  );
};

export default LayoutBlocksLightQuiz;
