import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1DefaultType = {
  text?: string;

  /** Style props */
  property1DefaultFlexShrink?: CSSProperties["flexShrink"];
  property1DefaultPadding?: CSSProperties["padding"];
  property1DefaultBoxSizing?: CSSProperties["boxSizing"];
  property1DefaultCursor?: CSSProperties["cursor"];
  property1DefaultBorderW?: CSSProperties["borderWidth"];
  property1DefaultBorderS?: CSSProperties["borderStyle"];
  property1DefaultBorderC?: CSSProperties["borderColor"];
  property1DefaultBackgroundColor?: CSSProperties["backgroundColor"];
  allDisplay?: CSSProperties["display"];
  allAlignItems?: CSSProperties["alignItems"];
  allJustifyContent?: CSSProperties["justifyContent"];
  allWidth?: CSSProperties["width"];
  allHeight?: CSSProperties["height"];
  allFlexShrink?: CSSProperties["flexShrink"];
  allColor?: CSSProperties["color"];
  HasClass?: string;

  /** Action props */
  onChipClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const All = styled.div<{
  allDisplay?: Property.Display;
  allAlignItems?: Property.AlignItems;
  allJustifyContent?: Property.JustifyContent;
  allWidth?: Property.Width;
  allHeight?: Property.Height;
  allFlexShrink?: Property.FlexShrink;
  allColor?: Property.Color;
}>`position: relative;
  line-height: 1.5625vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 2.083333333333333vh;
  flex-shrink: 0;
  display: ${(p) => p.allDisplay};
  align-items: ${(p) => p.allAlignItems};
  justify-content: ${(p) => p.allJustifyContent};
  width: ${(p) => p.allWidth}vw;
  height: ${(p) => p.allHeight}vh;
  flex-shrink: ${(p) => p.allFlexShrink};
  color: ${(p) => p.allColor};
`;
const Property1defaultRoot = styled.div<{
  property1DefaultFlexShrink?: Property.FlexShrink;
  property1DefaultPadding?: Property.Padding;
  property1DefaultBoxSizing?: Property.BoxSizing;
  property1DefaultCursor?: Property.Cursor;
  property1DefaultBorderW?: Property.BorderWidth;
  property1DefaultBorderC?: Property.BorderColor;
  property1DefaultBorderS?: Property.BorderStyle;
  property1DefaultBackgroundColor?: Property.BackgroundColor;
}>`border-radius: var(--corner-radius-round);
  background-color: var(--colors-blue-1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xsH) var(--padding-xsW);
  text-align: center;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  flex-shrink: ${(p) => p.property1DefaultFlexShrink};
  padding: ${(p) => p.property1DefaultPadding};
  box-sizing: ${(p) => p.property1DefaultBoxSizing};
  cursor: ${(p) => p.property1DefaultCursor};
  border-width: ${(p) => p.property1DefaultBorderW};
  border-color: ${(p) => p.property1DefaultBorderC};
  border-style: ${(p) => p.property1DefaultBorderS};
  background-color: ${(p) => p.property1DefaultBackgroundColor};
  &.selected {
    background-color: var(--colors-darkerblue);
    & div {color: var(--colors-btn-txt);}
  }
`;

const Property1Default: FunctionComponent<Property1DefaultType> = ({
  text = "All",
  property1DefaultFlexShrink,
  property1DefaultPadding,
  property1DefaultBoxSizing,
  property1DefaultCursor,
  property1DefaultBorderW,
  property1DefaultBorderC,
  property1DefaultBorderS,
  property1DefaultBackgroundColor,
  allDisplay,
  allAlignItems,
  allJustifyContent,
  allWidth,
  allHeight,
  allFlexShrink,
  allColor,
  HasClass,
  onChipClick,
}) => {
  return (
    <Property1defaultRoot
      property1DefaultFlexShrink={property1DefaultFlexShrink}
      property1DefaultPadding={property1DefaultPadding}
      property1DefaultBoxSizing={property1DefaultBoxSizing}
      property1DefaultCursor={property1DefaultCursor}
      property1DefaultBorderW={property1DefaultBorderW}
      property1DefaultBorderC={property1DefaultBorderC}
      property1DefaultBorderS={property1DefaultBorderS}
      property1DefaultBackgroundColor={property1DefaultBackgroundColor}
      className={HasClass}
      onClick={onChipClick}
    >
      <All
        allDisplay={allDisplay}
        allAlignItems={allAlignItems}
        allJustifyContent={allJustifyContent}
        allWidth={allWidth}
        allHeight={allHeight}
        allFlexShrink={allFlexShrink}
        allColor={allColor}
      >
        {text}
      </All>
    </Property1defaultRoot>
  );
};

export default Property1Default;
