import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1DefaultImageType = {
  property1DefaultImageProp?: string;

  /** Style props */
  property1DefaultIconWidth?: CSSProperties["width"];
  property1DefaultIconHeight?: CSSProperties["height"];
  property1DefaultIconPosition?: CSSProperties["position"];
  property1DefaultIconFlexShrink?: CSSProperties["flexShrink"];
  property1DefaultIconTop?: CSSProperties["top"];
  property1DefaultIconLeft?: CSSProperties["left"];
};

const Property1defaultIconRoot = styled.video<{
  property1DefaultIconWidth?: Property.Width;
  property1DefaultIconHeight?: Property.Height;
  property1DefaultIconPosition?: Property.Position;
  property1DefaultIconFlexShrink?: Property.FlexShrink;
  property1DefaultIconTop?: Property.Top;
  property1DefaultIconLeft?: Property.Left;
}>`border-radius: var(--corner-radius-xl);
  width: 27.77777777777778vw;
  height: 29.166666666666668vh;
  overflow: hidden;
  object-fit: cover;
  width: ${(p) => p.property1DefaultIconWidth};
  height: ${(p) => p.property1DefaultIconHeight};
  position: ${(p) => p.property1DefaultIconPosition};
  flex-shrink: ${(p) => p.property1DefaultIconFlexShrink};
  top: ${(p) => p.property1DefaultIconTop};
  left: ${(p) => p.property1DefaultIconLeft};
`;

const Property1DefaultImage: FunctionComponent<Property1DefaultImageType> = ({
  property1DefaultImageProp,
  property1DefaultIconWidth,
  property1DefaultIconHeight,
  property1DefaultIconPosition,
  property1DefaultIconFlexShrink,
  property1DefaultIconTop,
  property1DefaultIconLeft,
}) => {
  return (
    <Property1defaultIconRoot
      src={property1DefaultImageProp}
      property1DefaultIconWidth={property1DefaultIconWidth}
      property1DefaultIconHeight={property1DefaultIconHeight}
      property1DefaultIconPosition={property1DefaultIconPosition}
      property1DefaultIconFlexShrink={property1DefaultIconFlexShrink}
      property1DefaultIconTop={property1DefaultIconTop}
      property1DefaultIconLeft={property1DefaultIconLeft}
      controls={true}
    >
      Your browser does not support the video.
    </Property1defaultIconRoot>
  );
};

export default Property1DefaultImage;
