import { FunctionComponent, type CSSProperties, useState } from "react";
import { Property } from "csstype";
import StateDefaultEmptyYesChec1 from "./StateDefaultEmptyYesChec1";
import Property1Default15 from "./Property1Default15";
import StateDefault2 from "./StateDefault2";
import styled from "styled-components";
import styles from "./FrameComponent.module.css";
import HoverDisabled1 from "./HoverDisabled1";

type EditeditableType = {
  tutorCode?: string;
  back?: string;
  smallEdit?: string;
  smallDelete?: string;
  itemId: string;
  handleRemoveItem:(itemId: string)=>void;
  handleEditItem:(itemId: string, newW: string, newD: string)=>void;

  /** Style props */
  editeditableAlignItems?: CSSProperties["alignItems"];
  editeditableBackgroundColor?: CSSProperties["backgroundColor"];
  butttonEdit24x24Cursor?: CSSProperties["cursor"];
  butttonDelete40x28Cursor?: CSSProperties["cursor"];
};

const EditeditableRoot = styled.div<{
  editeditableAlignItems?: Property.AlignItems;
  editeditableBackgroundColor?: Property.BackgroundColor;
}>`border-radius: var(--corner-radius-m);
  background-color: var(--colors-white);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-5xsW);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  align-self: stretch;
  align-items: ${(p) => p.editeditableAlignItems};
  background-color: ${(p) => p.editeditableBackgroundColor};
`;

const Editeditable: FunctionComponent<EditeditableType> = ({
  tutorCode,
  back,
  editeditableAlignItems,
  editeditableBackgroundColor,
  butttonEdit24x24Cursor,
  butttonDelete40x28Cursor,
  itemId,
  handleRemoveItem,
  handleEditItem,
}) => {
  const [isEditable, setIsEditable] = useState(false)
  const [word, setWord] = useState(tutorCode)
  const [mean, setMean] = useState(back)

  const Edit = () => {
    setIsEditable(true)
  }
  const Save = () => {
    word && mean && setIsEditable(false)
    word && mean && handleEditItem(itemId, word, mean)
  }

  const onWordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWord(e.target.value)
  }
  const onMeanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMean(e.target.value)
  }

  return (
    <EditeditableRoot
      editeditableAlignItems={editeditableAlignItems}
      editeditableBackgroundColor={editeditableBackgroundColor}
    >
      {
        isEditable?
        <>
          <div className={styles.textfieldsmall1} style={{height:"2.34375vh", paddingLeft:"0.4861111111111111vw"}}>
            <input
              className={styles.discountCode}
              type="text"
              style={{width:"100%", padding:"0", fontSize:"1.8229166666666667vh"}}
              placeholder="Term"
              value={word}
              onChange={onWordChange}
            />
          </div>
          <div className={styles.textfieldsmall1} style={{height:"2.34375vh", paddingLeft:"0.4861111111111111vw"}}>
            <input
              className={styles.discountCode}
              type="text"
              style={{width:"100%", padding:"0", fontSize:"1.8229166666666667vh"}}
              placeholder="Meaning"
              value={mean}
              onChange={onMeanChange}
            />
          </div>
          <HoverDisabled1
            smallCheck="/Checkmark.svg"
            hoverDisabledMargin="0 !important"
            hoverDisabledPosition="relative"
            hoverDisabledTop="0.0vh"
            hoverDisabledRight="0.0vw"
            hoverDisabledFlexShrink="0"
            hoverDisabledZIndex="1"
            hoverDisabledBackgroundColor="var(--colors-blue-1)"
            onCheckClick={Save}
          />
          <StateDefault2
            smallDelete="/DeleteFC&Acc.svg"
            stateDefaultFlexShrink="0"
            stateDefaultCursor={butttonDelete40x28Cursor}
            stateDefaultBorderW="unset"
            stateDefaultBorderC="unset"
            stateDefaultBorderS="unset"
            stateDefaultBackgroundColor="unset"
            ChangeBGOnHover={true}
            onButttonDelete40x28Click={() => handleRemoveItem(itemId)}
          />
        </>
        :
        <>
          <StateDefaultEmptyYesChec1
            text={word}
            stateDefaultEmptyYesChecBoxSizing="border-box"
            stateDefaultEmptyYesChecOutline="unset"
            stateDefaultEmptyYesChecFontFamily="unset"
            stateDefaultEmptyYesChecFontSize="unset"
            stateDefaultEmptyYesChecFlexShrink="0"
            stateDefaultEmptyYesChecColor="unset"
            stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
            stateDefaultEmptyYesChecFlex="1"
            stateDefaultEmptyYesChecFontWeight="unset"
            stateDefaultEmptyYesChecPosition="unset"
            stateDefaultEmptyYesChecTop="unset"
            stateDefaultEmptyYesChecLeft="unset"
            stateDefaultEmptyYesChecWidth="unset"
            stateDefaultEmptyYesChecAlignSelf="unset"
            stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
            stateDefaultEmptyYesChecBorderW="unset"
            stateDefaultEmptyYesChecBorderC="unset"
            stateDefaultEmptyYesChecBorderS="unset"
            textFlex="unset"
            tutorCodeColor="var(--colors-dark-1)"
            tutorCodeFlex="unset"
            IsInput={false}
          />
          <StateDefaultEmptyYesChec1
            text={mean}
            stateDefaultEmptyYesChecBoxSizing="border-box"
            stateDefaultEmptyYesChecOutline="unset"
            stateDefaultEmptyYesChecFontFamily="unset"
            stateDefaultEmptyYesChecFontSize="unset"
            stateDefaultEmptyYesChecFlexShrink="0"
            stateDefaultEmptyYesChecColor="unset"
            stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
            stateDefaultEmptyYesChecFlex="1"
            stateDefaultEmptyYesChecFontWeight="unset"
            stateDefaultEmptyYesChecPosition="unset"
            stateDefaultEmptyYesChecTop="unset"
            stateDefaultEmptyYesChecLeft="unset"
            stateDefaultEmptyYesChecWidth="unset"
            stateDefaultEmptyYesChecAlignSelf="unset"
            stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
            stateDefaultEmptyYesChecBorderW="unset"
            stateDefaultEmptyYesChecBorderC="unset"
            stateDefaultEmptyYesChecBorderS="unset"
            textFlex="unset"
            tutorCodeColor="var(--colors-dark-1)"
            tutorCodeFlex="unset"
            IsInput={false}
          />
          <Property1Default15
            smallEdit="/Edit.svg"
            property1DefaultFlexShrink="0"
            property1DefaultCursor={butttonEdit24x24Cursor}
            property1DefaultBorderW="unset"
            property1DefaultBorderC="unset"
            property1DefaultBorderS="unset"
            property1DefaultBackgroundColor="unset"
            onButttonEdit24x24Click={Edit}
          />
          <StateDefault2
            smallDelete="/DeleteFC&Acc.svg"
            stateDefaultFlexShrink="0"
            stateDefaultCursor={butttonDelete40x28Cursor}
            stateDefaultBorderW="unset"
            stateDefaultBorderC="unset"
            stateDefaultBorderS="unset"
            stateDefaultBackgroundColor="unset"
            ChangeBGOnHover={true}
            onButttonDelete40x28Click={() => handleRemoveItem(itemId)}
          />
        </>
      }
    </EditeditableRoot>
  );
};

export default Editeditable;
