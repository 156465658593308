import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1LeftMultiLineNoType = {
  prop: string;
  spain: string;
  title: string;
  text: string;

  /** Style props */
  property1LeftMultiLineNoFlexShrink?: CSSProperties["flexShrink"];
  property1LeftMultiLineNoCursor?: CSSProperties["cursor"];
  property1LeftMultiLineNoBackgroundColor?: CSSProperties["backgroundColor"];
  property1LeftMultiLineNoBorderW?: CSSProperties["borderWidth"];
  property1LeftMultiLineNoBorderS?: CSSProperties["borderStyle"];
  property1LeftMultiLineNoBorderC?: CSSProperties["borderColor"];
  cuidadoHaciendaEchaColor?: CSSProperties["color"];
  aunqueBizumCuentaColor?: CSSProperties["color"];
  frameDivBorderLeft?: CSSProperties["borderLeft"];
  divColor?: CSSProperties["color"];
  spainPosition?: CSSProperties["position"];
  spainFontSize?: CSSProperties["fontSize"];
  spainLineHeight?: CSSProperties["lineHeight"];
  spainFontFamily?: CSSProperties["fontFamily"];
  spainColor?: CSSProperties["color"];
  spainTextAlign?: CSSProperties["textAlign"];
  spainDisplay?: CSSProperties["display"];
  spainOverflow?: CSSProperties["overflow"];
  spainTextOverflow?: CSSProperties["textOverflow"];
  spainWhiteSpace?: CSSProperties["whiteSpace"];
  spainWidth?: CSSProperties["width"];
  spainHeight?: CSSProperties["height"];
  spainFlexShrink?: CSSProperties["flexShrink"];
  spainMargin?: CSSProperties["margin"];

  /** Action props */
  onNewsCardListContainerClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const CuidadoHaciendaEcha = styled.div<{
  cuidadoHaciendaEchaColor?: Property.Color;
}>`
  align-self: stretch;
  position: relative;
  font-weight: 500;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 2.604166666666667vh;
  flex-shrink: 0;
  color: ${(p) => p.cuidadoHaciendaEchaColor};
`;
const AunqueBizumCuenta = styled.div<{
  aunqueBizumCuentaColor?: Property.Color;
}>`
  align-self: stretch;
  position: relative;
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 2.604166666666667vh;
  flex-shrink: 0;
  color: ${(p) => p.aunqueBizumCuentaColor};
`;
const CuidadoHaciendaEchaElOjoParent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-5xsW);
  gap: var(--gap-5xs);
  width: 34.44444444444444vw;
`;
const Div = styled.div<{ divColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 5.625vw;
  height: 2.604166666666667vh;
  flex-shrink: 0;
  color: ${(p) => p.divColor};
`;
const Spain = styled.div<{
  spainPosition?: Property.Position;
  spainFontSize?: Property.FontSize;
  spainLineHeight?: Property.LineHeight;
  spainFontFamily?: Property.FontFamily;
  spainColor?: Property.Color;
  spainTextAlign?: Property.TextAlign;
  spainDisplay?: Property.Display;
  spainOverflow?: Property.Overflow;
  spainTextOverflow?: Property.TextOverflow;
  spainWhiteSpace?: Property.WhiteSpace;
  spainWidth?: Property.Width;
  spainHeight?: Property.Height;
  spainFlexShrink?: Property.FlexShrink;
  spainMargin?: Property.Margin;
}>`position: relative;
  line-height: 2.604166666666667vh;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 2.569444444444444vw;
  height: 2.604166666666667vh;
  flex-shrink: 0;
  position: ${(p) => p.spainPosition};
  font-size: ${(p) => p.spainFontSize};
  line-height: ${(p) => p.spainLineHeight};
  font-family: ${(p) => p.spainFontFamily};
  color: ${(p) => p.spainColor};
  text-align: ${(p) => p.spainTextAlign};
  display: ${(p) => p.spainDisplay};
  overflow: ${(p) => p.spainOverflow};
  text-overflow: ${(p) => p.spainTextOverflow};
  white-space: ${(p) => p.spainWhiteSpace};
  width: ${(p) => p.spainWidth};
  height: ${(p) => p.spainHeight};
  flex-shrink: ${(p) => p.spainFlexShrink};
  margin: ${(p) => p.spainMargin};
`;
const Parent1 = styled.div<{ frameDivBorderLeft?: Property.BorderLeft }>`
  align-self: stretch;
  border-left: 0.06944444444444445vw solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 7.222222222222221vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-xsW);
  gap: var(--gap-5xs);
  font-size: var(--caption-cap-12-size);
  color: var(--colors-purple-dark);
  border-left: ${(p) => p.frameDivBorderLeft};
`;
const Property1leftMultilinenoRoot = styled.div<{
  property1LeftMultiLineNoFlexShrink?: Property.FlexShrink;
  property1LeftMultiLineNoCursor?: Property.Cursor;
  property1LeftMultiLineNoBackgroundColor?: Property.BackgroundColor;
  property1LeftMultiLineNoBorderW?: Property.BorderWidth;
  property1LeftMultiLineNoBorderC?: Property.BorderColor;
  property1LeftMultiLineNoBorderS?: Property.BorderStyle;
}>`border-radius: var(--corner-radius-l);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 44.72222222222222vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-baseH) var(--padding-5xsW);
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--buttons-btn-input-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  flex-shrink: ${(p) => p.property1LeftMultiLineNoFlexShrink};
  cursor: ${(p) => p.property1LeftMultiLineNoCursor};
  background-color: ${(p) => p.property1LeftMultiLineNoBackgroundColor};
  border-width: ${(p) => p.property1LeftMultiLineNoBorderW};
  border-color: ${(p) => p.property1LeftMultiLineNoBorderC};
  border-style: ${(p) => p.property1LeftMultiLineNoBorderS};
  &:hover {
    border-width: 0.130208333vh 0.0694444444vw;
    border-style: solid;
    border-color: var(--colors-blue2);
  }
`;

const Property1LeftMultiLineNo: FunctionComponent<
  Property1LeftMultiLineNoType
> = ({
  prop,
  spain,
  title,
  text,
  property1LeftMultiLineNoFlexShrink,
  property1LeftMultiLineNoCursor,
  property1LeftMultiLineNoBackgroundColor,
  property1LeftMultiLineNoBorderW,
  property1LeftMultiLineNoBorderC,
  property1LeftMultiLineNoBorderS,
  cuidadoHaciendaEchaColor,
  aunqueBizumCuentaColor,
  frameDivBorderLeft,
  divColor,
  spainPosition,
  spainFontSize,
  spainLineHeight,
  spainFontFamily,
  spainColor,
  spainTextAlign,
  spainDisplay,
  spainOverflow,
  spainTextOverflow,
  spainWhiteSpace,
  spainWidth,
  spainHeight,
  spainFlexShrink,
  spainMargin,
  onNewsCardListContainerClick,
}) => {
  return (
    <Property1leftMultilinenoRoot
      property1LeftMultiLineNoFlexShrink={property1LeftMultiLineNoFlexShrink}
      property1LeftMultiLineNoCursor={property1LeftMultiLineNoCursor}
      property1LeftMultiLineNoBackgroundColor={
        property1LeftMultiLineNoBackgroundColor
      }
      property1LeftMultiLineNoBorderW={property1LeftMultiLineNoBorderW}
      property1LeftMultiLineNoBorderC={property1LeftMultiLineNoBorderC}
      property1LeftMultiLineNoBorderS={property1LeftMultiLineNoBorderS}
      onClick={onNewsCardListContainerClick}
    >
      <CuidadoHaciendaEchaElOjoParent>
        <CuidadoHaciendaEcha
          cuidadoHaciendaEchaColor={cuidadoHaciendaEchaColor}
        >
          {title}
        </CuidadoHaciendaEcha>
        <AunqueBizumCuenta aunqueBizumCuentaColor={aunqueBizumCuentaColor}>
          {text}
        </AunqueBizumCuenta>
      </CuidadoHaciendaEchaElOjoParent>
      <Parent1 frameDivBorderLeft={frameDivBorderLeft}>
        <Div divColor={divColor}>{prop}</Div>
        <Spain
          spainPosition={spainPosition}
          spainFontSize={spainFontSize}
          spainLineHeight={spainLineHeight}
          spainFontFamily={spainFontFamily}
          spainColor={spainColor}
          spainTextAlign={spainTextAlign}
          spainDisplay={spainDisplay}
          spainOverflow={spainOverflow}
          spainTextOverflow={spainTextOverflow}
          spainWhiteSpace={spainWhiteSpace}
          spainWidth={spainWidth}
          spainHeight={spainHeight}
          spainFlexShrink={spainFlexShrink}
          spainMargin={spainMargin}
        >
          {spain}
        </Spain>
      </Parent1>
    </Property1leftMultilinenoRoot>
  );
};

export default Property1LeftMultiLineNo;
