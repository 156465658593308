import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import StateHoverIconno from './StateHoverIconno'
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const MainBlock = styled.div`
  color: black;
  h2, h4 {
    font-family: var(--title-font-family);
    font-weight: var(--font-weight-semibold);
  }
  .timer {
    text-align: right;
  }
  hr {
    height: 2px;
    background-color: black;
    width: 100%;
    opacity: 1;
    margin: 0;
    margin-bottom: 5px;
  }
  #audioBox {
    width: 200px;
    height: 30px;
    border: 2px solid #000;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  #customTimeDisplay {
    display: flex;
    width: 100%;
    align-items: center;
  }
  #audioProgressBar {
    flex-grow: 1;
    height: 5px;
    background-color: #ddd;
    margin: 0 10px;
    position: relative;
  }
  #elapsedTimeBar {
    height: 100%;
    background-color: #2196F3;
    width: 0%;
  }
  .blank-space {
    display: inline-block;
    border-bottom: 1px dashed black;
    width: 100px;
    height: 20px;
    margin: 0 5px;
    vertical-align: middle;
  }
  .cards1 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 1.19vw;
  }
  .optional6 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: var(--br-5xs);
    background-color: var(--colors-blue-1);
    width: 54.286vw;
    overflow: hidden;
    flex-direction: column;
    padding: 2.23vh 1.905vw;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  .optional-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    z-index: 0;
  }
  .checkbox-parent {
    flex-shrink: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: 0.63vw;
  }
  .checkbox {
    margin: 0;
    position: relative;
    border-radius: var(--corner-radius-s);
    accent-color: var(--colors-darkerblue);
    width: 1.27vw;
    height: 2.82vh;
    mix-blend-mode: normal;
  }
  .code {
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .optional1 {
    align-self: stretch;
    position: relative;
    line-height: 5.64vh;
    font-weight: 600;
    word-wrap: break-word;
  }
`;

const initialMainBlockContent = (nextTask: () => void, finishTasks: () => void, t: TFunction<"translation", undefined>) => (
  <>
    <h2 style={{margin: "0", fontSize:"calc(var(--buttons-btn-input-size) * 2)"}}>{t("Quiz")}</h2>
    <h4 style={{margin: "0", fontSize:"var(--buttons-btn-input-size)"}}> </h4>
    <div className="timer" style={{fontSize:"var(--buttons-btn-input-size)"}}></div>
    <hr />
    <div className="const-task" style={{alignSelf: "stretch",position: "relative",lineHeight: "var(--font-size-5xl)",fontSize: "var(--font-size-5xl)"}}></div>
    <div id="PlainText" style={{fontSize:"var(--buttons-btn-input-size)"}}></div>
    <div id="BlankText" style={{fontSize:"var(--buttons-btn-input-size)"}}></div>
    <div id="Thesis" style={{fontSize:"var(--buttons-btn-input-size)"}}></div>
    <div id="Task" style={{fontSize:"var(--buttons-btn-input-size)"}}></div>
    <div id="Stem" style={{fontSize:"var(--buttons-btn-input-size)", fontWeight:"bold"}}></div>
    <div id="Questions" style={{fontSize:"var(--buttons-btn-input-size)", alignSelf: "stretch",flexDirection: "column",alignItems: "flex-start",display: "flex",justifyContent: "flex-start",gap: "2.82vh"}}></div>
    <div id="AUDBox" style={{ display: 'none' }}>
      <div className="audio-start-timer" style={{ display: 'none', textAlign: 'left' }}>{t("RtSR")}</div>
      <div id="audioBox">
        <div id="customTimeDisplay">
          <span id="currentTime">0:00</span>/<span id="duration">0:00</span>
          <div id="audioProgressBar">
            <div id="elapsedTimeBar"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="InputText" style={{ display: "none" }}>
      <textarea style={{ width: '100%', resize: 'none', height: '300px' }}></textarea>
    </div>
    <div id="InputSentence" style={{ width: '65%', display: "none" }}>
      <input type="text" style={{ width: '100%' }} />
    </div>
    <div id="nextButton" style={{ display: 'block', position: 'relative', left: '95%', margin: '10px', width:"max-content" }}>
      <StateHoverIconno
        saveSession="->"
        stateHoverIconnoJustifyContent="flex-start"
        stateHoverIconnoWidth="max-content"
        stateHoverIconnoZIndex="unset"
        stateHoverIconnoBackgroundColor="transparent"
        stateHoverIconnoCursor="pointer"
        stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
        stateHoverIconnoBorderC="var(--colors-darkerblue)"
        stateHoverIconnoBorderS="solid"
        stateHoverIconnoHeight="4.166666666666666vh"
        stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
        stateHoverIconnoBoxSizing="border-box"
        stateHoverIconnoPosition="unset"
        stateHoverIconnoTop="unset"
        stateHoverIconnoLeft="unset"
        saveSessionColor="var(--colors-darkerblue)"
        saveSessionDisplay="inline-block"
        onButtonSecondaryClick={nextTask}
      />
    </div>
    <div id="finishButton" style={{ display: 'none', position: 'relative', left: '95%', margin: '10px', width:"max-content" }}>
      <StateHoverIconno
        saveSession="->"
        stateHoverIconnoJustifyContent="flex-start"
        stateHoverIconnoWidth="max-content"
        stateHoverIconnoZIndex="unset"
        stateHoverIconnoBackgroundColor="transparent"
        stateHoverIconnoCursor="pointer"
        stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
        stateHoverIconnoBorderC="var(--colors-darkerblue)"
        stateHoverIconnoBorderS="solid"
        stateHoverIconnoHeight="4.166666666666666vh"
        stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
        stateHoverIconnoBoxSizing="border-box"
        stateHoverIconnoPosition="unset"
        stateHoverIconnoTop="unset"
        stateHoverIconnoLeft="unset"
        saveSessionColor="var(--colors-darkerblue)"
        saveSessionDisplay="inline-block"
        onButtonSecondaryClick={finishTasks}
      />
    </div>
    <audio id="audio-player" style={{ display: "none" }}></audio>
  </>
);

let FSet=false

const waitForElement = (selector: string, callback: () => void, interval: number = 50, timeout: number = 100000) => {
  const startTime = Date.now();

  const checkExist = setInterval(() => {
    if (document.querySelector(selector) || Date.now() - startTime > timeout) {
      clearInterval(checkExist);
      callback();
    }
  }, interval);
};

const QuizSection: React.FC = () => {
  //const [output, setOutput] = useState<any>(null);
  const [recordedAudio, setRecordedAudio] = useState<FormData | null>(null);
  const [mainTimer, setMainTimer] = useState<number | null>(null);
  const [preRecTimer, setPreRecTimer] = useState<number | null>(null);
  const [recTimer, setRecTimer] = useState<number | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  //const [currentTime, setCurrentTime] = useState<number>(0);
  const [currIndex, setCurrIndex] = useState<number>(0);
  const { t } = useTranslation();
  
  const [mainBlockContent, setMainBlockContent] = useState<JSX.Element>(() => initialMainBlockContent(() => {}, () => {},t));
  const [triggerUpdate, setTriggerUpdate] = useState(false)
  
  const lockAllInputs = useCallback(() => {
    const inputs = document.querySelectorAll('input, select, textarea, button:not(:is(#nextButton button, #finishButton button))');
    inputs.forEach(input => {
      input.setAttribute('disabled', 'disabled');
    });
  }, []);

  const disableDragAndDrop = useCallback(() => {
    const draggables = document.querySelectorAll('.draggable-word');
    draggables.forEach(draggable => {
      draggable.removeAttribute('draggable');
    });
  }, []);

  const startCountdown = useCallback((duration: number) => {
    let timer = duration;
    document.querySelector('.timer')!.textContent = `Time left: ${timer} seconds`;

    const intervalId = setInterval(() => {
      timer--;
      document.querySelector('.timer')!.textContent = `Time left: ${timer} seconds`;

      if (timer <= 0) {
        document.querySelector('.timer')!.textContent = `Time is out!`;
        clearInterval(intervalId);
        lockAllInputs();
        disableDragAndDrop();
      }
    }, 1000);
    setMainTimer(intervalId as unknown as number);
  }, [disableDragAndDrop,lockAllInputs]);

  const playPromptAudio = useCallback(async (base64Audio: string, callback: () => void) => {
    const audioPlayer = document.getElementById('audio-player') as HTMLAudioElement;

    const byteCharacters = atob(base64Audio);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'audio/mpeg' });

    audioPlayer.onended = null;
    audioPlayer.src = URL.createObjectURL(blob);
    audioPlayer.onended = function() {
      callback();
    };
    audioPlayer.play();
  }, []);

  const renderBlanksFromJSON = useCallback((output: any) => {
    const container = document.getElementById('BlankText')!;
    let textWithBlanks = output.TextWithBlanks.Value;
    const blankDetails = output.Blanks;
    switch (blankDetails.Type) {
      case 'Text':
        const parts: string[] = textWithBlanks.split('{Blank}');
        const lastPart = parts.pop();
        textWithBlanks = parts.map(part => part + createTextInput().outerHTML).join('') + lastPart;
        break;
      case 'Dropdown':
        blankDetails.Values.forEach((values: string[]) => {
          textWithBlanks = textWithBlanks.replace('{Blank}', createDropdown(values).outerHTML);
        });
        break;
      case 'DragNDrop':
        //const blanksCount = (textWithBlanks.match(/{Blank}/g) || []).length;
        textWithBlanks = textWithBlanks.replace(/{Blank}/g, '<div class="blank-space" droppable="true"></div>');
        textWithBlanks += createDragDropList(blankDetails.Values).outerHTML;
        break;
    }

    container.innerHTML = textWithBlanks;
    if (blankDetails.Type === 'DragNDrop') {
      const words = document.querySelectorAll('.draggable-word');
      const blanks = document.querySelectorAll('.blank-space');
      let wordList = document.querySelectorAll('.dragdrop-list')[0];
      words.forEach(word => {
        addDragListenersToWord(word as HTMLElement);
      });

      blanks.forEach(blank => {
        blank.addEventListener('dragover', function (e) {
          e.preventDefault();
          const dragEvent = e as DragEvent;
          dragEvent.dataTransfer!.dropEffect = 'move';
        });

        blank.addEventListener('drop', function (e) {
          e.preventDefault();
          const dragEvent = e as DragEvent;
          const existingWord = blank.querySelector('.draggable-word') as HTMLElement;
          if (existingWord) {
            let wordToReturn = document.createElement('div');
            wordToReturn.textContent = existingWord.textContent;
            wordToReturn.style.display = 'inline';
            wordToReturn.style.padding = '3px';
            wordToReturn = wordList.appendChild(wordToReturn) as HTMLDivElement;
            addDragListenersToWord(wordToReturn);
            existingWord.remove();
          }

          const wordSpan = document.createElement('span');
          wordSpan.textContent = dragEvent.dataTransfer!.getData('text/plain');
          wordSpan.classList.add('draggable-word');
          blank.appendChild(wordSpan);
          addDragListenersToWord(wordSpan);

          const draggedWord = document.querySelector('.being-dragged') as HTMLElement;
          if (draggedWord) draggedWord.remove();
        });
      });
    }
  },[]);

  const handleQuestionDetails = useCallback((details: any, callback = () => {}) => {
    if (details.Audio) {
      playPromptAudio(details.Audio.Hash, callback);
    }
    if (details.Text) {
      document.getElementById("PlainText")!.textContent = details.Text.Value;
    }
    if (details.Thesis) {
      document.getElementById("Thesis")!.textContent = details.Thesis.Value;
    }
    if (details.Task) {
      document.getElementById("Task")!.textContent = details.Task.Value;
    }
    if (details.TextWithBlanks) {
      renderBlanksFromJSON(details);
    }
    if (details.Question) {
      document.getElementById("Questions")!.innerHTML = `<div>${details.Question.Value}</div>`;
    }
    if (details.Options) {
      appendOptionsToQuestions(details);
    }
    if (details.Stem) {
      document.getElementById("Stem")!.textContent = details.Stem.Value;
    }
  }, [playPromptAudio,renderBlanksFromJSON]);

  const startCountdownBeforeRecording = useCallback((duration: number, callback: () => void) => {
    (document.querySelector('.audio-start-timer') as HTMLElement)!.style.display = 'block';
    let timer = duration;
    document.querySelector('.audio-start-timer')!.textContent = `Waiting to Start Recording in ${timer} seconds`;

    const intervalId = setInterval(() => {
      timer--;
      document.querySelector('.audio-start-timer')!.textContent = `Waiting to Start Recording in ${timer} seconds`;
      if (timer <= 0) {
        clearInterval(intervalId);
        callback();
      }
    }, 1000);
    setPreRecTimer(intervalId as unknown as number);
  }, []);

  const startRecording = useCallback(() => {
    if ((document.getElementById("nextButton")!.style.display === "none") && (document.getElementById("finishButton")!.style.display === "none")) {
      return;
    }
    navigator.mediaDevices.getUserMedia({ audio: true }).then(function (stream) {
      const mediaRecorderInstance = new MediaRecorder(stream);
      setMediaRecorder(mediaRecorderInstance);

      mediaRecorderInstance.ondataavailable = function (e) {
        const chunks = [e.data];
        const blob = new Blob(chunks, { type: 'audio/wav' });
        const formData = new FormData();
        const timestamp = new Date().toISOString().replace(/[-T:.Z]/g, "");
        formData.append('audio', blob, `recording_${timestamp}.wav`);
        setRecordedAudio(formData);
      };

      mediaRecorderInstance.start();
    }).catch(function (error) {
      console.error(error);
    });
  }, []);

  const startRecordCountdown = useCallback((duration: number) => {
    startRecording();
    const limit = duration;
    let currentTime = 0;
    const currentTimeDisplay = document.getElementById('currentTime')!;
    const elapsedTimeBar = document.getElementById('elapsedTimeBar')!;
    const intervalId = setInterval(() => {
      currentTime++;
      let percentage = (currentTime / limit) * 100;
      elapsedTimeBar.style.width = percentage + '%';
      currentTimeDisplay.textContent = formatTime(currentTime);
  
      if (currentTime >= limit) {
        mediaRecorder!.stop();
        document.querySelector('.audio-start-timer')!.textContent = 'Recording stopped.';
        clearInterval(intervalId);
      }
    }, 1000);
    setRecTimer(intervalId as unknown as number);
  }, [mediaRecorder, startRecording]);

  const handleAnswerConstraints = useCallback((details: any, constraints: any) => {
    if (constraints && details.Audio && constraints.Type === "Audio") {
      document.getElementById("AUDBox")!.style.display = "block";
      const formatTime = (seconds: number) => {
        let min = Math.floor(seconds / 60).toString();
        let sec = Math.floor(seconds % 60).toString();
        if (parseInt(sec) < 10) sec = '0' + sec;
        return min + ':' + sec;
      };
      document.getElementById('duration')!.textContent = formatTime(constraints.CountdownRecordEndTimer);
      handleQuestionDetails(details, () => {
        setTimeout(() => {
          startCountdownBeforeRecording(constraints.CountdownStartRecordTimerAfterAudio, () => {
            document.querySelector('.audio-start-timer')!.textContent = 'Recording';
            startRecordCountdown(constraints.CountdownRecordEndTimer);
          });
        }, constraints.CountdownStartRecordTimerAfterAudio * 1000);
      });
    } else {
      handleQuestionDetails(details);
      if (constraints && constraints.Type === "Audio") {
        document.getElementById("AUDBox")!.style.display = "block";
        const formatTime = (seconds: number) => {
          let min = Math.floor(seconds / 60).toString();
          let sec = Math.floor(seconds % 60).toString();
          if (parseInt(sec) < 10) sec = '0' + sec;
          return min + ':' + sec;
        };
        document.getElementById('duration')!.textContent = formatTime(constraints.CountdownRecordEndTimer);
        startCountdownBeforeRecording(constraints.CountdownStartRecordTimer, () => {
          document.querySelector('.audio-start-timer')!.textContent = 'Recording';
          startRecordCountdown(constraints.CountdownRecordEndTimer);
        });
      }
      if (constraints && constraints.Type === "Sentence") {
        document.getElementById("InputSentence")!.style.display = "block";
      }
      if (constraints && constraints.Type === "Text") {
        document.getElementById("InputText")!.style.display = "block";
      }
    }
  }, [handleQuestionDetails,startCountdownBeforeRecording,startRecordCountdown]);

  const loadDOMFromOutput = useCallback((output: any) => {
    document.querySelector('h4')!.textContent = output.DisplayName || "";
    document.querySelector('.const-task')!.textContent = output.ConstTask || "";

    if (output.CountdownPerQuestion) {
      startCountdown(output.CountdownPerQuestion);
    }
    if (output.AnsConstraints) {
      handleAnswerConstraints(output, output.AnsConstraints);
    } else {
      handleAnswerConstraints(output, null);
    }
  }, [handleAnswerConstraints,startCountdown]);


  const formatTime = (seconds: number) => {
    let min = Math.floor(seconds / 60).toString();
    let sec = Math.floor(seconds % 60).toString();
    if (parseInt(sec) < 10) sec = '0' + sec;
    return min + ':' + sec;
  };

  const appendOptionsToQuestions = (details: any) => {
    const renderOptionsList = (optionsData: any) => {
      const containerDiv = document.getElementById('Questions');
      if (!containerDiv) {return}
      const buildSelectEventListener = (unlocks: any, inputDiv: HTMLElement, uid: string) => {
        return function () {
          const newDiv = document.createElement('div');
          unlocks.forEach((unlock: any) => {
            const unlockElement = renderElement(unlock, uid);
            newDiv.appendChild(unlockElement);
          });
          inputDiv.appendChild(newDiv);
          inputDiv.dataset.subDivId = newDiv.id = 'subDiv-' + Math.random().toString(36).substr(2, 9);
        };
      };

      const buildDeselectEventListener = (inputDiv: HTMLElement) => {
        return function () {
          const subDivId = inputDiv.dataset.subDivId;
          if (subDivId) {
            const subDiv = document.getElementById(subDivId);
            if (subDiv) {
              inputDiv.removeAttribute('data-sub-div-id');
              subDiv.remove();
            }
          }
        };
      };

      const renderElement = (elementData: any, parentId: string) => {
        const elementDiv = document.createElement('div');
        switch (elementData.Type) {
          case 'Text':
            const textLabel = document.createElement('label');
            textLabel.textContent = elementData.Question;
            textLabel.style.fontSize = 'var(--caption-cap-12-size)';
            elementDiv.appendChild(textLabel);
            const textInput = document.createElement('input');
            textInput.type = 'text';
            elementDiv.appendChild(textInput);
            break;
          case 'Date':
            const datetimeLabel = document.createElement('label');
            datetimeLabel.textContent = elementData.Question;
            datetimeLabel.style.fontSize = 'var(--caption-cap-12-size)';
            elementDiv.appendChild(datetimeLabel);
            const datetimeInput = document.createElement('input');
            datetimeInput.type = 'date';
            elementDiv.appendChild(datetimeInput);
            break;
          case 'Disclaimer':
            const disclaimerLabel = document.createElement('label');
            disclaimerLabel.textContent = elementData.Value;
            disclaimerLabel.style.fontSize = 'var(--caption-cap-12-size)';
            elementDiv.appendChild(disclaimerLabel);
            break;
          case 'OneWithUnlocks':
            const questionLabel = document.createElement('label');
            questionLabel.textContent = elementData.Question;
            elementDiv.appendChild(questionLabel);
            const optionsDiv = document.createElement('div');
            elementData.Values.forEach((option: any, index: number) => {
              let optionDiv = document.createElement('div');
              optionDiv.style.display = "flex";
              const optionRadio = document.createElement('input');
              let optionRadioLabel = document.createElement('label');
              optionRadioLabel.style.marginTop = '10px';
              optionRadio.type = 'radio';
              optionRadio.name = elementData.Question;
              optionRadio.value = option.Value;
              optionRadioLabel.textContent = option.Value;
              optionRadioLabel.style.fontSize = 'var(--caption-cap-12-size)';
              optionRadioLabel.style.marginLeft = '10px';
              const uniqueID = `${parentId}_${index}`;
              optionRadio.id = uniqueID;
              optionRadioLabel.setAttribute('for', uniqueID);
              optionDiv.appendChild(optionRadio);
              optionDiv.appendChild(optionRadioLabel);
              optionRadio.addEventListener('change', function () {
                const lastSelectedRadioValue = elementDiv.dataset.lastSelected;
                if (lastSelectedRadioValue) {
                  const lastRadio = elementDiv.querySelector(`input[value="${lastSelectedRadioValue}"]`);
                  if (lastRadio) {
                    buildDeselectEventListener(elementDiv)();
                  }
                }
                if (optionRadio.checked) {
                  elementDiv.dataset.lastSelected = optionRadio.value;
                  if (option.Unlocks) {
                    buildSelectEventListener(option.Unlocks, elementDiv, uniqueID)();
                    optionRadio.dataset.unlocks = JSON.stringify(option.Unlocks);
                  }
                }
              });
              optionsDiv.appendChild(optionDiv);
            });
            elementDiv.appendChild(optionsDiv);
            break;
        }
        return elementDiv;
      };

      let index = 0;
      for (const option of optionsData.Values) {
        let optionDiv = document.createElement('div');
        optionDiv.style.display = "flex";
        let optionLabel = document.createElement('label');
        optionLabel.style.marginTop = '10px';
        let uniqueID = optionsData.Question ? `${optionsData.Question.Value}_${index}` : `option_${index}`;
        switch (optionsData.Type) {
          case 'NoSelect':
            optionLabel.textContent = '- ' + option;
            optionLabel.style.fontSize = 'var(--caption-cap-12-size)';
            optionDiv.appendChild(optionLabel);
            break;
          case 'Multiple':
            const checkBox = document.createElement('input');
            checkBox.type = 'checkbox';
            optionLabel.style.marginLeft = '10px';
            optionLabel.textContent = option;
            optionLabel.style.fontSize = 'var(--caption-cap-12-size)';
            checkBox.id = uniqueID;
            optionLabel.setAttribute('for', uniqueID);
            optionDiv.appendChild(checkBox);
            optionDiv.appendChild(optionLabel);
            break;
          case 'One':
            const container2Div = document.createElement('div');
            container2Div.className = 'optional6';
            container2Div.style.width = '100%';
            container2Div.id = 'checkContainer';

            const innerDiv = document.createElement('div');
            innerDiv.className = 'optional-inner';

            const checkboxParentDiv = document.createElement('div');
            checkboxParentDiv.className = 'checkbox-parent';

            const radioButton = document.createElement('input');
            radioButton.type = 'radio';
            radioButton.name = 'oneOption';
            radioButton.value = option;
            radioButton.id = uniqueID;
            radioButton.className = 'checkbox';
            radioButton.autocomplete = 'off';

            const codeDiv = document.createElement('div');
            codeDiv.className = 'code';

            const optionLabel1 = document.createElement('div');
            optionLabel1.className = 'optional1';
            optionLabel1.textContent = option;
            optionLabel1.style.marginLeft = '10px';
            optionLabel1.style.fontSize = 'var(--buttons-btn-input-size)';
            optionLabel1.setAttribute('for', uniqueID);

            codeDiv.appendChild(optionLabel1);
            checkboxParentDiv.appendChild(radioButton);
            checkboxParentDiv.appendChild(codeDiv);
            innerDiv.appendChild(checkboxParentDiv);
            container2Div.appendChild(innerDiv);
            radioButton.addEventListener('change', function () {
              const previousSelected = document.querySelector('input[name="oneOption"]:checked') as HTMLInputElement;
              if (previousSelected) {
                previousSelected.checked = false;
              }
              radioButton.checked = true;
            });

            optionDiv.appendChild(container2Div);
            optionDiv.style.width="100%"
            break;
          case 'OneWithUnlocks':
            const radioForUnlocks = document.createElement('input');
            radioForUnlocks.type = 'radio';
            radioForUnlocks.name = 'oneWithUnlocksOption';
            radioForUnlocks.value = option.Value;
            optionLabel.textContent = option.Value;
            optionLabel.style.fontSize = 'var(--caption-cap-12-size)';
            optionLabel.style.marginLeft = '10px';
            radioForUnlocks.id = uniqueID;
            optionLabel.setAttribute('for', uniqueID);
            optionDiv.appendChild(radioForUnlocks);
            optionDiv.appendChild(optionLabel);
            radioForUnlocks.addEventListener('change', function () {
              const lastSelectedRadioValue = containerDiv.dataset.lastSelected;
              if (lastSelectedRadioValue) {
                const lastRadio = containerDiv.querySelector(`input[value="${lastSelectedRadioValue}"]`);
                if (lastRadio) {
                  buildDeselectEventListener(containerDiv)();
                }
              }
              if (radioForUnlocks.checked) {
                containerDiv.dataset.lastSelected = radioForUnlocks.value;
                if (option.Unlocks) {
                  buildSelectEventListener(option.Unlocks, containerDiv, uniqueID)();
                  radioForUnlocks.dataset.unlocks = JSON.stringify(option.Unlocks);
                }
              }
            });
        }
        containerDiv.appendChild(optionDiv);
        index++;
      }
    };
    renderOptionsList(details.Options);
  };

  const createDropdown = (options: string[]) => {
    const select = document.createElement('select');
    options.forEach(option => {
      const optionElement = document.createElement('option');
      optionElement.value = option;
      optionElement.innerText = option;
      select.appendChild(optionElement);
    });
    return select;
  };

  const createTextInput = () => {
    const input = document.createElement('input');
    input.type = 'text';
    return input;
  };

  const createDragDropList = (words: string[]) => {
    const list = document.createElement('div');
    list.className = 'dragdrop-list';
    words.forEach(word => {
      const wordElement = document.createElement('div');
      wordElement.className = 'draggable-word';
      wordElement.draggable = true;
      wordElement.innerText = word;
      wordElement.style.display = 'inline';
      wordElement.style.padding = '3px';
      list.appendChild(wordElement);
    });
    return list;
  };

  const gatherInputsAndSend = useCallback(async (wait: boolean) => {
    let dataToSend = new FormData();
    dataToSend.append('CurrentIndex', currIndex.toString());
    document.querySelectorAll('input[type="text"], textarea').forEach((element, index) => {
      if (!isElementEffectivelyHidden(element as HTMLElement)) {
        if (element.parentElement!.id === 'InputSentence') {
          dataToSend.append('input-sentence', (element as HTMLInputElement).value);
        } else if (element.parentElement!.id === 'InputText') {
          dataToSend.append('input-textarea', (element as HTMLTextAreaElement).value);
        } else {
          dataToSend.append('input-text-' + index, (element as HTMLInputElement).value);
        }
      }
    });
    document.querySelectorAll('input[type="date"]').forEach((element, index) => {
      if (!isElementEffectivelyHidden(element as HTMLElement)) {
        dataToSend.append('input-timestamp-' + index, (element as HTMLInputElement).value);
      }
    });
    document.querySelectorAll('select').forEach((element, index) => {
      dataToSend.append('select-dropdown-' + index, (element as HTMLSelectElement).value);
    });
    document.querySelectorAll('input[type="checkbox"]:checked, input[type="radio"]:checked').forEach((element) => {
      let labelValue = (element.nextElementSibling as HTMLLabelElement).textContent!;
      dataToSend.append('optionsGroup', labelValue);
    });
    document.querySelectorAll('.blank-space').forEach((element, index) => {
      if ((element as HTMLElement).innerText) {
        dataToSend.append('blank-space-' + index, (element as HTMLElement).innerText.trim());
      }
    });
    if (recordedAudio) {
      dataToSend.append('audio', recordedAudio.get('audio')!);
    }
    if (wait) {
      await fetch('/POSTResults', {
        method: 'POST',
        body: dataToSend
      }).then(async response => await response.text()).then(data => console.log(data)).catch(error => console.error('Error:', error));
    } else {
      fetch('/POSTResults', {
        method: 'POST',
        body: dataToSend
      });
    }
  }, [currIndex, recordedAudio]);

  const nextTask = useCallback(async () => {
    document.getElementById("nextButton")!.style.display = "none";
    clearInterval(mainTimer!);
    clearInterval(preRecTimer!);
    clearInterval(recTimer!);
    if (mediaRecorder) {
      mediaRecorder.stop();
      await new Promise(r => setTimeout(r, 2000));
    }
    await gatherInputsAndSend(false);
    setMediaRecorder(null);
    setMainBlockContent(<>{t("RetrievingQuestion")}</>)
    await fetch('/NextTask', {
      method: 'POST'
    }).then(async response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      await fetch('/GetQuizJSON', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(async response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
      }).then(data => {
        setCurrIndex(data.CurrentInd);
        setTriggerUpdate(prev => {
          console.log("Updating triggerUpdate:", !prev);
          return !prev;
        });
        setRecordedAudio(null);
        waitForElement("#mainBlock > h4", () => {
          if (data.last as boolean===true) {
            document.getElementById("nextButton")!.style.display = "none";
            document.getElementById("finishButton")!.style.display = "block";
          }
          loadDOMFromOutput(data);
        });
      }).catch(error => {
        console.error('There was a problem fetching the JSON: ', error);
      });
    });
  },[gatherInputsAndSend, loadDOMFromOutput, mainTimer, mediaRecorder, preRecTimer, recTimer,t])

  const finishTasks = useCallback(async () => {
    document.getElementById("finishButton")!.style.display = "none";
    clearInterval(mainTimer!);
    clearInterval(preRecTimer!);
    clearInterval(recTimer!);
    if (mediaRecorder) {
      mediaRecorder.stop();
      await new Promise(r => setTimeout(r, 2000));
    }
    await gatherInputsAndSend(true);
    await fetchWithTimeout('/FinishTopicTestQuiz', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }, 180000).then(async response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return await response.json();
    }).then(async data => {
      if (data.last as boolean===true) {
        window.location.reload();
      } else {
        if (mediaRecorder) {
          mediaRecorder.stop();
          await new Promise(r => setTimeout(r, 2000));
        }
        setMediaRecorder(null);
        setMainBlockContent(<>{t("RetrievingQuestion")}</>)
        await fetchWithTimeout('/GetQuizJSON', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }, 180000).then(async response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return await response.json();
        }).then(data => {
          setCurrIndex(data.CurrentInd);
          setTriggerUpdate(prev => {
            console.log("Updating triggerUpdate:", !prev);
            return !prev;
          });
          setRecordedAudio(null);
          waitForElement("#mainBlock > h4", () => {
            if (data.last as boolean===true) {
              document.getElementById("nextButton")!.style.display = "none";
              document.getElementById("finishButton")!.style.display = "block";
            }
            loadDOMFromOutput(data);
          });
        }).catch(error => {
          console.error('There was a problem fetching the JSON: ', error);
        });
      }
    }).catch(error => {
      console.error('There was a problem fetching the JSON: ', error);
    });
  },[gatherInputsAndSend, loadDOMFromOutput, mainTimer, mediaRecorder, preRecTimer, recTimer,t])

  /*const Choice = useCallback(async (opt: string) => {
    document.getElementById("FinButton")!.style.display = "none";
    document.getElementById("RepButton")!.style.display = "none";
    await fetch('/POSTResults', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "Choice": opt })
    }).then(async response => await response.text()).then(async data => {
      await fetchWithTimeout('/FinishTopicTestQuiz', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }, 180000).then(async response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
      }).then(async data => {
        if (opt === "finish") {
          window.location.reload();
        } else {
          await fetchWithTimeout('/GetQuizJSON', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }, 180000).then(async response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return await response.json();
          }).then(data => {
            setCurrIndex(data.CurrentInd);
            setTriggerUpdate(prev => {
              console.log("Updating triggerUpdate:", !prev);
              return !prev;
            });
            setRecordedAudio(null);
            waitForElement("#mainBlock > h4", () => {
              if (data.last as boolean===true) {
                document.getElementById("nextButton")!.style.display = "none";
                document.getElementById("finishButton")!.style.display = "block";
              }
              loadDOMFromOutput(data);
            });
          }).catch(error => {
            console.error('There was a problem fetching the JSON: ', error);
          });
        }
      });
    }).catch(error => console.error('Error:', error));
  },[loadDOMFromOutput])*/
  
  if (!FSet) {
    FSet=true
    setTriggerUpdate(prev=>!prev)
  }

  const checkPermission = useCallback(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(function (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
    }).catch(function (err) {
      alert("Website needs access to media for you to continue.");
    });
  }, []);

  const isElementEffectivelyHidden = (element: HTMLElement | null) => {
    while (element) {
      if (window.getComputedStyle(element).display === 'none' || element.hasAttribute('hidden')) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  };

  const fetchWithTimeout = async (url: string, options: RequestInit, timeoutDuration = 30000) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeoutDuration);

    const response = await fetch(url, {
      ...options,
      signal: controller.signal
    });

    clearTimeout(id);

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    return response;
  };

  /*const AppendAdvice = (text: string) => {
    document.getElementById("Advice")!.innerText += text;
  };

  const getChunkNH = async () => {
    return await fetch('/GetChunkNoHist', {
      method: 'GET'
    }).then(
      data => data.json()
    );
  };*/

  useEffect(() => {
    console.log("useEffect triggered with triggerUpdate:", triggerUpdate);
    setMainBlockContent(initialMainBlockContent(nextTask, finishTasks,t));
  }, [triggerUpdate, nextTask, finishTasks,t]);

  useEffect(() => {
    checkPermission();
  
    return () => {
      if (mainTimer !== null) {
        clearInterval(mainTimer);
      }
    };
  }, [checkPermission, mainTimer]);

  useEffect(() => {
    fetch('/GetQuizJSON', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    }).then(data => {
      setCurrIndex(data.CurrentInd);
      if (data.last as boolean===true) {
        document.getElementById("nextButton")!.style.display = "none";
        document.getElementById("finishButton")!.style.display = "block";
      }
      loadDOMFromOutput(data);
    }).catch(error => {
      console.error('There was a problem fetching the JSON: ', error);
    });
  }, [loadDOMFromOutput]);

  return (
    <MainBlock id="mainBlock" style={{width: "90%",gap: "var(--gap-11xs)",display: "flex",flexDirection: "column", position:"relative", left: "5%", color:"var(--colors-dark-1)"}}>
      {mainBlockContent}
    </MainBlock>
  );
};

export default QuizSection;

const addDragListenersToWord = (word: HTMLElement) => {
    word.setAttribute('draggable', 'true');
  
    word.addEventListener('dragstart', function (e) {
      const dragEvent = e as DragEvent;
      dragEvent.dataTransfer!.setData('text/plain', this.textContent!);
      this.classList.add('being-dragged');
    });
  
    word.addEventListener('dragend', function (e) {
      this.classList.remove('being-dragged');
      const parentElement = this.parentNode as HTMLElement;
      if (!parentElement.classList.contains('blank-space')) {
        const blankToReturn = document.querySelector('.origin-blank') as HTMLElement;
        if (blankToReturn) {
          blankToReturn.textContent = this.textContent;
          blankToReturn.classList.remove('origin-blank');
        }
      }
    });
  };
  
  