import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import Property1Default12 from "./Property1Default12";
import styled from "styled-components";

type Property1NavigationPropertType = {
  largeBack?: string;

  /** Style props */
  property1NavigationProperWidth?: CSSProperties["width"];
  property1NavigationProperPosition?: CSSProperties["position"];
  property1NavigationProperTop?: CSSProperties["top"];
  property1NavigationProperLeft?: CSSProperties["left"];
  property1NavigationProperFlexShrink?: CSSProperties["flexShrink"];
  property1NavigationProperPadding?: CSSProperties["padding"];
  property1NavigationProperBoxSizing?: CSSProperties["boxSizing"];
  backButtonCursor?: CSSProperties["cursor"];
  backButtonPadding?: CSSProperties["padding"];
  backButtonBackgroundColor?: CSSProperties["backgroundColor"];
  newsColor?: CSSProperties["color"];

  /** Action props */
  onBackButton1Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const News = styled.div<{ newsColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.newsColor};
`;
const Breadcrumpitem = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Property1navigationPropertRoot = styled.div<{
  property1NavigationProperWidth?: Property.Width;
  property1NavigationProperPosition?: Property.Position;
  property1NavigationProperTop?: Property.Top;
  property1NavigationProperLeft?: Property.Left;
  property1NavigationProperFlexShrink?: Property.FlexShrink;
  property1NavigationProperPadding?: Property.Padding;
  property1NavigationProperBoxSizing?: Property.BoxSizing;
}>`width: 40.27777777777778vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-purple-dark);
  font-family: var(--buttons-btn-input);
  width: ${(p) => p.property1NavigationProperWidth};
  position: ${(p) => p.property1NavigationProperPosition};
  top: ${(p) => p.property1NavigationProperTop};
  left: ${(p) => p.property1NavigationProperLeft};
  flex-shrink: ${(p) => p.property1NavigationProperFlexShrink};
  padding: ${(p) => p.property1NavigationProperPadding};
  box-sizing: ${(p) => p.property1NavigationProperBoxSizing};
`;

const Property1NavigationPropert: FunctionComponent<
  Property1NavigationPropertType
> = ({
  property1NavigationProperWidth,
  property1NavigationProperPosition,
  property1NavigationProperTop,
  property1NavigationProperLeft,
  property1NavigationProperFlexShrink,
  property1NavigationProperPadding,
  property1NavigationProperBoxSizing,
  newsColor,
  onBackButton1Click,
}) => {
  return (
    <Property1navigationPropertRoot
      property1NavigationProperWidth={property1NavigationProperWidth}
      property1NavigationProperPosition={property1NavigationProperPosition}
      property1NavigationProperTop={property1NavigationProperTop}
      property1NavigationProperLeft={property1NavigationProperLeft}
      property1NavigationProperFlexShrink={property1NavigationProperFlexShrink}
      property1NavigationProperPadding={property1NavigationProperPadding}
      property1NavigationProperBoxSizing={property1NavigationProperBoxSizing}
    >
      <Property1Default12
        largeBack="/ArrowHead.svg"
        property1DefaultCursor="pointer"
        property1DefaultBorderW="unset"
        property1DefaultBorderC="unset"
        property1DefaultBorderS="unset"
        property1DefaultPadding="unset"
        property1DefaultTransform="rotate(0deg)"
        property1DefaultBackgroundColor="var(--colors-blue-1)"
        largeBackIconTransform="unset"
        onBackButton2Click1={onBackButton1Click}
      />
      <Breadcrumpitem>
        <News newsColor={newsColor}>Back to news list</News>
      </Breadcrumpitem>
    </Property1navigationPropertRoot>
  );
};

export default Property1NavigationPropert;
