import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1Default11Type = {
  largeVolumeEnable?: string;

  /** Style props */
  property1DefaultCursor?: CSSProperties["cursor"];
  property1DefaultBorderW?: CSSProperties["borderWidth"];
  property1DefaultBorderS?: CSSProperties["borderStyle"];
  property1DefaultBorderC?: CSSProperties["borderColor"];
  property1DefaultPadding?: CSSProperties["padding"];
  property1DefaultBackgroundColor?: CSSProperties["backgroundColor"];

  /** Action props */
  onPlayAudioClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const LargevolumeenableIcon = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  opacity: 0.8;
  filter: var(--filters-dark-1);
`;
const Property1defaultRoot = styled.div<{
  property1DefaultCursor?: Property.Cursor;
  property1DefaultBorderW?: Property.BorderWidth;
  property1DefaultBorderC?: Property.BorderColor;
  property1DefaultBorderS?: Property.BorderStyle;
  property1DefaultPadding?: Property.Padding;
  property1DefaultBackgroundColor?: Property.BackgroundColor;
}>`border-radius: var(--corner-radius-round);
  background-color: var(--colors-blue-1);
  --side: min(1.6666666666666667vw,3.125vh);
  width: var(--side);
  height: var(--side);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: ${(p) => p.property1DefaultCursor};
  border-width: ${(p) => p.property1DefaultBorderW};
  border-color: ${(p) => p.property1DefaultBorderC};
  border-style: ${(p) => p.property1DefaultBorderS};
  padding: ${(p) => p.property1DefaultPadding};
  background-color: ${(p) => p.property1DefaultBackgroundColor};
  &:hover {
    background-color: var(--colors-darkerblue);
    & img {
      filter: var(--filters-btn-txt);
    }
  }
`;

const Property1Default11: FunctionComponent<Property1Default11Type> = ({
  largeVolumeEnable,
  property1DefaultCursor,
  property1DefaultBorderW,
  property1DefaultBorderC,
  property1DefaultBorderS,
  property1DefaultPadding,
  property1DefaultBackgroundColor,
  onPlayAudioClick,
}) => {
  return (
    <Property1defaultRoot
      property1DefaultCursor={property1DefaultCursor}
      property1DefaultBorderW={property1DefaultBorderW}
      property1DefaultBorderC={property1DefaultBorderC}
      property1DefaultBorderS={property1DefaultBorderS}
      property1DefaultPadding={property1DefaultPadding}
      property1DefaultBackgroundColor={property1DefaultBackgroundColor}
      onClick={onPlayAudioClick}
    >
      <LargevolumeenableIcon alt="" src={largeVolumeEnable} />
    </Property1defaultRoot>
  );
};

export default Property1Default11;
