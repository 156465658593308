import { FunctionComponent, useState, useCallback, forwardRef } from "react";
import AlertsTabNavigation from "./AlertsTabNavigation";
import styled from "styled-components";
import StateDefaultEmptyYesChec from "./StateDefaultEmptyYesChec";
import StateHoverIconno from "./StateHoverIconno";
import HoverNo from "./HoverNo";
import Property1Default10 from "./Property1Default10";
import TutorsContainer from "./TutorsContainer";
import FormContainerMyAlerts from "./FormContainerMyAlerts";
import ScrollHolder from "../components/ScrollHolder";

const TabsFixed = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-5xsW);
`;
const MyLearningActivities = styled.h3`
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 2.604166666666667vh;
  font-weight: 600;
  font-family: inherit;
  display: flex;
  align-items: center;
  width: 40.27777777777778vw;
  flex-shrink: 0;
`;
const MyLearningActivitiesWrapper = styled.div`
  align-self: stretch;
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-5xsW) 0.0vh var(--padding-9xlW);
  box-sizing: border-box;
`;
const Language = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 0.0vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Tutor = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 7.013888888888889vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Target = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 23.47222222222222vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Deadline = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 48.05555555555556vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const TutorCode = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 56.25vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Tableheadera = styled.div`
  position: relative;
  width: 63.61111111111111vw;
  height: 2.604166666666667vh;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: min-content;
  color: var(--colors-purple-dark);
`;
const ActivitiesholderChild = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  height: 0.13020833333333331vh;
`;
const Spanish = styled.div`
  position: absolute;
  top: 2.34375vh;
  left: 0.0vw;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 5.625vw;
  height: 2.604166666666667vh;
`;
const NoTutor = styled.div`
  position: absolute;
  top: 2.34375vh;
  left: 7.013888888888889vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ActiveSpanishUse = styled.p`
  margin: 0;
`;
const ActiveSpanishUseContainer = styled.div`
  position: absolute;
  top: 1.0416666666666665vh;
  left: 23.47222222222222vw;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const NotSet = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 5.625vw;
  height: 2.604166666666667vh;
  flex-shrink: 0;
`;
const NotSetWrapper = styled.div`
  position: absolute;
  top: 1.0416666666666665vh;
  left: 48.05555555555556vw;
  width: 7.222222222222221vw;
  height: 5.208333333333334vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const TextfieldsmallWrapper = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
const FrameGroup = styled.div`
  position: absolute;
  top: 1.0416666666666665vh;
  left: 56.25vw;
  width: 21.805555555555557vw;
  height: 5.208333333333334vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const Tablecontenta = styled.div`
  position: relative;
  width: 78.05555555555556vw;
  height: 7.291666666666667vh;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: min-content;
`;
const Alejandrolpez = styled.div`
  position: absolute;
  top: 1.3020833333333335vh;
  left: 2.2222222222222223vw;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 7.569444444444444vw;
`;
const CallParent = styled.div`
  position: absolute;
  top: 1.0416666666666665vh;
  left: 7.083333333333333vw;
  width: 12.222222222222221vw;
  height: 5.208333333333334vh;
  color: var(--colors-darkerblue);
`;
const ActiveSpanishUse1 = styled.div`
  position: absolute;
  top: 2.34375vh;
  left: 23.47222222222222vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Activitiesholder = styled.div`
  border-radius: var(--br-xs);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 80.41666666666667vw;
  max-height: 65vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-baseH) var(--padding-baseW);
  gap: var(--gap-5xs);
  font-size: var(--body-main-size);
`;
const FrameParent = styled.div`
  width: 80.27777777777779vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const SaveSession = styled.div`
  position: relative;
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
  font-weight: 500;
  font-family: var(--buttons-btn-input);
  color: var(--colors-btn-txt);
  text-align: left;
`;
const Buttonsecondary = styled.button`
  cursor: pointer;
  border: none;
  padding: 0.0vh var(--padding-xsW);
  background-color: var(--colors-darkerblue);
  border-radius: var(--corner-radius-l);
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;
const TabMyLearningActivities = styled.section`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
`;
const Page2MyGoalsRoot = styled.section`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) 1vw 0vh;
  gap: var(--gap-21xl);
`;

type TNType = {
  tutorName: string;
  onCallTutClick: (e: React.MouseEvent<HTMLElement>)=> void;
}
const TutorName: FunctionComponent<TNType> = ({ tutorName,onCallTutClick }) => (
  tutorName ? 
  <CallParent>
    <Property1Default10
      smallCall="/Call.svg"
      property1DefaultCursor="pointer"
      property1DefaultBorderW="none"
      property1DefaultBorderC="none"
      property1DefaultBorderS="none"
      property1DefaultPadding="0"
      property1DefaultPosition="absolute"
      property1DefaultTop="1.0416666666666665vh"
      property1DefaultLeft="0.0vw"
      property1DefaultBackgroundColor="var(--colors-green-light)"
      CallTutor={onCallTutClick}
    />
    <Alejandrolpez>{ tutorName }</Alejandrolpez>
  </CallParent>
  :
  <NoTutor>No Tutor</NoTutor>
);
type TDType = {
  target: string;
}
const TargetData: FunctionComponent<TDType> = ({ target }) => (
  target.length>50?
  <ActiveSpanishUseContainer>
    <ActiveSpanishUse>
      {target.substring(0, 50)}
    </ActiveSpanishUse>
    <ActiveSpanishUse>
      {target.substring(50,target.length)}
    </ActiveSpanishUse>
  </ActiveSpanishUseContainer>
  :
  <ActiveSpanishUse1>
    {target}
  </ActiveSpanishUse1>
);
type TCIType = {
  tutorCode: string;
  onButtonDelete24x24Click: () => void;
  onCheckClick2: (e: React.MouseEvent<HTMLElement>)=> void;
  onButtonSecondary6Click: (e: React.MouseEvent<HTMLElement>)=> void;
  delact: boolean;
}
const TutorCodeInput: FunctionComponent<TCIType > = ({ delact,tutorCode, onButtonDelete24x24Click,onCheckClick2,onButtonSecondary6Click}) => (
  <FrameGroup>
    {false && <TextfieldsmallWrapper>
      <StateDefaultEmptyYesChec
        value={tutorCode}
        stateDefaultEmptyYesChecBoxSizing="border-box"
        stateDefaultEmptyYesChecPosition="unset"
        stateDefaultEmptyYesChecGap="unset"
        stateDefaultEmptyYesChecOutline="none"
        stateDefaultEmptyYesChecFontFamily="Onest"
        stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
        stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
        stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-5xsW) var(--padding-9xsH) var(--padding-5xsW)"
        stateDefaultEmptyYesChecAlignSelf="stretch"
        stateDefaultEmptyYesChecWidth="unset"
        stateDefaultEmptyYesChecHeight="unset"
        stateDefaultEmptyYesChecFontWeight="unset"
        stateDefaultEmptyYesChecFlex="unset"
        stateDefaultEmptyYesChecZIndex="unset"
        stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
        stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
        stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
        stateDefaultEmptyYesChecBorderS="solid"
        stateDefaultEmptyTextWidth="85%"
      />
    </TextfieldsmallWrapper>}
    {delact?<StateHoverIconno
      saveSession="Study Now"
      stateHoverIconnoJustifyContent="center"
      stateHoverIconnoWidth="unset"
      stateHoverIconnoZIndex="unset"
      stateHoverIconnoBackgroundColor="transparent"
      stateHoverIconnoCursor="pointer"
      stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
      stateHoverIconnoBorderC="var(--colors-darkerblue)"
      stateHoverIconnoBorderS="solid"
      stateHoverIconnoHeight="unset"
      stateHoverIconnoPadding="var(--padding-9xsH) var(--padding-xsW)"
      stateHoverIconnoBoxSizing="border-box"
      stateHoverIconnoPosition="unset"
      stateHoverIconnoTop="unset"
      stateHoverIconnoLeft="unset"
      saveSessionColor="var(--colors-darkerblue)"
      saveSessionDisplay="inline-block"
      onButtonSecondaryClick={onButtonSecondary6Click}
    />:<>Active</>}
    {delact && <HoverNo
      mediumDelete="/DeleteGoal&TutoringLang.svg"
      hoverNoCursor="pointer"
      hoverNoBorderW="none"
      hoverNoBorderC="none"
      hoverNoBorderS="none"
      hoverNoBackgroundColor="transparent"
      onButtonDelete24x24Click={onButtonDelete24x24Click}
    />}
  </FrameGroup>
);

type ActType = {
  Activities: {
    id: number;
    language: string,
    tutorName: string,
    target: string,
    deadline: string,
    tutorCode: string
    act:boolean;
  }[];
  onCallTutClick: (e: React.MouseEvent<HTMLElement>)=> void;
  onCheckClick2: (e: React.MouseEvent<HTMLElement>)=> void;
  onButtonSecondary6Click: (e: React.MouseEvent<HTMLElement>, index: number)=> void;
}
const Activities = forwardRef<HTMLDivElement, ActType>(({ Activities: initialActivities,onCallTutClick,onCheckClick2,onButtonSecondary6Click, }, ref) => {
  const [activities, setActivities] = useState(initialActivities);
  const handleDeleteActivity = useCallback((activityKey: string, gid: number) => {
    if (window.confirm("Are You sure You want to Delete this Goal permanently?")) {
      fetch('/DeleteGoal', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({'gid': gid})
      })
      .then(async response => {
        return await response.json()
      }).then(data => {
        if(data.ok) {
          setActivities(currentActivities => currentActivities.filter((_, index) => `GoalLine${index}` !== activityKey));
        } else {
          console.error(data.msg);
          alert(data.msg)
        }
      })
    }
  }, []);
  return <Activitiesholder ref={ref}>
    <Tableheadera>
      <Language>Language</Language>
      {false && <Tutor>Tutor</Tutor>}
      <Target>Target</Target>
      <Deadline>Deadline</Deadline>
      {false && <TutorCode>Tutor Code</TutorCode>}
    </Tableheadera>
    {activities.map((activity, index) => (
      <>
        <ActivitiesholderChild />
        <Tablecontenta>
          <Spanish>{activity.language}</Spanish>
          {false && <TutorName tutorName={activity.tutorName} onCallTutClick={onCallTutClick} />}
          <TargetData target={activity.target} />
          <NotSetWrapper>
            <NotSet>{activity.deadline}</NotSet>
          </NotSetWrapper>
          <TutorCodeInput delact={activity.act} tutorCode={activity.tutorCode} onCheckClick2={onCheckClick2} onButtonSecondary6Click={(e)=>onButtonSecondary6Click(e,activity.id)} onButtonDelete24x24Click={() => handleDeleteActivity("GoalLine"+String(index),activity.id)} />
        </Tablecontenta>
      </>
    ))}
  </Activitiesholder>
});

const FormContainer4: FunctionComponent<{
    ScrollObj: {
      scrollRef: React.RefObject<HTMLDivElement>;
      siblingRef: React.RefObject<HTMLDivElement>;
      scrollIndicatorHeight: string;
      scrollIndicatorTop: string;
      recalculateScrollIndicator: () => Promise<void>;
    };
    ScrollObjTL: {
      scrollRef: React.RefObject<HTMLDivElement>;
      siblingRef: React.RefObject<HTMLDivElement>;
      scrollIndicatorHeight: string;
      scrollIndicatorTop: string;
      recalculateScrollIndicator: () => Promise<void>;
    };
    ScrollObjMA: {
      scrollRef: React.RefObject<HTMLDivElement>;
      siblingRef: React.RefObject<HTMLDivElement>;
      scrollIndicatorHeight: string;
      scrollIndicatorTop: string;
      recalculateScrollIndicator: () => Promise<void>;
    };
    ScrollObjMAH: {
      scrollRef: React.RefObject<HTMLDivElement>;
      siblingRef: React.RefObject<HTMLDivElement>;
      scrollIndicatorHeight: string;
      scrollIndicatorTop: string;
      recalculateScrollIndicator: () => Promise<void>;
    };
    TutPageScroll: {
      scrollRef: React.RefObject<HTMLDivElement>;
      siblingRef: React.RefObject<HTMLDivElement>;
      scrollIndicatorHeight: string;
      scrollIndicatorTop: string;
      recalculateScrollIndicator: () => Promise<void>;
    };
    ActivitiesV: {
      id: number;
      language: string;
      tutorName: string;
      target: string;
      deadline: string;
      tutorCode: string;
      act:boolean;
    }[]
  }> = ({ScrollObj,ScrollObjTL,ScrollObjMA,ScrollObjMAH,TutPageScroll,ActivitiesV}) => {
  const [textFieldSmall2Value, setTextFieldSmall2Value] = useState("");
  const [textFieldSmall3Value, setTextFieldSmall3Value] = useState("");

  const onButtonSecondary6Click = useCallback(
    (e: React.MouseEvent<HTMLElement>,index:number) => {
      fetch('/SetParams', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({'index': index})
      })
      .then(response => {
        if(response.ok) {  // Check if the request was successful
          window.location.href = '/';
        } else {
          console.error('Request failed.');
        }
      })
    },
    []
  );

  const onRadioButtonClick1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onSortingItemClick1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onSortingItem1Click1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary12Click1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary7Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      // Send the data using Fetch API
      fetch('/GetNewGoal', {
          method: 'POST'
      })
      .then(response => {
        if(response.ok) {  // Check if the request was successful
          window.location.href = '/';
        } else {
          console.error('Request failed.');
          alert("Error while processing request");
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert("Error while processing request");
      });
    },
    []
  );

  const onCallTutClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onCheckClick2 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );
  
  const [triggerTL,setTriggerTL] = useState(false)

  return (
    <Page2MyGoalsRoot id="MyGoalsSection">
      <TabsFixed>
        <AlertsTabNavigation TLTrig={()=>setTriggerTL(true)} TLScrollRec={ScrollObjTL.recalculateScrollIndicator} />
      </TabsFixed>
      <TabMyLearningActivities id="LearnSection">
        <FrameParent>
          <MyLearningActivitiesWrapper>
            <MyLearningActivities>My Learning Activities</MyLearningActivities>
          </MyLearningActivitiesWrapper>
          <Activities 
          Activities={ActivitiesV}
          onCallTutClick={onCallTutClick}
          onCheckClick2={onCheckClick2}
          onButtonSecondary6Click={onButtonSecondary6Click}
          ref={ScrollObj.siblingRef}
          />
          <ScrollHolder
            scrollHeight={ScrollObj.scrollIndicatorHeight}
            scrollIndicatorTop={ScrollObj.scrollIndicatorTop}
            ref={ScrollObj.scrollRef}
            scrollHolderHeight="65vh"
            scrollHolderAlignItems="unset"
            scrollHolderPosition="absolute"
            scrollHolderTop="23vh"
            scrollHolderLeft="98vw"
            scrollHolderMargin="unset"
            scrollHolderRight="unset"
            scrollHolderFlexShrink="unset"
            scrollHolderZIndex="unset"
            scrollHolderBackgroundColor="var(--colors-grey-stroke)"
            scrollBackgroundColor="var(--colors-purple-light)"
          />
        </FrameParent>
        <Buttonsecondary onClick={onButtonSecondary7Click}>
          <SaveSession>Create New Goal</SaveSession>
        </Buttonsecondary>
      </TabMyLearningActivities>
      {false && <TutorsContainer
        triggerTL={triggerTL}
        tutorCardsData={[
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English, Spanish",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "Afrikaans, English, Spanish",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "Afrikaans, English, Spanish, Thai, Portugese Brazil",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English, Spanish",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: false,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "Afrikaans, English, Spanish",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          },
          {
            id: 0,
            photo: "/photoholder@2x.png",
            name: "Anika Fourie",
            available: true,
            rating: 4.8,
            price: 25,
            language: "English",
            comments:[
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              },
              {
                name:"Jane Smith",
                rate:4.8,
                date:"11.11.2023",
                comment:"Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot! Excellent lessons, I liked it a lot!",
              }
            ]
          }
        ]}
        propColor="var(--colors-purple-dark)"
        propBorderW="1p"
        propBorderC="var(--colors-purple-light)"
        propBorderS="solid"
        propCursor="pointer"
        propBorderRight="0.06944444444444445vw solid var(--colors-grey-stroke)"
        propBorderRight1="0.06944444444444445vw solid var(--colors-grey-stroke)"
        propBorderRight2="0.06944444444444445vw solid var(--colors-grey-stroke)"
        onRadioButtonClick={onRadioButtonClick1}
        onSortingItemClick={onSortingItemClick1}
        onSortingItem1Click={onSortingItem1Click1}
        ScrollObj={ScrollObjTL}
        TutPageScroll={TutPageScroll}
      />}
      {false && <FormContainerMyAlerts
        EventsIn={
          [
            {
              text:"New Lesson with Teacher Alexandro",
              date:"20 November",
              weekDay:"Monday",
              start:"15:30",
              end:"16:30",
              tags:["New lesson request","Unresolved"],
              acts:[
                {
                  text:"Time change",
                  call:"TC",
                },
                {
                  text:"Decline",
                  call:"Dec",
                },
                {
                  text:"Accept",
                  call:"Acc",
                },
              ]
            },
            {
              text:"Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson  Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson",
              date:"20 November",
              weekDay:"Monday",
              start:"15:30",
              end:"16:30",
              tags:["New lesson request","Unresolved"],
              acts:[
                {
                  text:"Time change",
                  call:"TC",
                },
                {
                  text:"Decline",
                  call:"Dec",
                },
                {
                  text:"Accept",
                  call:"Acc",
                },
              ]
            },
            {
              text:"Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson  Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson",
              date:"20 November",
              weekDay:"Monday",
              start:"15:30",
              end:"16:30",
              tags:["New lesson request","Unresolved"],
              acts:[
                {
                  text:"Time change",
                  call:"TC",
                },
                {
                  text:"Decline",
                  call:"Dec",
                },
                {
                  text:"Accept",
                  call:"Acc",
                },
              ]
            },
            {
              text:"Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson  Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson",
              date:"20 November",
              weekDay:"Monday",
              start:"15:30",
              end:"16:30",
              tags:["New lesson request","Unresolved"],
              acts:[
                {
                  text:"Time change",
                  call:"TC",
                },
                {
                  text:"Decline",
                  call:"Dec",
                },
                {
                  text:"Accept",
                  call:"Acc",
                },
              ]
            }
          ]
        }
        EventsHIn={
          [
            {
              text:"New Lesson with Teacher Alexandro",
              date:"20 November",
              weekDay:"Monday",
              start:"15:30",
              end:"16:30",
              tags:["New lesson request","Unresolved"],
            },
            {
              text:"Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson  Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson",
              date:"20 November",
              weekDay:"Monday",
              start:"15:30",
              end:"16:30",
              tags:["New lesson request","Unresolved"],
            },
            {
              text:"Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson  Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson",
              date:"20 November",
              weekDay:"Monday",
              start:"15:30",
              end:"16:30",
              tags:["New lesson request","Unresolved"],
            },
            {
              text:"Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson  Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson",
              date:"20 November",
              weekDay:"Monday",
              start:"15:30",
              end:"16:30",
              tags:["New lesson request","Unresolved"],
            }
          ]
        }
        APointsIn={
          [
            {
              text:"Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson  Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson",
              date:"20 November",
              weekDay:"Monday",
              start:"01.01.2024 15:30",
              end:"03.01.2024 16:30",
              tags:["New lesson request","Unresolved"],
              acts:[
                {
                  text:"Time change",
                  call:"TC",
                },
                {
                  text:"Decline",
                  call:"Dec",
                },
                {
                  text:"Accept",
                  call:"Acc",
                },
              ]
            }
          ]
        }
        APointsHIn={
          [
            {
              text:"Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson  Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson Teacher Alexandro proposes to change the time of the first lesson",
              date:"20 November",
              weekDay:"Monday",
              start:"01.01.2024 15:30",
              end:"03.01.2024 16:30",
              tags:["New lesson request","Unresolved"],
            }
          ]
        }
        NotifsIn={
          [
            {
              text:"You accepted a request",
              date:"20 November",
              weekDay:"Monday",
              start:"15:30",
              tags:["New lesson request","Unresolved"],
            }
          ]
        }
        NotifsHIn={
          [
            {
              text:"You accepted a request",
              date:"20 November",
              weekDay:"Monday",
              start:"15:30",
              tags:["New lesson request","Unresolved"],
            }
          ]
        }
        iconCode="/mediumdropdownarrow@2x.png"
        iconCodeImageUrl="/largeback@2x.png"
        iconCodeText="/largeback@2x.png"
        iconCodeTimestamp="/mediumdropdownarrow@2x.png"
        iconCodeDimensions="/mediumdropdownarrow@2x.png"
        propColor="var(--colors-dark-1)"
        propColor1="var(--colors-dark-1)"
        propColor2="var(--colors-dark-1)"
        propColor3="var(--colors-dark-1)"
        propColor33="var(--colors-dark-1)"
        propColor39="var(--colors-dark-1)"
        propBackgroundColor="var(--colors-white)"
        propBackgroundColor1="var(--colors-grey-bg)"
        propBackgroundColor2="var(--colors-blue-1)"
        propBackgroundColor3="var(--colors-blue-1)"
        propBackgroundColor20="var(--colors-grey-bg)"
        propBackgroundColor25="var(--colors-grey-bg)"
        propBorderBottom="0.13020833333333331vh solid var(--colors-grey-stroke)"
        propBorderBottom1="0.13020833333333331vh solid var(--colors-grey-stroke)"
        propBorderBottom2="0.13020833333333331vh solid var(--colors-grey-stroke)"
        propBorderBottom3="0.13020833333333331vh solid var(--colors-grey-stroke)"
        propBorderW="0.130208333vh 0.0694444444vw"
        propBorderS="solid"
        propBorderC="var(--colors-grey-stroke)"
        onButtonSecondary12Click={onButtonSecondary12Click1}
        ScrollObjAl={ScrollObjMA}
        ScrollObjAlH={ScrollObjMAH}
      />}
    </Page2MyGoalsRoot>
  );
};

export default FormContainer4;
