import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Type3PagesType = {
  news?: string;
  news1?: string;
  news2?: string;

  /** Style props */
  type3PagesWidth?: CSSProperties["width"];
  type3PagesPosition?: CSSProperties["position"];
  type3PagesTop?: CSSProperties["top"];
  type3PagesLeft?: CSSProperties["left"];
  type3PagesFlexShrink?: CSSProperties["flexShrink"];
  type3PagesPadding?: CSSProperties["padding"];
  type3PagesBoxSizing?: CSSProperties["boxSizing"];
  newsColor?: CSSProperties["color"];
  newsColor1?: CSSProperties["color"];
  newsColor2?: CSSProperties["color"];
  ToClose: (e: React.MouseEvent<HTMLElement>) => void;
};

const News = styled.div<{ newsColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.newsColor};
`;
const Breadcrumpitem = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const News1 = styled.div<{ newsColor1?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.newsColor1};
`;
const News2 = styled.div<{ newsColor2?: Property.Color }>`
  flex: 1;
  position: relative;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.newsColor2};
`;
const Breadcrumpitem2 = styled.div`
  width: 14.027777777777779vw;
  height: 2.604166666666667vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const Type3PagesRoot = styled.div<{
  type3PagesWidth?: Property.Width;
  type3PagesPosition?: Property.Position;
  type3PagesTop?: Property.Top;
  type3PagesLeft?: Property.Left;
  type3PagesFlexShrink?: Property.FlexShrink;
  type3PagesPadding?: Property.Padding;
  type3PagesBoxSizing?: Property.BoxSizing;
}>`width: 40.27777777777778vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-purple-dark);
  font-family: var(--buttons-btn-input);
  width: ${(p) => p.type3PagesWidth};
  position: ${(p) => p.type3PagesPosition};
  top: ${(p) => p.type3PagesTop};
  left: ${(p) => p.type3PagesLeft};
  flex-shrink: ${(p) => p.type3PagesFlexShrink};
  padding: ${(p) => p.type3PagesPadding};
  box-sizing: ${(p) => p.type3PagesBoxSizing};
`;

const Type3Pages: FunctionComponent<Type3PagesType> = ({
  news,
  news1,
  news2,
  type3PagesWidth,
  type3PagesPosition,
  type3PagesTop,
  type3PagesLeft,
  type3PagesFlexShrink,
  type3PagesPadding,
  type3PagesBoxSizing,
  newsColor,
  newsColor1,
  newsColor2,
  ToClose
}) => {
  return (
    <Type3PagesRoot
      type3PagesWidth={type3PagesWidth}
      type3PagesPosition={type3PagesPosition}
      type3PagesTop={type3PagesTop}
      type3PagesLeft={type3PagesLeft}
      type3PagesFlexShrink={type3PagesFlexShrink}
      type3PagesPadding={type3PagesPadding}
      type3PagesBoxSizing={type3PagesBoxSizing}
    >
      <Breadcrumpitem onClick={ToClose}>
        <News newsColor={newsColor}>{news}</News>
      </Breadcrumpitem>
      <Breadcrumpitem onClick={ToClose}>
        <News1 newsColor1={newsColor1}>{news1}</News1>
      </Breadcrumpitem>
      <Breadcrumpitem2>
        <News2 newsColor2={newsColor2}>{news2}</News2>
      </Breadcrumpitem2>
    </Type3PagesRoot>
  );
};

export default Type3Pages;
