import { FunctionComponent, useState, useCallback, type CSSProperties, forwardRef } from "react";
import ContainerWithButtons from "./ContainerWithButtons";
import styled from "styled-components";
import Property1Default9 from "./Property1Default9";
import StateDefaultEmptyYesChec from "./StateDefaultEmptyYesChec";
import Property1Default6 from "./Property1Default6";
import StateHoverIconno from "./StateHoverIconno";
import StateDefault from "./StateDefault";
import SidebarStats from "./SidebarStats";

const Children = styled.h3`
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: inherit;
`;
const Rate = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 0;
`;
const Radiobutton = styled.input`
  margin: 0 !important;
  position: absolute;
  top: 0.71614583333vh;
  left: 4.54861111111vw;
  border-radius: var(--corner-radius-s);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-purple-light);
  box-sizing: border-box;
  width: 1.1805555555555556vw;
  height: 2.213541666666667vh;
  cursor: pointer;
  z-index: 1;
`;
const Sortingitem = styled.div`
  width: 5.694444444444445vw;
  height: 3.6458333333333335vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-5xs);
`;
const SortingfiltersChild = styled.div`
  position: relative;
  border-right: 0.06944444444444445vw solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 0.06944444444444445vw;
  height: 2.473958333333333vh;
`;
const Sortingfilters = styled.div`
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-base);
  font-size: var(--body-main-size);
  color: var(--colors-purple-dark);
`;
const ChildrenParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.0vh 0.0vw 0.0vh var(--padding-9xlW);
`;
const DanielShtein = styled.div`
  position: relative;
  font-size: var(--buttons-btn-input-size);
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: var(--buttons-btn-input);
  color: var(--colors-dark-1);
  text-align: left;
`;
const DanielShteinWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const MediumdropdownarrowIcon = styled.img<{IsRotated: boolean}>`
  position: relative;
  --side: min(1.3888888888888888vw,2.604166666666667vh);
  width: var(--side);
  height: var(--side);
  object-fit: cover;
  transform: ${({ IsRotated }) => IsRotated ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease;
  filter: var(--filters-purple-dark);
`;
const FrameGroup = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const FrameWrapper = styled.div`
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 80.27777777777779vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-9xlW);
`;
const TabsInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Name1 = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
`;
const NameParent = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
`;
const LeftAreaInner = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;
const LeftArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const Language = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
`;
const RightArea = styled.div`
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: var(--gap-xs);
`;
const CreateNewTab = styled.div`
  align-self: stretch;
  height: 6.770833333333333vh;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
const CreateNewTabWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Language2 = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 0.0vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.8;
`;
const Target = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 12.5vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.8;
`;
const Deadline = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 28.888888888888886vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.8;
`;
const SetupBy = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 37.083333333333336vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.8;
`;
const Progress = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 46.94444444444444vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.8;
`;
const AddBonus = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 67.5vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.8;
`;
const FrameChild = styled.div`
  position: absolute;
  top: 4.1015625vh;
  left: -0.03472222222vw;
  border-top: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 75.90277777777777vw;
  height: 0.13020833333333331vh;
`;
const LanguageParent = styled.div`
  position: relative;
  width: 75.83333333333333vw;
  height: 4.166666666666666vh;
`;
const LanguageBlocks = styled.div`
  align-self: stretch;
  border-radius: var(--br-base);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const EnglishUs = styled.div`
  position: absolute;
  top: 1.3020833333333335vh;
  left: 0.0vw;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ActiveEnglishUs = styled.p`
  margin: 0;
`;
const ActiveEnglishUsContainer = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 12.5vw;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 13.88888888888889vw;
`;
const Div = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 2.604166666666667vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Parent1 = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 28.888888888888886vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const DanieShtein = styled.div`
  position: absolute;
  top: 0.0vh;
  left: 37.083333333333336vw;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 6.458333333333334vw;
`;
const Earned1 = styled.div`
  position: relative;
  line-height: 1.5625vh;
`;
const Div2 = styled.div`
  position: relative;
  line-height: 1.5625vh;
  color: var(--colors-green);
`;
const Earned = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const InfoArea = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const ProgressBarChild = styled.div<{Width:CSSProperties["width"]}>`
  position: absolute;
  height: 100%;
  width: ${(p) => p.Width};
  top: 0.0vh;
  right: 82.88%;
  bottom: 0.0vh;
  left: 0%;
  border-radius: var(--br-5xs);
  background-color: var(--colors-green);
`;
const ProgressBar = styled.div`
  align-self: stretch;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--colors-grey-stroke);
  height: 1.5625vh;
  overflow: hidden;
  flex-shrink: 0;
`;
const Progress1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const StatsProgress = styled.div`
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  z-index: 0;
`;
const StatsProgressWrapper = styled.div`
  position: absolute;
  top: 0.78125vh;
  left: 46.94444444444444vw;
  width: 18.88888888888889vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: var(--caption-cap-12-size);
`;
const Row = styled.div`
  position: relative;
  width: 75.83333333333333vw;
  height: 5.208333333333334vh;
`;
const Non = styled.div`
  position: absolute;
  top: calc(50% - 1.3020833333333335vh);
  left: 12.5vw;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const NonWrapper = styled.div`
  position: absolute;
  top: calc(50% - 1.3020833333333335vh);
  left: 28.888888888888886vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const OnHover = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--colors-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-baseH) var(--padding-baseW);
  gap: var(--gap-base);
  font-size: var(--body-main-size);
`;
const FrameContainer = styled.div<{IsHidden?: boolean}>`
  align-self: stretch;
  background-color: var(--colors-grey-bg);
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-baseH) var(--padding-baseW);
  gap: var(--gap-5xl);
  display: ${(p) => p.IsHidden?"none":"flex"};
`;
const Tabs = styled.div`
  border-radius: var(--br-xs);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 80.41666666666667vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--caption-cap-12-size);
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const TabParentRoot = styled.section`
  border-radius: var(--br-xs);
  width: 80.2777778vw;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  z-index: 0;
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  height: 84.5vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

type rowType = {
  language:string;
  activity:string;
  date:string;
  time:string;
  tutor:string;
  earned:number;
  total:number;
  tokenFieldValue?:number;
}

type StudT = {
  rowData: rowType[];
  Name: string;
}

type TeachT = {
  Students: StudT[]; 
}

type SBT = {
  Student: StudT;
  sindex: number;
  onButtonSecondary35Click: (e:React.MouseEvent<HTMLElement>)=>void;
}
const StudBlock: FunctionComponent<SBT> = ({Student, sindex,onButtonSecondary35Click,}) => {
  const [IsHidden, setIsHidden] = useState(true);
  const [IsRotated, setIsRotated] = useState(false);
  const ChangeHideState = () => {
    setIsHidden(!IsHidden);
    setIsRotated(!IsRotated)
  };
  return (
  <>
    <TabsInner>
      <FrameWrapper>
        <FrameGroup onClick={ChangeHideState}>
          <DanielShteinWrapper>
            <DanielShtein>{Student.Name}</DanielShtein>
          </DanielShteinWrapper>
          <MediumdropdownarrowIcon
            alt=""
            src="/DropdownLocerArrow.svg"
            IsRotated={IsRotated}
          />
        </FrameGroup>
      </FrameWrapper>
    </TabsInner>
    <FrameContainer IsHidden={IsHidden}>
      <CreateNewTabWrapper>
        <CreateNewTab>
          <LeftArea>
            <LeftAreaInner>
              <NameParent>
                <Name1>Name</Name1>
                <StateDefaultEmptyYesChec
                  stateDefaultEmptyYesChecBoxSizing="border-box"
                  stateDefaultEmptyYesChecPosition="unset"
                  stateDefaultEmptyYesChecGap="unset"
                  stateDefaultEmptyYesChecOutline="none"
                  stateDefaultEmptyYesChecFontFamily="Onest"
                  stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                  stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                  stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-5xsW)"
                  stateDefaultEmptyYesChecAlignSelf="unset"
                  stateDefaultEmptyYesChecWidth="17.36111111111111vw"
                  stateDefaultEmptyYesChecHeight="3.7760416666666665vh"
                  stateDefaultEmptyYesChecFontWeight="500"
                  stateDefaultEmptyYesChecFlex="unset"
                  stateDefaultEmptyYesChecZIndex="unset"
                  stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                  stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                  stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                  stateDefaultEmptyYesChecBorderS="solid"
                  stateDefaultEmptyTextWidth="90%"
                />
              </NameParent>
            </LeftAreaInner>
            <NameParent>
              <Name1>Type</Name1>
              <Property1Default6
                property1DefaultWidth="11.180555555555555vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="unset"
                property1DefaultLeft="unset"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="Onest"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="1.8229166666666667vh"
                property1DefaultColor="var(--colors-purple-light)"
                property1DefaultAlignSelf="unset"
                options={["1","2"]}
                value={"1"}
              />
            </NameParent>
          </LeftArea>
          <RightArea>
            <Language>
              <Name1>Language</Name1>
              <Property1Default6
                property1DefaultWidth="11.180555555555555vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="unset"
                property1DefaultLeft="unset"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="Onest"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="1.8229166666666667vh"
                property1DefaultColor="var(--colors-purple-light)"
                property1DefaultAlignSelf="unset"
                options={["English","Russian"]}
                value={"English"}
              />
            </Language>
            <StateHoverIconno
              saveSession="Initiate Goal for Child Account"
              stateHoverIconnoJustifyContent="flex-start"
              stateHoverIconnoWidth="unset"
              stateHoverIconnoZIndex="unset"
              stateHoverIconnoBackgroundColor="transparent"
              stateHoverIconnoCursor="pointer"
              stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
              stateHoverIconnoBorderS="solid"
              stateHoverIconnoBorderC="var(--colors-darkerblue)"
              stateHoverIconnoHeight="4.166666666666666vh"
              stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
              stateHoverIconnoBoxSizing="border-box"
              stateHoverIconnoPosition="unset"
              stateHoverIconnoTop="unset"
              stateHoverIconnoLeft="unset"
              saveSessionColor="var(--colors-darkerblue)"
              saveSessionDisplay="inline-block"
              onButtonSecondaryClick={onButtonSecondary35Click}
            />
          </RightArea>
        </CreateNewTab>
      </CreateNewTabWrapper>
      <OnHover>
        <LanguageBlocks>
          <LanguageParent>
            <Language2>Language</Language2>
            <Target>Target</Target>
            <Deadline>Deadline</Deadline>
            <SetupBy>Setup by</SetupBy>
            <Progress>Progress</Progress>
            <AddBonus>Add bonus</AddBonus>
            <FrameChild />
          </LanguageParent>
        </LanguageBlocks>
        {(() => {
        const PopUp: FunctionComponent = () => {
          const [isSidebarVisible, setIsSidebarVisible] = useState(false);
          const [SidebarTab, setSidebarTab] = useState(0);
          const CreateToggleSidebar = (index: number) => {
            const toggleSidebar = () => {
              setSidebarTab(index);
              setIsSidebarVisible(!isSidebarVisible);
            };
            return toggleSidebar
          };
          const toggleSidebarGlobal = () => {
            setIsSidebarVisible(!isSidebarVisible);
          };
          const [TabsData, setTabsData] = useState(Student.rowData);
          return (
            <>
              {
                Student.rowData.map((item, index) => {
                  return <Row key={index}>
                    <EnglishUs>{item.language}</EnglishUs>
                    {
                      item.activity.length>0?
                      <ActiveEnglishUsContainer>
                        <ActiveEnglishUs>{item.activity}</ActiveEnglishUs>
                      </ActiveEnglishUsContainer>
                      :
                      <Non>Non</Non>
                    }
                    {
                      item.date==="" && item.time===""?
                      <NonWrapper>
                        <Div>Non</Div>
                      </NonWrapper>
                      :
                      <Parent1>
                        <Div>{item.date}</Div>
                        <Div>{item.time}</Div>
                      </Parent1>
                    }
                    <DanieShtein>{item.tutor}</DanieShtein>
                    <StatsProgressWrapper>
                      <StatsProgress>
                        <StateDefault
                          letsIconschartAlt="/Statistics.svg"
                          stateDefaultCursor="pointer"
                          stateDefaultBorderW="none"
                          stateDefaultBorderS="none"
                          stateDefaultBorderC="none"
                          stateDefaultPadding="0"
                          stateDefaultBackgroundColor="var(--colors-blue-1)"
                          onButttonChartButtonV12424Click={CreateToggleSidebar(index)}
                        />
                        <Progress1>
                          <InfoArea>
                            <Earned>
                              <Earned1>Earned:</Earned1>
                              <Div2>{item.earned}</Div2>
                            </Earned>
                            <Earned1>{item.total}</Earned1>
                          </InfoArea>
                          <ProgressBar>
                            <ProgressBarChild Width={String((item.total===0)?"0%":(100*item.earned/item.total)+"%")} />
                          </ProgressBar>
                        </Progress1>
                      </StatsProgress>
                    </StatsProgressWrapper>
                    <StateDefaultEmptyYesChec
                      text="In token"
                      stateDefaultEmptyYesChecBoxSizing="border-box"
                      stateDefaultEmptyYesChecPosition="absolute"
                      stateDefaultEmptyYesChecGap="unset"
                      stateDefaultEmptyYesChecOutline="none"
                      stateDefaultEmptyYesChecFontFamily="var(--buttons-btn-input)"
                      stateDefaultEmptyYesChecFontSize="var(--body-main-size)"
                      stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                      stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-5xsW)"
                      stateDefaultEmptyYesChecAlignSelf="unset"
                      stateDefaultEmptyYesChecWidth="8.402777777777779vw"
                      stateDefaultEmptyYesChecHeight="3.7760416666666665vh"
                      stateDefaultEmptyYesChecFontWeight="500"
                      stateDefaultEmptyYesChecFlex="1"
                      stateDefaultEmptyYesChecZIndex="unset"
                      stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                      stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                      stateDefaultEmptyYesChecBorderS="solid"
                      stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                      stateDefaultEmptyYesChecTop="0.71614583333vh"
                      stateDefaultEmptyYesChecLeft="67.4652777778vw"
                      stateDefaultEmptyTextWidth="80.15%"
                      type="text"
                      icon="AddToken.svg"
                  />
                  </Row>
                })
              }
              {isSidebarVisible && <SidebarStats setSidebarTab={setSidebarTab} PageData={TabsData}Page={SidebarTab} onClose={toggleSidebarGlobal} />}
              {isSidebarVisible && <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'var(--color-black-350)', // 35% transparency
                backdropFilter: 'blur(2px)', // Background blur effect
                zIndex: 99999998, // Ensure it's below the sidebar but above everything else
              }} />}
            </>
          )
        }
        return <PopUp/>
        })()}
      </OnHover>
    </FrameContainer>
  </>
)}

type SBTS = {
  Students: StudT[];
  onButtonSecondary35Click: (e:React.MouseEvent<HTMLElement>)=>void;
}

const ChildBlocks: FunctionComponent<SBTS> = ({Students, onButtonSecondary35Click}) => {
  return <>{Students.map((Student, sindex) => (
    <StudBlock Student={Student} sindex={sindex} onButtonSecondary35Click={onButtonSecondary35Click}/>
  ))
  }</>
}


const ContainerCardFormFilter2 = forwardRef<HTMLDivElement, TeachT>((Input, ref) => {

  const onButtonSecondary34Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onSortingItem3Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary35Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onRadioButton2Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );
  const [Students, setStudents] = useState(Input.Students)

  return (
    <TabParentRoot id="ParentSection" ref={ref}>
      <ContainerWithButtons
        tutorCodeInstructions={`Get Parent Code and Your Dependants and the other Parent/Relatives should add it to their accounts in their Profile section. The Parent Code allows You to link Your Dependants' accounts to You, see their progress, offer New Goals and set Achievement Bonuses for them. This code can be added by the other Parent/Relative, and they will appear in Tabs alongside Dependants: by setting their Tab type as "Parent/Relative" you grant them the ability to see each Dependent's progress and set Goals and Achievement Bonuses for them.`}
        tutorCodeDescription="Get Parent Code"
        clientGoalCode="1035481KOGVZ"
        onButtonSecondary26Click={onButtonSecondary34Click}
      />
      <FrameParent>
        <ChildrenParent>
          <Children>Children</Children>
          <Sortingfilters>
            <Sortingitem>
              <Rate>Available</Rate>
              <Radiobutton type="checkbox" onClick={onRadioButton2Click} />
            </Sortingitem>
            <SortingfiltersChild />
            <Property1Default9
              text="Rate"
              smallArrows="/NoSort.svg"
              property1DefaultCursor="pointer"
              property1DefaultBorderW="none"
              property1DefaultBorderS="none"
              property1DefaultBorderC="none"
              property1DefaultPadding="0"
              property1DefaultBackgroundColor="transparent"
              rateColor="var(--colors-purple-dark)"
              rateDisplay="inline-block"
              onSortingItemClick1={onSortingItem3Click}
            />
            <SortingfiltersChild />
            <Property1Default6
                property1DefaultWidth="9.791666666666666vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="unset"
                property1DefaultLeft="unset"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="var(--buttons-btn-input)"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="var(--body-main-size)"
                property1DefaultColor="var(--colors-dark-1)"
                property1DefaultAlignSelf="unset"
                options={["All Languages", "English","Russian"]}
                value={"All Languages"}
              />
          </Sortingfilters>
        </ChildrenParent>
        <Content>
          <Tabs>
            <ChildBlocks Students={Students} onButtonSecondary35Click={onButtonSecondary35Click} />
          </Tabs>
        </Content>
      </FrameParent>
    </TabParentRoot>
  );
});

export default ContainerCardFormFilter2;
