import { FunctionComponent, type CSSProperties, useState, useEffect, useRef } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import { Socket } from 'socket.io-client';
import { useTranslation } from "react-i18next";

type StateDefaultEmptynoType = {
  text?: string;
  clearAudioQueue: () => void;
  pauseA:() => void;
  playA:() => void;
  stopA:() => void;
  socket: Socket;
  isPushToTalk:boolean;
  isEditBeforeSend:boolean;
  LL: string;
  NL: string;

  /** Style props */
  stateDefaultEmptynoGap?: CSSProperties["gap"];
  stateDefaultEmptynoOutline?: CSSProperties["outline"];
  stateDefaultEmptynoFontFamily?: CSSProperties["fontFamily"];
  stateDefaultEmptynoFontSize?: CSSProperties["fontSize"];
  stateDefaultEmptynoColor?: CSSProperties["color"];
  stateDefaultEmptynoBackgroundColor?: CSSProperties["backgroundColor"];
  stateDefaultEmptynoBorderW?: CSSProperties["borderWidth"];
  stateDefaultEmptynoBorderS?: CSSProperties["borderStyle"];
  stateDefaultEmptynoBorderC?: CSSProperties["borderColor"];
  onClickSend?: (e: React.MouseEvent<HTMLElement>) => void;
  onClickVoice1?: (e: React.MouseEvent<HTMLElement>) => void;
  onClickVoice2?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Text1 = styled.div`
  width: 100%;
  min-height: 5.208333333333334vh;
  &:focus {
    outline: none;
  }
  &:empty:before {
    content: attr(data-placeholder);
    color: var(--colors-purple-light);
    pointer-events: none;
    display: block; /* For Safari */
  }
`;
const MediumsendIcon = styled.img`
  position: relative;
  --side: min(1.3888888888888888vw,2.604166666666667vh);
  width: var(--side);
  height: var(--side);
  object-fit: cover;
`;
const Buttonsend = styled.div<{hide:boolean}>`
  align-self: stretch;
  border-radius: var(--corner-radius-m);
  background-color: var(--colors-darkerblue);
  width: 2.7777777777777777vw;
  display: ${(p)=>(p.hide?"none":"flex")};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-11xsH) var(--padding-11xsW);
  box-sizing: border-box;
  cursor: pointer;
  & img {
    filter: var(--filters-btn-txt);
  }
  &:hover {
    background-color: var(--colors-blue2);
  }
`;
const Es = styled.div`
  position: relative;
  line-height: 1.5625vh;
  display: flex;
  align-items: flex-end;
  width: 1.1111111111111112vw;
  flex-shrink: 0;
`;
const Recrodvoiceb = styled.div`
  border-radius: var(--corner-radius-m);
  background-color: var(--colors-blue-1);
  height: 5.208333333333334vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xsH) var(--padding-xsW);
  box-sizing: border-box;
  cursor: pointer;
  color: var(--colors-dark-1);
  & img {
    filter: var(--filters-dark-1);
  }
  &:hover {
    background-color: var(--colors-darkerblue);
    & img {
      filter: var(--filters-btn-txt);
    }
    & div {
      color: var(--colors-btn-txt);
    }
  }
  &.selected {
    background-color: var(--colors-darkerblue);
    & img {
      filter: var(--filters-btn-txt);
    }
    & div {
      color: var(--colors-btn-txt);
    }
  }
  &.active {
    background: none;
    border: none;
    position: relative;
    z-index: 1;
    & img {
      filter: var(--filters-btn-txt);
    }
    & div {
      color: var(--colors-btn-txt);
    }
  }
`;
const RecrodvoicebDel = styled.div`
  border-radius: var(--corner-radius-m);
  background-color: var(--colors-red-1);
  height: 5.208333333333334vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xsH) var(--padding-xsW);
  box-sizing: border-box;
  cursor: pointer;
  & img {
    filter: var(--filters-red);
  }
  &:hover {
    background-color: var(--colors-red);
    & img {filter: var(--colors-btn-txt);}
  }
`;
const RecrodvoicebPlay = styled.div<{stop:boolean}>`
  border-radius: var(--corner-radius-m);
  background-color: ${(p)=>(p.stop?"var(--colors-blue2)":"var(--colors-blue-1)")};
  height: 5.208333333333334vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xsH) var(--padding-xsW);
  box-sizing: border-box;
  cursor: pointer;
  & img {
    filter: ${(p)=>(p.stop?"var(--filters-white)":"var(--filters-dark-1)")};
  }
`;
const ButtonsendParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  font-size: var(--caption-cap-12-size);
  float: right;
  clear:both;
`;
const ButtonsendParent2 = styled.div<{hide: boolean}>`
  display: ${(p)=>(p.hide?"none":"flex")};
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  font-size: var(--caption-cap-12-size);
`;

const DIV = styled.div<{H: number}>`
&:before {
  content: '' ;
  display:block;
  float: right;
  /* The height is essentially a "margin-top" to push the pullout Container down page. */
  height: calc(${(p)=>(p.H)}vh - 5.20833333vh);
}
`;
const StatedefaultEmptynoRoot = styled.div<{
  stateDefaultEmptynoGap?: Property.Gap;
  stateDefaultEmptynoOutline?: Property.Outline;
  stateDefaultEmptynoFontFamily?: Property.FontFamily;
  stateDefaultEmptynoFontSize?: Property.FontSize;
  stateDefaultEmptynoColor?: Property.Color;
  stateDefaultEmptynoBackgroundColor?: Property.BackgroundColor;
  stateDefaultEmptynoBorderW?: Property.BorderWidth;
  stateDefaultEmptynoBorderC?: Property.BorderColor;
  stateDefaultEmptynoBorderS?: Property.BorderStyle;
}>`border-radius: var(--br-xs);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  height: max-content;
  padding: var(--padding-9xsH) var(--padding-9xsW) var(--padding-9xsH) var(--padding-5xsW);
  font-size: var(--buttons-btn-input-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  outline: ${(p) => p.stateDefaultEmptynoOutline};
  font-family: ${(p) => p.stateDefaultEmptynoFontFamily};
  font-size: ${(p) => p.stateDefaultEmptynoFontSize};
  color: ${(p) => p.stateDefaultEmptynoColor};
  background-color: ${(p) => p.stateDefaultEmptynoBackgroundColor};
  border-width: ${(p) => p.stateDefaultEmptynoBorderW};
  border-color: ${(p) => p.stateDefaultEmptynoBorderC};
  border-style: ${(p) => p.stateDefaultEmptynoBorderS};
  z-index: 2;
  width: 100%;
  &:focus-within {
    outline-style: solid;
    outline-color: var(--colors-darkerblue);
    outline-width: 0.138888889vmax;
    border-color: ${(p) => p.stateDefaultEmptynoBackgroundColor};
  }
`;

interface TooltipProps {
  children: React.ReactNode;
  text: string;
}

const Tooltip: React.FC<TooltipProps> = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      {isVisible && (
        <div
          style={{
            position: 'absolute',
            bottom: '120%',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: "1.5625vh",
            lineHeight: "2.604166666666667vh",
            backgroundColor: 'var(--colors-dark-3)',
            color: 'var(--colors-btn-txt)',
            padding: '0.26041666666666663vh 0.4166666666666667vw',
            borderRadius: '4px',
            whiteSpace: 'nowrap',
            zIndex: 1000, // Ensure the tooltip is above other content
            // Additional styles for the tooltip
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

const rotspeed1 = (Math.random()*20-10) || 0.1
const rotspeed2 = (Math.random()*20-10) || 0.1
const rotspeed3 = (Math.random()*20-10) || 0.1
const rotspeed4 = (Math.random()*20-10) || 0.1
const rotspeed5 = (Math.random()*20-10) || 0.1
const rotspeed6 = (Math.random()*20-10) || 0.1

const formatDuration = (ms: number) => {
  const seconds = Math.floor(ms / 1000) % 60;
  const minutes = Math.floor(ms / 60000);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const StateDefaultEmptyno: FunctionComponent<StateDefaultEmptynoType> = ({
  text = "Content",
  stateDefaultEmptynoGap,
  stateDefaultEmptynoOutline,
  stateDefaultEmptynoFontFamily,
  stateDefaultEmptynoFontSize,
  stateDefaultEmptynoColor,
  stateDefaultEmptynoBackgroundColor,
  stateDefaultEmptynoBorderW,
  stateDefaultEmptynoBorderC,
  stateDefaultEmptynoBorderS,
  clearAudioQueue,
  pauseA,
  playA,
  stopA,
  socket,
  onClickSend,
  isPushToTalk,
  isEditBeforeSend,
  onClickVoice1,
  onClickVoice2,
  LL,
  NL,
}) => {
  let par=document.getElementById("InputField")
  const [H, setH] = useState(par?100 * par.clientHeight / 768:0)
  const [recordingState, setRecordingState] = useState<'idle' | 'countdown' | 'recording' | 'recorded'>('idle');
  const recordingStateRef = useRef(recordingState);
  const [countdown, setCountdown] = useState<number>(3);
  const countdownRef = useRef(countdown);
  const [recordedStartTime, setRecordedStartTime] = useState(0);
  const recordedStartTimeRef = useRef(recordedStartTime);
  const [recordedFinalTime, setRecordedFinalTime] = useState(0);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const [waveformPath, setWaveformPath] = useState<string[]>([]);
  const [waveformL, setWaveformL] = useState(0);

  const [now, setNow] = useState(Date.now());

  setInterval(() => {
    setNow(Date.now())
  }, 1)

  useEffect(() => {
    countdownRef.current = countdown;
  }, [countdown]);
  useEffect(() => {
    recordingStateRef.current = recordingState;
  }, [recordingState]);
  useEffect(() => {
    recordedStartTimeRef.current = recordedStartTime;
  }, [recordedStartTime]);

  const [selectIsActive, setSelectIsActive]= useState(false)

  useEffect(() => {
    const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

    // Convert the Blob to an array buffer
    const blobToArrayBuffer = (blob: Blob) => {
      return new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(blob);
      });
    };

    // Generate the waveform data
    const generateWaveform = async (audioBuffer: AudioBuffer) => {
      // Simplified: just take the first channel and a subset of samples for demonstration
      const rawData = audioBuffer.getChannelData(0);
      const samples = 50; // Number of samples for the visual representation
      const blockSize = Math.floor(rawData.length / samples); // Block size to aggregate
      let waveform: string[] = [];

      let max = -1
      for (let i = 0; i < samples; i++) {
        const blockStart = blockSize * i;
        let sum = 0;
        for (let j = 0; j < blockSize; j++) {
          sum += Math.abs(rawData[blockStart + j]); // sum of all samples in the block
        }
        const average = sum / blockSize;
        max=max<average?average:max;
      }

      for (let i = 0; i < samples; i++) {
        const blockStart = blockSize * i; // the location of the first sample in the block
        let sum = 0;
        for (let j = 0; j < blockSize; j++) {
          sum += Math.abs(rawData[blockStart + j]); // sum of all samples in the block
        }
        const average = sum / blockSize; // average value for the block
        const y = 50*average/max; // Y value scaled for visualization

        // Construct the SVG path (simplified)
        waveform=[...waveform,`M${i},${50 + y} L${i},${50 - y}`];
      }
      
      return {a:waveform, b:samples};
    };

    // Process the Blob
    audioBlob && blobToArrayBuffer(audioBlob)
      .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
      .then(async (audioBuffer) => {
        const {a:path, b:l} = await generateWaveform(audioBuffer);
        setWaveformPath(path);
        setAudioUrl(URL.createObjectURL(audioBlob)); // Set the audio URL for playback
        setWaveformL(l)//(document.getElementById("waweID") as any).clientWidth/290*l
      })
      .catch(error => console.error('Error processing audio blob', error));

    return () => {
      audioContext.close(); // Clean up the audio context on unmount
    };
  }, [audioBlob]);

  const [selectedLang, setSelectedLang] = useState<'Learn' | 'Native' | null>(null)
  
  useEffect(() => {
    let countdownInterval: NodeJS.Timer;
    let rotateInterval: NodeJS.Timer;
    let recordingChunks: BlobPart[] = [];

    const startRecording = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordingChunks.push(event.data);
        }
      };

      recorder.onstop = () => {
        const audioBlob = new Blob(recordingChunks, { type: 'audio/mp4' });
        setAudioBlob(audioBlob);
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        recorder.stream.getTracks().forEach(track => track.stop());
        setRecordedFinalTime(Date.now()-recordedStartTimeRef.current)
        setRecordedStartTime(0);
        recordingChunks=[]
        if (isPushToTalk) {
          setRecordingState('idle');
          setTimeout(()=>{
            if (audioBlob) {
              clearAudioQueue();
              const reader = new FileReader();
              reader.readAsArrayBuffer(audioBlob);
              const l = selectedLang
              const we = isEditBeforeSend
              reader.onloadend = () => {
                socket.emit('audio_message',{ "audio": reader.result, "lang": l, "withEdit": we });
              };
              setSelectedLang(null);
            }
          }, 1000)
          stopA()
        }
      };
      recorder.start();
      setRecordedStartTime(Date.now());
    };

    if (recordingState === "countdown") {
      countdownInterval = setInterval(() => {
        if (recordingStateRef.current === "countdown") {
          if (countdownRef.current>1) {
            setCountdown(countdownRef.current-1)
          } else {
            setRecordingState('recording');
            startRecording();
            clearInterval(countdownInterval);
            setCountdown(3)
          }
        } else {
          clearInterval(countdownInterval);
          setCountdown(3)
        }
      }, 1000); // Adjust countdown duration as needed
      setSelectIsActive(false)
    } else if (recordingState === "recording") {
      setSelectIsActive(true)
      rotateInterval = setInterval(() => {
        if (recordingStateRef.current === "recording") {
          if (selectedLang==='Learn') {
            setrot1(rot1Ref.current+rotspeed1)
            setrot2(rot2Ref.current+rotspeed2)
            setrot3(rot3Ref.current+rotspeed3)
          } else if (selectedLang==='Native') {
            setrot4(rot4Ref.current+rotspeed4)
            setrot5(rot5Ref.current+rotspeed5)
            setrot6(rot6Ref.current+rotspeed6)
          } else {
            clearInterval(rotateInterval);
          }
        } else {
          clearInterval(rotateInterval);
        }
      }, 1);
    } else if (recordingState === "recorded") {
      mediaRecorder?.stop();
      setSelectIsActive(false)
    } else if (recordingState === "idle") {
      setCountdown(3)
      setSelectedLang(null)
      setSelectIsActive(false)
    }
  }, [recordingState, audioUrl, mediaRecorder, selectedLang, recordedStartTime,audioBlob, clearAudioQueue, isEditBeforeSend, isPushToTalk, socket, stopA]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(new Audio());

  useEffect(() => {
    const audio = audioRef.current;

    // Function to update the current time
    const updateTime = () => setCurrentTime(audio.currentTime);

    // Add event listener to update current time
    audio.addEventListener('timeupdate', updateTime);

    // Clean up event listener
    return () => {
      audio.removeEventListener('timeupdate', updateTime);
    };
  }, []);

  const playAudio = () => {
    if (audioBlob && !isPlaying) {
      // Create an object URL for the Blob
      const audioUrl = URL.createObjectURL(audioBlob);
      audioRef.current.src = audioUrl;
      audioRef.current.play();
      setIsPlaying(true);

      // Clean up the object URL after playback
      audioRef.current.onended = () => {
        URL.revokeObjectURL(audioUrl);
        setIsPlaying(false);
        playA()
      };
    }
  };

  const stopAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setCurrentTime(0);
      setIsPlaying(false);
    }
  };
  const AudioProgress=currentTime/audioRef.current.duration

  const [rot1, setrot1]= useState(Math.random()*20-10)
  const [rot2, setrot2]= useState(Math.random()*20-10)
  const [rot3, setrot3]= useState(Math.random()*20-10)
  const [rot4, setrot4]= useState(Math.random()*20-10)
  const [rot5, setrot5]= useState(Math.random()*20-10)
  const [rot6, setrot6]= useState(Math.random()*20-10)
  const rot1Ref= useRef(rot1)
  const rot2Ref= useRef(rot2)
  const rot3Ref= useRef(rot3)
  const rot4Ref= useRef(rot4)
  const rot5Ref= useRef(rot5)
  const rot6Ref= useRef(rot6)
  useEffect(() => {
    rot1Ref.current = rot1;
  }, [rot1]);
  useEffect(() => {
    rot2Ref.current = rot2;
  }, [rot2]);
  useEffect(() => {
    rot3Ref.current = rot3;
  }, [rot3]);
  useEffect(() => {
    rot4Ref.current = rot4;
  }, [rot4]);
  useEffect(() => {
    rot5Ref.current = rot5;
  }, [rot5]);
  useEffect(() => {
    rot6Ref.current = rot6;
  }, [rot6]);
  const { t } = useTranslation();

  return (
    <StatedefaultEmptynoRoot
      stateDefaultEmptynoGap={stateDefaultEmptynoGap}
      stateDefaultEmptynoOutline={stateDefaultEmptynoOutline}
      stateDefaultEmptynoFontFamily={stateDefaultEmptynoFontFamily}
      stateDefaultEmptynoFontSize={stateDefaultEmptynoFontSize}
      stateDefaultEmptynoColor={stateDefaultEmptynoColor}
      stateDefaultEmptynoBackgroundColor={stateDefaultEmptynoBackgroundColor}
      stateDefaultEmptynoBorderW={stateDefaultEmptynoBorderW}
      stateDefaultEmptynoBorderC={stateDefaultEmptynoBorderC}
      stateDefaultEmptynoBorderS={stateDefaultEmptynoBorderS}
    >
      <DIV H={H}>
        <ButtonsendParent>
          <Buttonsend
            onClick={(e)=>{
              if (recordingStateRef.current==='idle' && onClickSend) {
                onClickSend(e);
                let a=document.getElementById("InputField");
                a && (a.innerText="");
              } else {
                setRecordingState('idle');
                setTimeout(()=>{
                  if (audioBlob) {
                    clearAudioQueue();
                    const reader = new FileReader();
                    reader.readAsArrayBuffer(audioBlob);
                    const l = selectedLang
                    const we = isEditBeforeSend
                    reader.onloadend = () => {
                        socket.emit('audio_message',{ "audio": reader.result, "lang": l, "withEdit": we });
                    };
                    setSelectedLang(null);
                  }
                }, 1000)
              }
              stopA()
            }}
            hide={recordingState==="countdown" || recordingState==="recording"}
          >
            <MediumsendIcon alt="" src="/Send.svg" />
          </Buttonsend>
          <Tooltip text={t("HoldAudioInfo")}>
            <div style={{
              height: "8.072916666666668vh",
              width: "4.305555555555555vw",
              position: "absolute",
              margin: "0 !important",
              top: "calc(50% - 4.036458333333334vh)",
              left: "calc(50% - 2.1527777777777777vw)",
              borderRadius: "43px",
              backgroundColor: "var(--colors-blue2)",
              transform: ` rotate(${rot1}deg)`,
              transformOrigin: "50% 50%",
              opacity: "0.5",
              display: (selectedLang==='Learn'?(selectIsActive?"block":"none"):"none"),
            }}>
            </div>
            <div style={{
              height: "8.13802083333vh",
              width: "4.34027777778vw",
              position: "absolute",
              margin: "0 !important",
              top: "calc(50% - 3.34635416667vh)",
              left: "calc(50% - 2.48611111111vw)",
              borderRadius: "24px",
              backgroundColor: "var(--colors-blue2)",
              transform: ` rotate(${rot2}deg)`,
              transformOrigin: "50% 50%",
              opacity: "0.5",
              display: (selectedLang==='Learn'?(selectIsActive?"block":"none"):"none"),
              }}>
            </div>
            <div style={{
              height: "7.35677083333vh",
              width: "3.92361111111vw",
              position: "absolute",
              margin: "0 !important",
              bottom: "-1.734375vh",
              left: "calc(50% - 2.24305555556vw)",
              borderRadius: "24px 12px 18px 16px",
              backgroundColor: "var(--colors-blue2)",
              transform: ` rotate(${rot3}deg)`,
              transformOrigin: "50% 50%",
              opacity: "0.5",
              display: (selectedLang==='Learn'?(selectIsActive?"block":"none"):"none"),
            }}>
            </div>
            <Recrodvoiceb
              className={selectedLang==='Learn'?(selectIsActive?"active":"selected"):""}
              onMouseDown={()=>{setRecordingState('countdown');setSelectedLang("Learn");pauseA()}}
              onMouseUp={()=>{
                setRecordingState(recordingState==="countdown"?'idle':(recordingState==="recording"?"recorded":"idle"));
                playA()
              }}
              onMouseLeave={()=>{
                if (recordingState==="countdown" || recordingState==="recording") {
                  setRecordingState(recordingState==="countdown"?'idle':(recordingState==="recording"?"recorded":"idle"))
                };
                playA()
              }}
              style={{zIndex:"1"}}
            >
              <Es>{LL}</Es>
              <MediumsendIcon alt="" src="/Mic.svg" />
            </Recrodvoiceb>
          </Tooltip>
          <Tooltip text={t("HoldAudioInfo")}>
            <div style={{
              height: "8.072916666666668vh",
              width: "4.305555555555555vw",
              position: "absolute",
              margin: "0 !important",
              top: "calc(50% - 4.036458333333334vh)",
              left: "calc(50% - 2.1527777777777777vw)",
              borderRadius: "43px",
              backgroundColor: "var(--colors-blue2)",
              transform: ` rotate(${rot4}deg)`,
              transformOrigin: "50% 50%",
              opacity: "0.5",
              display: (selectedLang==='Native'?(selectIsActive?"unset":"none"):"none"),
            }}>
            </div>
            <div style={{
              height: "8.13802083333vh",
              width: "4.34027777778vw",
              position: "absolute",
              margin: "0 !important",
              top: "calc(50% - 3.34635416667vh)",
              left: "calc(50% - 2.48611111111vw)",
              borderRadius: "24px",
              backgroundColor: "var(--colors-blue2)",
              transform: ` rotate(${rot5}deg)`,
              transformOrigin: "50% 50%",
              opacity: "0.5",
              display: (selectedLang==='Native'?(selectIsActive?"unset":"none"):"none"),
              }}>
            </div>
            <div style={{
              height: "7.35677083333vh",
              width: "3.92361111111vw",
              position: "absolute",
              margin: "0 !important",
              bottom: "-1.734375vh",
              left: "calc(50% - 2.24305555556vw)",
              borderRadius: "24px 12px 18px 16px",
              backgroundColor: "var(--colors-blue2)",
              transform: ` rotate(${rot6}deg)`,
              transformOrigin: "50% 50%",
              opacity: "0.5",
              display: (selectedLang==='Native'?(selectIsActive?"unset":"none"):"none"),
            }}>
            </div>
            <Recrodvoiceb
              className={selectedLang==='Native'?(selectIsActive?"active":"selected"):""}
              onMouseDown={()=>{setRecordingState('countdown');setSelectedLang("Native");pauseA()}}
              onMouseUp={()=>{
                setRecordingState(recordingState==="countdown"?'idle':(recordingState==="recording"?"recorded":"idle"));
                playA()
              }}
              onMouseLeave={()=>{
                if (recordingState==="countdown" || recordingState==="recording") {
                  setRecordingState(recordingState==="countdown"?'idle':(recordingState==="recording"?"recorded":"idle"))
                };
                playA()
              }}
              style={{zIndex:"1"}}
            >
              <Es>{NL}</Es>
              <MediumsendIcon alt="" src="/Mic.svg" />
            </Recrodvoiceb>
          </Tooltip>
        </ButtonsendParent>
      </DIV>
      {recordingState === 'idle' && (
      <Text1 id="InputField" data-placeholder={t("TypeYourMessage")} contentEditable={true} onInput={
        ()=>{
          setH(par?100 * par.clientHeight / 768:0)
          let el=document.getElementById("InputField")
          if (!el) {return}
          const isEmpty = el.innerHTML === "<br>";
          if (isEmpty) {
            el.innerHTML=""
          }
        }}
      >
      </Text1>
      )}
      {recordingState === 'countdown' && (
      <div style={{display:"flex", flexDirection:"row", padding:"1.0416666666666665vh 0"}}>
        <div style={{display:"flex", flexDirection:"row", width:"3.75vw"}}>
          <img
            src="/Recording.svg"
            alt=""
            width="16"
            height="20"
          />
          <div style={{width:"1.38888889vw", paddingLeft:"0.9722222222222222vw", color:"var(--colors-dark-1)", fontSize:"1.5625vh", lineHeight:"2.604166666666667vh"}}>{countdown}</div>
        </div>
        <div style={{width: "26.8055556vw",textAlign:"center",color:"var(--colors-purple-light)", fontSize:"1.5625vh", lineHeight:"2.604166666666667vh"}}>Recording will start in {countdown} seconds</div>
      </div>
      )}
      {recordingState === 'recording' && (
      <div style={{display:"flex", flexDirection:"row", padding:"1.0416666666666665vh 0"}}>
        <div style={{display:"flex", flexDirection:"row", width:"3.75vw"}}>
          <img
            src="/Recording.svg"
            alt=""
            width="16"
            height="20"
          />
          <div style={{width:"2.36111111vw", color:"var(--colors-dark-1)", fontSize:"1.5625vh", lineHeight:"2.604166666666667vh"}}>{formatDuration(now-recordedStartTimeRef.current)}</div>
        </div>
        <div style={{width: "26.8055556vw",textAlign:"center",color:"var(--colors-purple-light)", fontSize:"1.5625vh", lineHeight:"2.604166666666667vh"}}>Release to finish recording</div>
      </div>
      )}
      {
        (recordingState === 'recorded') && (
          <div style={{display:"flex",flexDirection:"row", gap:"0.3472222222222222vw"}}>
            <ButtonsendParent2 hide={recordingState!=="recorded" && recordingState!=="playing"}>
              <RecrodvoicebPlay stop={isPlaying} onClick={()=>{isPlaying?stopAudio():playAudio();isPlaying?playA():pauseA()}}>
                <MediumsendIcon alt="" src={isPlaying?"/Stop.svg":"/Play.svg"} />
              </RecrodvoicebPlay>
              <RecrodvoicebDel onClick={()=>{setRecordingState('idle');setSelectedLang(null)}}>
                <MediumsendIcon alt="" src="/DeleteFC&Acc.svg" />
              </RecrodvoicebDel>
            </ButtonsendParent2>
            <div style={{padding:"1.04166667vh 0vw"}}>
              {isPlaying?formatDuration(audioRef.current.currentTime*1000):formatDuration(recordedFinalTime)}
            </div>
            {recordingState === 'recorded' && (
            <svg preserveAspectRatio="none" width="290" height="34" viewBox={"0 0 "+waveformL+" 100"} xmlns="http://www.w3.org/2000/svg">
              <defs>
                <clipPath id="progressClip">
                  <rect x="0" y="0" width={isPlaying?AudioProgress * 100:0} height="100" />
                </clipPath>
              </defs>
              <g>
                <path id="SVGL" d='M0,50 L50,50' fill="none" stroke={isPlaying?"var(--colors-purple-light)":"var(--colors-purple-light)"} strokeWidth="1" />
                {
                  waveformPath.map((wp,i)=>{
                    return <path id={String(i)} d={wp} fill="none"  stroke={isPlaying?"var(--colors-purple-light)":"var(--colors-purple-dark)"} strokeWidth="0.5" />
                  })
                }
              </g>
              <g clipPath="url(#progressClip)">
                <path id="SVGL" d='M0,50 L50,50' fill="none" stroke="var(--colors-blue2)" strokeWidth="1" />
                {
                  waveformPath.map((wp,i)=>{
                    return <path id={String(i)} d={wp} fill="none" stroke="var(--colors-blue2)" strokeWidth="0.5" />
                  })
                }
              </g>
            </svg>
            )}
          </div>
        )
      }
    </StatedefaultEmptynoRoot>
  );
};

export default StateDefaultEmptyno;
