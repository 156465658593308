import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1v2Type = {
  voiceDialogueAnytime?: string;
  smallCheck?: string;

  /** Style props */
  property1v2BackgroundColor?: CSSProperties["backgroundColor"];
  voiceDialogueAnytimeColor?: CSSProperties["color"];
};

const VoiceDialogueAnytime = styled.div<{
  voiceDialogueAnytimeColor?: Property.Color;
}>`
  align-self: stretch;
  position: relative;
  line-height: 2.604166666666667vh;
  color: ${(p) => p.voiceDialogueAnytimeColor};
`;
const Code = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const SmallcheckIcon = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  filter: var(--filters-green);
`;
const Property1v2Root = styled.div<{
  property1v2BackgroundColor?: Property.BackgroundColor;
}>`
  border-radius: var(--corner-radius-m);
  background-color: var(--colors-white);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-5xsH) var(--padding-xsW);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  align-self: stretch;
  background-color: ${(p) => p.property1v2BackgroundColor};
`;

const Property1v2: FunctionComponent<Property1v2Type> = ({
  voiceDialogueAnytime,
  smallCheck,
  property1v2BackgroundColor,
  voiceDialogueAnytimeColor,
}) => {
  return (
    <Property1v2Root property1v2BackgroundColor={property1v2BackgroundColor}>
      <Code>
        <VoiceDialogueAnytime
          voiceDialogueAnytimeColor={voiceDialogueAnytimeColor}
        >
          {voiceDialogueAnytime}
        </VoiceDialogueAnytime>
      </Code>
      <SmallcheckIcon alt="" src={smallCheck} />
    </Property1v2Root>
  );
};

export default Property1v2;
