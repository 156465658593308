import { FunctionComponent } from "react";
import styles from "./MainFrame.module.css";
import styled from "styled-components";

const Butttonrotate24x24 = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--colors-grey-bg);
  margin: 0 !important;
  position: absolute;
  top: 1.0416666666666665vh;
  right: 0.57638888888vw;
  border-radius: var(--corner-radius-round);
  --side: min(1.6666666666666667vw,3.125vh);
  width: var(--side);
  height: var(--side);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  & img {
    filter: var(--filters-purple-light);
  }
  &:hover {
    background-color: var(--colors-darkerblue);
    & img {
      filter: var(--filters-btn-txt);
    }
  }
`;
const Layer1Icon = styled.img`
  position: relative;
  --side: min(1.25vw,2.34375vh);
  width: var(--side);
  height: var(--side);
  object-fit: contain;
`;

const MainFrame: FunctionComponent<{
  Next: ()=>void;
  Prev: ()=>void;
  Rotate: ()=>void;
  Close: ()=>void;
  index: number;
  Data: {
    id: number;
    frontContent: string;
    backContent: string;
  }[];
}> = ({
  Next,
  Prev,
  Rotate,
  Close,
  index,
  Data,
}) => {
  return (
    <div className={styles.mainFrame}>
      <div className={styles.close} onClick={Close}>
        <img
          className={styles.mediumcloseIcon}
          loading="eager"
          alt=""
          src="/mediumclose1.svg"
        />
      </div>
      <section className={styles.basicFrame}>
        <div className={styles.flashcardLarge}>
          <Butttonrotate24x24 onClick={Rotate}>
            <Layer1Icon alt="" src="/RotateFC.svg" />
          </Butttonrotate24x24>
          <button className={styles.recrodvoicea} onClick={()=>(alert("Sound!"))}>
            <img
            className={styles.groupInput}
            loading="eager"
            alt=""
            src="/FCSound.svg"
            />
          </button>
          <h2 className={styles.significado1}>{Data[index].frontContent}</h2>
          <div className={styles.lineSeparatorParent}>
            <div className={styles.lineSeparator}>
              <div className={styles.buttonsArea}>{index}</div>
              /
              <div className={styles.buttonsArea1}>{Data.length}</div>
            </div>
            <div className={styles.buttonsArea2}>
              <button className={styles.parentFrame} onClick={Prev}>
                <div className={styles.previous}>{`<`} Previous</div>
              </button>
              <button className={styles.parentFrame} onClick={Next}>
                <div className={styles.previous}>Next {`>`}</div>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainFrame;
