import { FunctionComponent } from "react";
import StateDefault3 from "./StateDefault3";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type StudyFormContainerType = {
  /** Action props */
  onSideNavigationBClick?: (e: React.MouseEvent<HTMLElement>) => void;
};
const SidenavigationbParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;
const FrameParentRoot = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;

const StudyFormContainer: FunctionComponent<StudyFormContainerType> = ({
  onSideNavigationBClick,
}) => {
  const { t } = useTranslation();
  return (
    <FrameParentRoot>
      <SidenavigationbParent >
        <StateDefault3
          navigationMainIcon="/Study.svg"
          study={t("Test")}
          stateDefaultCursor="pointer"
          stateDefaultBorderW="none"
          stateDefaultBorderS="none"
          stateDefaultBorderC="none"
          stateDefaultBackgroundColor="transparent"
          stateDefaultZIndex="0"
          stateDefaultBoxSizing="border-box"
          studyDisplay="inline-block"
          HasClass="selected"
          IconBackground="var(--colors-darkerblue)"
          onSideNavigationBClick1={onSideNavigationBClick}
        />
      </SidenavigationbParent>
    </FrameParentRoot>
  );
};

export default StudyFormContainer;
