import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LayoutBlocksLight from "./pages/LayoutBlocksLight";
import LayoutBlocksLightNoLogin from "./pages/LayoutBlocksLightNoLogin";
import LayoutBlocksLightQuiz from "./pages/LayoutBlocksLightQuiz";
import { Socket } from "socket.io-client";

type TitleT = {
  datetime: string;
  language: string;
  categories: string[];
  title: string;
  content?: string;
  image?: string;
  video?: string;
  source: string;
  link: string;
  description?: string;
}

type inT ={from:'tutor'|'student';text:string}

type Prof = {
  Email: string;
  TZ: string;
  Lang: string;
  Curr: string;
  currLVL: string;
}

function App(
  In:{
    switchDarkMode: ()=>void;
    darkMode: boolean;
    ProfileData: Prof;
    News: TitleT[];
    FlashCardsIn: {id: number; frontContent:string; backContent:string}[];
    HTMLs: string[];
    texts: inT[];
    AllLangs:string[];
    LogedIn: boolean;
    T: string;
    G: string;
    P: string;
    NL: string;
    LL: string;
    IQ: boolean;
    Ylang:string;
    hv: boolean;
    QT: "None" | "Initial" | "GoalCreate" | "GoalChoose" | "TestFinal";
    Acts: {
      id: number;
      language: string;
      tutorName: string;
      target: string;
      deadline: string;
      tutorCode: string;
      act:boolean;
    }[];
    socket: Socket | null;
  }
) {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);
  
  return (
    <Routes>
      <Route
        path="/"
        element={
          In.LogedIn?
          In.IQ?
          <LayoutBlocksLightQuiz
            darkMode={In.darkMode}
            switchDarkMode={In.switchDarkMode}
            QT={In.QT}
          />
          :
          <LayoutBlocksLight
            darkMode={In.darkMode}
            switchDarkMode={In.switchDarkMode}
            ProfileData={In.ProfileData}
            News={In.News}
            FlashCardsIn={In.FlashCardsIn}
            HTMLs={In.HTMLs}
            texts={In.texts}
            AllLangs={In.AllLangs}
            socket={In.socket!}
            NL={In.NL}
            LL={In.LL}
            P={In.P}
            Ylang={In.Ylang}
            hv={In.hv}
            Acts={In.Acts}
          />
          :
          <LayoutBlocksLightNoLogin
            darkMode={In.darkMode}
            switchDarkMode={In.switchDarkMode}
          />
        }
      />
    </Routes>
  );
}
export default App;