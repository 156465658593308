import { FunctionComponent } from "react";
import StateDefault3 from "./StateDefault3";
import styled from "styled-components";
import Property1Default17 from "./Property1Default17";
import { useTranslation } from 'react-i18next';

type StudyFormContainerType = {
  /** Action props */
  onSideNavigationBClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onSideNavigationB1Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onSideNavigationB2Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onSideNavigationB3Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const FrameChild = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 9.375vh;
  left: 0.0vw;
  max-height: 100%;
  width: 16.38888888888889vw;
  object-fit: cover;
  opacity: 0.8;
  z-index: 0;
`;
const FrameItem = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 18.75vh;
  left: 0.0vw;
  max-height: 100%;
  width: 16.38888888888889vw;
  object-fit: cover;
  opacity: 0.8;
  z-index: 0;
`;
const FrameInner = styled.img`
  position: absolute;
  margin: 0 !important;
  top: 28.125vh;
  left: 0.0vw;
  max-height: 100%;
  width: 16.38888888888889vw;
  object-fit: cover;
  opacity: 0.8;
  z-index: 0;
`;
const SidenavigationbParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;
const FrameParentRoot = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;

const StudyFormContainer: FunctionComponent<StudyFormContainerType> = ({
  onSideNavigationBClick,
  onSideNavigationB1Click,
  onSideNavigationB2Click,
  onSideNavigationB3Click,
}) => {
  const { t } = useTranslation();
  return (
    <FrameParentRoot>
      <SidenavigationbParent>
        <StateDefault3
          navigationMainIcon="/Study.svg"
          study={t("Study")}
          stateDefaultCursor="pointer"
          stateDefaultBorderW="none"
          stateDefaultBorderS="none"
          stateDefaultBorderC="none"
          stateDefaultBackgroundColor="transparent"
          stateDefaultZIndex="0"
          stateDefaultBoxSizing="border-box"
          studyDisplay="inline-block"
          HasClass="selected"
          IconBackground="var(--colors-darkerblue)"
          onSideNavigationBClick1={onSideNavigationBClick}
        />
        <StateDefault3
          navigationMainIcon="/MyGoals.svg"
          study={t("MyGoals")}
          stateDefaultCursor="pointer"
          stateDefaultBorderW="none"
          stateDefaultBorderS="none"
          stateDefaultBorderC="none"
          stateDefaultBackgroundColor="transparent"
          stateDefaultZIndex="0"
          stateDefaultBoxSizing="border-box"
          studyDisplay="inline-block"
          IconBackground="var(--colors-pink)"
          onSideNavigationBClick1={onSideNavigationB1Click}
        />
        {false && <StateDefault3
          navigationMainIcon="/TeachParent.svg"
          study="Teach | Parent"
          stateDefaultCursor="pointer"
          stateDefaultBorderW="none"
          stateDefaultBorderS="none"
          stateDefaultBorderC="none"
          stateDefaultBackgroundColor="transparent"
          stateDefaultZIndex="0"
          stateDefaultBoxSizing="border-box"
          studyDisplay="inline-block"
          IconBackground="var(--colors-green)"
          onSideNavigationBClick1={onSideNavigationB2Click}
        />}
        <StateDefault3
          navigationMainIcon="/Profile.svg"
          study={t("Profile")}
          stateDefaultCursor="pointer"
          stateDefaultBorderW="none"
          stateDefaultBorderS="none"
          stateDefaultBorderC="none"
          stateDefaultBackgroundColor="transparent"
          stateDefaultZIndex="0"
          stateDefaultBoxSizing="border-box"
          studyDisplay="inline-block"
          IconBackground="var(--colors-blue2)"
          onSideNavigationBClick1={onSideNavigationB3Click}
        />
        <FrameChild alt="" src="/line-10@2x.png" />
        <FrameItem alt="" src="/line-10@2x.png" />
        <FrameInner alt="" src="/line-10@2x.png" />
      </SidenavigationbParent>
      {false && <Property1Default17 />}
    </FrameParentRoot>
  );
};

export default StudyFormContainer;
