import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type StateDefaultIconyesType = {
  /** Style props */
  stateDefaultIconyesJustifyContent?: CSSProperties["justifyContent"];
  stateDefaultIconyesGap?: CSSProperties["gap"];
  stateDefaultIconyesFlex?: CSSProperties["flex"];
  stateDefaultIconyesCursor?: CSSProperties["cursor"];
  stateDefaultIconyesWidth?: CSSProperties["width"];
  stateDefaultIconyesBorderW?: CSSProperties["borderWidth"];
  stateDefaultIconyesBorderS?: CSSProperties["borderStyle"];
  stateDefaultIconyesBorderC?: CSSProperties["borderColor"];

  /** Action props */
  onButtonSecondary28Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const SmalldeleteIcon = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const SaveSession = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  font-weight: 500;
`;
const StatedefaultIconyesRoot = styled.div<{
  stateDefaultIconyesJustifyContent?: Property.JustifyContent;
  stateDefaultIconyesGap?: Property.Gap;
  stateDefaultIconyesFlex?: Property.Flex;
  stateDefaultIconyesCursor?: Property.Cursor;
  stateDefaultIconyesWidth?: Property.Width;
  stateDefaultIconyesBorderW?: Property.BorderWidth;
  stateDefaultIconyesBorderC?: Property.BorderColor;
  stateDefaultIconyesBorderS?: Property.BorderStyle;
}>`border-radius: var(--corner-radius-l);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-darkerblue);
  box-sizing: border-box;
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-xsW);
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-darkerblue);
  font-family: var(--buttons-btn-input);
  justify-content: ${(p) => p.stateDefaultIconyesJustifyContent};
  gap: ${(p) => p.stateDefaultIconyesGap};
  flex: ${(p) => p.stateDefaultIconyesFlex};
  cursor: ${(p) => p.stateDefaultIconyesCursor};
  width: ${(p) => p.stateDefaultIconyesWidth};
  border-width: ${(p) => p.stateDefaultIconyesBorderW};
  border-color: ${(p) => p.stateDefaultIconyesBorderC};
  border-style: ${(p) => p.stateDefaultIconyesBorderS};
  & img {
    filter: var(--filters-darkerblue);
  }
  &:hover {
    background-color: var(--colors-darkerblue);
    & div {
      color: var(--colors-btn-txt);
    }
    & img {
      filter: var(--filters-btn-txt);
    }
  }
`;

const StateDefaultIconyes: FunctionComponent<StateDefaultIconyesType> = ({
  stateDefaultIconyesJustifyContent,
  stateDefaultIconyesGap,
  stateDefaultIconyesFlex,
  stateDefaultIconyesCursor,
  stateDefaultIconyesWidth,
  stateDefaultIconyesBorderW,
  stateDefaultIconyesBorderC,
  stateDefaultIconyesBorderS,
  onButtonSecondary28Click,
}) => {
  return (
    <StatedefaultIconyesRoot
      stateDefaultIconyesJustifyContent={stateDefaultIconyesJustifyContent}
      stateDefaultIconyesGap={stateDefaultIconyesGap}
      stateDefaultIconyesFlex={stateDefaultIconyesFlex}
      stateDefaultIconyesCursor={stateDefaultIconyesCursor}
      stateDefaultIconyesWidth={stateDefaultIconyesWidth}
      stateDefaultIconyesBorderW={stateDefaultIconyesBorderW}
      stateDefaultIconyesBorderC={stateDefaultIconyesBorderC}
      stateDefaultIconyesBorderS={stateDefaultIconyesBorderS}
      onClick={onButtonSecondary28Click}
    >
      <SmalldeleteIcon alt="" src="/Upload.svg" />
      <SaveSession>Upload File</SaveSession>
    </StatedefaultIconyesRoot>
  );
};

export default StateDefaultIconyes;
