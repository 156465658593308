import { FunctionComponent, useState } from "react";
import styles from "./POP1.module.css";
import Property1Default11 from "./Property1Default11";
import { useTranslation } from "react-i18next";

const POP1: FunctionComponent<{
  onHide: () => void;
  selection: string;
  ww: string;
  TranslateB: (e: React.MouseEvent<HTMLElement>,inp:string,ww:string) => void;
  AudioB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  VideoB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  hv: boolean;
}> = ({ onHide, selection, TranslateB, VideoB,ww,AudioB,hv }) => {
  const [hover1, setHover1] = useState(false)
  const [hover2, setHover2] = useState(false)
  const { t } = useTranslation();
  return (
    <div className={styles.mainFrame}>
      <section className={styles.tooltip}>
        <div className={styles.frameWithAudio}>
          <Property1Default11
            largeVolumeEnable="/Audio.svg"
            property1DefaultCursor="pointer"
            property1DefaultBorderW="none"
            property1DefaultBorderS="none"
            property1DefaultBorderC="none"
            property1DefaultPadding="0"
            property1DefaultBackgroundColor="var(--colors-blue-1)"
            onPlayAudioClick={(event)=>{AudioB(event,selection);onHide()}}
          />
        </div>
        <div className={styles.tertiaryFrame}>
          <div className={styles.buttontretiary} onMouseEnter={()=>(setHover1(true))} onMouseLeave={()=>(setHover1(false))} onClick={(event)=>{TranslateB(event,selection,ww);onHide()}}>
            <div className={styles.endSession} style={{color:(hover1?"var(--colors-darkerblue)":"var(--colors-dark-1)")}}>{t("Translate")}</div>
          </div>
          {(hv===false) && <div className={styles.buttontretiary} onMouseEnter={()=>(setHover2(true))} onMouseLeave={()=>(setHover2(false))} onClick={(event)=>{VideoB(event,selection);onHide()}}>
            <div className={styles.endSession} style={{color:(hover2?"var(--colors-darkerblue)":"var(--colors-dark-1)")}}>{t("Video")}</div>
          </div>}
        </div>
      </section>
    </div>
  );
};

export default POP1;
