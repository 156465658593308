import { FunctionComponent, type CSSProperties, useState } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import StateHoverIconno from "./StateHoverIconno";
import Property1Default12 from "./Property1Default12";
import Property13ButtonsResolved from "./Property13ButtonsResolved";
import Property11ButtonResolvedn from "./Property11ButtonResolvedn";
import Property1TextResolvedno from "./Property1TextResolvedno";
import ScrollHolder from "../components/ScrollHolder";

type ActT = {
  text: string;
  call: string;
}

type EventT = {
  text: string;
  date: string;
  weekDay: string;
  start: string;
  end: string;
  tags: string[];
  acts: ActT[];
}
type EventHT = {
  text: string;
  date: string;
  weekDay: string;
  start: string;
  end: string;
  tags: string[];
}
type APT = {
  text: string;
  date: string;
  weekDay: string;
  start: string;
  end: string;
  tags: string[];
  acts: ActT[];
}
type APHT = {
  text: string;
  date: string;
  weekDay: string;
  start: string;
  end: string;
  tags: string[];
}
type NotificationT = {
  text: string;
  date: string;
  weekDay: string;
  start: string;
  tags: string[];
}
type NotificationHT = {
  text: string;
  date: string;
  weekDay: string;
  start: string;
  tags: string[];
}

type FormContainerMyAlertsType = {
  EventsIn: EventT[];
  EventsHIn: EventHT[];
  APointsIn: APT[];
  APointsHIn: APHT[];
  NotifsIn: NotificationT[];
  NotifsHIn: NotificationHT[];
  iconCode?: string;
  iconCodeImageUrl?: string;
  iconCodeText?: string;
  iconCodeTimestamp?: string;
  iconCodeDimensions?: string;
  ScrollObjAl: {
    scrollRef: React.RefObject<HTMLDivElement>;
    siblingRef: React.RefObject<HTMLDivElement>;
    scrollIndicatorHeight: string;
    scrollIndicatorTop: string;
    recalculateScrollIndicator: () => Promise<void>;
  };
  ScrollObjAlH: {
    scrollRef: React.RefObject<HTMLDivElement>;
    siblingRef: React.RefObject<HTMLDivElement>;
    scrollIndicatorHeight: string;
    scrollIndicatorTop: string;
    recalculateScrollIndicator: () => Promise<void>;
  };

  /** Style props */
  propColor?: CSSProperties["color"];
  propColor1?: CSSProperties["color"];
  propColor2?: CSSProperties["color"];
  propColor3?: CSSProperties["color"];
  propColor33?: CSSProperties["color"];
  propColor39?: CSSProperties["color"];
  propBackgroundColor?: CSSProperties["backgroundColor"];
  propBackgroundColor1?: CSSProperties["backgroundColor"];
  propBackgroundColor2?: CSSProperties["backgroundColor"];
  propBackgroundColor3?: CSSProperties["backgroundColor"];
  propBackgroundColor20?: CSSProperties["backgroundColor"];
  propBackgroundColor25?: CSSProperties["backgroundColor"];
  propBorderBottom?: CSSProperties["borderBottom"];
  propBorderBottom1?: CSSProperties["borderBottom"];
  propBorderBottom2?: CSSProperties["borderBottom"];
  propBorderBottom3?: CSSProperties["borderBottom"];
  propBorderW?: CSSProperties["borderWidth"];
  propBorderS?: CSSProperties["borderStyle"];
  propBorderC?: CSSProperties["borderColor"];

  /** Action props */
  onButtonSecondary12Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const MyAlerts = styled.h3<{ propColor?: Property.Color }>`
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: inherit;
  color: ${(p) => p.propColor};
`;
const EndSession = styled.div<{ propColor1?: Property.Color }>`
  position: relative;
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
  font-weight: 500;
  font-family: var(--buttons-btn-input);
  color: var(--colors-white);
  text-align: left;
  color: ${(p) => p.propColor1};
`;
const Buttontretiary = styled.button`
  cursor: pointer;
  border: none;
  padding: 0.0vh var(--padding-xsW);
  background-color: transparent;
  height: 3.6458333333333335vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  &:hover {
    & div {color: var(--colors-darkerblue);}
  }
`;
const MyAlertsParent = styled.div`
  width: 80.27777777777779vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.0vh var(--padding-9xlW);
  box-sizing: border-box;
`;
const CalendarEvents = styled.div<{ propColor2?: Property.Color }>`
  position: relative;
  font-size: var(--buttons-btn-input-size);
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: var(--buttons-btn-input);
  color: var(--colors-white);
  text-align: left;
  color: ${(p) => p.propColor2};
`;
const MediumdropdownarrowIcon = styled.img<{IsRotated: boolean}>`
  position: relative;
  --side: min(1.3888888888888888vw,2.604166666666667vh);
  width: var(--side);
  height: var(--side);
  object-fit: cover;
  transform: ${({ IsRotated }) => IsRotated ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease;
  filter: var(--filters-purple-dark);
`;
const CalendarEventsParent = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const FrameWrapper = styled.div<{ propBorderBottom?: Property.BorderBottom }>`
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 80.2777778vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-9xlW);
  border-bottom: ${(p) => p.propBorderBottom};
`;
const Nov20 = styled.div<{ propColor3?: Property.Color }>`
  position: relative;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 54.65277777777777vw;
  flex-shrink: 0;
  opacity: 0.8;
  color: ${(p) => p.propColor3};
`;
const ButtonsecondaryParent = styled.div`
  height: 3.6458333333333335vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-3xs);
`;
const Nov20272023Parent = styled.div`
  width: 80.2777778vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-baseH) var(--padding-99xlW);
  box-sizing: border-box;
`;
const AlertsParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const FrameContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const FrameGroup = styled.div<{
  propBackgroundColor1?: Property.BackgroundColor;
  IsHidden?: boolean;
}>`
  align-self: stretch;
  background-color: var(--colors-grey-bg);
  display: ${(p) => p.IsHidden?"none":"flex"};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.0vh 0.0vw var(--padding-baseH);
  background-color: ${(p) => p.propBackgroundColor1};
`;
const CalendarEvents1 = styled.div<{ propColor33?: Property.Color }>`
  position: relative;
  font-size: var(--buttons-btn-input-size);
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: var(--buttons-btn-input);
  color: var(--colors-white);
  text-align: left;
  color: ${(p) => p.propColor33};
`;
const ActionPointsWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const FrameWrapper1 = styled.div<{ propBorderBottom1?: Property.BorderBottom }>`
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 80.2777778vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-9xlW);
  border-bottom: ${(p) => p.propBorderBottom1};
`;
const AlertsWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const FrameWrapper2 = styled.div<{
  propBackgroundColor20?: Property.BackgroundColor;
  propBorderBottom2?: Property.BorderBottom;
  IsHidden?: boolean;
}>`align-self: stretch;
  background-color: var(--colors-grey-bg);
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  display: ${(p) => p.IsHidden?"none":"flex"};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-baseH) 0.0vw;
  background-color: ${(p) => p.propBackgroundColor20};
  border-bottom: ${(p) => p.propBorderBottom2};
`;
const FrameDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const CalendarEvents2 = styled.div<{ propColor39?: Property.Color }>`
  position: relative;
  font-size: var(--buttons-btn-input-size);
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: var(--buttons-btn-input);
  color: var(--colors-white);
  text-align: left;
  color: ${(p) => p.propColor39};
`;
const FrameWrapper11 = styled.div<{
  propBorderBottom3?: Property.BorderBottom;
}>`
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 80.2777778vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-9xlW);
  border-bottom: ${(p) => p.propBorderBottom3};
`;
const FrameWrapper5 = styled.div<{
  propBackgroundColor25?: Property.BackgroundColor;
  IsHidden?: boolean;
}>`
  align-self: stretch;
  background-color: var(--colors-grey-bg);
  display: ${(p) => p.IsHidden?"none":"flex"};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-baseH) 0.0vw;
  background-color: ${(p) => p.propBackgroundColor25};
`;
const FrameParent = styled.div<{
  propBackgroundColor?: Property.BackgroundColor;
  propBorderW?: Property.BorderWidth;
  propBorderS?: Property.BorderStyle;
  propBorderC?: Property.BorderColor;
}>`border-radius: var(--br-xs);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 80.2777778vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--body-main-size);
  flex-grow: 1;
  flex-shrink: 0;
  min-height: min-content;
  max-height: 77vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  };
  background-color: ${(p) => p.propBackgroundColor};
  border-width: ${(p) => p.propBorderW};
  border-style: ${(p) => p.propBorderS};
  border-color: ${(p) => p.propBorderC};
`;
const FrameParent1 = styled.div<{
  propBackgroundColor?: Property.BackgroundColor;
  propBorderW?: Property.BorderWidth;
  propBorderS?: Property.BorderStyle;
  propBorderC?: Property.BorderColor;
}>`border-radius: var(--br-xs);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 80.2777778vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--body-main-size);
  flex-grow: 1;
  flex-shrink: 0;
  min-height: min-content;
  max-height: 77vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  };
  background-color: ${(p) => p.propBackgroundColor};
  border-width: ${(p) => p.propBorderW};
  border-style: ${(p) => p.propBorderS};
  border-color: ${(p) => p.propBorderC};
`;
const TabMyAlertsRoot = styled.section`
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-white);
  font-family: var(--buttons-btn-input);
  max-height: 84.5vh;
`;

const FormContainerMyAlerts: FunctionComponent<FormContainerMyAlertsType> = ({
  propColor,
  propColor1,
  propColor2,
  propColor3,
  propColor33,
  propColor39,
  propBackgroundColor,
  propBackgroundColor1,
  propBackgroundColor2,
  propBackgroundColor3,
  propBackgroundColor20,
  propBackgroundColor25,
  propBorderBottom,
  propBorderBottom1,
  propBorderBottom2,
  propBorderBottom3,
  propBorderW,
  propBorderS,
  propBorderC,
  onButtonSecondary12Click,
  EventsIn,
  EventsHIn,
  APointsIn,
  APointsHIn,
  NotifsIn,
  NotifsHIn,
  ScrollObjAl,
  ScrollObjAlH,
}) => {
  const [IsHidden1, setIsHidden1] = useState(true);
  const [IsRotated1, setIsRotated1] = useState(false);
  const ChangeHideState1 = () => {
    setIsHidden1(!IsHidden1);
    setIsRotated1(!IsRotated1);
    ScrollObjAl.recalculateScrollIndicator();
  };
  const [IsHidden2, setIsHidden2] = useState(true);
  const [IsRotated2, setIsRotated2] = useState(false);
  const ChangeHideState2 = () => {
    setIsHidden2(!IsHidden2);
    setIsRotated2(!IsRotated2);
    ScrollObjAl.recalculateScrollIndicator();
  };
  const [IsHidden3, setIsHidden3] = useState(true);
  const [IsRotated3, setIsRotated3] = useState(false);
  const ChangeHideState3 = () => {
    setIsHidden3(!IsHidden3);
    setIsRotated3(!IsRotated3);
    ScrollObjAl.recalculateScrollIndicator();
  };

  const [IsHidden1H, setIsHidden1H] = useState(true);
  const [IsRotated1H, setIsRotated1H] = useState(false);
  const ChangeHideState1H = () => {
    setIsHidden1H(!IsHidden1H);
    setIsRotated1H(!IsRotated1H);
    ScrollObjAlH.recalculateScrollIndicator();
  };
  const [IsHidden2H, setIsHidden2H] = useState(true);
  const [IsRotated2H, setIsRotated2H] = useState(false);
  const ChangeHideState2H = () => {
    setIsHidden2H(!IsHidden2H);
    setIsRotated2H(!IsRotated2H);
    ScrollObjAlH.recalculateScrollIndicator();
  };
  const [IsHidden3H, setIsHidden3H] = useState(true);
  const [IsRotated3H, setIsRotated3H] = useState(false);
  const ChangeHideState3H = () => {
    setIsHidden3H(!IsHidden3H);
    setIsRotated3H(!IsRotated3H);
    ScrollObjAlH.recalculateScrollIndicator();
  };
  const [Events, setEvents] = useState(EventsIn)
  const [EventsH, setEventsH] = useState(EventsHIn)
  const [APoints, setAPoints] = useState(APointsIn)
  const [APointsH, setAPointsH] = useState(APointsHIn)
  const [Notifs, setNotifs] = useState(NotifsIn)
  const [NotifsH, setNotifsH] = useState(NotifsHIn)

  const [histVisible, setHistVisible] = useState(false)
  return (
    <TabMyAlertsRoot id="AlertSection">
      {!histVisible?
      <>
        <MyAlertsParent>
          <MyAlerts propColor={propColor}>My Alerts</MyAlerts>
          <Buttontretiary onClick={()=>{setHistVisible(true);ScrollObjAlH.recalculateScrollIndicator();}}>
            <EndSession
              propColor1={propColor1}
            >{`My Alerts history >`}</EndSession>
          </Buttontretiary>
        </MyAlertsParent>
        <FrameParent
          propBackgroundColor={propBackgroundColor}
          propBorderW={propBorderW}
          propBorderS={propBorderS}
          propBorderC={propBorderC}
          ref={ScrollObjAl.siblingRef}
        >
          <FrameWrapper propBorderBottom={propBorderBottom}>
            <CalendarEventsParent onClick={ChangeHideState1}>
              <CalendarEvents propColor2={propColor2}>
                Calendar events
              </CalendarEvents>
              <MediumdropdownarrowIcon
                alt=""
                src="/DropdownLocerArrow.svg"
                IsRotated={IsRotated1}
              />
            </CalendarEventsParent>
          </FrameWrapper>
          <FrameGroup propBackgroundColor1={propBackgroundColor1} IsHidden={IsHidden1}>
            <Nov20272023Parent>
              <Nov20 propColor3={propColor3}>Nov 20 - 27, 2023</Nov20>
              <ButtonsecondaryParent>
                <StateHoverIconno
                  saveSession="Today"
                  stateHoverIconnoJustifyContent="flex-start"
                  stateHoverIconnoWidth="unset"
                  stateHoverIconnoZIndex="unset"
                  stateHoverIconnoBackgroundColor="transparent"
                  stateHoverIconnoCursor="pointer"
                  stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                  stateHoverIconnoBorderS="solid"
                  stateHoverIconnoBorderC="var(--colors-darkerblue)"
                  stateHoverIconnoHeight="4.166666666666666vh"
                  stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                  stateHoverIconnoBoxSizing="border-box"
                  stateHoverIconnoPosition="relative"
                  stateHoverIconnoTop="unset"
                  stateHoverIconnoLeft="unset"
                  saveSessionColor="var(--colors-darkerblue)"
                  saveSessionDisplay="inline-block"
                  onButtonSecondaryClick={onButtonSecondary12Click}
                />
                <Property1Default12
                  largeBack="/ArrowHead.svg"
                  property1DefaultCursor="pointer"
                  property1DefaultBorderW="none"
                  property1DefaultBorderS="none"
                  property1DefaultBorderC="none"
                  property1DefaultPadding="0"
                  property1DefaultTransform="rotate(0deg)"
                  property1DefaultBackgroundColor={propBackgroundColor2}
                  largeBackIconTransform="unset"
                />
                <Property1Default12
                  largeBack="/ArrowHead.svg"
                  property1DefaultCursor="pointer"
                  property1DefaultBorderW="none"
                  property1DefaultBorderC="none"
                  property1DefaultBorderS="none"
                  property1DefaultPadding="0"
                  property1DefaultTransform="rotate(0deg)"
                  property1DefaultBackgroundColor={propBackgroundColor3}
                  largeBackIconTransform="rotate(180deg)"
                />
              </ButtonsecondaryParent>
            </Nov20272023Parent>
            <FrameContainer>
              <AlertsParent>
                {
                  Events.map((Event, index) => {
                    return (
                      <Property13ButtonsResolved
                        date={Event.date}
                        weekday={Event.weekDay}
                        timestart={Event.start}
                        timeend={Event.end}
                        Actions={Event.acts}
                        tags={Event.tags}
                        key={"Event"+String(index)}
                        teacherAlexandroProposesT={Event.text}
                        property13ButtonsResolvedBackgroundColor="var(--colors-blue-bg)"
                        monday20NovemberColor="var(--colors-dark-1)"
                        chipBackgroundColor="var(--colors-blue-1)"
                        allColor="var(--colors-white)"
                        chipBackgroundColor1="var(--colors-blue-1)"
                        allColor1="var(--colors-white)"
                        divColor="var(--colors-dark-1)"
                        teacherAlexandroProposesColor="var(--colors-dark-1)"
                        buttonSecondaryCursor="pointer"
                        buttonSecondaryBackgroundColor="transparent"
                        saveSessionColor="var(--colors-darkerblue)"
                        saveSessionDisplay="inline-block"
                        buttonSecondaryCursor1="pointer"
                        buttonSecondaryBackgroundColor1="transparent"
                        saveSessionColor1="var(--colors-darkerblue)"
                        saveSessionDisplay1="inline-block"
                        buttonSecondaryCursor2="pointer"
                        buttonSecondaryBackgroundColor2="var(--colors-darkerblue)"
                        saveSessionDisplay2="inline-block"
                      />
                    )
                  })
                }
              </AlertsParent>
            </FrameContainer>
          </FrameGroup>
          <FrameDiv>
            <FrameWrapper1 propBorderBottom1={propBorderBottom1}>
              <CalendarEventsParent onClick={ChangeHideState2}>
                <ActionPointsWrapper>
                  <CalendarEvents1 propColor33={propColor33}>
                    Action Points
                  </CalendarEvents1>
                </ActionPointsWrapper>
                <MediumdropdownarrowIcon
                  alt=""
                  src="/DropdownLocerArrow.svg"
                  IsRotated={IsRotated2}
                />
              </CalendarEventsParent>
            </FrameWrapper1>
            <FrameWrapper2
              propBackgroundColor20={propBackgroundColor20}
              propBorderBottom2={propBorderBottom2}
              IsHidden={IsHidden2}
            >
              <FrameContainer>
                <AlertsWrapper>
                  {
                    APoints.map((AP,index)=> {
                      return (
                        <Property11ButtonResolvedn
                          key={"ActP"+String(index)}
                          text={AP.text}
                          date={AP.date}
                          weekday={AP.weekDay}
                          timestart={AP.start}
                          timeend={AP.end}
                          tags={AP.tags}
                          Actions={AP.acts}
                          property11ButtonResolvednBackgroundColor="var(--colors-blue-bg)"
                          monday20NovemberColor="var(--colors-dark-1)"
                          chipBackgroundColor="var(--colors-blue-1)"
                          allColor="var(--colors-white)"
                          chipBackgroundColor1="var(--colors-blue-1)"
                          allColor1="var(--colors-white)"
                          divColor="var(--colors-dark-1)"
                          youAcceptedFirstColor="var(--colors-dark-1)"
                          buttonSecondaryCursor="pointer"
                          buttonSecondaryBackgroundColor="var(--colors-darkerblue)"
                          saveSessionDisplay="inline-block"
                        />
                      )
                    })
                  }
                </AlertsWrapper>
              </FrameContainer>
            </FrameWrapper2>
          </FrameDiv>
          <FrameDiv>
            <FrameWrapper11 propBorderBottom3={propBorderBottom3}>
              <CalendarEventsParent onClick={ChangeHideState3}>
                <CalendarEvents2 propColor39={propColor39}>
                  Notifications
                </CalendarEvents2>
                <MediumdropdownarrowIcon
                  alt=""
                  src="/DropdownLocerArrow.svg"
                  IsRotated={IsRotated3}
                />
              </CalendarEventsParent>
            </FrameWrapper11>
            <FrameWrapper5 propBackgroundColor25={propBackgroundColor25} IsHidden={IsHidden3}>
              <AlertsWrapper>
                {
                    Notifs.map((Notif,index)=> {
                      return (
                        <Property1TextResolvedno
                          key={"Notif"+String(index)}
                          text={Notif.text}
                          date={Notif.date}
                          weekday={Notif.weekDay}
                          timestart={Notif.start}
                          tags={Notif.tags}
                          property1TextResolvednoBackgroundColor="var(--colors-blue-bg)"
                          monday20NovemberColor="var(--colors-dark-1)"
                          chipBackgroundColor="var(--colors-blue-1)"
                          allColor="var(--colors-white)"
                          chipBackgroundColor1="var(--colors-blue-1)"
                          allColor1="var(--colors-white)"
                          divColor="var(--colors-dark-1)"
                          youAcceptedFirstColor="var(--colors-dark-1)"
                        />
                      )
                    })
                  }
              </AlertsWrapper>
            </FrameWrapper5>
          </FrameDiv>
        </FrameParent>
        <ScrollHolder
          scrollHeight={ScrollObjAl.scrollIndicatorHeight}
          scrollIndicatorTop={ScrollObjAl.scrollIndicatorTop}
          ref={ScrollObjAl.scrollRef}
          scrollHolderHeight="84.5vh"
          scrollHolderAlignItems="unset"
          scrollHolderPosition="absolute"
          scrollHolderTop="15vh"
          scrollHolderLeft="98vw"
          scrollHolderMargin="unset"
          scrollHolderRight="unset"
          scrollHolderFlexShrink="unset"
          scrollHolderZIndex="unset"
          scrollHolderBackgroundColor="var(--colors-grey-stroke)"
          scrollBackgroundColor="var(--colors-purple-light)"
        />
      </>
      :
      <>
        <MyAlertsParent>
          <MyAlerts propColor={propColor}>My Alerts History</MyAlerts>
          <Buttontretiary onClick={()=>{setHistVisible(false);ScrollObjAl.recalculateScrollIndicator();}}>
            <EndSession
              propColor1={propColor1}
            >{`< My Alerts`}</EndSession>
          </Buttontretiary>
        </MyAlertsParent>
        <FrameParent1
          propBackgroundColor={propBackgroundColor}
          propBorderW={propBorderW}
          propBorderS={propBorderS}
          propBorderC={propBorderC}
          ref={ScrollObjAlH.siblingRef}
        >
          <FrameWrapper propBorderBottom={propBorderBottom}>
            <CalendarEventsParent onClick={ChangeHideState1H}>
              <CalendarEvents propColor2={propColor2}>
                Calendar events
              </CalendarEvents>
              <MediumdropdownarrowIcon
                alt=""
                src="/DropdownLocerArrow.svg"
                IsRotated={IsRotated1H}
              />
            </CalendarEventsParent>
          </FrameWrapper>
          <FrameGroup propBackgroundColor1={propBackgroundColor1} IsHidden={IsHidden1H}>
            <Nov20272023Parent>
              <Nov20 propColor3={propColor3}>Nov 20 - 27, 2023</Nov20>
              <ButtonsecondaryParent>
                <StateHoverIconno
                  saveSession="Today"
                  stateHoverIconnoJustifyContent="flex-start"
                  stateHoverIconnoWidth="unset"
                  stateHoverIconnoZIndex="unset"
                  stateHoverIconnoBackgroundColor="transparent"
                  stateHoverIconnoCursor="pointer"
                  stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                  stateHoverIconnoBorderS="solid"
                  stateHoverIconnoBorderC="var(--colors-darkerblue)"
                  stateHoverIconnoHeight="4.166666666666666vh"
                  stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                  stateHoverIconnoBoxSizing="border-box"
                  stateHoverIconnoPosition="relative"
                  stateHoverIconnoTop="unset"
                  stateHoverIconnoLeft="unset"
                  saveSessionColor="var(--colors-darkerblue)"
                  saveSessionDisplay="inline-block"
                  onButtonSecondaryClick={onButtonSecondary12Click}
                />
                <Property1Default12
                  largeBack="/ArrowHead.svg"
                  property1DefaultCursor="pointer"
                  property1DefaultBorderW="none"
                  property1DefaultBorderS="none"
                  property1DefaultBorderC="none"
                  property1DefaultPadding="0"
                  property1DefaultTransform="rotate(0deg)"
                  property1DefaultBackgroundColor={propBackgroundColor2}
                  largeBackIconTransform="unset"
                />
                <Property1Default12
                  largeBack="/ArrowHead.svg"
                  property1DefaultCursor="pointer"
                  property1DefaultBorderW="none"
                  property1DefaultBorderS="none"
                  property1DefaultBorderC="none"
                  property1DefaultPadding="0"
                  property1DefaultTransform="rotate(0deg)"
                  property1DefaultBackgroundColor={propBackgroundColor3}
                  largeBackIconTransform="rotate(180deg)"
                />
              </ButtonsecondaryParent>
            </Nov20272023Parent>
            <FrameContainer>
              <AlertsParent>
                {
                  EventsH.map((Event, index) => {
                    return (
                      <Property13ButtonsResolved
                        date={Event.date}
                        weekday={Event.weekDay}
                        timestart={Event.start}
                        timeend={Event.end}
                        Actions={[]}
                        tags={Event.tags}
                        key={"EventH"+String(index)}
                        teacherAlexandroProposesT={Event.text}
                        property13ButtonsResolvedBackgroundColor="var(--colors-blue-bg)"
                        monday20NovemberColor="var(--colors-dark-1)"
                        chipBackgroundColor="var(--colors-blue-1)"
                        allColor="var(--colors-white)"
                        chipBackgroundColor1="var(--colors-blue-1)"
                        allColor1="var(--colors-white)"
                        divColor="var(--colors-dark-1)"
                        teacherAlexandroProposesColor="var(--colors-dark-1)"
                        buttonSecondaryCursor="pointer"
                        buttonSecondaryBackgroundColor="transparent"
                        saveSessionColor="var(--colors-darkerblue)"
                        saveSessionDisplay="inline-block"
                        buttonSecondaryCursor1="pointer"
                        buttonSecondaryBackgroundColor1="transparent"
                        saveSessionColor1="var(--colors-darkerblue)"
                        saveSessionDisplay1="inline-block"
                        buttonSecondaryCursor2="pointer"
                        buttonSecondaryBackgroundColor2="var(--colors-darkerblue)"
                        saveSessionDisplay2="inline-block"
                      />
                    )
                  })
                }
              </AlertsParent>
            </FrameContainer>
          </FrameGroup>
          <FrameDiv>
            <FrameWrapper1 propBorderBottom1={propBorderBottom1}>
              <CalendarEventsParent onClick={ChangeHideState2H}>
                <ActionPointsWrapper>
                  <CalendarEvents1 propColor33={propColor33}>
                    Action Points
                  </CalendarEvents1>
                </ActionPointsWrapper>
                <MediumdropdownarrowIcon
                  alt=""
                  src="/DropdownLocerArrow.svg"
                  IsRotated={IsRotated2H}
                />
              </CalendarEventsParent>
            </FrameWrapper1>
            <FrameWrapper2
              propBackgroundColor20={propBackgroundColor20}
              propBorderBottom2={propBorderBottom2}
              IsHidden={IsHidden2H}
            >
              <FrameContainer>
                <AlertsWrapper>
                  {
                    APointsH.map((AP,index)=> {
                      return (
                        <Property11ButtonResolvedn
                          key={"ActPH"+String(index)}
                          text={AP.text}
                          date={AP.date}
                          weekday={AP.weekDay}
                          timestart={AP.start}
                          timeend={AP.end}
                          tags={AP.tags}
                          Actions={[]}
                          property11ButtonResolvednBackgroundColor="var(--colors-blue-bg)"
                          monday20NovemberColor="var(--colors-dark-1)"
                          chipBackgroundColor="var(--colors-blue-1)"
                          allColor="var(--colors-white)"
                          chipBackgroundColor1="var(--colors-blue-1)"
                          allColor1="var(--colors-white)"
                          divColor="var(--colors-dark-1)"
                          youAcceptedFirstColor="var(--colors-dark-1)"
                          buttonSecondaryCursor="pointer"
                          buttonSecondaryBackgroundColor="var(--colors-darkerblue)"
                          saveSessionDisplay="inline-block"
                        />
                      )
                    })
                  }
                </AlertsWrapper>
              </FrameContainer>
            </FrameWrapper2>
          </FrameDiv>
          <FrameDiv>
            <FrameWrapper11 propBorderBottom3={propBorderBottom3}>
              <CalendarEventsParent onClick={ChangeHideState3H}>
                <CalendarEvents2 propColor39={propColor39}>
                  Notifications
                </CalendarEvents2>
                <MediumdropdownarrowIcon
                  alt=""
                  src="/DropdownLocerArrow.svg"
                  IsRotated={IsRotated3H}
                />
              </CalendarEventsParent>
            </FrameWrapper11>
            <FrameWrapper5 propBackgroundColor25={propBackgroundColor25} IsHidden={IsHidden3H}>
              <AlertsWrapper>
                {
                    NotifsH.map((Notif,index)=> {
                      return (
                        <Property1TextResolvedno
                          key={"NotifH"+String(index)}
                          text={Notif.text}
                          date={Notif.date}
                          weekday={Notif.weekDay}
                          timestart={Notif.start}
                          tags={Notif.tags}
                          property1TextResolvednoBackgroundColor="var(--colors-blue-bg)"
                          monday20NovemberColor="var(--colors-dark-1)"
                          chipBackgroundColor="var(--colors-blue-1)"
                          allColor="var(--colors-white)"
                          chipBackgroundColor1="var(--colors-blue-1)"
                          allColor1="var(--colors-white)"
                          divColor="var(--colors-dark-1)"
                          youAcceptedFirstColor="var(--colors-dark-1)"
                        />
                      )
                    })
                  }
              </AlertsWrapper>
            </FrameWrapper5>
          </FrameDiv>
        </FrameParent1>
        <ScrollHolder
          scrollHeight={ScrollObjAlH.scrollIndicatorHeight}
          scrollIndicatorTop={ScrollObjAlH.scrollIndicatorTop}
          ref={ScrollObjAlH.scrollRef}
          scrollHolderHeight="84.5vh"
          scrollHolderAlignItems="unset"
          scrollHolderPosition="absolute"
          scrollHolderTop="15vh"
          scrollHolderLeft="98vw"
          scrollHolderMargin="unset"
          scrollHolderRight="unset"
          scrollHolderFlexShrink="unset"
          scrollHolderZIndex="unset"
          scrollHolderBackgroundColor="var(--colors-grey-stroke)"
          scrollBackgroundColor="var(--colors-purple-light)"
        />
      </>
      }
    </TabMyAlertsRoot>
  );
};

export default FormContainerMyAlerts;
