import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import HoverDisabled from "./HoverDisabled";
import React, { useState } from 'react';

type StateDefaultEmptyYesChecType = {
  text?: string;
  id?: string

  /** Style props */
  stateDefaultEmptyYesChecBoxSizing?: CSSProperties["boxSizing"];
  stateDefaultEmptyYesChecPosition?: CSSProperties["position"];
  stateDefaultEmptyYesChecGap?: CSSProperties["gap"];
  stateDefaultEmptyYesChecOutline?: CSSProperties["outline"];
  stateDefaultEmptyYesChecFontFamily?: CSSProperties["fontFamily"];
  stateDefaultEmptyYesChecFontSize?: CSSProperties["fontSize"];
  stateDefaultEmptyYesChecColor?: CSSProperties["color"];
  stateDefaultEmptyYesChecPadding?: CSSProperties["padding"];
  stateDefaultEmptyYesChecAlignSelf?: CSSProperties["alignSelf"];
  stateDefaultEmptyYesChecWidth?: CSSProperties["width"];
  stateDefaultEmptyTextWidth?: CSSProperties["width"];
  stateDefaultEmptyYesChecHeight?: CSSProperties["height"];
  stateDefaultEmptyYesChecFontWeight?: CSSProperties["fontWeight"];
  stateDefaultEmptyYesChecFlex?: CSSProperties["flex"];
  stateDefaultEmptyYesChecZIndex?: CSSProperties["zIndex"];
  stateDefaultEmptyYesChecBackgroundColor?: CSSProperties["backgroundColor"];
  stateDefaultEmptyYesChecBorderW?: CSSProperties["borderWidth"];
  stateDefaultEmptyYesChecBorderS?: CSSProperties["borderStyle"];
  stateDefaultEmptyYesChecBorderC?: CSSProperties["borderColor"];
  stateDefaultEmptyYesChecTop?: CSSProperties["top"];
  stateDefaultEmptyYesChecBottom?: CSSProperties["bottom"];
  stateDefaultEmptyYesChecLeft?: CSSProperties["left"];
  stateDefaultEmptyYesChecRight?: CSSProperties["right"];
  InputWidth?: CSSProperties["width"];
  value?: string;
  type?: string;
  icon?: string;


  onCheckClick?: (e: React.MouseEvent<HTMLElement>) => boolean;
};

const Text1 = styled.input<{
  InputWidth?: Property.Width;
  stateDefaultEmptyYesChecHeight?: Property.Height;
  stateDefaultEmptyYesChecFontSize?: Property.FontSize;
  stateDefaultEmptyYesChecPadding?: Property.Padding;
}>`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  z-index: 0;
  border-width: 0;
  width: 8.333333333333332vw;
  width: ${(p) => p.InputWidth};
  height: calc(${(p) => (p.stateDefaultEmptyYesChecHeight)} - 2 * ${(p) => ((p.stateDefaultEmptyYesChecPadding as string).split(' ')[0])});
  font-size: var(--body-main-size);
  font-size: ${(p) => p.stateDefaultEmptyYesChecFontSize};
  background-color: var(--colors-white);
  color: var(--colors-dark-1);
  &:focus {
    outline: none;
  }
`;
const StatedefaultEmptyyesChecRoot = styled.div<{
  stateDefaultEmptyYesChecBoxSizing?: Property.BoxSizing;
  stateDefaultEmptyYesChecPosition?: Property.Position;
  stateDefaultEmptyYesChecGap?: Property.Gap;
  stateDefaultEmptyYesChecOutline?: Property.Outline;
  stateDefaultEmptyYesChecFontFamily?: Property.FontFamily;
  stateDefaultEmptyYesChecFontSize?: Property.FontSize;
  stateDefaultEmptyYesChecColor?: Property.Color;
  stateDefaultEmptyYesChecPadding?: Property.Padding;
  stateDefaultEmptyYesChecAlignSelf?: Property.AlignSelf;
  stateDefaultEmptyYesChecWidth?: Property.Width;
  stateDefaultEmptyYesChecHeight?: Property.Height;
  stateDefaultEmptyYesChecFontWeight?: Property.FontWeight;
  stateDefaultEmptyYesChecFlex?: Property.Flex;
  stateDefaultEmptyYesChecZIndex?: Property.ZIndex;
  stateDefaultEmptyYesChecBackgroundColor?: Property.BackgroundColor;
  stateDefaultEmptyYesChecBorderW?: Property.BorderWidth;
  stateDefaultEmptyYesChecBorderC?: Property.BorderColor;
  stateDefaultEmptyYesChecBorderS?: Property.BorderStyle;
  stateDefaultEmptyYesChecTop?: Property.Top;
  stateDefaultEmptyYesChecBottom?: Property.Bottom;
  stateDefaultEmptyYesChecLeft?: Property.Left;
  stateDefaultEmptyYesChecRight?: Property.Right;
}>`border-radius: var(--corner-radius-m);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW);
  position: relative;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-purple-light);
  font-family: var(--buttons-btn-input);
  align-self: stretch;
  box-sizing: ${(p) => p.stateDefaultEmptyYesChecBoxSizing};
  position: ${(p) => p.stateDefaultEmptyYesChecPosition};
  gap: ${(p) => p.stateDefaultEmptyYesChecGap};
  outline: ${(p) => p.stateDefaultEmptyYesChecOutline};
  font-family: ${(p) => p.stateDefaultEmptyYesChecFontFamily};
  font-size: ${(p) => p.stateDefaultEmptyYesChecFontSize};
  color: ${(p) => p.stateDefaultEmptyYesChecColor};
  padding: ${(p) => p.stateDefaultEmptyYesChecPadding};
  align-self: ${(p) => p.stateDefaultEmptyYesChecAlignSelf};
  width: ${(p) => p.stateDefaultEmptyYesChecWidth};
  height: ${(p) => p.stateDefaultEmptyYesChecHeight};
  font-weight: ${(p) => p.stateDefaultEmptyYesChecFontWeight};
  flex: ${(p) => p.stateDefaultEmptyYesChecFlex};
  z-index: ${(p) => p.stateDefaultEmptyYesChecZIndex};
  background-color: ${(p) => p.stateDefaultEmptyYesChecBackgroundColor};
  border-width: ${(p) => p.stateDefaultEmptyYesChecBorderW};
  border-color: ${(p) => p.stateDefaultEmptyYesChecBorderC};
  border-style: ${(p) => p.stateDefaultEmptyYesChecBorderS};
  top: ${(p) => p.stateDefaultEmptyYesChecTop};
  bottom: ${(p) => p.stateDefaultEmptyYesChecBottom};
  left: ${(p) => p.stateDefaultEmptyYesChecLeft};
  right: ${(p) => p.stateDefaultEmptyYesChecRight};
  &:focus-within {
    border-color: var(--colors-darkerblue);
  }
`;

const StateDefaultEmptyYesChec: FunctionComponent<
  StateDefaultEmptyYesChecType
> = ({
  text = "Tutor Code ",
  type="text",
  value,
  id,
  stateDefaultEmptyYesChecBoxSizing,
  stateDefaultEmptyYesChecPosition,
  stateDefaultEmptyYesChecGap,
  stateDefaultEmptyYesChecOutline,
  stateDefaultEmptyYesChecFontFamily,
  stateDefaultEmptyYesChecFontSize,
  stateDefaultEmptyYesChecColor,
  stateDefaultEmptyYesChecPadding,
  stateDefaultEmptyYesChecAlignSelf,
  stateDefaultEmptyYesChecWidth,
  stateDefaultEmptyTextWidth,
  stateDefaultEmptyYesChecHeight,
  stateDefaultEmptyYesChecFontWeight,
  stateDefaultEmptyYesChecFlex,
  stateDefaultEmptyYesChecZIndex,
  stateDefaultEmptyYesChecBackgroundColor,
  stateDefaultEmptyYesChecBorderW,
  stateDefaultEmptyYesChecBorderC,
  stateDefaultEmptyYesChecBorderS,
  stateDefaultEmptyYesChecTop,
  stateDefaultEmptyYesChecBottom,
  stateDefaultEmptyYesChecLeft,
  stateDefaultEmptyYesChecRight,
  icon,
  onCheckClick,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [currentState, setCurrentState] = useState<"default"|"success"|"error">("default")
  return (
    <StatedefaultEmptyyesChecRoot
      stateDefaultEmptyYesChecBoxSizing={stateDefaultEmptyYesChecBoxSizing}
      stateDefaultEmptyYesChecPosition={stateDefaultEmptyYesChecPosition}
      stateDefaultEmptyYesChecGap={stateDefaultEmptyYesChecGap}
      stateDefaultEmptyYesChecOutline={stateDefaultEmptyYesChecOutline}
      stateDefaultEmptyYesChecFontFamily={stateDefaultEmptyYesChecFontFamily}
      stateDefaultEmptyYesChecFontSize={stateDefaultEmptyYesChecFontSize}
      stateDefaultEmptyYesChecColor={stateDefaultEmptyYesChecColor}
      stateDefaultEmptyYesChecPadding={stateDefaultEmptyYesChecPadding}
      stateDefaultEmptyYesChecAlignSelf={stateDefaultEmptyYesChecAlignSelf}
      stateDefaultEmptyYesChecWidth={stateDefaultEmptyYesChecWidth}
      stateDefaultEmptyYesChecHeight={stateDefaultEmptyYesChecHeight}
      stateDefaultEmptyYesChecFontWeight={stateDefaultEmptyYesChecFontWeight}
      stateDefaultEmptyYesChecFlex={stateDefaultEmptyYesChecFlex}
      stateDefaultEmptyYesChecZIndex={stateDefaultEmptyYesChecZIndex}
      stateDefaultEmptyYesChecBackgroundColor={
        stateDefaultEmptyYesChecBackgroundColor
      }
      stateDefaultEmptyYesChecBorderW={stateDefaultEmptyYesChecBorderW}
      stateDefaultEmptyYesChecBorderC={currentState==="default"?stateDefaultEmptyYesChecBorderC:(currentState==="success"?"var(--colors-green)":"var(--colors-red)")}
      stateDefaultEmptyYesChecBorderS={stateDefaultEmptyYesChecBorderS}
      stateDefaultEmptyYesChecTop={stateDefaultEmptyYesChecTop}
      stateDefaultEmptyYesChecBottom={stateDefaultEmptyYesChecBottom}
      stateDefaultEmptyYesChecLeft={stateDefaultEmptyYesChecLeft}
      stateDefaultEmptyYesChecRight={stateDefaultEmptyYesChecRight}
    >
      <Text1
      type={type}
      placeholder={text}
      value={inputValue}
      InputWidth={stateDefaultEmptyTextWidth}
      stateDefaultEmptyYesChecHeight={stateDefaultEmptyYesChecHeight}
      stateDefaultEmptyYesChecFontSize={stateDefaultEmptyYesChecFontSize}
      stateDefaultEmptyYesChecPadding={stateDefaultEmptyYesChecPadding}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      id={id}
      />
      <HoverDisabled
        smallCheck={icon?icon:"/Checkmark.svg"}
        hoverDisabledMargin="0 !important"
        hoverDisabledPosition="relative"
        hoverDisabledTop="0.0vh"
        hoverDisabledRight="0.0vw"
        hoverDisabledFlexShrink="0"
        hoverDisabledZIndex="1"
        hoverDisabledBackgroundColor="var(--colors-blue-1)"
        onCheckClick={(e)=>{
          if (onCheckClick) {
            let fine=onCheckClick(e)
            setCurrentState(fine?"success":"error")
          }
        }}
      />
    </StatedefaultEmptyyesChecRoot>
  );
};

export default StateDefaultEmptyYesChec;
