import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type StateDefault2Type = {
  smallDelete?: string;
  ChangeBGOnHover?: boolean;

  /** Style props */
  stateDefaultFlexShrink?: CSSProperties["flexShrink"];
  stateDefaultCursor?: CSSProperties["cursor"];
  stateDefaultBorderW?: CSSProperties["borderWidth"];
  stateDefaultBorderS?: CSSProperties["borderStyle"];
  stateDefaultBorderC?: CSSProperties["borderColor"];
  stateDefaultBackgroundColor?: CSSProperties["backgroundColor"];

  /** Action props */
  onButttonDelete40x28Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const SmalldeleteIcon = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const StatedefaultRoot = styled.div<{
  stateDefaultFlexShrink?: Property.FlexShrink;
  stateDefaultCursor?: Property.Cursor;
  stateDefaultBorderW?: Property.BorderWidth;
  stateDefaultBorderC?: Property.BorderColor;
  stateDefaultBorderS?: Property.BorderStyle;
  stateDefaultBackgroundColor?: Property.BackgroundColor;
  ChangeBGOnHover?: boolean;
}>`border-radius: var(--corner-radius-m);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xsH) var(--padding-9xsW);
  flex-shrink: ${(p) => p.stateDefaultFlexShrink};
  cursor: ${(p) => p.stateDefaultCursor};
  border-width: ${(p) => p.stateDefaultBorderW};
  border-color: ${(p) => p.stateDefaultBorderC};
  border-style: ${(p) => p.stateDefaultBorderS};
  background-color: ${(p) => p.stateDefaultBackgroundColor};
  & img {filter: var(--filters-purple-light);}
  &:hover {
    ${(p) => p.ChangeBGOnHover?"background-color: var(--colors-red-1);":""}
    & img {filter: var(--filters-red);}
  }
  `;

const StateDefault2: FunctionComponent<StateDefault2Type> = ({
  smallDelete,
  stateDefaultFlexShrink,
  stateDefaultCursor,
  stateDefaultBorderW,
  stateDefaultBorderC,
  stateDefaultBorderS,
  stateDefaultBackgroundColor,
  ChangeBGOnHover=false,
  onButttonDelete40x28Click,
}) => {
  return (
    <StatedefaultRoot
      stateDefaultFlexShrink={stateDefaultFlexShrink}
      stateDefaultCursor={stateDefaultCursor}
      stateDefaultBorderW={stateDefaultBorderW}
      stateDefaultBorderC={stateDefaultBorderC}
      stateDefaultBorderS={stateDefaultBorderS}
      stateDefaultBackgroundColor={stateDefaultBackgroundColor}
      onClick={onButttonDelete40x28Click}
      ChangeBGOnHover={ChangeBGOnHover}
    >
      <SmalldeleteIcon alt="" src={smallDelete} />
    </StatedefaultRoot>
  );
};

export default StateDefault2;
