import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type StateDefault1Type = {
  text?: string;

  /** Style props */
  stateDefaultBorderBottom?: CSSProperties["borderBottom"];
  stateDefaultFlexShrink?: CSSProperties["flexShrink"];
  stateDefaultFlex?: CSSProperties["flex"];
  stateDefaultWidth?: CSSProperties["width"];
  stateDefaultBoxSizing?: CSSProperties["boxSizing"];
  stateDefaultCursor?: CSSProperties["cursor"];
  stateDefaultBorderW?: CSSProperties["borderWidth"];
  stateDefaultBorderS?: CSSProperties["borderStyle"];
  stateDefaultBorderC?: CSSProperties["borderColor"];
  stateDefaultBackgroundColor?: CSSProperties["backgroundColor"];
  meaningColor?: CSSProperties["color"];
  meaningDisplay?: CSSProperties["display"];
  HasClass?: string;

  /** Action props */
  onTabSecondaryClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Meaning = styled.div<{
  meaningColor?: Property.Color;
  meaningDisplay?: Property.Display;
}>`position: relative;
  line-height: 2.604166666666667vh;
  font-weight: 500;
  color: ${(p) => p.meaningColor};
  display: ${(p) => p.meaningDisplay};
`;
const StatedefaultRoot = styled.div<{
  stateDefaultBorderBottom?: Property.BorderBottom;
  stateDefaultFlexShrink?: Property.FlexShrink;
  stateDefaultFlex?: Property.Flex;
  stateDefaultWidth?: Property.Width;
  stateDefaultBoxSizing?: Property.BoxSizing;
  stateDefaultCursor?: Property.Cursor;
  stateDefaultBorderW?: Property.BorderWidth;
  stateDefaultBorderC?: Property.BorderColor;
  stateDefaultBorderS?: Property.BorderStyle;
  stateDefaultBackgroundColor?: Property.BackgroundColor;
}>`border-bottom: 0.13020833333333331vh solid var(--color-gray-100);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xsH) var(--padding-xsW);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  flex: 1;
  border-bottom: ${(p) => p.stateDefaultBorderBottom};
  flex-shrink: ${(p) => p.stateDefaultFlexShrink};
  flex: ${(p) => p.stateDefaultFlex};
  width: ${(p) => p.stateDefaultWidth};
  box-sizing: ${(p) => p.stateDefaultBoxSizing};
  cursor: ${(p) => p.stateDefaultCursor};
  border-width: ${(p) => p.stateDefaultBorderW};
  border-color: ${(p) => p.stateDefaultBorderC};
  border-style: ${(p) => p.stateDefaultBorderS};
  background-color: ${(p) => p.stateDefaultBackgroundColor};
  &.selected {
    & div {color: var(--colors-darkerblue);}
    border-bottom: 0.13020833333333331vh solid var(--colors-darkerblue);
  }
  &:not(.selected):hover {
    & div {color: var(--colors-darkerblue);}
    border-bottom: 0.13020833333333331vh solid var(--colors-darkerblue);
  }
`;

const StateDefault1: FunctionComponent<StateDefault1Type> = ({
  text = "Meaning",
  stateDefaultBorderBottom,
  stateDefaultFlexShrink,
  stateDefaultFlex,
  stateDefaultWidth,
  stateDefaultBoxSizing,
  stateDefaultCursor,
  stateDefaultBorderW,
  stateDefaultBorderC,
  stateDefaultBorderS,
  stateDefaultBackgroundColor,
  meaningColor,
  meaningDisplay,
  HasClass,
  onTabSecondaryClick,
}) => {
  return (
    <StatedefaultRoot
      stateDefaultBorderBottom={stateDefaultBorderBottom}
      stateDefaultFlexShrink={stateDefaultFlexShrink}
      stateDefaultFlex={stateDefaultFlex}
      stateDefaultWidth={stateDefaultWidth}
      stateDefaultBoxSizing={stateDefaultBoxSizing}
      stateDefaultCursor={stateDefaultCursor}
      stateDefaultBorderW={stateDefaultBorderW}
      stateDefaultBorderC={stateDefaultBorderC}
      stateDefaultBorderS={stateDefaultBorderS}
      stateDefaultBackgroundColor={stateDefaultBackgroundColor}
      className={HasClass}
      onClick={onTabSecondaryClick}
    >
      <Meaning meaningColor={meaningColor} meaningDisplay={meaningDisplay}>
        {text}
      </Meaning>
    </StatedefaultRoot>
  );
};

export default StateDefault1;
