import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import StateDefault1 from "./StateDefault1";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

type Property1SecondaryType = {
  meaning?: string;
  RSSec: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection";
  hv: boolean;

  /** Style props */
  property1SecondaryWidth?: CSSProperties["width"];
  property1SecondaryMargin?: CSSProperties["margin"];
  property1SecondaryPosition?: CSSProperties["position"];
  property1SecondaryTop?: CSSProperties["top"];
  property1SecondaryRight?: CSSProperties["right"];
  property1SecondaryLeft?: CSSProperties["left"];
  property1SecondaryZIndex?: CSSProperties["zIndex"];
  tabSecondaryBorderBottom?: CSSProperties["borderBottom"];
  tabSecondaryBoxSizing?: CSSProperties["boxSizing"];
  tabSecondaryCursor?: CSSProperties["cursor"];
  tabSecondaryBackgroundColor?: CSSProperties["backgroundColor"];
  meaningColor?: CSSProperties["color"];
  meaningDisplay?: CSSProperties["display"];
  tabSecondaryBorderBottom1?: CSSProperties["borderBottom"];
  tabSecondaryBoxSizing1?: CSSProperties["boxSizing"];
  tabSecondaryCursor1?: CSSProperties["cursor"];
  tabSecondaryBackgroundColor1?: CSSProperties["backgroundColor"];
  meaningDisplay1?: CSSProperties["display"];
  meaningColor1?: CSSProperties["color"];
  tabSecondaryBorderBottom2?: CSSProperties["borderBottom"];
  tabSecondaryBoxSizing2?: CSSProperties["boxSizing"];
  tabSecondaryCursor2?: CSSProperties["cursor"];
  tabSecondaryBackgroundColor2?: CSSProperties["backgroundColor"];
  meaningDisplay2?: CSSProperties["display"];
  meaningColor2?: CSSProperties["color"];
  tabSecondaryBorderBottom3?: CSSProperties["borderBottom"];
  tabSecondaryCursor3?: CSSProperties["cursor"];
  tabSecondaryBackgroundColor3?: CSSProperties["backgroundColor"];
  meaningDisplay3?: CSSProperties["display"];
  meaningColor3?: CSSProperties["color"];

  /** Action props */
  MeaningB?: (e: React.MouseEvent<HTMLElement>) => void;
  GrammarB?: (e: React.MouseEvent<HTMLElement>) => void;
  VideoB?: (e: React.MouseEvent<HTMLElement>) => void;
  FlashcardsB?: (e: React.MouseEvent<HTMLElement>) => void;
  TranslateB?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Property1secondaryRoot = styled.div<{
  property1SecondaryWidth?: Property.Width;
  property1SecondaryMargin?: Property.Margin;
  property1SecondaryPosition?: Property.Position;
  property1SecondaryTop?: Property.Top;
  property1SecondaryRight?: Property.Right;
  property1SecondaryLeft?: Property.Left;
  property1SecondaryZIndex?: Property.ZIndex;
}>`width: 30.0vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: ${(p) => p.property1SecondaryWidth};
  margin: ${(p) => p.property1SecondaryMargin};
  position: ${(p) => p.property1SecondaryPosition};
  top: ${(p) => p.property1SecondaryTop};
  right: ${(p) => p.property1SecondaryRight};
  left: ${(p) => p.property1SecondaryLeft};
  z-index: ${(p) => p.property1SecondaryZIndex};
`;

const Property1Secondary: FunctionComponent<Property1SecondaryType> = ({
  meaning,
  property1SecondaryWidth,
  property1SecondaryMargin,
  property1SecondaryPosition,
  property1SecondaryTop,
  property1SecondaryRight,
  property1SecondaryLeft,
  property1SecondaryZIndex,
  MeaningB,
  GrammarB,
  VideoB,
  FlashcardsB,
  TranslateB,
  RSSec,
  hv,
}) => {
  const { t } = useTranslation();
  return (
    <Property1secondaryRoot
      property1SecondaryWidth={property1SecondaryWidth}
      property1SecondaryMargin={property1SecondaryMargin}
      property1SecondaryPosition={property1SecondaryPosition}
      property1SecondaryTop={property1SecondaryTop}
      property1SecondaryRight={property1SecondaryRight}
      property1SecondaryLeft={property1SecondaryLeft}
      property1SecondaryZIndex={property1SecondaryZIndex}
    >
      <StateDefault1
        text={t("Meaning")}
        stateDefaultBorderBottom="unset"
        stateDefaultFlexShrink="0"
        stateDefaultFlex="1"
        stateDefaultWidth="unset"
        stateDefaultBoxSizing="border-box"
        stateDefaultCursor="unset"
        stateDefaultBorderW="unset"
        stateDefaultBorderC="unset"
        stateDefaultBorderS="unset"
        stateDefaultBackgroundColor="unset"
        meaningColor="unset"
        meaningDisplay="inline-block"
        HasClass={RSSec==="MeaningSection"?"selected":undefined}
        onTabSecondaryClick={MeaningB}
      />
      <StateDefault1
        text={t("Grammar")}
        stateDefaultBorderBottom="0.13020833333333331vh solid var(--color-gray-100)"
        stateDefaultFlexShrink="0"
        stateDefaultFlex="1"
        stateDefaultWidth="unset"
        stateDefaultBoxSizing="border-box"
        stateDefaultCursor="unset"
        stateDefaultBorderW="unset"
        stateDefaultBorderC="unset"
        stateDefaultBorderS="unset"
        stateDefaultBackgroundColor="unset"
        meaningColor="var(--colors-dark-1)"
        meaningDisplay="inline-block"
        HasClass={RSSec==="GrammarSection"?"selected":undefined}
        onTabSecondaryClick={GrammarB}
      />
      <StateDefault1
        text={t("Translate")}
        stateDefaultBorderBottom="0.13020833333333331vh solid var(--color-gray-100)"
        stateDefaultFlexShrink="0"
        stateDefaultFlex="1"
        stateDefaultWidth="unset"
        stateDefaultBoxSizing="border-box"
        stateDefaultCursor="unset"
        stateDefaultBorderW="unset"
        stateDefaultBorderC="unset"
        stateDefaultBorderS="unset"
        stateDefaultBackgroundColor="unset"
        meaningColor="var(--colors-dark-1)"
        meaningDisplay="inline-block"
        HasClass={RSSec==="TranslateSection"?"selected":undefined}
        onTabSecondaryClick={TranslateB}
      />
      {(hv===false) && <StateDefault1
        text={t("Video")}
        stateDefaultBorderBottom="0.13020833333333331vh solid var(--color-gray-100)"
        stateDefaultFlexShrink="0"
        stateDefaultFlex="1"
        stateDefaultWidth="unset"
        stateDefaultBoxSizing="border-box"
        stateDefaultCursor="unset"
        stateDefaultBorderW="unset"
        stateDefaultBorderC="unset"
        stateDefaultBorderS="unset"
        stateDefaultBackgroundColor="unset"
        meaningColor="var(--colors-dark-1)"
        meaningDisplay="inline-block"
        HasClass={RSSec==="VideoSection"?"selected":undefined}
        onTabSecondaryClick={VideoB}
      />}
      <StateDefault1
        text={t("Flashcards")}
        stateDefaultBorderBottom="0.13020833333333331vh solid var(--color-gray-100)"
        stateDefaultFlexShrink="0"
        stateDefaultFlex="unset"
        stateDefaultWidth="7.5vw"
        stateDefaultBoxSizing="border-box"
        stateDefaultCursor="unset"
        stateDefaultBorderW="unset"
        stateDefaultBorderC="unset"
        stateDefaultBorderS="unset"
        stateDefaultBackgroundColor="unset"
        meaningColor="var(--colors-dark-1)"
        meaningDisplay="inline-block"
        HasClass={RSSec==="FlashcardSection"?"selected":undefined}
        onTabSecondaryClick={FlashcardsB}
      />
    </Property1secondaryRoot>
  );
};

export default Property1Secondary;
