import { createGlobalStyle } from "styled-components";

export default createGlobalStyle<{
darkMode: boolean;
}>`
    body {
      margin: 0; line-height: normal;
    }
:root {

/* fonts */
--buttons-btn-input: Onest;
--body-message: 'Courier New';

/* Colors */
--colors-white: ${(p)=>p.darkMode?"#232130":"#ffffff"};
--colors-btn-txt: ${(p)=>p.darkMode?"#ffffff":"#ffffff"};
--colors-darkerblue: ${(p)=>p.darkMode?"#6c84ff":"#4754f0"};
--colors-dark-1: ${(p)=>p.darkMode?"#ffffff":"#38344f"};
--colors-dark-2: ${(p)=>p.darkMode?"#2f2b45":"#2f2b45"};
--colors-dark-3: ${(p)=>p.darkMode?"#1f1d2b":"#1f1d2b"};
--colors-dark-sidepanel: ${(p)=>p.darkMode?"#1f1d2b":"#38344f"};
--colors-grey-bg: ${(p)=>p.darkMode?"#181722":"#f3f3f8"};
--color-gray-100: ${(p)=>p.darkMode?"#ffffff1a":"#38344f1a"};
--color-gray-400: ${(p)=>p.darkMode?"#ffffff66":"#38344f66"};
--color-other-gray-200: ${(p)=>p.darkMode?"#312c4733":"#312c4733"};
--color-black-350: ${(p)=>p.darkMode?"#00000059":"#00000059"};
--color-black-150: ${(p)=>p.darkMode?"#00000026":"#00000026"};
--color-black-120: ${(p)=>p.darkMode?"#0000001f":"#0000001f"};
--colors-blue-bg: ${(p)=>p.darkMode?"#1d263b":"#dde7f7"};
--colors-blue-1: ${(p)=>p.darkMode?"#353a64":"#dbddf7"};
--neutral-light-n800: ${(p)=>p.darkMode?"#":"#172b4d"};
--colors-grey-stroke: ${(p)=>p.darkMode?"#2d2b3f":"#e1e6ef"};
--colors-purple-light: ${(p)=>p.darkMode?"#8f8fb8":"#9b9bc1"};
--colors-blue2: ${(p)=>p.darkMode?"#4393f4":"#4393f4"};
--colors-purple-dark: ${(p)=>p.darkMode?"#9191ca":"#5d5d9c"};
--colors-green-light: ${(p)=>p.darkMode?"#26493b":"#c7e7d5"};
--colors-red: ${(p)=>p.darkMode?"#e83f3f":"#e83f3f"};
--colors-input-stroke: ${(p)=>p.darkMode?"#5f5b7a":"#e1e6ef"};
--colors-green: ${(p)=>p.darkMode?"#30c25c":"#30c25c"};
--colors-pink: ${(p)=>p.darkMode?"#C773D4":"#C773D4"};
--colors-red-1: ${(p)=>p.darkMode?"#4a2029":"#FCE8E8"};
--colors-cyan: ${(p)=>p.darkMode?"#61dafb":"#61dafb"};
--colors-another-dark: ${(p)=>p.darkMode?"#282c34":"#282c34"};

--filters-white: brightness(0) saturate(100%) ${(p)=>p.darkMode?"invert(9%) sepia(5%) saturate(3995%) hue-rotate(208deg) brightness(98%) contrast(89%)":"invert(94%) sepia(100%) saturate(0%) hue-rotate(17deg) brightness(106%) contrast(108%)"};
--filters-btn-txt: brightness(0) saturate(100%) ${(p)=>p.darkMode?"invert(94%) sepia(100%) saturate(0%) hue-rotate(17deg) brightness(106%) contrast(108%)":"invert(94%) sepia(100%) saturate(0%) hue-rotate(17deg) brightness(106%) contrast(108%)"};
--filters-darkerblue: brightness(0) saturate(100%) ${(p)=>p.darkMode?"invert(48%) sepia(97%) saturate(2337%) hue-rotate(211deg) brightness(103%) contrast(102%)":"invert(23%) sepia(41%) saturate(5020%) hue-rotate(232deg) brightness(107%) contrast(88%)"};
--filters-red: brightness(0) saturate(100%) ${(p)=>p.darkMode?"invert(25%) sepia(85%) saturate(3470%) hue-rotate(347deg) brightness(124%) contrast(82%)":"invert(25%) sepia(85%) saturate(3470%) hue-rotate(347deg) brightness(124%) contrast(82%)"};
--filters-dark-1: brightness(0) saturate(100%) ${(p)=>p.darkMode?"invert(94%) sepia(100%) saturate(0%) hue-rotate(17deg) brightness(106%) contrast(108%)":"invert(17%) sepia(31%) saturate(692%) hue-rotate(209deg) brightness(95%) contrast(88%)"};
--filters-purple-light: brightness(0) saturate(100%) ${(p)=>p.darkMode?"invert(70%) sepia(7%) saturate(1300%) hue-rotate(202deg) brightness(82%) contrast(88%)":"invert(69%) sepia(25%) saturate(349%) hue-rotate(202deg) brightness(88%) contrast(83%)"};
--filters-purple-dark: brightness(0) saturate(100%) ${(p)=>p.darkMode?"invert(70%) sepia(12%) saturate(1142%) hue-rotate(202deg) brightness(82%) contrast(90%)":"invert(40%) sepia(9%) saturate(2295%) hue-rotate(202deg) brightness(93%) contrast(96%)"};
--filters-green: brightness(0) saturate(100%) ${(p)=>p.darkMode?"invert(60%) sepia(16%) saturate(1906%) hue-rotate(85deg) brightness(102%) contrast(85%)":"invert(60%) sepia(16%) saturate(1906%) hue-rotate(85deg) brightness(102%) contrast(85%)"};

/* font sizes */
--body-main-size: 1.82291667vh;
--body-message-size: 2.60416667vh;
--caption-cap-12-size: 1.5625vh;
--buttons-btn-input-size: 2.08333333vh;
--font-size-5xl: 3.125vh;

/* Gaps */
--gap-11xs: 0.13888888888vw 0.26041666666vh;
--gap-9xs: 0.27777777777vw 0.52083333333vh;
--gap-5xs: 0.55555555555vw 1.04166666667vh;
--gap-4xs: 0.625vw 1.171875vh;
--gap-3xs: 0.69444444444vw 1.30208333333vh;
--gap-xs: 0.83333333333vw 1.5625vh;
--gap-base: 1.11111111111vw 2.08333333333vh;
--gap-xl: 1.38888888889vw 2.60416666667vh;
--gap-5xl: 1.66666666667vw 3.125vh;
--gap-13xl: 2.22222222222vw 4.16666666667vh;
--gap-21xl: 2.77777777778vw 5.20833333333vh;

/* Paddings */
--padding-11xsW: 0.13888888888vw;
--padding-9xsW: 0.27777777777vw;
--padding-7xsW: 0.41666666666vw;
--padding-6xsW: 0.48611111111vw;
--padding-5xsW: 0.55555555555vw;
--padding-3xsW: 0.69444444444vw;
--padding-xsW: 0.83333333333vw;
--padding-smW: 0.97222222222vw;
--padding-miniW: 1.04166666667vw;
--padding-baseW: 1.11111111111vw;
--padding-xlW: 1.38888888889vw;
--padding-5xlW: 1.66666666667vw;
--padding-8xlW: 1.875vw;
--padding-9xlW: 1.94444444444vw;
--padding-13xlW: 2.22222222222vw;
--padding-21xlW: 2.77777777778vw;
--padding-42xlW: 4.23611111111vw;
--padding-77xlW: 6.66666666667vw;
--padding-99xlW: 8.19444444444vw;
--padding-217xlW: 16.3888888889vw;

--padding-11xsH: 0.26041666666vh;
--padding-9xsH: 0.52083333333vh;
--padding-7xsH: 0.78125vh;
--padding-6xsH: 0.91145833333vh;
--padding-5xsH: 1.04166666667vh;
--padding-3xsH: 1.30208333333vh;
--padding-xsH: 1.5625vh;
--padding-smH: 1.82291666667vh;
--padding-miniH: 1.953125vh;
--padding-baseH: 2.08333333333vh;
--padding-xlH: 2.60416666667vh;
--padding-5xlH: 3.125vh;
--padding-8xlH: 3.515625vh;
--padding-9xlH: 3.64583333333vh;
--padding-13xlH: 4.16666666667vh;
--padding-21xlH: 5.20833333333vh;
--padding-42xlH: 7.94270833333vh;
--padding-77xlH: 12.5vh;
--padding-99xlH: 15.3645833333vh;
--padding-217xlH: 30.7291666667vh;

/* Spacing */
--corner-radius-l: 12px;
--corner-radius-s: 4px;
--corner-radius-round: 99px;
--corner-radius-m: 8px;
--corner-radius-xl: 16px;

/* Border radiuses */
--br-xs: 12px;
--br-9xs: 4px;
--br-80xl: 99px;
--br-5xs: 8px;
--br-base: 16px;
--br-xl: 20px;
--br-81xl: 100px;

}
`;
