import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type StateDefault3Type = {
  navigationMainIcon?: string;
  study?: string;

  /** Style props */
  stateDefaultCursor?: CSSProperties["cursor"];
  stateDefaultBorderW?: CSSProperties["borderWidth"];
  stateDefaultBorderS?: CSSProperties["borderStyle"];
  stateDefaultBorderC?: CSSProperties["borderColor"];
  stateDefaultBackgroundColor?: CSSProperties["backgroundColor"];
  stateDefaultZIndex?: CSSProperties["zIndex"];
  stateDefaultBoxSizing?: CSSProperties["boxSizing"];
  studyDisplay?: CSSProperties["display"];
  HasClass?: string;
  IconBackground?: CSSProperties["backgroundColor"];

  /** Action props */
  onSideNavigationBClick1?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Navigationmainicon = styled.div<{ BG?: Property.Background }>`
  position: relative;
  border-radius: var(--corner-radius-l);
  --side: min(2.2222222222222223vw,4.166666666666666vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  background-color: var(--colors-btn-txt);
  background-color: ${(p) => p.BG};
`;
const Image = styled.img`
  --side: min(1.3888888888888888vw,2.604166666666667vh);
  width: var(--side);
  height: var(--side);
  padding-top: 0.78125vh;
  padding-left: calc((min(2.2222222222222223vw,4.166666666666666vh) - var(--side)) / 2);
  filter: var(--filters-btn-txt);
`;
const Study = styled.div<{ studyDisplay?: Property.Display }>`
  position: relative;
  line-height: 3.125vh;
  opacity: 0.8;
  display: ${(p) => p.studyDisplay};
`;
const StatedefaultRoot = styled.div<{
  stateDefaultCursor?: Property.Cursor;
  stateDefaultBorderW?: Property.BorderWidth;
  stateDefaultBorderC?: Property.BorderColor;
  stateDefaultBorderS?: Property.BorderStyle;
  stateDefaultBackgroundColor?: Property.BackgroundColor;
  stateDefaultZIndex?: Property.ZIndex;
  stateDefaultBoxSizing?: Property.BoxSizing;
}>`border-radius: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xlH) var(--padding-xlW) var(--padding-xlH) var(--padding-5xlW);
  box-sizing: border-box;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--buttons-btn-input-size);
  color: var(--colors-btn-txt);
  font-family: var(--buttons-btn-input);
  align-self: stretch;
  cursor: ${(p) => p.stateDefaultCursor};
  border-width: ${(p) => p.stateDefaultBorderW};
  border-color: ${(p) => p.stateDefaultBorderC};
  border-style: ${(p) => p.stateDefaultBorderS};
  background-color: ${(p) => p.stateDefaultBackgroundColor};
  z-index: ${(p) => p.stateDefaultZIndex};
  box-sizing: ${(p) => p.stateDefaultBoxSizing};
  &.selected {
    background-color: var(--colors-dark-2);
  }
  &:not(.selected):hover {
    background-color: var(--colors-purple-dark);
  }
`;

const StateDefault3: FunctionComponent<StateDefault3Type> = ({
  navigationMainIcon,
  study,
  stateDefaultCursor,
  stateDefaultBorderW,
  stateDefaultBorderC,
  stateDefaultBorderS,
  stateDefaultBackgroundColor,
  stateDefaultZIndex,
  stateDefaultBoxSizing,
  studyDisplay,
  HasClass,
  IconBackground,
  onSideNavigationBClick1,
}) => {
  return (
    <StatedefaultRoot
      stateDefaultCursor={stateDefaultCursor}
      stateDefaultBorderW={stateDefaultBorderW}
      stateDefaultBorderC={stateDefaultBorderC}
      stateDefaultBorderS={stateDefaultBorderS}
      stateDefaultBackgroundColor={stateDefaultBackgroundColor}
      stateDefaultZIndex={stateDefaultZIndex}
      stateDefaultBoxSizing={stateDefaultBoxSizing}
      className={HasClass}
      onClick={onSideNavigationBClick1}
    >
      <Navigationmainicon BG={IconBackground}><Image alt="" src={navigationMainIcon} /></Navigationmainicon> 
      <Study studyDisplay={studyDisplay}>{study}</Study>
    </StatedefaultRoot>
  );
};

export default StateDefault3;
