import { FunctionComponent, type CSSProperties, useState } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import StateHoverIconno from "./StateHoverIconno";
import Property1Default5 from "./Property1Default5";
import Property1v2 from "./Property1v2";
import Download from "./Download";
import SocialsArea from "./SocialsArea";
import ActionsContainer from "./ActionsContainer";
import ActionsContainer1 from "./ActionsContainer1";
import styles from "./Actions.module.css";

type BalanceContainerType = {
  iconCode?: string;
  darkMode: boolean;

  /** Style props */
  propColor?: CSSProperties["color"];
  propBorderTop?: CSSProperties["borderTop"];
  propColor1?: CSSProperties["color"];
  propColor15?: CSSProperties["color"];
};

const Balance = styled.h3<{ propColor?: Property.Color }>`
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: inherit;
  color: ${(p) => p.propColor};
`;
const BalanceWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.0vh 0.0vw 0.0vh var(--padding-5xsW);
`;
const FrameChild = styled.div<{ propBorderTop?: Property.BorderTop }>`
  align-self: stretch;
  position: relative;
  border-top: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  height: 0.13020833333333331vh;
  border-top: ${(p) => p.propBorderTop};
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const PurchaseStarlikeCredits = styled.div<{ propColor1?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  color: ${(p) => p.propColor1};
`;
const CreateNewTab = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--buttons-btn-input-size);
`;
const Plans = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const SubscriptionBenefits = styled.h3<{ propColor15?: Property.Color }>`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: inherit;
  color: ${(p) => p.propColor15};
`;
const Div = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const Benefits = styled.div`
  align-self: stretch;
  border-radius: var(--br-base);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const Container = styled.div<{isHidden: boolean}>`
  border-radius: var(--br-xs);
  width: 80.27777777777779vw;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  display: ${(p)=>(p.isHidden?"none":"flex")}
`;
const Page5BalanceRoot = styled.section`
  height: 100.0vh;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) var(--padding-5xlW);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-white);
  font-family: var(--buttons-btn-input);
`;

const BalanceContainer: FunctionComponent<BalanceContainerType> = ({
  iconCode,
  propColor,
  propBorderTop,
  propColor1,
  propColor15,
  darkMode,
}) => {
  const [isHidden1, setIsHidden1] = useState(false)
  const [isHidden2, setIsHidden2] = useState(true)
  const [isHiddenInner1, setIsHiddenInner1] = useState(false)
  const [isHiddenInner2, setIsHiddenInner2] = useState(true)
  const HideBalane = () => {
    setIsHidden1(true)
    setIsHidden2(false)
  }
  const ShowBalane = () => {
    setIsHidden1(false)
    setIsHidden2(true)
  }
  const HideWithdraw = () => {
    setIsHiddenInner1(false)
    setIsHiddenInner2(true)
  }
  const ShowWithdraw = () => {
    setIsHiddenInner1(true)
    setIsHiddenInner2(false)
  }

  return (
    <Page5BalanceRoot id="BalanceSection">
      <Container isHidden={isHidden1}>
        <FrameParent>
          <BalanceWrapper>
            <Balance propColor={propColor}>Balance</Balance>
          </BalanceWrapper>
          <FrameChild propBorderTop={propBorderTop} />
        </FrameParent>
        <CreateNewTab>
          <PurchaseStarlikeCredits propColor1={propColor1}>
            Purchase StarLike Credits
          </PurchaseStarlikeCredits>
          <StateHoverIconno
            onButtonSecondaryClick={HideBalane}
            saveSession="Top Up Now"
            stateHoverIconnoJustifyContent="flex-start"
            stateHoverIconnoWidth="unset"
            stateHoverIconnoZIndex="unset"
            stateHoverIconnoBackgroundColor="transparent"
            stateHoverIconnoCursor="pointer"
            stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
            stateHoverIconnoBorderS="solid"
            stateHoverIconnoBorderC="var(--colors-darkerblue)"
            stateHoverIconnoHeight="4.166666666666666vh"
            stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
            stateHoverIconnoBoxSizing="border-box"
            stateHoverIconnoPosition="unset"
            stateHoverIconnoTop="unset"
            stateHoverIconnoLeft="unset"
            saveSessionColor="var(--colors-darkerblue)"
            saveSessionDisplay="inline-block"
          />
        </CreateNewTab>
        <Plans>
          <Property1Default5
            weeklyPlan="Weekly"
            prop="$17.99"
            property1DefaultFlexShrink="0"
            property1DefaultBackgroundColor="var(--colors-white)"
            property1DefaultBorderW="0.130208333vh 0.0694444444vw"
            property1DefaultBorderC="var(--colors-grey-stroke)"
            property1DefaultBorderS="solid "
            weeklyPlanMargin="0"
            weeklyPlanColor="var(--colors-dark-1)"
            divColor="var(--colors-darkerblue)"
            allIncludedColor="var(--colors-dark-1)"
            buttonSecondaryCursor="pointer"
            buttonSecondaryBackgroundColor="transparent"
            saveSessionColor="var(--colors-darkerblue)"
            saveSessionDisplay="inline-block"
          />
          <Property1Default5
            weeklyPlan="Monthly"
            prop="$42.29"
            property1DefaultFlexShrink="0"
            property1DefaultBackgroundColor="var(--colors-white)"
            property1DefaultBorderW="0.130208333vh 0.0694444444vw"
            property1DefaultBorderC="var(--colors-grey-stroke)"
            property1DefaultBorderS="solid"
            weeklyPlanMargin="0"
            weeklyPlanColor="var(--colors-dark-1)"
            divColor="var(--colors-darkerblue)"
            allIncludedColor="var(--colors-dark-1)"
            buttonSecondaryCursor="pointer"
            buttonSecondaryBackgroundColor="transparent"
            saveSessionColor="var(--colors-darkerblue)"
            saveSessionDisplay="inline-block"
          />
          <Property1Default5
            weeklyPlan="Annually"
            prop="$497.29"
            property1DefaultFlexShrink="0"
            property1DefaultBackgroundColor="var(--colors-white)"
            property1DefaultBorderW="0.130208333vh 0.0694444444vw"
            property1DefaultBorderC="var(--colors-grey-stroke)"
            property1DefaultBorderS="solid"
            weeklyPlanMargin="0"
            weeklyPlanColor="var(--colors-dark-1)"
            divColor="var(--colors-darkerblue)"
            allIncludedColor="var(--colors-dark-1)"
            buttonSecondaryCursor="pointer"
            buttonSecondaryBackgroundColor="transparent"
            saveSessionColor="var(--colors-darkerblue)"
            saveSessionDisplay="inline-block"
          />
        </Plans>
        <Benefits>
          <SubscriptionBenefits propColor15={propColor15}>
            Subscription Benefits
          </SubscriptionBenefits>
          <Plans>
            <Div>
              <Property1v2
                voiceDialogueAnytime="Voice dialogue anytime"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Unlimited word quizzes"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Test questions practice"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Flashcards"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Repetition memory techniques"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Notifications"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="E-mail alerts"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
            </Div>
            <Div>
              <Property1v2
                voiceDialogueAnytime="Specialisation wordlists"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Clickable words"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Access tutors"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Load latest news"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Access video pronounciation library"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Dependant progress"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
              <Property1v2
                voiceDialogueAnytime="Bonus set for dependants"
                smallCheck={iconCode}
                property1v2BackgroundColor="var(--colors-white)"
                voiceDialogueAnytimeColor="var(--colors-dark-1)"
              />
            </Div>
          </Plans>
        </Benefits>
      </Container>
      <div style={{display:(isHidden2?"none":"flex"), flexDirection:"row"}}>
        <div className={styles.sideNavigationB}>
          {false && <SocialsArea />}
        </div>
        <div className={styles.filterButtonComps} style={{display:(isHiddenInner1?"none":"flex")}}>
          <ActionsContainer onClickF={ShowBalane} onClickW={ShowWithdraw} darkMode={darkMode===true} />
        </div>
        {false && <div className={styles.filterButtonComps} style={{display:(isHiddenInner2?"none":"flex"), padding:"28.385416666666668vh 0 0"}}>
          <ActionsContainer1 Back={HideWithdraw} darkMode={darkMode===true} />
        </div>}
        <div className={styles.googleplayframe} style={{paddingLeft:"17.0833333vw"}}>
          {false && <Download />}
        </div>
      </div>
    </Page5BalanceRoot>
  );
};

export default BalanceContainer;
