import { FunctionComponent, useState } from "react";
import styles from "./POP2.module.css";
import Property1Default11 from "./Property1Default11";
import { isBooleanObject } from "util/types";
import { useTranslation } from "react-i18next";

const POP2: FunctionComponent<{
  onHide: () => void;
  selection: string;
  MeaningB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  GrammarB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  VideoB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  AudioB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  FlashcardsB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  hv: boolean;
}> = ({ onHide, selection, MeaningB, GrammarB, VideoB, FlashcardsB,AudioB,hv }) => {
  const [hover1, setHover1] = useState(false)
  const [hover2, setHover2] = useState(false)
  const [hover3, setHover3] = useState(false)
  const [hover4, setHover4] = useState(false)
  const { t } = useTranslation();
  return (
    <div className={styles.frameRoot}>
      <section className={styles.tooltip}>
        <div className={styles.frameMediaPlayer}>
          <Property1Default11
            largeVolumeEnable="/Audio.svg"
            property1DefaultCursor="pointer"
            property1DefaultBorderW="none"
            property1DefaultBorderS="none"
            property1DefaultBorderC="none"
            property1DefaultPadding="0"
            property1DefaultBackgroundColor="var(--colors-blue-1)"
            onPlayAudioClick={(event)=>{AudioB(event,selection);onHide()}}
          />
        </div>
        <div className={styles.endSessionButtons}>
          <div
            className={styles.buttontretiary}
            onMouseEnter={()=>(setHover1(true))}
            onMouseLeave={()=>(setHover1(false))}
            onClick={(event)=>{MeaningB(event,selection);onHide()}}
          >
            <div className={styles.endSession} style={{color:(hover1?"var(--colors-darkerblue)":"var(--colors-dark-1)")}}>{t("Meaning")}</div>
          </div>
          <div
            className={styles.buttontretiary}
            onMouseEnter={()=>(setHover2(true))}
            onMouseLeave={()=>(setHover2(false))}
            onClick={(event)=>{GrammarB(event,selection);onHide()}}
          >
            <div
            className={styles.endSession} style={{color:(hover2?"var(--colors-darkerblue)":"var(--colors-dark-1)")}}>{t("Grammar")}</div>
          </div>
          {(hv===false) && <div
            className={styles.buttontretiary}
            onMouseEnter={()=>(setHover3(true))}
            onMouseLeave={()=>(setHover3(false))}
            onClick={(event)=>{VideoB(event,selection);onHide()}}
          >
            <div
            className={styles.endSession} style={{color:(hover3?"var(--colors-darkerblue)":"var(--colors-dark-1)")}}>{t("Video")}</div>
          </div>}
          <div
            className={styles.buttontretiary}
            onMouseEnter={()=>(setHover4(true))}
            onMouseLeave={()=>(setHover4(false))}
            onClick={(event)=>{FlashcardsB(event,selection);onHide()}}
          >
            <div
            className={styles.endSession} style={{color:(hover4?"var(--colors-darkerblue)":"var(--colors-dark-1)")}}>{t("Flashcard")}</div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default POP2;
