import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import Property1Default from "./Property1Default";
import StateHoverIconno from "./StateHoverIconno";

type ActT = {
  text: string;
  call: string;
}

type Property13ButtonsResolvedType = {
  teacherAlexandroProposesT?: string;
  date: string;
  weekday: string;
  timestart: string;
  timeend: string;
  tags: string[];
  Actions: ActT[];

  /** Style props */
  property13ButtonsResolvedBackgroundColor?: CSSProperties["backgroundColor"];
  monday20NovemberColor?: CSSProperties["color"];
  chipBackgroundColor?: CSSProperties["backgroundColor"];
  allColor?: CSSProperties["color"];
  chipBackgroundColor1?: CSSProperties["backgroundColor"];
  allColor1?: CSSProperties["color"];
  divColor?: CSSProperties["color"];
  teacherAlexandroProposesColor?: CSSProperties["color"];
  buttonSecondaryCursor?: CSSProperties["cursor"];
  buttonSecondaryBackgroundColor?: CSSProperties["backgroundColor"];
  saveSessionColor?: CSSProperties["color"];
  saveSessionDisplay?: CSSProperties["display"];
  buttonSecondaryCursor1?: CSSProperties["cursor"];
  buttonSecondaryBackgroundColor1?: CSSProperties["backgroundColor"];
  saveSessionColor1?: CSSProperties["color"];
  saveSessionDisplay1?: CSSProperties["display"];
  buttonSecondaryCursor2?: CSSProperties["cursor"];
  buttonSecondaryBackgroundColor2?: CSSProperties["backgroundColor"];
  saveSessionDisplay2?: CSSProperties["display"];

  /** Action props */
  onButtonSecondary13Click1?: () => void;
  onButtonSecondary14Click1?: () => void;
  onButtonSecondary15Click1?: () => void;
};

const Monday20November = styled.div<{ monday20NovemberColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10.0vw;
  height: 2.604166666666667vh;
  flex-shrink: 0;
  color: ${(p) => p.monday20NovemberColor};
`;
const ChipParent = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Monday20NovemberParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const Div = styled.div<{ divColor?: Property.Color }>`
  align-self: stretch;
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 2.604166666666667vh;
  flex-shrink: 0;
  color: ${(p) => p.divColor};
`;
const Wrapper = styled.div`
  align-self: stretch;
  width: 10.0vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const TeacherAlexandroProposes = styled.div<{
  teacherAlexandroProposesColor?: Property.Color;
}>`
  align-self: stretch;
  position: relative;
  line-height: 2.604166666666667vh;
  white-space: pre-wrap;
  color: ${(p) => p.teacherAlexandroProposesColor};
`;
const TeacherAlexandroProposesToWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.0vh var(--padding-5xlW);
`;
const FrameGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const ButtonsecondaryParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-xs);
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--buttons-btn-input-size);
`;
const Property13buttonsResolvedRoot = styled.div<{
  property13ButtonsResolvedBackgroundColor?: Property.BackgroundColor;
}>`
  border-radius: var(--corner-radius-l);
  background-color: var(--colors-blue-bg);
  width: 63.888888888888886vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-baseH) var(--padding-baseW);
  box-sizing: border-box;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  background-color: ${(p) => p.property13ButtonsResolvedBackgroundColor};
`;

const Property13ButtonsResolved: FunctionComponent<
  Property13ButtonsResolvedType
> = ({
  teacherAlexandroProposesT,
  property13ButtonsResolvedBackgroundColor,
  monday20NovemberColor,
  divColor,
  teacherAlexandroProposesColor,
  date,
  weekday,
  timestart,
  timeend,
  tags,
  Actions,
}) => {
  return (
    <Property13buttonsResolvedRoot
      property13ButtonsResolvedBackgroundColor={
        property13ButtonsResolvedBackgroundColor
      }
    >
      <Monday20NovemberParent>
        <Monday20November monday20NovemberColor={monday20NovemberColor}>
          {weekday}, {date}
        </Monday20November>
        <ChipParent>
          {
            tags.map((tag, index)=>(
              <Property1Default
                text={tag}
                property1DefaultFlexShrink="0"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultBoxSizing="border-box"
                property1DefaultCursor="unset"
                property1DefaultBorderW="unset"
                property1DefaultBorderC="unset"
                property1DefaultBorderS="unset"
                property1DefaultBackgroundColor="var(--colors-blue-1)"
                allDisplay="inline-block"
                allAlignItems="unset"
                allJustifyContent="unset"
                allWidth="max-content"
                allHeight="1.5625"
                allFlexShrink="unset"
                allColor="var(--colors-dark-1)"
              />
            ))
          }
        </ChipParent>
      </Monday20NovemberParent>
      <FrameParent>
        <FrameGroup>
          <Wrapper>
            <Div divColor={divColor}>{timestart} - {timeend}</Div>
          </Wrapper>
          <TeacherAlexandroProposesToWrapper>
            <TeacherAlexandroProposes
              teacherAlexandroProposesColor={teacherAlexandroProposesColor}
            >
              {teacherAlexandroProposesT}
            </TeacherAlexandroProposes>
          </TeacherAlexandroProposesToWrapper>
        </FrameGroup>
        <ButtonsecondaryParent>
          {
            Actions.map((Action, index)=> {
              return (
                index < Actions.length-1?
                <StateHoverIconno
                  key={"Act"+String(index)}
                  saveSession={Action.text}
                  stateHoverIconnoJustifyContent="flex-start"
                  stateHoverIconnoWidth="unset"
                  stateHoverIconnoZIndex="unset"
                  stateHoverIconnoBackgroundColor="unset"
                  stateHoverIconnoCursor="pointer"
                  stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                  stateHoverIconnoBorderC="var(--colors-darkerblue)"
                  stateHoverIconnoBorderS="solid"
                  stateHoverIconnoHeight="4.166666666666666vh"
                  stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                  stateHoverIconnoBoxSizing="border-box"
                  stateHoverIconnoPosition="unset"
                  stateHoverIconnoTop="unset"
                  stateHoverIconnoLeft="unset"
                  saveSessionColor="var(--colors-darkerblue)"
                  saveSessionDisplay="inline-block"
                  onButtonSecondaryClick={()=>{alert(Action.call)}}
                />
                :
                <StateHoverIconno
                  key={"Act"+String(index)}
                  saveSession={Action.text}
                  stateHoverIconnoJustifyContent="flex-start"
                  stateHoverIconnoWidth="unset"
                  stateHoverIconnoZIndex="unset"
                  stateHoverIconnoBackgroundColor="var(--colors-darkerblue)"
                  stateHoverIconnoCursor="pointer"
                  stateHoverIconnoBorderW="unset"
                  stateHoverIconnoBorderC="unset"
                  stateHoverIconnoBorderS="unset"
                  stateHoverIconnoHeight="4.166666666666666vh"
                  stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                  stateHoverIconnoBoxSizing="border-box"
                  stateHoverIconnoPosition="unset"
                  stateHoverIconnoTop="unset"
                  stateHoverIconnoLeft="unset"
                  saveSessionColor="var(--colors-btn-txt)"
                  saveSessionDisplay="inline-block"
                  onButtonSecondaryClick={()=>{alert(Action.call)}}
                />
              )
            })
          }
        </ButtonsecondaryParent>
      </FrameParent>
    </Property13buttonsResolvedRoot>
  );
};

export default Property13ButtonsResolved;
