import { FunctionComponent, useState, useCallback } from "react";
import StudyContainer1NoLog from "../components/StudyContainer1NoLog";
import styled from "styled-components";

const Navigationmainb = styled.nav`
  margin: 0;
  background-color: var(--colors-dark-sidepanel);
  width: 16.38888888888889vw;
  height: 100.0vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) 0.0vw;
  box-sizing: border-box;
`;
const Page1Study = styled.section`
  align-self: stretch;
  display: inline;
  color: var(--colors-dark-1);
  font-size: var(--font-size-5xl);
  width: 50%;
  position: relative;
  left: 25%;
  top: 25vh;
  font-family: var(--body-message);
  text-align: justify;
`;
const BlocksOfPages = styled.main`
  width: 83.61111111111111vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const LayoutBlocksLightRoot = styled.div`
  position: relative;
  background-color: var(--colors-grey-bg);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  & ::selection {
    background: var(--colors-green);
  }
`;
const Close =styled.div`
  --side: min(2.2222222222222223vw,4.166666666666666vh);
  width: var(--side);
  height: var(--side);
  border-radius: var(--br-81xl);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-darkerblue);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xsH) var(--padding-8xsW);
  cursor: pointer;
`
const MediumcloseIcon =styled.img`
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  --side: min(1.3888888888888888vw,2.604166666666667vh);
  width: var(--side);
  height: var(--side);
`

type LBL = {
  darkMode: boolean;
  switchDarkMode: ()=>void;
}

const LayoutBlocksLightNoLogin: FunctionComponent<LBL> = (InputData) => {
  const onSideNavigationBClick1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      showElement("StudySection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );

  const [LogInOpen, setLogInOpen] = useState(false);
  const OpenLogIn = () => {
    setLogInOpen(true)
  }
  const CloseLogIn = () => {
    setLogInOpen(false)
  }
  const SignIn = () => {
    var ch1 = (document.getElementById("TC") as HTMLInputElement).checked
    var ch2 = (document.getElementById("PP") as HTMLInputElement).checked
    var log = (document.getElementById("username") as HTMLInputElement).value
    var pwd = (document.getElementById("password") as HTMLInputElement).value
    const offsetMinutes = new Date().getTimezoneOffset();
    const sign = offsetMinutes < 0 ? '+' : '-';
    const hours = Math.abs(Math.floor(offsetMinutes / 60)).toString().padStart(2, '0');
    const minutes = Math.abs(offsetMinutes % 60).toString().padStart(2, '0');
    const timezone = `UTC${sign}${hours}:${minutes}`;

    const validateEmail = (email: string): boolean => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const verifyEmail = async (email: string) => {
      try {
        fetch('/verifyEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 'email': email })
        }).then((response)=>{
        if (response.ok) {
          alert('Verification email sent. Please check your email to verify.');
        } else {
          alert('Error sending verification email. Please try again.');
        }})
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while sending the verification email.');
      }
    };

    if (!validateEmail(log)) {
      alert('Please enter a valid email address.');
      return;
    }

    if (ch1 && ch2) {
      fetch('/regLog',
        {
          method:'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'login':log,
            'password':pwd,
            'ch1':ch1,
            'ch2':ch2,
            'tz':timezone
          })
        }
      ).then(
        async response => {
          const result = await response.json();
          if (response.ok) {
            if (result.verification_needed) {
              await verifyEmail(log);
            } else {
              window.location.reload();
            }
          } else {
            alert(result.message || 'Login failed.');
          }
        }
      ).catch(
        error => alert('An error occurred: ' + error.message)
      )
    } else {
      alert("Please confirm you have read and accepted the Terms & Conditions and Privacy Policy of the Platform.")
    }
  }
  function AsGuest() {
    let ch1 = (document.getElementById("TC") as HTMLInputElement)!.checked;
    let ch2 = (document.getElementById("PP") as HTMLInputElement)!.checked;
    const offsetMinutes = new Date().getTimezoneOffset();
    const sign = offsetMinutes < 0 ? '+' : '-';
    const hours = Math.abs(Math.floor(offsetMinutes / 60)).toString().padStart(2, '0');
    const minutes = Math.abs(offsetMinutes % 60).toString().padStart(2, '0');
    const timezone = `UTC${sign}${hours}:${minutes}`;
    if (ch1 && ch2) {
      fetch('/regAsGuest', {
        method:'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"tz":timezone})
      })
      .then(response => response.json())
      .then(
        data => {alert(`Your Credentials are:\nlogin: ${data.login}\npassword: ${data.pswd}\nSave them now. You will not be able to see this message again.`);window.location.reload();}
      )
    } else {
      alert("Please confirm you have read and accepted the Terms & Conditions and Privacy Policy of the Platform.")
    }
  }
  return (
    <LayoutBlocksLightRoot>
      
      {
        LogInOpen && <>
          <Close onClick={CloseLogIn} style={{position: "absolute", top: "20vh", left: "70vw", display: "flex", zIndex:99999999}}>
            <MediumcloseIcon
              loading="eager"
              alt=""
              src="/mediumclose1.svg"
            />
          </Close>
          <div style={{padding: "0.5%",backgroundColor: "var(--colors-grey-bg)", position: "absolute", top: "25vh", left: "40vw", display: "flex", flexDirection: "column",justifyContent:"center", width: "600px", height: "300px", borderRadius:"var(--corner-radius-l)", zIndex:99999999}}>
            <div style={{display: "flex", flexDirection: "row"}}>
              <div style={{width:"100%"}}>
                <h1 style={{fontSize:"30px",fontWeight:"300", color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)", width:"100%", justifyContent:"center", display:"flex"}}>Sign in/Register</h1>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row",justifyContent: "center",gap: "1vw"}}>
              <div>
                <div>
                  <div>		          	
                    <label htmlFor="username" style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>Username</label>
                    <div>
                      <i className="fa fa-user"></i>
                      <input type="text" id="username" placeholder="" style={{fontFamily:"var(--buttons-btn-input)"}}></input>
                    </div>		            		            		            
                  </div>              
                </div>
                <div>
                  <div>
                    <label htmlFor="password" style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>Password</label>
                    <div>
                      <i className="fa fa-lock"></i>
                      <input type="password" id="password" placeholder="" style={{fontFamily:"var(--buttons-btn-input)"}}></input>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <a className="btn btn-block btn-social btn-google-plus" href="/loginGoogle" style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)",borderRadius: "var(--corner-radius-l)", width: "80%"}}>
                  <i className="fa fa-google-plus"></i> Sign in with Google
                </a>
                {false && <a className="btn btn-block btn-social btn-facebook" href="/loginFacebook" style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)",borderRadius: "var(--corner-radius-l)", width: "80%"}}>
                  <i className="fa fa-facebook"></i> Sign in with Facebook
                </a>}
                {false && <div className="btn btn-block btn-social btn-guest" onClick={AsGuest} style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)",borderRadius: "var(--corner-radius-l)", width: "80%"}}>
                  <i className="fa fa-guest"></i> Sign in as Guest
                </div>}
                <div style={{marginTop:"10px", color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>
                  By accessing this Platform I accept:
                </div>
                <div style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>
                  <input type="checkbox" id="TC" style={{opacity: "0.6"}}></input>
                  <a href="/TC" style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>
                    Terms &  Conditions
                  </a> of this Platform
                </div>
                <div style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>
                  <input type="checkbox" id="PP" style={{opacity: "0.6"}}></input>
                  <a href="/PP" style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>
                    Privacy Policy
                  </a> of this Platform
                </div>
              </div>
            </div>
            <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
              <div>
                <input type="button" onClick={SignIn} value="SIGN IN | REGISTER" style={{fontFamily:"var(--buttons-btn-input)", marginTop: "3vh"}}></input>
              </div>
            </div>
          </div>
        </>
      }
      {LogInOpen &&
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--color-black-350)',
            backdropFilter: 'blur(2px)',
            zIndex: 99999998,
          }} 
        />
      }
      <Navigationmainb>
        <StudyContainer1NoLog
          switchDarkMode={InputData.switchDarkMode}
          darkMode={InputData.darkMode}
          onSideNavigationBClick1={onSideNavigationBClick1}
          OpenLogIn={OpenLogIn}
        />
      </Navigationmainb>
      <BlocksOfPages>
        <Page1Study id="StudySection">
        Welcome to the new experience in language learning where a Student can dive into language learning by accessing full capabilities of both AI and Human Tutors. The flexibility of learning through a live conversation and at your own pace is much appreciated by the students. At the heart of our project is a student’s motivation, therefore we try to keep lessons focused and concise, with the instant possibility of testing and fast progress. If you are a parent or guardian, you can set the goals, motivate a student, and get progress updates. To proceed please <div style={{display:"inline",whiteSpace:"nowrap"}}><button type="button" onClick={OpenLogIn} style={{backgroundColor: "transparent",border: "none",cursor: "pointer",textDecoration: "underline",display: "inline",margin: "0",padding: "0",color: "var(--colors-dark-1)",fontSize: "var(--font-size-5xl)", fontFamily: "var(--body-message)"}}>Log in or Sign Up</button></div>.
        </Page1Study>
      </BlocksOfPages>
    </LayoutBlocksLightRoot>
  );
};

export default LayoutBlocksLightNoLogin;
