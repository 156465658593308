import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import StateHoverIconno from "./StateHoverIconno";

type Property1Default5Type = {
  weeklyPlan?: string;
  prop?: string;

  /** Style props */
  property1DefaultFlexShrink?: CSSProperties["flexShrink"];
  property1DefaultBackgroundColor?: CSSProperties["backgroundColor"];
  property1DefaultBorderW?: CSSProperties["borderWidth"];
  property1DefaultBorderS?: CSSProperties["borderStyle"];
  property1DefaultBorderC?: CSSProperties["borderColor"];
  weeklyPlanMargin?: CSSProperties["margin"];
  weeklyPlanColor?: CSSProperties["color"];
  divColor?: CSSProperties["color"];
  allIncludedColor?: CSSProperties["color"];
  buttonSecondaryCursor?: CSSProperties["cursor"];
  buttonSecondaryBackgroundColor?: CSSProperties["backgroundColor"];
  saveSessionColor?: CSSProperties["color"];
  saveSessionDisplay?: CSSProperties["display"];

  /** Action props */
  onButtonSecondary40Click1?: () => void;
};

const WeeklyPlan = styled.div<{
  weeklyPlanMargin?: Property.Margin;
  weeklyPlanColor?: Property.Color;
}>`position: relative;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  margin: ${(p) => p.weeklyPlanMargin};
  color: ${(p) => p.weeklyPlanColor};
`;
const Div = styled.div<{ divColor?: Property.Color }>`
  position: relative;
  font-size: var(--font-size-5xl);
  line-height: 4.166666666666666vh;
  font-weight: 600;
  color: var(--colors-darkerblue);
  color: ${(p) => p.divColor};
`;
const WeeklyPlanParent = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-5xs);
`;
const AllIncluded = styled.div<{ allIncludedColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  color: ${(p) => p.allIncludedColor};
`;
const AllIncludedParent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  font-size: var(--body-main-size);
`;
const Property1defaultRoot = styled.div<{
  property1DefaultFlexShrink?: Property.FlexShrink;
  property1DefaultBackgroundColor?: Property.BackgroundColor;
  property1DefaultBorderW?: Property.BorderWidth;
  property1DefaultBorderC?: Property.BorderColor;
  property1DefaultBorderS?: Property.BorderStyle;
}>`border-radius: var(--corner-radius-l);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xsH) var(--padding-5xlW);
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  flex: 1;
  flex-shrink: ${(p) => p.property1DefaultFlexShrink};
  background-color: ${(p) => p.property1DefaultBackgroundColor};
  border-width: ${(p) => p.property1DefaultBorderW};
  border-color: ${(p) => p.property1DefaultBorderC};
  border-style: ${(p) => p.property1DefaultBorderS};
`;

const Property1Default5: FunctionComponent<Property1Default5Type> = ({
  weeklyPlan,
  prop,
  property1DefaultFlexShrink,
  property1DefaultBackgroundColor,
  property1DefaultBorderW,
  property1DefaultBorderC,
  property1DefaultBorderS,
  weeklyPlanMargin,
  weeklyPlanColor,
  divColor,
  allIncludedColor,
  buttonSecondaryCursor,
}) => {
  return (
    <Property1defaultRoot
      property1DefaultFlexShrink={property1DefaultFlexShrink}
      property1DefaultBackgroundColor={property1DefaultBackgroundColor}
      property1DefaultBorderW={property1DefaultBorderW}
      property1DefaultBorderC={property1DefaultBorderC}
      property1DefaultBorderS={property1DefaultBorderS}
    >
      <WeeklyPlanParent>
        <WeeklyPlan
          weeklyPlanMargin={weeklyPlanMargin}
          weeklyPlanColor={weeklyPlanColor}
        >
          {weeklyPlan}
        </WeeklyPlan>
        <Div divColor={divColor}>{prop}</Div>
      </WeeklyPlanParent>
      <AllIncludedParent>
        <AllIncluded allIncludedColor={allIncludedColor}>
          All included
        </AllIncluded>
        <StateHoverIconno
          saveSession="Subscribe"
          stateHoverIconnoJustifyContent="flex-start"
          stateHoverIconnoWidth="unset"
          stateHoverIconnoZIndex="unset"
          stateHoverIconnoBackgroundColor="unset"
          stateHoverIconnoCursor={buttonSecondaryCursor}
          stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
          stateHoverIconnoBorderC="var(--colors-darkerblue)"
          stateHoverIconnoBorderS="solid"
          stateHoverIconnoHeight="4.166666666666666vh"
          stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
          stateHoverIconnoBoxSizing="border-box"
          stateHoverIconnoPosition="unset"
          stateHoverIconnoTop="unset"
          stateHoverIconnoLeft="unset"
          saveSessionColor="var(--colors-darkerblue)"
          saveSessionDisplay="inline-block"
        />
      </AllIncludedParent>
    </Property1defaultRoot>
  );
};

export default Property1Default5;
