import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import StateDefaultNotificationno from "./StateDefaultNotificationno";
import styled from "styled-components";
import Property1Default4 from "./Property1Default4";
import Property1Default6 from "./Property1Default6";
import { useTranslation } from "react-i18next";

type Property1PrimaryProperty2Type = {
  smallChat?: string;
  smallFlashCards?: string;
  smallNews?: string;
  largeStop?: string;
  currLVL: string;

  /** Style props */
  property1PrimaryProperty2Width?: CSSProperties["width"];
  property1PrimaryProperty2Margin?: CSSProperties["margin"];
  property1PrimaryProperty2Position?: CSSProperties["position"];
  property1PrimaryProperty2Top?: CSSProperties["top"];
  property1PrimaryProperty2Right?: CSSProperties["right"];
  property1PrimaryProperty2Left?: CSSProperties["left"];
  property1PrimaryProperty2ZIndex?: CSSProperties["zIndex"];
  property1PrimaryProperty2BorderBottom?: CSSProperties["borderBottom"];
  tabPrimaryBBorderBottom?: CSSProperties["borderBottom"];
  tabPrimaryBBoxSizing?: CSSProperties["boxSizing"];
  tabPrimaryBCursor?: CSSProperties["cursor"];
  tabPrimaryBBackgroundColor?: CSSProperties["backgroundColor"];
  smallChatIconOpacity?: CSSProperties["opacity"];
  flashCardsColor?: CSSProperties["color"];
  flashCardsDisplay?: CSSProperties["display"];
  tabPrimaryBBoxSizing1?: CSSProperties["boxSizing"];
  tabPrimaryBCursor1?: CSSProperties["cursor"];
  tabPrimaryBBackgroundColor1?: CSSProperties["backgroundColor"];
  flashCardsDisplay1?: CSSProperties["display"];
  flashCardsColor1?: CSSProperties["color"];
  tabPrimaryBBoxSizing2?: CSSProperties["boxSizing"];
  tabPrimaryBCursor2?: CSSProperties["cursor"];
  tabPrimaryBBackgroundColor2?: CSSProperties["backgroundColor"];
  flashCardsDisplay2?: CSSProperties["display"];
  flashCardsColor2?: CSSProperties["color"];
  frameDivBorderBottom?: CSSProperties["borderBottom"];
  sessionTime1hColor?: CSSProperties["color"];
  stopSessionCursor?: CSSProperties["cursor"];
  stopSessionBorderW?: CSSProperties["borderWidth"];
  stopSessionBorderS?: CSSProperties["borderStyle"];
  stopSessionBorderC?: CSSProperties["borderColor"];
  stopSessionPadding?: CSSProperties["padding"];
  stopSessionBackgroundColor?: CSSProperties["backgroundColor"];

  /** Action props */
  onTabPrimaryBClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onTabPrimaryB1Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onTabPrimaryB2Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onStopSessionClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const SessionTime1h = styled.div<{ sessionTime1hColor?: Property.Color }>`
  position: relative;
  line-height: 2.604166666666667vh;
  color: ${(p) => p.sessionTime1hColor};
`;
const Sessiontime = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const SessiontimeParent = styled.div<{
  frameDivBorderBottom?: Property.BorderBottom;
}>`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0.0vh var(--padding-5xlW) 0.0vh 0.0vw;
  gap: var(--gap-5xs);
  border-bottom: ${(p) => p.frameDivBorderBottom};
`;
const Property1primaryProperty2Root = styled.div<{
  property1PrimaryProperty2Width?: Property.Width;
  property1PrimaryProperty2Margin?: Property.Margin;
  property1PrimaryProperty2Position?: Property.Position;
  property1PrimaryProperty2Top?: Property.Top;
  property1PrimaryProperty2Right?: Property.Right;
  property1PrimaryProperty2Left?: Property.Left;
  property1PrimaryProperty2ZIndex?: Property.ZIndex;
  property1PrimaryProperty2BorderBottom?: Property.BorderBottom;
}>`border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 46.38888888888889vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-purple-dark);
  font-family: var(--buttons-btn-input);
  width: ${(p) => p.property1PrimaryProperty2Width};
  margin: ${(p) => p.property1PrimaryProperty2Margin};
  position: ${(p) => p.property1PrimaryProperty2Position};
  top: ${(p) => p.property1PrimaryProperty2Top};
  right: ${(p) => p.property1PrimaryProperty2Right};
  left: ${(p) => p.property1PrimaryProperty2Left};
  z-index: ${(p) => p.property1PrimaryProperty2ZIndex};
  border-bottom: ${(p) => p.property1PrimaryProperty2BorderBottom};
`;

const Property1PrimaryProperty2: FunctionComponent<
  Property1PrimaryProperty2Type
> = ({
  smallChat,
  smallFlashCards,
  smallNews,
  largeStop,
  property1PrimaryProperty2Width,
  property1PrimaryProperty2Margin,
  property1PrimaryProperty2Position,
  property1PrimaryProperty2Top,
  property1PrimaryProperty2Right,
  property1PrimaryProperty2Left,
  property1PrimaryProperty2ZIndex,
  property1PrimaryProperty2BorderBottom,
  frameDivBorderBottom,
  sessionTime1hColor,
  stopSessionCursor,
  stopSessionBorderW,
  stopSessionBorderC,
  stopSessionBorderS,
  stopSessionPadding,
  stopSessionBackgroundColor,
  currLVL,
  onTabPrimaryBClick,
  onTabPrimaryB1Click,
  onTabPrimaryB2Click,
  onStopSessionClick,
}) => {
  const { t } = useTranslation();
  return (
    <Property1primaryProperty2Root
      property1PrimaryProperty2Width={property1PrimaryProperty2Width}
      property1PrimaryProperty2Margin={property1PrimaryProperty2Margin}
      property1PrimaryProperty2Position={property1PrimaryProperty2Position}
      property1PrimaryProperty2Top={property1PrimaryProperty2Top}
      property1PrimaryProperty2Right={property1PrimaryProperty2Right}
      property1PrimaryProperty2Left={property1PrimaryProperty2Left}
      property1PrimaryProperty2ZIndex={property1PrimaryProperty2ZIndex}
      property1PrimaryProperty2BorderBottom={
        property1PrimaryProperty2BorderBottom
      }
    >
      <StateDefaultNotificationno
        smallFlashCards={smallChat}
        text={t("Chat")}
        stateDefaultNotificationnCursor="unset"
        stateDefaultNotificationnBorderW="unset"
        stateDefaultNotificationnBorderC="unset"
        stateDefaultNotificationnBorderS="unset"
        stateDefaultNotificationnBackgroundColor="unset"
        stateDefaultNotificationnWidth="max-content"
        stateDefaultNotificationnFlexShrink="0"
        stateDefaultNotificationnBoxSizing="border-box"
        stateDefaultNotificationnBorderBottom="unset"
        smallFlashCardsIconOverflow="unset"
        smallFlashCardsIconFlexShrink="unset"
        smallFlashCardsIconOpacity="0.8"
        flashCardsDisplay="inline-block"
        flashCardsColor="unset"
        onTabPrimaryB3Click={onTabPrimaryBClick}
        HasClass="selected"
      />
      <StateDefaultNotificationno
        smallFlashCards={smallFlashCards}
        text={t("TestFlashcards")}
        stateDefaultNotificationnCursor="unset"
        stateDefaultNotificationnBorderW="unset"
        stateDefaultNotificationnBorderC="unset"
        stateDefaultNotificationnBorderS="unset"
        stateDefaultNotificationnBackgroundColor="unset"
        stateDefaultNotificationnWidth="max-content"
        stateDefaultNotificationnFlexShrink="0"
        stateDefaultNotificationnBoxSizing="border-box"
        stateDefaultNotificationnBorderBottom="unset"
        smallFlashCardsIconOverflow="unset"
        smallFlashCardsIconFlexShrink="unset"
        smallFlashCardsIconOpacity="unset"
        flashCardsDisplay="inline-block"
        flashCardsColor="var(--colors-dark-1)"
        onTabPrimaryB3Click={onTabPrimaryB1Click}
      />
      {false && <StateDefaultNotificationno
        smallFlashCards={smallNews}
        text={t("News")}
        stateDefaultNotificationnCursor="unset"
        stateDefaultNotificationnBorderW="unset"
        stateDefaultNotificationnBorderC="unset"
        stateDefaultNotificationnBorderS="unset"
        stateDefaultNotificationnBackgroundColor="unset"
        stateDefaultNotificationnWidth="max-content"
        stateDefaultNotificationnFlexShrink="0"
        stateDefaultNotificationnBoxSizing="border-box"
        stateDefaultNotificationnBorderBottom="unset"
        smallFlashCardsIconOverflow="unset"
        smallFlashCardsIconFlexShrink="unset"
        smallFlashCardsIconOpacity="unset"
        flashCardsDisplay="inline-block"
        flashCardsColor="var(--colors-dark-1)"
        onTabPrimaryB3Click={onTabPrimaryB2Click}
      />}
      {false && <><SessiontimeParent frameDivBorderBottom={frameDivBorderBottom}>
        <Sessiontime>
          <SessionTime1h
            sessionTime1hColor={sessionTime1hColor}
          >{`Session Time: 1h 13m 27s `}</SessionTime1h>
        </Sessiontime>
        <Property1Default4
          largeStop={largeStop}
          property1DefaultCursor={stopSessionCursor}
          property1DefaultBorderW={stopSessionBorderW}
          property1DefaultBorderC={stopSessionBorderC}
          property1DefaultBorderS={stopSessionBorderS}
          property1DefaultPadding={stopSessionPadding}
          property1DefaultBackgroundColor={stopSessionBackgroundColor}
          onStopSessionClick={onStopSessionClick}
        />
      </SessiontimeParent>
      <SessiontimeParent frameDivBorderBottom={frameDivBorderBottom}>
        Learning:
        <Property1Default6
          property1DefaultWidth="unset"
          property1DefaultJustifyContent="space-between"
          property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW) 0.52083333333vh"
          property1DefaultGap="unset"
          property1DefaultPosition="relative"
          property1DefaultTop="unset"
          property1DefaultLeft="unset"
          property1DefaultBackgroundColor="transparent"
          property1DefaultFontFamily="Onest"
          property1DefaultFontWeight="500"
          property1DefaultFontSize="1.8229166666666667vh"
          property1DefaultColor="var(--colors-purple-light)"
          property1DefaultAlignSelf="center"
          options={["Latvian"]}
          value={"Latvian"}
        />
        <Property1Default6
          property1DefaultWidth="unset"
          property1DefaultJustifyContent="space-between"
          property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW) 0.52083333333vh"
          property1DefaultGap="unset"
          property1DefaultPosition="relative"
          property1DefaultTop="unset"
          property1DefaultLeft="unset"
          property1DefaultBackgroundColor="transparent"
          property1DefaultFontFamily="Onest"
          property1DefaultFontWeight="500"
          property1DefaultFontSize="1.8229166666666667vh"
          property1DefaultColor="var(--colors-purple-light)"
          property1DefaultAlignSelf="center"
          options={["A0", "A1"]}
          value={currLVL}
          onVChange={(inp: string)=>{fetch('/SetCurrLevel',{method:'POST',headers: {'Content-Type': 'application/json'},body:JSON.stringify({level:inp})})}}
        />
      </SessiontimeParent>
      </>}
    </Property1primaryProperty2Root>
  );
};

export default Property1PrimaryProperty2;
