import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import StateHoverIconno from "./StateHoverIconno";

type AfterCloseNoType = {
  total?: string;

  /** Style props */
  afterCloseNoBackgroundColor?: CSSProperties["backgroundColor"];
  flashArdsColor?: CSSProperties["color"];
  flashArdsMargin?: CSSProperties["margin"];
  totalColor?: CSSProperties["color"];
  buttonSecondaryCursor?: CSSProperties["cursor"];
  buttonSecondaryBorderW?: CSSProperties["borderWidth"];
  buttonSecondaryBorderS?: CSSProperties["borderStyle"];
  buttonSecondaryBorderC?: CSSProperties["borderColor"];
  buttonSecondaryBackgroundColor?: CSSProperties["backgroundColor"];
  saveSessionDisplay?: CSSProperties["display"];

  /** Action props */
  onButtonSecondary3Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const FlashArds = styled.div<{
  flashArdsColor?: Property.Color;
  flashArdsMargin?: Property.Margin;
}>`position: relative;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  z-index: 2;
  color: ${(p) => p.flashArdsColor};
  margin: ${(p) => p.flashArdsMargin};
`;
const Total = styled.div<{ totalColor?: Property.Color }>`
  align-self: stretch;
  position: relative;
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
  text-align: center;
  z-index: 1;
  color: ${(p) => p.totalColor};
`;
const AfterClosenoRoot = styled.div<{
  afterCloseNoBackgroundColor?: Property.BackgroundColor;
}>`
  border-radius: var(--corner-radius-l);
  background-color: var(--colors-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-baseH) 0.0vw;
  box-sizing: border-box;
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-2);
  font-family: var(--buttons-btn-input);
  align-self: stretch;
  width: 22.916666666666664vw;
  height: 20.833333333333336vh;
  background-color: ${(p) => p.afterCloseNoBackgroundColor};
`;

const AfterCloseNo: FunctionComponent<AfterCloseNoType> = ({
  total,
  afterCloseNoBackgroundColor,
  flashArdsColor,
  flashArdsMargin,
  totalColor,
  onButtonSecondary3Click,
}) => {
  return (
    <AfterClosenoRoot afterCloseNoBackgroundColor={afterCloseNoBackgroundColor}>
      <FlashArds
        flashArdsColor={flashArdsColor}
        flashArdsMargin={flashArdsMargin}
      >
        Flash Сards
      </FlashArds>
      <Total totalColor={totalColor}>{total}</Total>
      <StateHoverIconno
        saveSession="View All"
        stateHoverIconnoJustifyContent="center"
        stateHoverIconnoWidth="8.333333333333332vw"
        stateHoverIconnoZIndex="0"
        stateHoverIconnoBackgroundColor="var(--colors-darkerblue)"
        stateHoverIconnoCursor="pointer"
        stateHoverIconnoBorderW="unset"
        stateHoverIconnoBorderC="unset"
        stateHoverIconnoBorderS="unset"
        stateHoverIconnoHeight="4.166666666666666vh"
        stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
        stateHoverIconnoBoxSizing="border-box"
        stateHoverIconnoPosition="unset"
        stateHoverIconnoTop="unset"
        stateHoverIconnoLeft="unset"
        saveSessionColor="var(--colors-btn-txt)"
        saveSessionDisplay="inline-block"
        onButtonSecondaryClick={onButtonSecondary3Click}
      />
    </AfterClosenoRoot>
  );
};

export default AfterCloseNo;
