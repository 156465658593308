import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1LargeImageType = {
  property1LargeImageProper?: string;

  /** Style props */
  property1LargeIconPosition?: CSSProperties["position"];
  property1LargeIconBorderRadius?: CSSProperties["borderRadius"];
  property1LargeIconWidth?: CSSProperties["width"];
  property1LargeIconHeight?: CSSProperties["height"];
};

const Property1largeIconRoot = styled.img<{
  property1LargeIconPosition?: Property.Position;
  property1LargeIconBorderRadius?: Property.BorderRadius;
  property1LargeIconWidth?: Property.Width;
  property1LargeIconHeight?: Property.Height;
}>`border-radius: var(--corner-radius-xl);
  width: 14.722222222222223vw;
  height: 27.604166666666668vh;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  position: ${(p) => p.property1LargeIconPosition};
  border-radius: ${(p) => p.property1LargeIconBorderRadius};
  width: ${(p) => p.property1LargeIconWidth};
  height: ${(p) => p.property1LargeIconHeight};
`;

const Property1LargeImage: FunctionComponent<Property1LargeImageType> = ({
  property1LargeImageProper,
  property1LargeIconPosition,
  property1LargeIconBorderRadius,
  property1LargeIconWidth,
  property1LargeIconHeight,
}) => {
  return (
    <Property1largeIconRoot
      alt=""
      src={property1LargeImageProper}
      property1LargeIconPosition={property1LargeIconPosition}
      property1LargeIconBorderRadius={property1LargeIconBorderRadius}
      property1LargeIconWidth={property1LargeIconWidth}
      property1LargeIconHeight={property1LargeIconHeight}
    />
  );
};

export default Property1LargeImage;
