import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";
import Property1Default11 from "./Property1Default11";

type Property1Variant2Type = {
  largeVolumeEnable?: string;
  ScrollObj: {
    scrollRef: React.RefObject<HTMLDivElement>;
    siblingRef: React.RefObject<HTMLDivElement>;
    scrollIndicatorHeight: string;
    scrollIndicatorTop: string;
    recalculateScrollIndicator: () => Promise<void>;
  };

  /** Style props */
  property1Variant2Height?: CSSProperties["height"];
  property1Variant2Position?: CSSProperties["position"];
  property1Variant2Top?: CSSProperties["top"];
  property1Variant2Left?: CSSProperties["left"];
  property1Variant2Overflow?: CSSProperties["overflow"];
  holaMargin?: CSSProperties["margin"];
  holaColor?: CSSProperties["color"];
  playAudioCursor?: CSSProperties["cursor"];
  playAudioBorderW?: CSSProperties["borderWidth"];
  playAudioBorderS?: CSSProperties["borderStyle"];
  playAudioBorderC?: CSSProperties["borderColor"];
  playAudioPadding?: CSSProperties["padding"];
  playAudioBackgroundColor?: CSSProperties["backgroundColor"];
  holaContainerColor?: CSSProperties["color"];

  /** Action props */
  onPlayAudio1Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Hola = styled.div<{
  holaMargin?: Property.Margin;
  holaColor?: Property.Color;
}>`position: relative;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  margin: ${(p) => p.holaMargin};
  color: ${(p) => p.holaColor};
`;
const HolaParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const HolaCmoEsts = styled.p`
  margin: 0;
  height: 38vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  };
`;
const HolaContainer = styled.div<{ holaContainerColor?: Property.Color }>`
  align-self: stretch;
  position: relative;
  font-size: var(--buttons-btn-input-size);
  line-height: 2.604166666666667vh;
  color: ${(p) => p.holaContainerColor};
`;
const Property1variant2Root = styled.div<{
  property1Variant2Height?: Property.Height;
  property1Variant2Position?: Property.Position;
  property1Variant2Top?: Property.Top;
  property1Variant2Left?: Property.Left;
  property1Variant2Overflow?: Property.Overflow;
}>`width: 27.77777777777778vw;
  height: 48.4375vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  height: ${(p) => p.property1Variant2Height};
  position: ${(p) => p.property1Variant2Position};
  top: ${(p) => p.property1Variant2Top};
  left: ${(p) => p.property1Variant2Left};
  overflow: ${(p) => p.property1Variant2Overflow};
`;

const Property1Variant2: FunctionComponent<Property1Variant2Type> = ({
  largeVolumeEnable,
  property1Variant2Height,
  property1Variant2Position,
  property1Variant2Top,
  property1Variant2Left,
  property1Variant2Overflow,
  holaMargin,
  holaColor,
  playAudioCursor,
  playAudioBorderW,
  playAudioBorderC,
  playAudioBorderS,
  playAudioPadding,
  playAudioBackgroundColor,
  holaContainerColor,
  ScrollObj,
  onPlayAudio1Click,
}) => {
  return (
    <Property1variant2Root
      property1Variant2Height={property1Variant2Height}
      property1Variant2Position={property1Variant2Position}
      property1Variant2Top={property1Variant2Top}
      property1Variant2Left={property1Variant2Left}
      property1Variant2Overflow={property1Variant2Overflow}
      ref={ScrollObj.siblingRef}
    >
      <HolaParent>
        <Hola holaMargin={holaMargin} holaColor={holaColor} id="GrammarWord">
        </Hola>
        <Property1Default11
          largeVolumeEnable={largeVolumeEnable}
          property1DefaultBorderW={playAudioBorderW}
          property1DefaultBorderC={playAudioBorderC}
          property1DefaultBorderS={playAudioBorderS}
          property1DefaultCursor={playAudioCursor}
          property1DefaultPadding={playAudioPadding}
          property1DefaultBackgroundColor={playAudioBackgroundColor}
          onPlayAudioClick={onPlayAudio1Click}
        />
      </HolaParent>
      <HolaContainer holaContainerColor={holaContainerColor}>
        <HolaCmoEsts id="GrammarGram">
        </HolaCmoEsts>
      </HolaContainer>
    </Property1variant2Root>
  );
};

export default Property1Variant2;
