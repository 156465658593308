import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import Property1Default from "./Property1Default";
import styled from "styled-components";

type CatT = {
  name: string;
}

type Property1NavigationPropert1Type = {
  Categories: CatT[];
  /** Style props */
  property1NavigationProperMargin?: CSSProperties["margin"];
  property1NavigationProperPosition?: CSSProperties["position"];
  property1NavigationProperTop?: CSSProperties["top"];
  property1NavigationProperLeft?: CSSProperties["left"];
  property1NavigationProperZIndex?: CSSProperties["zIndex"];
  chipBackgroundColor?: CSSProperties["backgroundColor"];
  chipBoxSizing?: CSSProperties["boxSizing"];
  chipCursor?: CSSProperties["cursor"];
  allColor?: CSSProperties["color"];
  allDisplay?: CSSProperties["display"];
  chipBoxSizing1?: CSSProperties["boxSizing"];
  chipCursor1?: CSSProperties["cursor"];
  chipBackgroundColor1?: CSSProperties["backgroundColor"];
  allDisplay1?: CSSProperties["display"];
  allColor1?: CSSProperties["color"];
  chipBoxSizing2?: CSSProperties["boxSizing"];
  chipCursor2?: CSSProperties["cursor"];
  chipBackgroundColor2?: CSSProperties["backgroundColor"];
  allDisplay2?: CSSProperties["display"];
  allColor2?: CSSProperties["color"];
  chipBoxSizing3?: CSSProperties["boxSizing"];
  chipCursor3?: CSSProperties["cursor"];
  chipBackgroundColor3?: CSSProperties["backgroundColor"];
  allDisplay3?: CSSProperties["display"];
  allColor3?: CSSProperties["color"];
  chipBoxSizing4?: CSSProperties["boxSizing"];
  chipCursor4?: CSSProperties["cursor"];
  chipBackgroundColor4?: CSSProperties["backgroundColor"];
  allDisplay4?: CSSProperties["display"];
  allColor4?: CSSProperties["color"];
  chipBoxSizing5?: CSSProperties["boxSizing"];
  chipCursor5?: CSSProperties["cursor"];
  chipBackgroundColor5?: CSSProperties["backgroundColor"];
  allDisplay5?: CSSProperties["display"];
  allColor5?: CSSProperties["color"];
  chipBoxSizing6?: CSSProperties["boxSizing"];
  chipCursor6?: CSSProperties["cursor"];
  chipBackgroundColor6?: CSSProperties["backgroundColor"];
  allDisplay6?: CSSProperties["display"];
  allColor6?: CSSProperties["color"];
  chipBoxSizing7?: CSSProperties["boxSizing"];
  chipCursor7?: CSSProperties["cursor"];
  chipBackgroundColor7?: CSSProperties["backgroundColor"];
  allDisplay7?: CSSProperties["display"];
  allColor7?: CSSProperties["color"];

  /** Action props */
  onChip3Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onChip4Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onChip5Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onChip6Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onChip7Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onChip8Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onChip9Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onChip10Click?: (e: React.MouseEvent<HTMLElement>) => void;
  showCategory: (name: string) => void;
};

const Property1navigationPropertRoot = styled.div<{
  property1NavigationProperMargin?: Property.Margin;
  property1NavigationProperPosition?: Property.Position;
  property1NavigationProperTop?: Property.Top;
  property1NavigationProperLeft?: Property.Left;
  property1NavigationProperZIndex?: Property.ZIndex;
}>`width: 46.38888888888889vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-xsW);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  margin: ${(p) => p.property1NavigationProperMargin};
  position: ${(p) => p.property1NavigationProperPosition};
  top: ${(p) => p.property1NavigationProperTop};
  left: ${(p) => p.property1NavigationProperLeft};
  z-index: ${(p) => p.property1NavigationProperZIndex};
`;

const Property1NavigationPropert1: FunctionComponent<
  Property1NavigationPropert1Type
> = ({
  property1NavigationProperMargin,
  property1NavigationProperPosition,
  property1NavigationProperTop,
  property1NavigationProperLeft,
  property1NavigationProperZIndex,
  onChip3Click,
  showCategory,
  Categories,
}) => {
  return (
    <Property1navigationPropertRoot
      property1NavigationProperMargin={property1NavigationProperMargin}
      property1NavigationProperPosition={property1NavigationProperPosition}
      property1NavigationProperTop={property1NavigationProperTop}
      property1NavigationProperLeft={property1NavigationProperLeft}
      property1NavigationProperZIndex={property1NavigationProperZIndex}
    >
      <Property1Default
        text="All"
        property1DefaultFlexShrink="0"
        property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
        property1DefaultBoxSizing="border-box"
        property1DefaultCursor="pointer"
        property1DefaultBorderW="unset"
        property1DefaultBorderC="unset"
        property1DefaultBorderS="unset"
        property1DefaultBackgroundColor="var(--colors-blue-1)"
        allDisplay="inline-block"
        allAlignItems="unset"
        allJustifyContent="unset"
        allWidth="max-content"
        allHeight="1.5625"
        allFlexShrink="unset"
        allColor="var(--colors-dark-1)"
        HasClass="selected"
        onChipClick={onChip3Click}
      />
      {
        Categories.map((Category)=> {
          const onChipClick = (e: React.MouseEvent<HTMLElement>) => {
            showCategory(Category.name)
            const element = e.currentTarget as HTMLElement;
            requestAnimationFrame(() => {
              if (element.parentElement) {
                const el = element.parentElement.querySelector(".selected")
                if (el) {
                  // Remove the 'selected' class
                  el.classList.remove("selected");
                }
                element.classList.add("selected")
              }
            });
          };
          return (
            <Property1Default
              text={Category.name}
              property1DefaultFlexShrink="0"
              property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
              property1DefaultBoxSizing="border-box"
              property1DefaultCursor="pointer"
              property1DefaultBorderW="unset"
              property1DefaultBorderC="unset"
              property1DefaultBorderS="unset"
              property1DefaultBackgroundColor="var(--colors-blue-1)"
              allDisplay="inline-block"
              allAlignItems="unset"
              allJustifyContent="unset"
              allWidth="max-content"
              allHeight="1.5625"
              allFlexShrink="unset"
              allColor="var(--colors-dark-1)"
              onChipClick={onChipClick}
            />
          )
        })
      }
    </Property1navigationPropertRoot>
  );
};

export default Property1NavigationPropert1;
