import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "./global";
import i18n from './i18n';
import { io } from 'socket.io-client';

const container = document.getElementById("root");
const root = createRoot(container!);

const Page: React.FC = () => {
  const [darkMode, setDarkMode] = React.useState(false)
  const switchDarkMode = () => {
    setDarkMode(!darkMode)
  }

  const [ProfileData, setProfileData] = React.useState(window.INITIAL_ProfileData || {});
  const [News, setNews] = React.useState(window.INITIAL_News || []);
  const [FlashCardsIn, setFlashCardsIn] = React.useState(window.INITIAL_FlashCardsIn || []);
  const [HTMLs, setHTMLs] = React.useState(window.INITIAL_HTMLs || []);
  const [texts, settexts] = React.useState(window.INITIAL_texts || []);
  const [AllLangs, setAllLangs] = React.useState(window.ALL_LANGS || []);
  const [logedIn, _] = React.useState(window.INITIAL_LOG_IN);
  const [T, _0] = React.useState(window.Token || "");
  const [G, _1] = React.useState(window.GID || "");
  const [P, _2] = React.useState(window.P || "");
  const [Ylang, _3] = React.useState(window.Ylang || "");
  const [hv, _4] = React.useState(window.hv);
  const [NL, _5] = React.useState(window.NL || "US");
  const [LL, _6] = React.useState(window.LL || "LV");
  const [IQ, _7] = React.useState(window.IQ);
  const [QT, _8] = React.useState(window.QT || "None");
  const [Activities, _9] = React.useState(window.Activities || []);

  const [isLanguageLoaded, setIsLanguageLoaded] = React.useState(false);

  React.useEffect(() => {
    const loadLanguage = async () => {
      const userPreferredLanguage = window.NLLocal || "en";
      await i18n.changeLanguage(userPreferredLanguage);
      setIsLanguageLoaded(true);
    };

    loadLanguage();
  }, []);
  
  if (!isLanguageLoaded){
    return <></>
  }

  navigator.mediaDevices.getUserMedia({ audio: true }).then(function (stream) {
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop());
  }).catch(function (err) {
    alert("Website needs access to media for some features to work.");
  });
  //'http://localhost:5001'
  const socket = logedIn && G!==""?io('https://zippyvox.com'):null;
  logedIn && G!=="" && socket && socket.emit("setT",{"T":T, "G":G})

  return (
    <BrowserRouter>
      <GlobalStyles darkMode={darkMode} />
      <App
        darkMode={darkMode}
        switchDarkMode={switchDarkMode}
        ProfileData={ProfileData}
        News={News}
        FlashCardsIn={FlashCardsIn}
        HTMLs={HTMLs}
        texts={texts}
        AllLangs={AllLangs}
        LogedIn={logedIn}
        T={T}
        G={G}
        P={P}
        NL={NL}
        LL={LL}
        IQ={IQ}
        Ylang={Ylang}
        hv={hv}
        Acts={Activities}
        QT={QT}
        socket={socket}
      />
    </BrowserRouter>
  )
}

root.render(
  <Page/>
);
