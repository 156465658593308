import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1Default7Type = {
  /** Style props */
  bioColor?: CSSProperties["color"];
  hiThereImColor?: CSSProperties["color"];
};

const Bio = styled.div<{ bioColor?: Property.Color }>`
  align-self: stretch;
  position: relative;
  line-height: 133%;
  font-weight: 500;
  color: ${(p) => p.bioColor};
`;
const HiThereIm = styled.div<{ hiThereImColor?: Property.Color }>`
  align-self: stretch;
  position: relative;
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
  color: ${(p) => p.hiThereImColor};
`;
const Property1defaultRoot = styled.div`
  width: 30.0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--buttons-btn-input-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
`;

const Property1Default7: FunctionComponent<Property1Default7Type> = ({
  bioColor,
  hiThereImColor,
}) => {
  return (
    <Property1defaultRoot>
      <Bio bioColor={bioColor}>Bio</Bio>
      <HiThereIm hiThereImColor={hiThereImColor}>
        Hi there! I'm Anika, born and raised in Johannesburg, South Africa. With
        4 years of teaching experience, I'm passionate about making Afrikaans
        accessible and enjoyable for learners of all ages. Let's embark on a
        journey of language and cultural discovery together!
      </HiThereIm>
    </Property1defaultRoot>
  );
};

export default Property1Default7;
