import { FunctionComponent, type CSSProperties, useState, ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, useEffect } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1Default6Type = {
  /** Style props */
  property1DefaultWidth?: CSSProperties["width"];
  property1DefaultJustifyContent?: CSSProperties["justifyContent"];
  property1DefaultPadding?: CSSProperties["padding"];
  property1DefaultGap?: CSSProperties["gap"];
  property1DefaultPosition?: CSSProperties["position"];
  property1DefaultTop?: CSSProperties["top"];
  property1DefaultLeft?: CSSProperties["left"];
  property1DefaultBackgroundColor?: CSSProperties["backgroundColor"];
  property1DefaultFontFamily?: CSSProperties["fontFamily"];
  property1DefaultFontWeight?: CSSProperties["fontWeight"];
  property1DefaultFontSize?: CSSProperties["fontSize"];
  property1DefaultColor?: CSSProperties["color"];
  property1DefaultAlignSelf?: CSSProperties["alignSelf"];
  value?: string;
  options: string[];
  id?: string;
  onVChange?: (a: string)=>void;
};

const AllLanguages = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--colors-dark-1);
`;
const SmalldropdownarrowIcon = styled.img<{ shouldRotate: boolean }>`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  object-fit: cover;
  transform: ${({ shouldRotate }) => shouldRotate ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease;
  filter: var(--filters-dark-1);
`;
const Property1defaultRoot = styled.div<{
  property1DefaultWidth?: Property.Width;
  property1DefaultJustifyContent?: Property.JustifyContent;
  property1DefaultPadding?: Property.Padding;
  property1DefaultGap?: Property.Gap;
  property1DefaultPosition?: Property.Position;
  property1DefaultTop?: Property.Top;
  property1DefaultLeft?: Property.Left;
  property1DefaultBackgroundColor?: Property.BackgroundColor;
  property1DefaultFontFamily?: Property.FontFamily;
  property1DefaultFontWeight?: Property.FontWeight;
  property1DefaultFontSize?: Property.FontSize;
  property1DefaultColor?: Property.Color;
  property1DefaultAlignSelf?: Property.AlignSelf;
}>`border-radius: var(--corner-radius-m);
  border-color: var(--colors-blue2);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  box-sizing: border-box;
  width: 9.791666666666666vw;
  height: 3.7760416666666665vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.0vh var(--padding-xsW);
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-purple-light);
  font-family: var(--buttons-btn-input);
  width: ${(p) => p.property1DefaultWidth};
  justify-content: ${(p) => p.property1DefaultJustifyContent};
  padding: ${(p) => p.property1DefaultPadding};
  gap: ${(p) => p.property1DefaultGap};
  position: ${(p) => p.property1DefaultPosition};
  top: ${(p) => p.property1DefaultTop};
  left: ${(p) => p.property1DefaultLeft};
  background-color: ${(p) => p.property1DefaultBackgroundColor};
  font-family: ${(p) => p.property1DefaultFontFamily};
  font-weight: ${(p) => p.property1DefaultFontWeight};
  font-size: ${(p) => p.property1DefaultFontSize};
  color: ${(p) => p.property1DefaultColor};
  align-self: ${(p) => p.property1DefaultAlignSelf};
`;

const CustomSelect = styled.select`
  position: absolute;
  top: 0; // Position the dropdown right below the parent
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; // Make the select transparent so the wrapper is visible
  cursor: pointer;
  background-color: var(--colors-white);
  color: var(--colors-dark-1);
`;

const Property1Default6: FunctionComponent<Property1Default6Type> = ({
  property1DefaultWidth,
  property1DefaultJustifyContent,
  property1DefaultPadding,
  property1DefaultGap,
  property1DefaultPosition,
  property1DefaultTop,
  property1DefaultLeft,
  property1DefaultBackgroundColor,
  property1DefaultFontFamily,
  property1DefaultFontWeight,
  property1DefaultFontSize,
  property1DefaultColor,
  property1DefaultAlignSelf,
  value,
  options,
  id,
  onVChange,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value); // Update the selected value
    onVChange && onVChange(e.target.value)
  };
  const [dropdownCounter, setDropdownCounter] = useState(0); // Counter for dropdown interactions
  const [isFocused, setIsFocused] = useState(false); // Track if select is focused
  const handleSelectFocus = (e: FocusEvent<HTMLSelectElement>) => {
    setIsFocused(true); // Set focused state to true
  };

  const handleSelectMouseDown = (e: MouseEvent<HTMLSelectElement>) => {
    // Only increment counter if already focused
    setDropdownCounter((dropdownCounter + 1) % 2);
  };

  const handleSelectBlur = (e: FocusEvent<HTMLSelectElement>) => {
    setIsFocused(false); // Set focused state to false
    setDropdownCounter(0); // Reset counter on blur
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLSelectElement>) => {
    if (isFocused && (e.key === 'Escape' || e.key === 'Tab' || e.key==='Enter')) {
      setDropdownCounter(0); // Reset counter on Escape or Tab key
    }
  };

  return (
    <Property1defaultRoot
      property1DefaultWidth={property1DefaultWidth}
      property1DefaultJustifyContent={property1DefaultJustifyContent}
      property1DefaultPadding={property1DefaultPadding}
      property1DefaultGap={property1DefaultGap}
      property1DefaultPosition={property1DefaultPosition}
      property1DefaultTop={property1DefaultTop}
      property1DefaultLeft={property1DefaultLeft}
      property1DefaultBackgroundColor={property1DefaultBackgroundColor}
      property1DefaultFontFamily={property1DefaultFontFamily}
      property1DefaultFontWeight={property1DefaultFontWeight}
      property1DefaultFontSize={property1DefaultFontSize}
      property1DefaultColor={property1DefaultColor}
      property1DefaultAlignSelf={property1DefaultAlignSelf}
    >
      <AllLanguages>{selectedValue}</AllLanguages>
      <SmalldropdownarrowIcon shouldRotate={dropdownCounter === 1} alt="" src="/DropdownArrow.svg" />
      <CustomSelect
      value={selectedValue}
      onChange={handleSelectChange}
      onFocus={handleSelectFocus}
      onBlur={handleSelectBlur}
      onClick={handleSelectMouseDown}
      onKeyDown={handleKeyDown}
      id={id}
      >
      {options.map((opt, _) => (
        <option value={opt}>{opt}</option>
      ))}
      </CustomSelect>
    </Property1defaultRoot>
  );
};

export default Property1Default6;
