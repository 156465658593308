import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1Default9Type = {
  text?: string;
  smallArrows?: string;

  /** Style props */
  property1DefaultCursor?: CSSProperties["cursor"];
  property1DefaultBorderW?: CSSProperties["borderWidth"];
  property1DefaultBorderS?: CSSProperties["borderStyle"];
  property1DefaultBorderC?: CSSProperties["borderColor"];
  property1DefaultPadding?: CSSProperties["padding"];
  property1DefaultBackgroundColor?: CSSProperties["backgroundColor"];
  rateColor?: CSSProperties["color"];
  rateDisplay?: CSSProperties["display"];

  /** Action props */
  onSortingItemClick1?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Rate = styled.div<{
  rateColor?: Property.Color;
  rateDisplay?: Property.Display;
}>`position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(p) => p.rateColor};
  display: ${(p) => p.rateDisplay};
`;
const SmallarrowsIcon = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  object-fit: cover;
`;
const Property1defaultRoot = styled.div<{
  property1DefaultCursor?: Property.Cursor;
  property1DefaultBorderW?: Property.BorderWidth;
  property1DefaultBorderC?: Property.BorderColor;
  property1DefaultBorderS?: Property.BorderStyle;
  property1DefaultPadding?: Property.Padding;
  property1DefaultBackgroundColor?: Property.BackgroundColor;
}>`height: 3.6458333333333335vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-purple-light);
  font-family: var(--buttons-btn-input);
  cursor: ${(p) => p.property1DefaultCursor};
  border-width: ${(p) => p.property1DefaultBorderW};
  border-color: ${(p) => p.property1DefaultBorderC};
  border-style: ${(p) => p.property1DefaultBorderS};
  padding: ${(p) => p.property1DefaultPadding};
  background-color: ${(p) => p.property1DefaultBackgroundColor};
  & img {
    filter: var(--filters-purple-light);
  }
  &:hover {
    & div {
      color: var(--colors-dark-1);
    }
    & img {
      filter: var(--filters-dark-1);
    }
  }
`;

const Property1Default9: FunctionComponent<Property1Default9Type> = ({
  text = "Rate",
  smallArrows,
  property1DefaultCursor,
  property1DefaultBorderW,
  property1DefaultBorderC,
  property1DefaultBorderS,
  property1DefaultPadding,
  property1DefaultBackgroundColor,
  rateColor,
  rateDisplay,
  onSortingItemClick1,
}) => {
  return (
    <Property1defaultRoot
      property1DefaultCursor={property1DefaultCursor}
      property1DefaultBorderW={property1DefaultBorderW}
      property1DefaultBorderC={property1DefaultBorderC}
      property1DefaultBorderS={property1DefaultBorderS}
      property1DefaultPadding={property1DefaultPadding}
      property1DefaultBackgroundColor={property1DefaultBackgroundColor}
      onClick={onSortingItemClick1}
    >
      <Rate rateColor={rateColor} rateDisplay={rateDisplay}>
        {text}
      </Rate>
      <SmallarrowsIcon alt="" src={smallArrows} />
    </Property1defaultRoot>
  );
};

export default Property1Default9;
