import { FunctionComponent, type CSSProperties,  useState, useRef, forwardRef } from "react";
import { Property } from "csstype";
import Property1LargeImage from "./Property1LargeImage";
import Property1Default8 from "./Property1Default8";
import Property1Default7 from "./Property1Default7";
import styled from "styled-components";
import StateHoverIconno from "./StateHoverIconno";
import Type3Pages from "./Type3Pages";

type comment = {
  name:string;
  date:string;
  rate:number;
  comment:string;
}

type TCTOther = {
  id:number;
  photo:string;
  name:string;
  available:boolean;
  rating:number;
  price:number;
  language:string;
  comments:comment[];
}

type TeacherPageContainerType = {
  largeStar?: string;
  largeStar1?: string;
  largeStar2?: string;
  largeStar3?: string;
  frame126?: string;
  smallStar?: string;
  smallStar1?: string;
  smallStar2?: string;
  smallStar3?: string;
  interfaceStar?: string;
  smallStar4?: string;
  smallStar5?: string;
  smallStar6?: string;
  smallStar7?: string;
  interfaceStar1?: string;
  smallStar8?: string;
  smallStar9?: string;
  smallStar10?: string;
  smallStar11?: string;
  interfaceStar2?: string;
  smallStar12?: string;
  smallStar13?: string;
  smallStar14?: string;
  smallStar15?: string;
  interfaceStar3?: string;
  smallStar16?: string;
  smallStar17?: string;
  smallStar18?: string;
  smallStar19?: string;
  interfaceStar4?: string;
  smallStar20?: string;
  smallStar21?: string;
  smallStar22?: string;
  smallStar23?: string;
  interfaceStar5?: string;
  isVisible: boolean;
  dataIndex: number;
  data: TCTOther[];

  /** Style props */
  propColor?: CSSProperties["color"];
  propColor1?: CSSProperties["color"];
  propColor2?: CSSProperties["color"];
  propColor3?: CSSProperties["color"];
  propColor4?: CSSProperties["color"];
  propColor5?: CSSProperties["color"];
  propColor6?: CSSProperties["color"];
  propColor7?: CSSProperties["color"];
  propColor8?: CSSProperties["color"];
  propBackgroundColor?: CSSProperties["backgroundColor"];
  propColor9?: CSSProperties["color"];
  propColor10?: CSSProperties["color"];
  propColor11?: CSSProperties["color"];
  propCursor?: CSSProperties["cursor"];
  propColor12?: CSSProperties["color"];
  propColor13?: CSSProperties["color"];
  propColor14?: CSSProperties["color"];
  propColor15?: CSSProperties["color"];
  propColor16?: CSSProperties["color"];
  propColor17?: CSSProperties["color"];
  propColor18?: CSSProperties["color"];
  propColor19?: CSSProperties["color"];
  propColor20?: CSSProperties["color"];
  propColor21?: CSSProperties["color"];
  propColor22?: CSSProperties["color"];
  propColor23?: CSSProperties["color"];
  propColor24?: CSSProperties["color"];
  propColor25?: CSSProperties["color"];
  propColor26?: CSSProperties["color"];
  propColor27?: CSSProperties["color"];
  propColor28?: CSSProperties["color"];
  propColor29?: CSSProperties["color"];
  propColor30?: CSSProperties["color"];
  propColor31?: CSSProperties["color"];
  propColor32?: CSSProperties["color"];

  /** Action props */
  onButtonSecondary8Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onButtonSecondary9Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onButtonSecondary10Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onStarsContainer1Click?: (e: React.MouseEvent<HTMLElement>) => void;
  onButtonSecondary11Click?: (e: React.MouseEvent<HTMLElement>) => void;
  ToClose: (e: React.MouseEvent<HTMLElement>) => void;
};

const RateThisTutor = styled.div<{ propColor11?: Property.Color }>`
  align-self: stretch;
  position: relative;
  line-height: 133%;
  font-weight: 500;
  color: ${(p) => p.propColor11};
`;
const SmallstarIcon = styled.div<{percent: string,}>`
  position: relative;
  --side: min(1.9444444444444444vw,3.6458333333333335vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  background: linear-gradient(
    to right,
    var(--colors-darkerblue) 0%,
    var(--colors-darkerblue) ${(p) => p.percent},
    var(--colors-purple-light) ${(p) => p.percent},
    var(--colors-purple-light) 100%
  );
  -webkit-mask: url('SmallStar.svg') no-repeat center / contain;
  mask: url('SmallStar.svg') no-repeat center / contain;
  display: inline-block;
  mask-composite: exclude;
`;
const Stars = styled.div<{ propCursor?: Property.Cursor }>`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0;
  cursor: ${(p) => p.propCursor};
`;
const Starsbtn = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const Rate = styled.div`
  width: 30.0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const JaneSmith = styled.div<{ propColor13?: Property.Color }>`
  position: relative;
  line-height: 133%;
  font-weight: 500;
  color: ${(p) => p.propColor13};
`;
const Div = styled.div<{ propColor14?: Property.Color }>`
  position: relative;
  font-size: var(--caption-cap-12-size);
  line-height: 2.604166666666667vh;
  color: var(--colors-purple-light);
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 1.5277777777777777vw;
  height: 2.34375vh;
  flex-shrink: 0;
  color: ${(p) => p.propColor14};
`;
const Header = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const JaneSmith1 = styled.div<{ propColor15?: Property.Color }>`
  position: relative;
  line-height: 133%;
  font-weight: 500;
  color: ${(p) => p.propColor15};
`;
const JaneSmithWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const SmallstarIcon4 = styled.div<{percent: string,}>`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  background: linear-gradient(
    to right,
    var(--colors-darkerblue) 0%,
    var(--colors-darkerblue) ${(p) => p.percent},
    var(--colors-purple-light) ${(p) => p.percent},
    var(--colors-purple-light) 100%
  );
  -webkit-mask: url('SmallStar.svg') no-repeat center / contain;
  mask: url('SmallStar.svg') no-repeat center / contain;
  display: inline-block;
  mask-composite: exclude;
`;
const Stars1 = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
`;
const Div1 = styled.div<{ propColor16?: Property.Color }>`
  position: relative;
  line-height: 133%;
  display: flex;
  align-items: center;
  width: 3.680555555555556vw;
  height: 1.6927083333333333vh;
  flex-shrink: 0;
  color: ${(p) => p.propColor16};
`;
const FrameParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: var(--gap-5xs);
  font-size: var(--caption-cap-12-size);
  color: var(--colors-purple-dark);
`;
const ExcellentLessonsI = styled.div<{ propColor17?: Property.Color }>`
  align-self: stretch;
  position: relative;
  line-height: 133%;
  color: ${(p) => p.propColor17};
`;
const Review = styled.div`
  width: 30.0vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const Reviewholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  font-size: var(--body-main-size);
`;
const Tutordetails = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`;
const PhotoholderParent = styled.div`
  position: absolute;
  top: 2.604166666666667vh;
  left: 0.0vw;
  width: 80.27777777777779vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.0vh 0.0vw 0.0vh var(--padding-217xlW);
  box-sizing: border-box;
  gap: var(--gap-5xl);
`;
const TabTeacherPageRoot = styled.section<{isVisible: boolean,}>`
  position: relative;
  width: 80.2777778vw;
  height: 139.71354166666669vh;
  flex-shrink: 0;
  text-align: left;
  align-content: start;
  font-size: var(--buttons-btn-input-size);
  color: var(--colors-white);
  font-family: var(--buttons-btn-input);
  max-height: 84.5vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  display: ${(p) => p.isVisible? 'flex':'none'};
`;

const TeacherPageContainer= forwardRef<HTMLDivElement, TeacherPageContainerType>(({
  propCursor,
  propColor14,
  propColor16,
  isVisible,
  data,
  dataIndex,
  ToClose,
}, ref) => {
  const starsRef = useRef<HTMLDivElement>(null);
  const [percentages, setPercentages] = useState([0, 0, 0, 0, 0]);
  const handleMouseMove = (index: number, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const starWidth = e.currentTarget.offsetWidth;
    const offset = e.pageX - e.currentTarget.getBoundingClientRect().left;
    const hoveredStarPercentage = (offset / starWidth) * 100;
  
    const newPercentages = percentages.map((_, idx) => {
      if (idx < index) return 100; // Full star
      if (idx === index) return hoveredStarPercentage; // Partial star
      return 0; // Empty star
    });
  
    setPercentages(newPercentages);
  };
  const [finalPercentages, setFinalPercentages] = useState([0, 0, 0, 0, 0]);
  const CalcStarPercent = (rate:number, SI:number) => {
    let sum=(rate-SI+1)*100;
    return sum>100?100:sum<0?0:sum;
  }
  return (
    <TabTeacherPageRoot isVisible={isVisible} ref={ref}>
      <PhotoholderParent>
        <Property1LargeImage
          property1LargeImageProper="/photoholder@2x.png"
          property1LargeIconPosition="relative"
          property1LargeIconBorderRadius="var(--corner-radius-xl)"
          property1LargeIconWidth="14.722222222222223vw"
          property1LargeIconHeight="27.604166666666668vh"
        />
        <Tutordetails>
          <Property1Default8
            data={data}
            dataIndex={dataIndex}
            largeStar="/LargeStar.svg"
            largeStar1="/LargeStar.svg"
            largeStar2="/LargeStar.svg"
            largeStar3="/LargeStar.svg"
            frame126="/LargeStar.svg"
            anikaFourieColor="var(--colors-dark-1)"
            anikaFourieMargin="0"
            rateColor="var(--colors-purple-dark)"
            divColor="var(--colors-purple-dark)"
            languageColor="var(--colors-purple-dark)"
            afrikaansColor="var(--colors-dark-1)"
            priceColor="var(--colors-purple-dark)"
            hourColor="var(--colors-dark-1)"
            buttonSecondaryCursor="pointer"
            buttonSecondaryBackgroundColor="transparent"
            saveSessionColor="var(--colors-darkerblue)"
            saveSessionDisplay="inline-block"
            buttonSecondaryCursor1="pointer"
            buttonSecondaryBackgroundColor1="transparent"
            saveSessionColor1="var(--colors-darkerblue)"
            saveSessionDisplay1="inline-block"
            buttonSecondaryCursor2="pointer"
            buttonSecondaryBackgroundColor2="var(--colors-darkerblue)"
            saveSessionDisplay2="inline-block"
          />
          <Property1Default7 bioColor="var(--colors-dark-1)" hiThereImColor="var(--colors-dark-1)" />
          <Rate>
            <RateThisTutor propColor11="var(--colors-dark-1)">
              Rate this tutor
            </RateThisTutor>
            <Starsbtn>
              <Stars propCursor={propCursor} onClick={()=>(setFinalPercentages(percentages))}>
              {percentages.map((percent, index) => (
                <SmallstarIcon
                  key={index}
                  percent={`${percent}%`}
                  onMouseMove={(e) => handleMouseMove(index, e)}
                  onMouseLeave={() => setPercentages(finalPercentages)} // Reset on mouse leave
                />
              ))}
              </Stars>
              <StateHoverIconno
                saveSession="Write review"
                stateHoverIconnoJustifyContent="flex-start"
                stateHoverIconnoWidth="unset"
                stateHoverIconnoZIndex="unset"
                stateHoverIconnoBackgroundColor="transparent"
                stateHoverIconnoCursor="pointer"
                stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                stateHoverIconnoBorderC="var(--colors-darkerblue)"
                stateHoverIconnoBorderS="solid"
                stateHoverIconnoHeight="4.166666666666666vh"
                stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                stateHoverIconnoBoxSizing="border-box"
                stateHoverIconnoPosition="unset"
                stateHoverIconnoTop="unset"
                stateHoverIconnoLeft="unset"
                saveSessionColor="var(--colors-darkerblue)"
                saveSessionDisplay="inline-block"
              />
            </Starsbtn>
          </Rate>
          <Rate>
            <Header>
              <JaneSmith propColor13="var(--colors-dark-1)">Reviews</JaneSmith>
              <Div propColor14={propColor14}>77</Div>
            </Header>
            <Reviewholder>
              {
                data[dataIndex].comments.map((comment) => (
                  <Review>
                    <JaneSmithWrapper>
                      <JaneSmith1 propColor15="var(--colors-dark-1)">{comment.name}</JaneSmith1>
                    </JaneSmithWrapper>
                    <FrameParent>
                      <Stars1>
                        <SmallstarIcon4 percent={String(CalcStarPercent(comment.rate,1))+"%"} />
                        <SmallstarIcon4 percent={String(CalcStarPercent(comment.rate,2))+"%"} />
                        <SmallstarIcon4 percent={String(CalcStarPercent(comment.rate,3))+"%"} />
                        <SmallstarIcon4 percent={String(CalcStarPercent(comment.rate,4))+"%"} />
                        <SmallstarIcon4 percent={String(CalcStarPercent(comment.rate,5))+"%"} />
                      </Stars1>
                      <Div1 propColor16={propColor16}>{comment.date}</Div1>
                    </FrameParent>
                    <ExcellentLessonsI
                      propColor17="var(--colors-dark-1)"
                    >{comment.comment}</ExcellentLessonsI>
                  </Review>
                ))
              }
            </Reviewholder>
          </Rate>
        </Tutordetails>
      </PhotoholderParent>
      <Type3Pages
        news={`My Goals >`}
        news1={`Tutors >`}
        news2={data[dataIndex].name}
        type3PagesWidth="80.27777777777779vw"
        type3PagesPosition="absolute"
        type3PagesTop="0vh"
        type3PagesLeft="0.0vw"
        type3PagesFlexShrink="0"
        type3PagesPadding="0.0vh var(--padding-9xlW)"
        type3PagesBoxSizing="border-box"
        newsColor="var(--colors-purple-dark)"
        newsColor1="var(--colors-purple-dark)"
        newsColor2="var(--colors-purple-dark)"
        ToClose={ToClose}
      />
    </TabTeacherPageRoot>
  );
});

export default TeacherPageContainer;
