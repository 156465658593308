import { FunctionComponent, useState, useCallback, useEffect, useRef } from "react";
import StudyContainer1 from "../components/StudyContainer1";
import styled from "styled-components";
import Property1PrimaryProperty2 from "../components/Property1PrimaryProperty2";
import Property1usermessage from "../components/Property1usermessage";
import StateSmallText from "../components/StateSmallText";
import ScrollHolder from "../components/ScrollHolder";
import Property1Default from "../components/Property1Default";
import StateHoverIconno from "../components/StateHoverIconno";
import AfterCloseNo from "../components/AfterCloseNo";
import Editeditable from "../components/Editeditable";
import Property1NavigationPropert1 from "../components/Property1NavigationPropert1";
import Property1Default14 from "../components/Property1Default14";
import Property1Default13 from "../components/Property1Default13";
import Property1List from "../components/Property1List";
import Property1TextProperty2De from "../components/Property1TextProperty2De";
import Property1NavigationPropert from "../components/Property1NavigationPropert";
import Property1DefaultImage from "../components/Property1DefaultImage";
import Property1Secondary from "../components/Property1Secondary";
import Property1Default11 from "../components/Property1Default11";
import Property1Variant2 from "../components/Property1Variant2";
import StateDefaultEmptyYesChec1 from "../components/StateDefaultEmptyYesChec1";
import HoverDisabled from "../components/HoverDisabled";
import Editdefault from "../components/Editdefault";
import FormContainer4 from "../components/FormContainer4";
import StateDefaultNotificationno from "../components/StateDefaultNotificationno";
import FormContainer2 from "../components/FormContainer2";
import ContainerCardFormFilter2 from "../components/ContainerCardFormFilter2";
import Property1Default6 from "../components/Property1Default6";
import StateemptyScrollno from "../components/StateemptyScrollno";
import StateDefaultEmptyYesChec from "../components/StateDefaultEmptyYesChec";
import BalanceContainer from "../components/BalanceContainer";
import parse from 'html-react-parser';
import MainFrame from "../components/MainFrame";
import FrameContainer from "../components/FrameContainer";
import POP1 from "../components/POP1";
import POP2 from "../components/POP2";
import { Socket } from 'socket.io-client';
import moment from 'moment';
import 'moment-timezone';
import { useTranslation } from "react-i18next";

const Navigationmainb = styled.nav`
  margin: 0;
  background-color: var(--colors-dark-sidepanel);
  width: 16.38888888888889vw;
  height: 100.0vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) 0.0vw;
  box-sizing: border-box;
`;
const ChattextWrapper = styled.div`
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-5xsH) var(--padding-5xsW);
`;
const Chatinput = styled.div`
  position: absolute;
  top: -1.3020833333333335vh;
  left: 0.5555555555555556vw;
  width: 46.38888888888889vw;
  height: 85.546875vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;
const Chat = styled.section`
  position: relative;
  width: 47.5vw;
  height: 86.19791666666666vh;
  z-index: 2;
  display: flex
`;
const TabsLvl2 = styled.nav`
  margin: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const HereYouCan = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: inline-block;
  width: 47.5vw;
`;
const ButtonsecondaryParent = styled.div`
  width: 47.5vw;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
const InsideTests = styled.section`
  flex-shrink: 0;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
`;
const Checkbox = styled.input`
  margin: 0;
  position: relative;
  border-radius: var(--corner-radius-s);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 1.1805555555555556vw;
  height: 2.213541666666667vh;
  mix-blend-mode: normal;
`;
const ContactUs = styled.h3`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: inherit;
`;
const Code = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const CheckboxParent = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const RequiredInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  z-index: 1;
`;
const RequiredTermsAre = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--caption-cap-12-size);
  line-height: 2.604166666666667vh;
  z-index: 0;
`;
const Required = styled.div`
  align-self: stretch;
  border-radius: var(--br-5xs);
  background-color: var(--colors-green-light);
  width: 23.055555555555557vw;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) var(--padding-5xlW);
  box-sizing: border-box;
  gap: var(--gap-base);
`;
const Optional = styled.div`
  border-radius: var(--br-5xs);
  background-color: var(--colors-blue-1);
  width: 23.0555556vw;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) var(--padding-5xlW);
  box-sizing: border-box;
  gap: var(--gap-base);
`;
const Cards = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--body-message-size);
`;
const Questions1 = styled.div`
  align-self: stretch;
  position: relative;
  font-weight: 500;
`;
const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const QuestionsSectionCovers = styled.div`
  position: relative;
  font-size: var(--caption-cap-12-size);
  line-height: 2.604166666666667vh;
`;
const Questions = styled.div<{isVisible: boolean}>`
  border-radius: var(--br-5xs);
  background-color: var(--colors-white);
  width: 47.5vw;
  height: 6.770833333333333vh;
  display: ${(p)=> (p.isVisible?"flex":"none")};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-baseH) var(--padding-5xlW);
  box-sizing: border-box;
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const WordsChild = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 0.13020833333333331vh solid var(--colors-grey-bg);
  box-sizing: border-box;
  height: 0.13020833333333331vh;
`;
const Writing = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 2.604166666666667vh;
`;
const FrameWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
const FrameGroup = styled.div<{isVisible: boolean}>`
  align-self: stretch;
  display: ${(p)=> (p.isVisible?"flex":"none")};
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: var(--body-main-size);
`;
const Words = styled.div<{isVisible: boolean}>`
  border-radius: var(--br-5xs);
  background-color: var(--colors-white);
  width: 47.5vw;
  display: ${(p)=> (p.isVisible?"flex":"none")};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-baseH) var(--padding-5xlW);
  box-sizing: border-box;
  gap: var(--gap-base);
`;
const CardsParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const InsidePracticeInner = styled.div`
  width: 47.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--buttons-btn-input-size);
`;
const HereAreYour = styled.p`
  margin: 0;
  height: 38vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  };
`;
const HereAreYour2 = styled.p`
  margin: 0;
`;
const HereAreYourContainer = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: inline-block;
  width: 21.805555555555557vw;
  flex-shrink: 0;
`;
const HereAreYourActiveTermsAndParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
`;
const RequiredTerms = styled.div`
  position: relative;
  font-size: var(--buttons-btn-input-size);
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: var(--buttons-btn-input);
  color: var(--colors-dark-1);
  text-align: left;
`;
const Div = styled.div`
  position: relative;
  font-size: var(--caption-cap-12-size);
  line-height: 2.604166666666667vh;
  font-family: var(--buttons-btn-input);
  color: var(--colors-dark-1);
  text-align: left;
`;
const Wrapper = styled.div`
  border-radius: var(--br-81xl);
  background-color: var(--colors-grey-bg);
  --side: min(1.3888888888888888vw,2.604166666666667vh);
  width: var(--side);
  height: var(--side);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const MediumdropdownarrowIcon = styled.img<{IsRotated: boolean}>`
  position: relative;
  --side: min(1.3888888888888888vw,2.604166666666667vh);
  width: var(--side);
  height: var(--side);
  object-fit: cover;
  transform: ${({ IsRotated }) => IsRotated ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease;
  filter: var(--filters-purple-dark);
`;
const RequiredTermsParent = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const FrameWrapper2 = styled.div`
  align-self: stretch;
  background-color: var(--colors-white);
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-9xlW);
`;
const Layer1Icon = styled.img`
  position: relative;
  --side: min(1.25vw,2.34375vh);
  width: var(--side);
  height: var(--side);
  object-fit: contain;
`;
const Butttonrotate24x24 = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: var(--colors-grey-bg);
  margin: 0 !important;
  position: absolute;
  top: 1.0416666666666665vh;
  right: 0.57638888888vw;
  border-radius: var(--corner-radius-round);
  --side: min(1.6666666666666667vw,3.125vh);
  width: var(--side);
  height: var(--side);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
  & img {
    filter: var(--filters-purple-light);
  }
  &:hover {
    background-color: var(--colors-darkerblue);
    & img {
      filter: var(--filters-btn-txt);
    }
  }
`;
const Significado = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 2.604166666666667vh;
  z-index: 0;
`;
const RequiredTerm = styled.div`
  border-radius: var(--corner-radius-l);
  background-color: var(--colors-white);
  height: 17.708333333333336vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-21xlH) var(--padding-baseW) var(--padding-baseH);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  width: 14.4907638889vw;
`;
const RequiredTerm1 = styled.div`
  border-radius: var(--corner-radius-l);
  background-color: var(--colors-blue-1);
  height: 17.708333333333336vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-21xlH) var(--padding-baseW) var(--padding-baseH);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  width: 14.4907638889vw;
`;
const RequiredTermParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const RequiredTerms1 = styled.div<{isHidden: boolean}>`
  align-self: stretch;
  background-color: var(--colors-grey-bg);
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  display: ${(p) => (p.isHidden?'none':'flex')};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-baseH) var(--padding-baseW);
  gap: var(--gap-base);
`;
const Table1Parent = styled.div<{isHidden: boolean}>`
  align-self: stretch;
  background-color: var(--colors-grey-bg);
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  display: ${(p)=>(p.isHidden?"none":"flex")};
  flex-direction: column;
  & div[class] {
    align-self: center;
  }
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-baseH) 0.0vw;
  gap: var(--gap-5xl);
  font-size: var(--buttons-btn-input-size);
  & > h1 {
    align-self: center;
  }
  & > table {
    align-self: center;
    border-radius: var(--br-xs);
    & td {
      padding: var(--padding-3xsH) var(--padding-8xlW);
    }
  }
`;
const CustomLine = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const CardsArea = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
`;
const CustomLineParent = styled.div<{isHidden: boolean}>`
  align-self: stretch;
  background-color: var(--colors-grey-bg);
  display: ${(p)=>(p.isHidden?"none":"flex")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-baseH) var(--padding-baseW);
  gap: var(--gap-base);
`;
const FrameParent1 = styled.div`
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--body-main-size);
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 0;
  min-height: min-content;
`;
const InsideFlashcardsquestions = styled.section`
  width: 47.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  max-height: 80.9vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  };
`;
const TestFlashcards = styled.section`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  z-index: 2;
`;
const Articles = styled.div`
  width: 46.3888889vw;
  height: 78.515625vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.0vh var(--padding-xsW);
  box-sizing: border-box;
  z-index: 0;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const NewsImages = styled.div<{isVisible:boolean}>`
  width: 46.3888889vw;
  overflow: hidden;
  display: ${(p)=>(p.isVisible?"flex":"none")};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 1;
`;
const NewsList = styled.div<{isVisible:boolean}>`
  display: ${(p)=>(p.isVisible?"flex":"none")};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 0;
  overflow: hidden;
`;
const NewsVariants = styled.section`
  width: 47.5vw;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-9xlH) var(--padding-5xsW) var(--padding-baseH);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-base);
  z-index: 3;
`;
const Holder = styled.div`
  position: absolute;
  top: 4.166666666666666vh;
  left: 0.0vw;
  height: 79.16666666666666vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const NewsPersonalPage = styled.section<{isVisible:boolean}>`
  position: relative;
  width: 46.38888888888889vw;
  height: 83.33333333333334vh;
  z-index: 4;
  display: ${(p) => (p.isVisible?"flex":"none")};
`;
const Ls = styled.div`
  width: 49.166666666666664vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-77xlH) 0.0vw 0.0vh var(--padding-5xlW);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-5xs);
`;
const Hola = styled.h3`
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: inherit;
`;
const HolaParent = styled.div`
  position: absolute;
  top: 1.953125vh;
  left: 0.0vw;
  width: 27.77777777777778vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const HolaContainer = styled.div`
  position: absolute;
  top: 9.244791666666668vh;
  left: 0.0vw;
  font-size: var(--buttons-btn-input-size);
  line-height: 2.604166666666667vh;
  display: inline-block;
  width: 27.7777778vw;
`;
const TabMeaning = styled.section`
  position: relative;
  width: 27.7777778vw;
  height: 19.661458333333336vh;
  z-index: 1;
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  display: flex;
`;
const TabGrammar = styled.section`
  position: relative;
  width: 27.7777778vw;
  height: 47.78645833333333vh;
  display: none
`;
const TabVideo = styled.section`
  position: relative;
  width: 27.7777778vw;
  height: 50.78125vh;
  overflow: hidden;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  display: none
`;
const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 27.77777777777778vw; // Adjust as needed
  height: 50.78125vh; // Adjust as needed
  overflow: hidden;
`;

const VideoSection = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;
const UnderlinedWordsAre = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: inline-block;
  width: 27.7777778vw;
`;
const InputsArea = styled.div`
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
`;
const ApplyDelete = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const CustomLine1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
`;
const FlashCardSmallParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 27vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  };
`;
const CustomLineGroup = styled.div`
  width: 27.7777778vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const TabFlashcards = styled.section`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-miniH) 0.0vw 0.0vh;
  gap: var(--gap-5xl);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
`;
const TabsChange = styled.div`
  border-radius: var(--corner-radius-xl);
  background-color: var(--colors-white);
  width: 31.11111111111111vw;
  height: 57.8125vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-42xlH) var(--padding-5xsW) var(--padding-5xsH);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-4xs);
`;
const Rs = styled.div`
  width: 33vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) var(--padding-5xlW) 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
`;
const Page1Study = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Tabnavigation = styled.nav`
  margin: 0;
  align-self: stretch;
  border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 0;
`;
const TabsFixed = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-5xsW);
  z-index: 0;
`;
const Page3TeachParent = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) 1vw 0vh;
  gap: var(--gap-21xl);
`;
const ContactForm = styled.div`
  position: absolute;
  top: 3.125vh;
  left: 50.83333333333333vw;
  border-radius: var(--br-base);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 31.11111111111111vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) var(--padding-5xlW);
  gap: var(--gap-5xl);
`;
const Email1 = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10.416666666666668vw;
  height: 4.6875vh;
  flex-shrink: 0;
`;
const Guest7fzlmuyn1700651105 = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 14.652777777777779vw;
  height: 4.6875vh;
  flex-shrink: 0;
`;
const Email = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) 0.0vw;
  gap: var(--gap-5xl);
`;
const FieldsChild = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  height: 0.13020833333333331vh;
`;
const TextfieldsmallParent = styled.div`
  flex: 1;
  height: 4.6875vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const Russian = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Utc0200Parent = styled.div`
  flex: 1;
  height: 4.6875vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const TextfieldsmallWrapper = styled.div`
  flex: 1;
  height: 4.6875vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const YourDisplayName = styled.div`
  position: absolute;
  margin: 0 !important;
  top: 4.6875vh;
  left: 0.0vw;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  z-index: 0;
`;
const YourDisplayNameWillBeVisiParent = styled.div`
  flex: 1;
  height: 4.6875vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-xs);
  font-size: var(--caption-cap-12-size);
`;
const Fields = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const SmalldeleteIcon = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const SaveSession = styled.div`
  position: relative;
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
  font-weight: 500;
  font-family: var(--buttons-btn-input);
  color: var(--colors-red);
  text-align: left;
`;
const Buttonsecondary = styled.button`
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-red);
  padding: 0.0vh var(--padding-xsW);
  background-color: transparent;
  border-radius: var(--br-xs);
  box-sizing: border-box;
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  cursor: pointer;
  & img {
    filter: var(--filters-red);
  }
  &:hover {
    background-color: var(--colors-red);
    & div {
      color: var(--colors-white);
    }
    & img {
      filter: var(--filters-btn-txt);
    }
  }
`;
const Container1 = styled.div`
  position: absolute;
  top: 3.125vh;
  left: 1.6666666666666667vw;
  border-radius: var(--br-xs);
  width: 47.5vw;
  height: 93.75vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  font-size: var(--body-main-size);
`;
const Page4Profile = styled.section`
  position: relative;
  width: 83.61111111111111vw;
  height: 100.0vh;
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  display: none
`;
const BlocksOfPages = styled.main`
  width: 83.61111111111111vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const LayoutBlocksLightRoot = styled.div`
  position: relative;
  background-color: var(--colors-grey-bg);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  & ::selection {
    background: var(--colors-green);
  }
`;

function adjustRange(range: Range) {
  if (!range.collapsed && (range.startContainer.nodeType !== Node.TEXT_NODE || range.endContainer.nodeType !== Node.TEXT_NODE)) {
    // Clone the range to avoid directly modifying the original selection.
    range = range.cloneRange();

    // Adjust the start of the range
    if (range.startContainer.nodeType !== Node.TEXT_NODE) {
      let startNode = range.startContainer.childNodes[range.startOffset] || range.startContainer.firstChild;
      while (startNode && startNode.nodeType !== Node.TEXT_NODE && startNode.firstChild) {
        startNode = startNode.firstChild;
      }
      if (startNode) {
        range.setStart(startNode, 0);
      }
    }

    // Adjust the end of the range
    if (range.endContainer.nodeType !== Node.TEXT_NODE) {
      let endNode = range.endContainer.childNodes[range.endOffset] || range.endContainer.lastChild;
      while (endNode && endNode.nodeType !== Node.TEXT_NODE && endNode.lastChild) {
        endNode = endNode.lastChild;
      }
      if (endNode && endNode.textContent) {
        range.setEnd(endNode, endNode.textContent.length);
      }
    }
  }
  return range;
}

function getWWFromSelection() {
  const sel = window.getSelection();
  if (!sel || !sel.rangeCount) return "";

  const range = sel.getRangeAt(0).cloneRange();
  const terminators = [".", "!", "?", " ", ":", ","];

  // Expanding the range backwards until a sentence terminator is found
  while (range.startOffset > 0 && !terminators.includes(range.toString().charAt(0))) {
    range.setStart(range.startContainer, range.startOffset - 1);
  }

  // Expanding the range forward until a sentence terminator is found
  while (range && range.endContainer.textContent && range.endOffset < range.endContainer.textContent.length && !terminators.includes(range.toString().slice(-1))) {
    range.setEnd(range.endContainer, range.endOffset + 1);
  }

  // Extract the content from the range and trim any leading terminators
  let result = range.toString().trim();
  while (terminators.includes(result.charAt(0))) {
    result = result.slice(1).trim();
  }

  return result;
}

const RightClickMenu: React.FC<{
  MeaningB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  GrammarB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  VideoB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  FlashcardsB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  TranslateB: (e: React.MouseEvent<HTMLElement>,inp:string,ww:string) => void;
  AudioB: (e: React.MouseEvent<HTMLElement>,inp:string) => void;
  hv: boolean;
}> = ( {
  MeaningB,
  GrammarB,
  VideoB,
  FlashcardsB,
  TranslateB,
  AudioB,
  hv,
} ) => {
  // State for managing the visibility and position of the context menu
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [menuPosition, setMenuPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [currentSelection, setCurrentSelection] = useState<string>('');
  const [ww, setWW] = useState<string>('');
  const [isPhrase, setIsPhrase] = useState<boolean>(false);

  const menuRef = useRef<HTMLDivElement>(null);

  // Hide dropdowns
  const hideDropdowns = () => {
    setMenuVisible(false);
    setCurrentSelection('');
  };

  // Handle right-click event
  useEffect(() => {
    const handleRightClick = (event: MouseEvent) => {
      if (!document.getElementById("Interactive-Element")?.contains(event.target as Node)) return;
      try {
        const selection = document.getSelection();
        if (selection && selection.rangeCount > 0) {
          let range = selection.getRangeAt(0);
          if (!range.collapsed) {
            // Expand selection to encompass complete words or sentences
            expandSelection(range);
          } else {
            // Attempt to create a new selection at the right-click position
            const caretPosition = (document as any).caretPositionFromPoint(event.clientX, event.clientY);
            let range: Range | null = null;
            if (caretPosition) {
              range = document.createRange();
              range.setStart(caretPosition.offsetNode, caretPosition.offset);
              range.setEnd(caretPosition.offsetNode, caretPosition.offset);
            } else {
              // Fallback for browsers that still support caretRangeFromPoint
              const caretRange = document.caretRangeFromPoint(event.clientX, event.clientY);
              if (caretRange) {
                range = caretRange;
              }
            }
            const newRange = range;
            if (newRange) {
              expandSelection(newRange);
              selection.removeAllRanges();
              selection.addRange(newRange);
            }
          }
        }

        setMenuVisible(true);
        setMenuPosition({ x: event.clientX, y: event.clientY });
        setCurrentSelection(selection?.toString() || '');
        setWW(getWWFromSelection());
        setIsPhrase((selection?.toString() || '').split(' ').length > 1);
      } catch (error) {
        return
      }
      event.preventDefault();
    };

    const expandSelection = (range: Range) => {
      range=adjustRange(range)
      const startNodeText = range.startContainer.textContent || '';
      const endNodeText = range.endContainer.textContent || '';
      let start = range.startOffset;
      let end = range.endOffset;

      // Expand the start of the range to the beginning of the word
      while (start > 0 && !startNodeText[start - 1].match(/\s/)) {
        start--;
      }

      // Expand the end of the range to the end of the word
      while (end < endNodeText.length && !endNodeText[end].match(/\s/)) {
        end++;
      }

      // Adjust the range to the new start and end points
      range.setStart(range.startContainer, start);
      range.setEnd(range.endContainer, end);
    };
    let a=document.getElementById("Interactive-Element")

    if (a){a.addEventListener('contextmenu', handleRightClick)};

    return () => {
      if (a){a.removeEventListener('contextmenu', handleRightClick)};
    };
  }, []);

  // Click outside to hide the menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        hideDropdowns();
      }
    };
    let a=document.getElementById("Interactive-Element")
    if (a){document.addEventListener('mousedown', handleClickOutside)};

    return () => {
      if (a){document.removeEventListener('mousedown', handleClickOutside);}
    };
  }, []);

  return (
    <div ref={menuRef} style={{ position: 'relative' }}>
      {menuVisible && (
        <div
          style={{
            position: 'absolute',
            top: `${menuPosition.y}px`,
            left: `calc(${menuPosition.x}px - 16.3888889vw)`,
            padding: '1.3020833333333335vh',
            zIndex: 1000,
          }}
        >
          {isPhrase ? (
            <POP1 onHide={hideDropdowns} ww={ww} selection={currentSelection} TranslateB={TranslateB} VideoB={VideoB} AudioB={AudioB} hv={hv} />
          ) : (
            <POP2 onHide={hideDropdowns} selection={currentSelection} MeaningB={MeaningB} GrammarB={GrammarB} VideoB={VideoB} FlashcardsB={FlashcardsB} AudioB={AudioB} hv={hv} />
          )}
        </div>
      )}
    </div>
  );
};

export function base64ToBlob(base64: string, mimeType: string): Blob {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

type TitleT = {
  datetime: string;
  language: string;
  categories: string[];
  title: string;
  content?: string;
  image?: string;
  video?: string;
  source: string;
  link: string;
  description?: string;
}

type inT ={from:'tutor'|'student';text:string}

type LBL = {
  ProfileData: Prof;
  News: TitleT[];
  FlashCardsIn: {id: number; frontContent:string; backContent:string}[];
  HTMLs: string[];
  texts: inT[];
  darkMode: boolean;
  switchDarkMode: ()=>void;
  socket: Socket;
  AllLangs:string[];
  NL: string;
  LL: string;
  P: string;
  Ylang: string;
  hv: boolean;
  Acts: {
    id: number;
    language: string;
    tutorName: string;
    target: string;
    deadline: string;
    tutorCode: string;
    act:boolean;
  }[];
}
let sInited=false
let isPlaying=false
let audioQueue:{blob: Blob;text: string;}[]=[];

type Prof = {
  Email: string;
  TZ: string;
  Lang: string;
  Curr: string;
  currLVL: string;
}

const getAllTimezonesWithOffsets = (): string[] => {
  const timeZoneNames: string[] = moment.tz.names();
  let uniqueOffsets: Set<string> = new Set();

  timeZoneNames.forEach(zone => {
      const offset: string = moment.tz(zone).format('Z');
      uniqueOffsets.add(`UTC${offset}`);
  });

  return Array.from(uniqueOffsets);
};
function compareUTCOffsets(a: string, b: string): number {
  // Extracting the sign, hours, and minutes from the UTC string
  const matchA = a.match(/([+-])(\d{2}):(\d{2})/);
  const matchB = b.match(/([+-])(\d{2}):(\d{2})/);

  if (!matchA || !matchB) {
    throw new Error('Invalid UTC offset format');
  }

  const [, signA, hoursA, minutesA] = matchA;
  const [, signB, hoursB, minutesB] = matchB;

  // Convert hours and minutes to a single value (in minutes) for easy comparison
  const offsetA = parseInt(hoursA, 10) * 60 + parseInt(minutesA, 10);
  const offsetB = parseInt(hoursB, 10) * 60 + parseInt(minutesB, 10);

  // Adjust based on the sign
  const totalA = (signA === '+' ? 1 : -1) * offsetA;
  const totalB = (signB === '+' ? 1 : -1) * offsetB;

  return totalA - totalB;
}
const utcOffsets = getAllTimezonesWithOffsets().sort(compareUTCOffsets);

function downloadElementWithStyles(elementId: string): void {
  const element = document.getElementById(elementId);

  if (!element) {
    console.error(`Element with ID ${elementId} not found.`);
    return;
  }

  // Create a new document
  const doc = document.implementation.createHTMLDocument("New Document");

  // Clone the element and its parents
  let currentElement: HTMLElement | null = element;
  let prevElement: HTMLElement | null = null;
  let first=true
  while (currentElement) {
    if (first) {
      const clone = currentElement.cloneNode(first) as HTMLElement;
      first=false;
      prevElement=clone
      currentElement = currentElement.parentElement;
    } else {
      const clone = currentElement.cloneNode(first) as HTMLElement;
      prevElement && clone.insertBefore(prevElement,clone.firstChild)
      prevElement=clone
      currentElement = currentElement.parentElement;
    }
  }
  prevElement && doc.body.insertBefore(prevElement, doc.body.firstChild);

  // Retain styles
  const styleSheets = Array.from(document.styleSheets);
  styleSheets.forEach((styleSheet: CSSStyleSheet) => {
    try {
      if (styleSheet.cssRules) {
        const newStyleSheet = doc.createElement('style');
        Array.from(styleSheet.cssRules).forEach(rule => {
          newStyleSheet.appendChild(doc.createTextNode(rule.cssText));
        });
        doc.head.appendChild(newStyleSheet);
      }
    } catch (e) {
      console.warn('Could not access stylesheet: ', styleSheet, e);
    }
  });

  // Serialize the new document to a string
  const serializedDoc = new XMLSerializer().serializeToString(doc);

  // Create a Blob from the serialized string
  const blob = new Blob([serializedDoc], { type: 'text/html' });

  // Create a link element to trigger the download
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = 'filtered.html';
  a.style.display = 'none';

  document.body.appendChild(a);
  a.click();

  // Clean up
  document.body.removeChild(a);
  URL.revokeObjectURL(a.href);
}

const LayoutBlocksLight: FunctionComponent<LBL> = (InputData) => {
  const [widget, setWidget] = useState<any>(null);

  const onYouGlishAPIReady = () => {
    const newWidget = new (window as any).YG.Widget("Video", {
      width: 400, // Set width as needed
      components: 84 // Use appropriate component code
    });
    setWidget(newWidget);
  };

  useEffect(() => {
    const script = document.createElement('script');
    if (InputData.hv===false) {
      // Load the YouGlish widget script dynamically
      script.src = 'https://youglish.com/public/emb/widget.js';
      script.async = true;
      script.charset = 'utf-8';
      script.onload = () => onYouGlishAPIReady();
      document.body.appendChild(script);
    }
    return () => {
      document.body.removeChild(script);
    };
  }, [InputData.hv]);

  const CreateScrollConnection = (key: string) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const scrollRef = useRef<HTMLDivElement>(null);
    const siblingRef = useRef<HTMLDivElement>(null);
    const [scrollIndicatorHeight, setScrollIndicatorHeight] = useState<string>('0%');
    const [scrollIndicatorTop, setScrollIndicatorTop] = useState<string>('0%');

    const calculateScrollIndicator = (scrollElement: HTMLDivElement | null) => {
      if (scrollElement) {
        const visibleRatio = scrollElement.clientHeight / scrollElement.scrollHeight;
        const indicatorHeight = `${visibleRatio * 100}%`;
        const scrollPercent = scrollElement.scrollTop / (scrollElement.scrollHeight - scrollElement.clientHeight);
        const indicatorTop = `${scrollPercent * (100 - parseFloat(indicatorHeight))}%`;

        return { indicatorHeight, indicatorTop };
      }
      return { indicatorHeight: '0%', indicatorTop: '0%' };
    };

    useEffect(() => {
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        setScrollPosition(target.scrollTop);
        // Assuming this handles the scrollable content
        if (target.scrollHeight > target.clientHeight) {
          const visibleRatio = target.clientHeight / target.scrollHeight;
          const indicatorHeight = `${visibleRatio * 100}%`;
          const scrollPercent = target.scrollTop / (target.scrollHeight - target.clientHeight);
          const indicatorTop = `${scrollPercent * (100 - parseFloat(indicatorHeight))}%`;

          setScrollIndicatorHeight(indicatorHeight);
          setScrollIndicatorTop(indicatorTop);
        }
      };

      const scrollElement = siblingRef.current; // Assuming siblingRef is the scrollable element

      const intervalId = setInterval(() => {
        const scrollElement = siblingRef.current;
        if (scrollElement) {
          clearInterval(intervalId);
          // Element is now available, attach the event listener
          scrollElement.addEventListener('scroll', handleScroll);
          (async () => {
            await new Promise(f => setTimeout(f, 1));
            const scrollElement = siblingRef.current; // or scrollRef.current, depending on which you're targeting
            const { indicatorHeight, indicatorTop } = calculateScrollIndicator(scrollElement);
            setScrollIndicatorHeight(indicatorHeight);
            setScrollIndicatorTop(indicatorTop);
          })()
        }
      }, 100);

      return () => {
        clearInterval(intervalId);
        if (scrollElement) {
          scrollElement.removeEventListener('scroll', handleScroll);
        }
      };
    }, [key]);

    return { scrollRef, siblingRef, scrollIndicatorHeight, scrollIndicatorTop, recalculateScrollIndicator: async () => {
      await new Promise(f => setTimeout(f, 1));
      const scrollElement = siblingRef.current; // or scrollRef.current, depending on which you're targeting
      const { indicatorHeight, indicatorTop } = calculateScrollIndicator(scrollElement);
      setScrollIndicatorHeight(indicatorHeight);
      setScrollIndicatorTop(indicatorTop);
    }};
  };

  const p1 = CreateScrollConnection("All")
  const p2 = CreateScrollConnection("Category")
  const p3 = CreateScrollConnection("Title")

  const chatScroll = CreateScrollConnection("Chat")

  const MeanScroll = CreateScrollConnection("Mean")
  const GramScroll = CreateScrollConnection("Gram")
  const TScroll = CreateScrollConnection("T")
  const RSFCScroll = CreateScrollConnection("RSFC")
  const FQScroll = CreateScrollConnection("FQ")
  const MLAScroll = CreateScrollConnection("MLA")
  const TutorListScroll = CreateScrollConnection("TutorList")
  const AlertsScroll = CreateScrollConnection("Alerts")
  const AHistScroll = CreateScrollConnection("AHist")
  const TutorScroll = CreateScrollConnection("Tutor")
  const ParentScroll = CreateScrollConnection("Parent")
  const TutPageScroll = CreateScrollConnection("TutPage")

  const onTabPrimaryBClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const hideElement = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "none";
      }
    };
    const showElement = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "flex";
      }
    };

    showElement("ChatSection");
    hideElement("TFSection");
    hideElement("NewsSection");
    const element = e.currentTarget as HTMLElement;
    requestAnimationFrame(() => {
      if (element.parentElement) {
        const el = element.parentElement.querySelector(".selected")
        if (el) {
          // Remove the 'selected' class
          el.classList.remove("selected");
        }
        element.classList.add("selected")
      }
    });
  }, []);

  const onTabPrimaryB1Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      hideElement("ChatSection");
      showElement("TFSection");
      hideElement("NewsSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
      FQScroll.recalculateScrollIndicator();
    },
    [FQScroll]
  );

  const onTabPrimaryB2Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      hideElement("ChatSection");
      hideElement("TFSection");
      showElement("NewsSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );

  const onStopSessionClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    alert("stop");
  }, []);

  const onButtonDownloadClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      downloadElementWithStyles("ChatTextWrap")
    },
    []
  );

  const onClickVoice1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onClickVoice2 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );
  

  const onChipClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const hideElement = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "none";
      }
    };
    const showElement = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "flex";
      }
    };

    hideElement("FQSection");
    hideElement("PracticeSection");
    showElement("TestSection");
    const element = e.currentTarget as HTMLElement;
    requestAnimationFrame(() => {
      if (element.parentElement) {
        const el = element.parentElement.querySelector(".selected")
        if (el) {
          // Remove the 'selected' class
          el.classList.remove("selected");
        }
        element.classList.add("selected")
      }
    });
  }, []);

  const onChip1Click = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const hideElement = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "none";
      }
    };
    const showElement = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "flex";
      }
    };

    hideElement("FQSection");
    showElement("PracticeSection");
    hideElement("TestSection");
    const element = e.currentTarget as HTMLElement;
    requestAnimationFrame(() => {
      if (element.parentElement) {
        const el = element.parentElement.querySelector(".selected")
        if (el) {
          // Remove the 'selected' class
          el.classList.remove("selected");
        }
        element.classList.add("selected")
      }
    });
  }, []);

  const onChip2Click = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const hideElement = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "none";
      }
    };
    const showElement = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "flex";
      }
    };

    showElement("FQSection");
    hideElement("PracticeSection");
    hideElement("TestSection");
    const element = e.currentTarget as HTMLElement;
    requestAnimationFrame(() => {
      if (element.parentElement) {
        const el = element.parentElement.querySelector(".selected")
        if (el) {
          // Remove the 'selected' class
          el.classList.remove("selected");
        }
        element.classList.add("selected")
      }
    });
  }, []);

  const onButtonSecondaryClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      fetch('/CreateTopicTest',{method:'POST'}).then(response=>window.location.reload())
    },
    []
  );

  const onButtonSecondary1Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary2Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );
  const [IsCr, setIsCr] = useState(false)
  const onButtonSecondary4Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setIsCr(true)
    },
    []
  );

  let CatsA: string[] = [];
  // Equivalent of list comprehension with extend
  InputData.News.forEach(cat => {
    CatsA.push(...cat.categories);
  });
  // Removing duplicates by using a Set and converting it back to an array
  CatsA = Array.from(new Set(CatsA));
  let Cats: {name:string}[] = []
  CatsA.forEach(cat => {
    Cats.push({name:cat});
  });

  const [IsAllVisible, setIsAllVisible] = useState(true)
  const [DisplayedNews, setDisplayedNews] = useState<{item:TitleT,index:number}[]>([])
  const [isTitleDisplayed, setIsTitleDisplayed] = useState(false)//HERE
  const [DisplayedTitle, setDisplayedTitle] = useState<TitleT>({datetime: "",
    language: "",
    categories: [""],
    title: "",
    content: "",
    source: "",
    link: "",})

  const showAllCategory = useCallback(() => {
    setIsAllVisible(true)
    p1.recalculateScrollIndicator()
  },[p1])
  const showCategory = (name: string) => {
    setIsAllVisible(false)
    const newsWithIndexes = InputData.News.map((item, index) => ({
      item,
      index
    }));

    // Then, filter this array based on the category name
    let N = newsWithIndexes.filter(({ item }) => item.categories.includes(name));
    setDisplayedNews(N)
    p2.recalculateScrollIndicator()
  }

  const GenShowTitle = (index: number) => {
    return () => {
      setIsTitleDisplayed(true)
      setDisplayedTitle(InputData.News[index])
      p3.recalculateScrollIndicator()
    }
  }

  const hideTitle = useCallback(() => {
    setIsTitleDisplayed(false)
    p1.recalculateScrollIndicator()
    p2.recalculateScrollIndicator()
  },[p1, p2])

  const onChip3Click = useCallback((e: React.MouseEvent<HTMLElement>) => {
    showAllCategory()
    const element = e.currentTarget as HTMLElement;
    requestAnimationFrame(() => {
      if (element.parentElement) {
        const el = element.parentElement.querySelector(".selected")
        if (el) {
          // Remove the 'selected' class
          el.classList.remove("selected");
        }
        element.classList.add("selected")
      }
    });
  }, [showAllCategory]);

  const onBackButtonClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    hideTitle()
  }, [hideTitle]);

  const onBackButton1Click = useCallback((e: React.MouseEvent<HTMLElement>) => {
    hideTitle()
  }, [hideTitle]);

  const [RSSec, setRSSec] = useState<"MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection">("MeaningSection")

  const MeaningB = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection") => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
        setRSSec(id)
      };
  
      showElement("MeaningSection");
      hideElement("GrammarSection");
      hideElement("VideoSection");
      hideElement("FlashcardSection");
      hideElement("TranslateSection");

      let node = document.getElementById("GrammarWord")
      var W = document.getElementById("MeaningWord")
      if (node && node.innerText && W && !W.innerText) {
        let inp: string =node.innerText
        fetch('/GetExplanation', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({content: inp})
        })
        //.then(response => response.json())
        .then(async data => {
          var a = await data.text()
          var M = document.getElementById("MeaningMean")
          if (W && M) {
            W.innerText=inp
            M.innerText=a
          }
        });
      }
    },
    []
  );

  const GrammarB = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection") => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
        setRSSec(id)
      };
  
      hideElement("MeaningSection");
      showElement("GrammarSection");
      hideElement("VideoSection");
      hideElement("FlashcardSection");
      hideElement("TranslateSection");

      let node = document.getElementById("MeaningWord")
      var W = document.getElementById("GrammarWord")
      if (node && node.innerText && W && !W.innerText) {
        let inp: string =node.innerText
        fetch('/GetGrammar', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({content: inp})
        })
        //.then(response => response.json())
        .then(async data => {
          var a = await data.text()
          var M = document.getElementById("GrammarGram")
          if (W && M) {
            W.innerText=inp
            M.innerText=a
          }
        });
      }
    },
    []
  );

  const VideoB = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection") => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
        setRSSec(id)
      };
  
      hideElement("MeaningSection");
      hideElement("GrammarSection");
      showElement("VideoSection");
      hideElement("FlashcardSection");
      hideElement("TranslateSection");
    },
    []
  );

  const FlashcardsB = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection") => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
        setRSSec(id)
      };
  
      hideElement("MeaningSection");
      hideElement("GrammarSection");
      hideElement("VideoSection");
      showElement("FlashcardSection");
      hideElement("TranslateSection");
      RSFCScroll.recalculateScrollIndicator();
    },
    [RSFCScroll]
  );

  const TranslateB = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection") => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
        setRSSec(id)
      };
  
      hideElement("MeaningSection");
      hideElement("GrammarSection");
      hideElement("VideoSection");
      hideElement("FlashcardSection");
      showElement("TranslateSection");
    },
    []
  );
  const MeaningB2 = useCallback(
    (e: React.MouseEvent<HTMLElement>,inp:string) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection") => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
        setRSSec(id)
      };
  
      showElement("MeaningSection");
      hideElement("GrammarSection");
      hideElement("VideoSection");
      hideElement("FlashcardSection");
      hideElement("TranslateSection");

      fetch('/GetExplanation', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({content: inp})
      })
      //.then(response => response.json())
      .then(async data => {
        var a = await data.text()
        var W = document.getElementById("MeaningWord")
        var M = document.getElementById("MeaningMean")
        if (W && M) {
          W.innerText=inp
          M.innerText=a
        }
      });
    },
    []
  );

  const GrammarB2 = useCallback(
    (e: React.MouseEvent<HTMLElement>,inp:string) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection") => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
        setRSSec(id)
      };
  
      hideElement("MeaningSection");
      showElement("GrammarSection");
      hideElement("VideoSection");
      hideElement("FlashcardSection");
      hideElement("TranslateSection");

      fetch('/GetGrammar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({content: inp})
      })
      //.then(response => response.json())
      .then(async data => {
        var a = await data.text()
        var W = document.getElementById("GrammarWord")
        var M = document.getElementById("GrammarGram")
        if (W && M) {
          W.innerText=inp
          M.innerText=a
        }
      });
    },
    []
  );

  const VideoB2 = useCallback(
    (e: React.MouseEvent<HTMLElement>,inp:string) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection") => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
        setRSSec(id)
      };
  
      hideElement("MeaningSection");
      hideElement("GrammarSection");
      showElement("VideoSection");
      hideElement("FlashcardSection");
      hideElement("TranslateSection");
      widget.fetch(inp,InputData.Ylang);
    },
    [InputData.Ylang,widget]
  );

  const FlashcardsB2 = useCallback(
    (e: React.MouseEvent<HTMLElement>,inp:string) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection") => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
        setRSSec(id)
      };
  
      hideElement("MeaningSection");
      hideElement("GrammarSection");
      hideElement("VideoSection");
      showElement("FlashcardSection");
      hideElement("TranslateSection");
      RSFCScroll.recalculateScrollIndicator();
      fetch('/getFCDescr', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({content: inp})
      })
      .then(async response => await response.json())
      .then(data => {
        var n = data.word
        var d = data.descr
        var W = document.getElementById("termIn") as HTMLInputElement
        var M = document.getElementById("meaningIn") as HTMLInputElement
        if (W && M) {
          W.value=n
          M.value=d
        }
      });
    },
    [RSFCScroll]
  );

  const TranslateB2 = useCallback(
    (e: React.MouseEvent<HTMLElement>,inp:string,ww:string) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: "MeaningSection"|"GrammarSection"|"VideoSection"|"FlashcardSection"|"TranslateSection") => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
        setRSSec(id)
      };
  
      hideElement("MeaningSection");
      hideElement("GrammarSection");
      hideElement("VideoSection");
      hideElement("FlashcardSection");
      showElement("TranslateSection");
      fetch('/GetTranslate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({content: inp,ww: ww})
      })
      //.then(response => response.json())
      .then(async data => {
        var a = await data.text()
        var W = document.getElementById("TranslateWords")
        var M = document.getElementById("TranslateMean")
        if (W && M) {
          W.innerText=inp
          M.innerText=a
        }
      });
    },
    []
  );

  const [parentPVis, setParentPVis] = useState(false)

  const onTabPrimaryB6Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      showElement("TutorSection");
      hideElement("ParentSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
      setParentPVis(false);
    },
    []
  );

  const onTabPrimaryB7Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      hideElement("TutorSection");
      showElement("ParentSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
      ParentScroll.recalculateScrollIndicator();
      setParentPVis(true);
    },
    [ParentScroll]
  );

  const onButtonSecondary36Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      let dataToSend = {msgtype:"",msg:"",email:""};
      const msgtype = document.getElementById('msgtype') as HTMLSelectElement;
      const msg = document.getElementById('msg') as HTMLInputElement;
      const email = document.getElementById('EmailIn') as HTMLTextAreaElement;
      if (!email || email.value==="" || !msg || msg.value==="" || !msgtype) {
        alert("fill in Your email and message")
        return
      }
      dataToSend.msgtype=msgtype.value
      dataToSend.msg=msg.value
      dataToSend.email=email.value

      // Send the data using Fetch API
      fetch('/Contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend)
      })
      .then(response => {
      if(response.ok) {  // Check if the request was successful
        (document.getElementById('msg') as HTMLInputElement)!.value="";
        (document.getElementById('EmailIn') as HTMLTextAreaElement)!.value="";
      } else {
        console.error('Request failed.');
      }
    })
    .catch(error => {
        console.error('Error:', error);
    });
    },
    []
  );

  const [isRequiredTermsHidden, setIsRequiredTermsHidden] = useState(true);
  const [isRequiredTermsRotated, setIsRequiredTermsRotated] = useState(false);
  const ChangeHideRequiredTerms = () => {
    setIsRequiredTermsHidden(!isRequiredTermsHidden);
    setIsRequiredTermsRotated(!isRequiredTermsRotated);
    FQScroll.recalculateScrollIndicator();
  };
  const [isGrammaticalTablesHidden, setIsGrammaticalTablesHidden] = useState(true);
  const [isGrammaticalTablesRotated, setIsGrammaticalTablesRotated] = useState(false);
  const ChangeHideGrammaticalTables = () => {
    setIsGrammaticalTablesHidden(!isGrammaticalTablesHidden);
    setIsGrammaticalTablesRotated(!isGrammaticalTablesRotated);
    FQScroll.recalculateScrollIndicator();
  };
  const [isCustomTermsHidden, setIsCustomTermsHidden] = useState(true);
  const [isCustomTermsRotated, setIsCustomTermsRotated] = useState(false);
  const ChangeHideCustomTerms = () => {
    setIsCustomTermsHidden(!isCustomTermsHidden);
    setIsCustomTermsRotated(!isCustomTermsRotated);
    FQScroll.recalculateScrollIndicator();
  };

  const onButtonSecondary38Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const [FCWindowIndex, setFCWindowIndex] = useState(0)
  const [FCMain, setFCMain] = useState(InputData.FlashCardsIn);

  const handleRemoveItem2 = useCallback((itemId: string) => {
    let yes=window.confirm("Are You sure You want to Delete this Flashcard");
    if (!yes) {
      return
    }
    if (FCWindowIndex>=FCMain.length-1) {
      setFCWindowIndex(0)
    }
    let id=parseInt(itemId.split("Main")[1])
    fetch('/deleteFlashcard', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id})
    })
    .then(async response => await response.json())
    .then(data => {
      setFCListItems(currentItems => currentItems.filter(item => item !== id));
      setFCMain(currentItems => currentItems.filter(item => item.id !== id));
    })
    
  }, [FCMain.length,FCWindowIndex]);

  const handleEditItem = useCallback((itemId: string, newW: string, newD: string) => {
    let id=parseInt(itemId.split("Main")[1])
    fetch('/editFlashcard', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id, word: newW, descr: newD})
    })
    .then(async response => await response.json())
    .then(data => {
      setFCMain(currentItems => [...currentItems.filter(item => item.id !== id),{id: id,frontContent: newW,backContent: newD}]);
    })
    
  }, []);
  
  const [items, setFCListItems] = useState(Array.from(FCMain, (item, _) => item.id));

  const handleRemoveItem = useCallback((itemId: number) => {
    let yes=window.confirm("Are You sure You want to Delete this Flashcard");
    if (!yes) {
      return
    }
    if (FCWindowIndex>=FCMain.length-1) {
      setFCWindowIndex(0)
    }
    fetch('/deleteFlashcard', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: itemId})
    })
    .then(async response => await response.json())
    .then(data => {
      setFCListItems(currentItems => currentItems.filter(item => item !== itemId));
      setFCMain(currentItems => currentItems.filter(item => item.id !== itemId))
    })
  }, [FCMain.length,FCWindowIndex]);

  const onApplyDeleteClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    var W = document.getElementById("termIn") as HTMLInputElement
    var M = document.getElementById("meaningIn") as HTMLInputElement
    if (W && M) {
      let word = W.value
      let mean = M.value
      fetch('/addFlashcard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({word: word, descr: mean})
      })
      .then(async response => {
        let data=await response.json()
        setFCMain(Prev=>[...Prev,{id: data.id,frontContent: word,backContent: mean}])
        setFCListItems(Prev=>[...Prev,data.id])
        W.value=""
        M.value=""
      }
      )
    }
  }, []);
  const onApplyDeleteClick2 = useCallback((e: React.MouseEvent<HTMLElement>) => {
    var W = document.getElementById("TermIn") as HTMLInputElement
    var M = document.getElementById("MeaningIn") as HTMLInputElement
    if (W && M) {
      let word = W.value
      let mean = M.value
      fetch('/addFlashcard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({word: word, descr: mean})
      })
      .then(async response => {
        let data=await response.json()
        W.value=""
        M.value=""
        setIsCr(false)
        setFCMain(Prev=>[...Prev,{id: data.id,frontContent: word,backContent: mean}])
        setFCListItems(Prev=>[...Prev,data.id])
      }
      )
    }
  }, []);

  const [RequiredState, setRequiredState] = useState(false)
  const [OptionalState, setOptionalState] = useState(false)

  const [QuestionsState, setQuestionsState] = useState(false)
  const [WordsState, setWordsState] = useState(false)

  const [WritingState, setWritingState] = useState(false)
  const [SpeakingState, setSpeakingState] = useState(false)
  const [MultipleChoiceState, setMultipleChoiceState] = useState(false)

  const handleChangeRequiredState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequiredState(event.target.checked);
  };
  const handleChangeOptionalState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptionalState(event.target.checked);
  };

  const handleChangeQuestionsState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionsState(event.target.checked);
  };
  const handleChangeWordsState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWordsState(event.target.checked);
  };

  const handleChangeWritingState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWritingState(event.target.checked);
  };
  const handleChangeSpeakingState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSpeakingState(event.target.checked);
  };
  const handleChangeMultipleChoiceState = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMultipleChoiceState(event.target.checked);
  };

  const IsButtonActive = (RequiredState || OptionalState) && (QuestionsState || WordsState) && (!WordsState || (WordsState && (WritingState || SpeakingState || MultipleChoiceState)))

  const IsSecondLvlVisible = (RequiredState || OptionalState)

  const IsThirdLvlVisible = (RequiredState || OptionalState) && WordsState

  const [flippedCards, setFlippedCards] = useState<{[key: number]: boolean;}>({});
  const [flashcardsData, setFlashcardsData] = useState<{id: number; frontContent:string; backContent:string}[]>([]);
  
  // Function to toggle flip state
  const toggleFlip = (id: number) => {
    setFlippedCards((prevState: {[key: number]: boolean;}) => ({ ...prevState, [id]: !prevState[id] }));
  };

  // Split data into rows of 3 cards
  const rows = [];
  for (let i = 0; i < flashcardsData.length; i += 3) {
    rows.push(flashcardsData.slice(i, i + 3));
  }

  const [currFC, setCurrFC] = useState(0)
  const [isFront, setIsFront] = useState(true)
  const [isFCVisible, setIsFCVisible] = useState(false)

  const PrevFCPopUp = ()=>{
    setIsFront(true)
    setCurrFC(currFC-1<0?flashcardsData.length-1:currFC-1)
  }
  const NextFCPopUp = ()=>{
    setIsFront(true)
    setCurrFC(currFC+1>=flashcardsData.length?0:currFC+1)
  }

  const RotateFCPopUp = ()=>{
    setIsFront(!isFront)
  }

  const CreateOpenFCPopUp = (id: number)=>{
    return ()=>{
      setCurrFC(id)
      setIsFront(true)
      setIsFCVisible(true)
    }
  }
  const CloseFCPopUp = ()=>{
    setIsFront(true)
    setIsFCVisible(false)
  }

  const [FCIsOpen, setFCIsOpen] = useState(false)
  const [FCHasBeenOpened, setFCHasBeenOpened] = useState(false)
  const [isFrontWin, setIsFrontWin] = useState(true)

  const PrevFCWin = ()=>{
    setIsFrontWin(true)
    setFCWindowIndex(FCWindowIndex-1<0?FCMain.length-1:FCWindowIndex-1)
  }
  const NextFCWin = ()=>{
    setIsFrontWin(true)
    setFCWindowIndex(FCWindowIndex+1>=FCMain.length?0:FCWindowIndex+1)
  }

  const RotateFCWin = ()=>{
    setIsFrontWin(!isFrontWin)
  }

  const OpenFCWin = ()=>{
    setFCHasBeenOpened(true)
    setIsFrontWin(true)
    setFCIsOpen(true)
  }
  const CloseFCWin = ()=>{
    setIsFrontWin(true)
    setFCIsOpen(false)
  }

  const [isHoveredFCSound, setIsHoveredFCSound] = useState(false);

  const [Texts, setTexts] = useState(InputData.texts)

  const [currentA, setCurrentA] = useState<HTMLAudioElement|null>(null)

  const setAudioQueue = (a: {blob: Blob;text: string;}[])=> {
    audioQueue=a
  }
  const clearAudioQueue = ()=> {
    while (audioQueue.length>0) {
      audioQueue.pop()
    }
    setCurrentA(null)
  }

  const pauseA = useCallback(() => {
    if (currentA) {
        currentA.pause();
    }
  },[currentA]);
  const playA = useCallback(() => {
    if (currentA) {
      currentA.play().catch(error => {
        console.error('Failed to play audio:', error);
      });
    }
  },[currentA]);
  const stopA = () => {
    if (currentA) {
      currentA.pause();
      currentA.currentTime = currentA.duration;
      setCurrentA(null)
    }
  };

    
  const AudioB = useCallback(
    (e: React.MouseEvent<HTMLElement>,inp:string) => {
      fetch('/TextToSpeech', {method: 'POST', body:inp})
      .then(response => response.blob())
      .then(blob => {
        const audioUrl = URL.createObjectURL(blob);
        const audio = new Audio(audioUrl);

        audio.playbackRate = 1; // Set playback speed
        audio.onended = () => {
            playA()
        };
        pauseA()
        audio.play()
      })
    },
    [pauseA,playA]
  );

  const playNextAudio = () => {
    if (audioQueue.length === 0) return
    isPlaying=true;
    const nextAudio = audioQueue[0];
    const audioUrl = URL.createObjectURL(nextAudio.blob);
    const audio = new Audio(audioUrl);
    setCurrentA(audio)

    audio.playbackRate = 1; // Set playback speed

    audio.onended = () => {
        isPlaying=false;
        setAudioQueue(audioQueue.slice(1,audioQueue.length));
        if (audioQueue.length !== 0) {
          playNextAudio();
        } else {
          setCurrentA(null)
        }
    };

    setTexts((prevTexts) => {
      if (prevTexts.length > 0 && prevTexts[prevTexts.length - 1].from === 'tutor') {
        const updatedText = prevTexts[prevTexts.length - 1].text + nextAudio.text;
        return [
            ...prevTexts.slice(0, prevTexts.length - 1),
            { from: 'tutor', text: updatedText }
        ];
      } else {
        return [
            ...prevTexts,
            { from: 'tutor', text: nextAudio.text }
        ];
      }
    });

    // Assuming chatScroll is a utility function or object that handles scrolling
    chatScroll.recalculateScrollIndicator();

    audio.play();
  };

  if (!sInited) {
    sInited=true;
    InputData.socket.on('response', (data) => {
      setTexts((prevTexts) => {
        if (prevTexts.length > 0 && prevTexts[prevTexts.length - 1].from === 'tutor') {
          const updatedText = prevTexts[prevTexts.length - 1].text + data.delta;
          return [
              ...prevTexts.slice(0, prevTexts.length - 1),
              { from: 'tutor', text: updatedText }
          ];
        } else {
          return [
              ...prevTexts,
              { from: 'tutor', text: data.delta }
          ];
        }
      });

      // Assuming chatScroll is a utility function or object that handles scrolling
      chatScroll.recalculateScrollIndicator();
    });
    InputData.socket.on('stt_response', (data) => {
      setTexts((prevTexts) => {
        return [
            ...prevTexts,
            { from: 'student', text: data.text }
        ];
      });
    });
    InputData.socket.on('stt_edit', (data) => {
      let a=document.getElementById("InputField");
      a && (a.innerText=data.text)
    });
    InputData.socket.on('audio_response', (data) => {
      const audioData = data.audio;
      const text = data.delta;
      const audioBlob = base64ToBlob(audioData, 'audio/mp3');
      audioQueue.push({ "blob": audioBlob, "text": text })
      if (!isPlaying && audioQueue.length !== 0) {
        isPlaying=true;
        playNextAudio();
      }
    });
    InputData.socket.emit("message",{ "message": InputData.P });
  }

  const [isChatSetVis, setIsChatSetVis] = useState(false)
  const [isPushToTalk, setIsPushToTalk] = useState(false);
  const [isEditBeforeSend, setIsEditBeforeSend] = useState(false);
  
  const handlePushToTalkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPushToTalk(event.target.checked);
};

const handleEditBeforeSendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditBeforeSend(event.target.checked);
};
  const [CurrNL, setCurrNL] = useState(InputData.ProfileData.Lang);
  const [NL, setNLCode] = useState(InputData.NL);
  const [LL, setLLCode] = useState(InputData.LL);
  const [ProfLang, setProfLang] = useState(InputData.ProfileData.Lang);
  const [TZ, setTZ] = useState(InputData.ProfileData.TZ);
  const [Curr, setCurr] = useState(InputData.ProfileData.Curr);
  const { t, i18n } = useTranslation();

  return (
    <LayoutBlocksLightRoot>
      <Navigationmainb>
        <StudyContainer1
        MyGoalsScrollRel={MLAScroll.recalculateScrollIndicator}
        TPScrollRel={TutorScroll.recalculateScrollIndicator}
        switchDarkMode={InputData.switchDarkMode}
        darkMode={InputData.darkMode}
        />
      </Navigationmainb>
      <BlocksOfPages>
        <Page1Study id="StudySection">
          <RightClickMenu
            MeaningB={MeaningB2}
            GrammarB={GrammarB2}
            VideoB={VideoB2}
            FlashcardsB={FlashcardsB2}
            TranslateB={TranslateB2}
            AudioB={AudioB}
            hv={InputData.hv}
          />
          <Ls id="Interactive-Element">
            <Property1PrimaryProperty2
              smallChat="/Chat.svg"
              smallFlashCards="/TestFlash.svg"
              smallNews="/News.svg"
              largeStop="/Stop.svg"
              currLVL={InputData.ProfileData.currLVL}
              property1PrimaryProperty2Width="calc(100% - 2.7777777777777777vw)"
              property1PrimaryProperty2Margin="0 !important"
              property1PrimaryProperty2Position="absolute"
              property1PrimaryProperty2Top="3.125vh"
              property1PrimaryProperty2Right="0.5555555555555556vw"
              property1PrimaryProperty2Left="2.2222222222222223vw"
              property1PrimaryProperty2ZIndex="0"
              property1PrimaryProperty2BorderBottom="0.13020833333333331vh solid var(--colors-grey-stroke)"
              tabPrimaryBBorderBottom="unset"
              tabPrimaryBBoxSizing="border-box"
              tabPrimaryBCursor="pointer"
              tabPrimaryBBackgroundColor="transparent"
              smallChatIconOpacity="unset"
              flashCardsColor="var(--colors-dark-1)"
              flashCardsDisplay="inline-block"
              tabPrimaryBBoxSizing1="border-box"
              tabPrimaryBCursor1="pointer"
              tabPrimaryBBackgroundColor1="transparent"
              flashCardsDisplay1="inline-block"
              flashCardsColor1="var(--colors-dark-1)"
              tabPrimaryBBoxSizing2="border-box"
              tabPrimaryBCursor2="pointer"
              tabPrimaryBBackgroundColor2="transparent"
              flashCardsDisplay2="inline-block"
              flashCardsColor2="var(--colors-dark-1)"
              frameDivBorderBottom="0.13020833333333331vh solid var(--colors-grey-stroke)"
              sessionTime1hColor="var(--colors-purple-dark)"
              stopSessionCursor="pointer"
              stopSessionBorderW="none"
              stopSessionBorderC="none"
              stopSessionBorderS="none"
              stopSessionPadding="0"
              stopSessionBackgroundColor="var(--colors-blue-1)"
              onTabPrimaryBClick={onTabPrimaryBClick}
              onTabPrimaryB1Click={onTabPrimaryB1Click}
              onTabPrimaryB2Click={onTabPrimaryB2Click}
              onStopSessionClick={onStopSessionClick}
            />
            <Chat id="ChatSection">
              <Chatinput>
                <ChattextWrapper id="ChatTextWrap" >
                  <Property1usermessage
                    ref={chatScroll.siblingRef}
                    aquTienesLasContainerMargin="0"
                    aquTienesLasContainerFontWeight="400"
                    aquTienesLasContainerColor="var(--colors-dark-1)"
                    messageTextBackgroundColor="var(--colors-grey-stroke)"
                    porFavorMargin="0"
                    porFavorFontWeight="400"
                    porFavorColor="var(--colors-dark-1)"
                    punto1EnfoqueContainerMargin="0"
                    punto1EnfoqueContainerFontWeight="400"
                    punto1EnfoqueContainerColor="var(--colors-dark-1)"
                    inputs={Texts}
                  />
                </ChattextWrapper>
                <StateSmallText
                  frameDivOverflow="unset"
                  onButtonDownloadClick={onButtonDownloadClick}
                  onClickSend={()=>{
                    let a=document.getElementById("InputField");
                    if (a && a.innerText) {
                      clearAudioQueue();
                      InputData.socket.emit("message",{ "message": a.innerText });
                      a && (setTexts([...Texts, {from:'student', text:a.innerText}]))
                      chatScroll.recalculateScrollIndicator()
                    }
                  }}
                  onClickVoice1={onClickVoice1}
                  onClickVoice2={onClickVoice2}
                  clearAudioQueue={clearAudioQueue}
                  socket={InputData.socket}
                  pauseA={pauseA}
                  playA={playA}
                  stopA={stopA}
                  isPushToTalk={isPushToTalk}
                  isEditBeforeSend={isEditBeforeSend}
                  LL={LL}
                  NL={NL}
                />
              </Chatinput>
              <ScrollHolder
                scrollHeight={chatScroll.scrollIndicatorHeight}
                scrollIndicatorTop={chatScroll.scrollIndicatorTop}
                ref={chatScroll.scrollRef}
                scrollHolderHeight="77.08333333333334vh"
                scrollHolderAlignItems="unset"
                scrollHolderPosition="absolute"
                scrollHolderTop="0"
                scrollHolderLeft="46.80555555555556vw"
                scrollHolderMargin="unset"
                scrollHolderRight="unset"
                scrollHolderFlexShrink="unset"
                scrollHolderZIndex="unset"
                scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                scrollBackgroundColor="var(--colors-purple-light)"
              />
              <div style={{"position": "absolute","bottom": "calc(min(1.1111111111111112vw,2.083333333333333vh) * 2 )","left": "47.3vw"}}>
                <Property1Default11
                  largeVolumeEnable="/Gear.svg"
                  property1DefaultBorderW="none"
                  property1DefaultBorderC="none"
                  property1DefaultBorderS="none"
                  property1DefaultCursor="pointer"
                  property1DefaultPadding="0"
                  property1DefaultBackgroundColor="var(--colors-blue-1)"
                  onPlayAudioClick={()=>setIsChatSetVis(true)}
                />
              </div>
              {isChatSetVis && 
                <div
                  style={{
                    position: 'absolute',
                    top: `10vh`,
                    left: `5vw`,
                    width: `50vw`,
                    height: `50vh`,
                    padding: '1.3020833333333335vh',
                    zIndex: 99999999,
                    borderRadius: "var(--br-xs)",
                    backgroundColor: "var(--colors-white)",
                    boxShadow: "0vw 0.130208333vh 0.260416667vh var(--color-other-gray-200)",
                    borderWidth: "0.130208333vh 0.0694444444vw",
                    borderStyle: "solid",
                    borderColor: "var(--colors-grey-stroke)",
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button onClick={() => setIsChatSetVis(false)} style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}>
                      ✖
                    </button>
                  </div>
                  <div style={{ marginTop: '2vh', paddingLeft: '2vw' }}>
                    <label style={{ display: 'block', marginBottom: '1vh' }}>
                      <input 
                        type="checkbox" 
                        style={{ marginRight: '1vw' }} 
                        checked={isPushToTalk} 
                        onChange={handlePushToTalkChange} 
                      />
                      Push To Talk
                    </label>
                    <label style={{ display: 'block', marginBottom: '1vh' }}>
                      <input 
                        type="checkbox" 
                        style={{ marginRight: '1vw' }} 
                        checked={isEditBeforeSend} 
                        onChange={handleEditBeforeSendChange} 
                      />
                      Edit Before Send
                    </label>
                  </div>
                </div>
              }
              {isChatSetVis && <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'var(--color-black-350)', // 35% transparency
                backdropFilter: 'blur(2px)', // Background blur effect
                zIndex: 99999998, // Ensure it's below the sidebar but above everything else
              }} />}
            </Chat>
            <TestFlashcards id="TFSection">
              <TabsLvl2>
                <Property1Default
                  text={t("Tests")}
                  property1DefaultFlexShrink="0"
                  property1DefaultPadding="var(--padding-7xsH) var(--padding-xsW)"
                  property1DefaultBoxSizing="border-box"
                  property1DefaultCursor="pointer"
                  property1DefaultBorderW="none"
                  property1DefaultBorderC="none"
                  property1DefaultBorderS="none"
                  property1DefaultBackgroundColor="var(--colors-blue-1)"
                  allDisplay="inline-block"
                  allAlignItems="unset"
                  allJustifyContent="unset"
                  allWidth="max-content"
                  allHeight="1.5625"
                  allFlexShrink="unset"
                  allColor="var(--colors-dark-1)"
                  onChipClick={onChipClick}
                />
                <Property1Default
                  text={t("Practice")}
                  property1DefaultFlexShrink="0"
                  property1DefaultPadding="var(--padding-7xsH) var(--padding-xsW)"
                  property1DefaultBoxSizing="border-box"
                  property1DefaultCursor="pointer"
                  property1DefaultBorderW="none"
                  property1DefaultBorderC="none"
                  property1DefaultBorderS="none"
                  property1DefaultBackgroundColor="var(--colors-blue-1)"
                  allDisplay="inline-block"
                  allAlignItems="unset"
                  allJustifyContent="unset"
                  allWidth="max-content"
                  allHeight="1.5625"
                  allFlexShrink="unset"
                  allColor="var(--colors-dark-1)"
                  onChipClick={onChip1Click}
                />
                <Property1Default
                  text={t("FlashcardsQuestions")}
                  property1DefaultFlexShrink="0"
                  property1DefaultPadding="var(--padding-7xsH) var(--padding-xsW)"
                  property1DefaultBoxSizing="border-box"
                  property1DefaultCursor="pointer"
                  property1DefaultBorderW="none"
                  property1DefaultBorderC="none"
                  property1DefaultBorderS="none"
                  property1DefaultBackgroundColor="var(--colors-blue-1)"
                  allDisplay="inline-block"
                  allAlignItems="unset"
                  allJustifyContent="unset"
                  allWidth="max-content"
                  allHeight="1.5625"
                  allFlexShrink="unset"
                  allColor="var(--colors-dark-1)"
                  HasClass="selected"
                  onChipClick={onChip2Click}
                />
              </TabsLvl2>
              <InsideTests id="TestSection">
                <HereYouCan>
                  Here you can do next topic assessment Test, which includes
                  your required active terms and questions from the previous
                  topic you studied: you can jump straight to it without editing
                  Flashcards|Questions and without Practicing them, if you feel
                  sufficiently confident. You can also choose to test your
                  aptitude for the final test as per your Goal, just to get a
                  feel what stage of reaching your study Goal you are currently
                  at and to get comfortable with the format and timing of your
                  final test: Sample Final Test is updated regularly to ensure
                  you get as much practice with it as is necessary for you.
                </HereYouCan>
                <ButtonsecondaryParent>
                  <StateHoverIconno
                    saveSession={t("StartTopicTest")}
                    stateHoverIconnoJustifyContent="flex-start"
                    stateHoverIconnoWidth="unset"
                    stateHoverIconnoZIndex="unset"
                    stateHoverIconnoBackgroundColor="transparent"
                    stateHoverIconnoCursor="pointer"
                    stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                    stateHoverIconnoBorderC="var(--colors-darkerblue)"
                    stateHoverIconnoBorderS="solid"
                    stateHoverIconnoHeight="4.166666666666666vh"
                    stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                    stateHoverIconnoBoxSizing="border-box"
                    stateHoverIconnoPosition="unset"
                    stateHoverIconnoTop="unset"
                    stateHoverIconnoLeft="unset"
                    saveSessionColor="var(--colors-darkerblue)"
                    saveSessionDisplay="inline-block"
                    onButtonSecondaryClick={onButtonSecondaryClick}
                  />
                  {false && <StateHoverIconno
                    saveSession="Start Simple Final Test"
                    stateHoverIconnoJustifyContent="flex-start"
                    stateHoverIconnoWidth="unset"
                    stateHoverIconnoZIndex="unset"
                    stateHoverIconnoBackgroundColor="var(--colors-darkerblue)"
                    stateHoverIconnoCursor="pointer"
                    stateHoverIconnoBorderW="none"
                    stateHoverIconnoBorderC="none"
                    stateHoverIconnoBorderS="none"
                    stateHoverIconnoHeight="4.166666666666666vh"
                    stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                    stateHoverIconnoBoxSizing="border-box"
                    stateHoverIconnoPosition="unset"
                    stateHoverIconnoTop="unset"
                    stateHoverIconnoLeft="unset"
                    saveSessionColor="var(--colors-btn-txt)"
                    saveSessionDisplay="inline-block"
                    onButtonSecondaryClick={onButtonSecondary1Click}
                  />}
                </ButtonsecondaryParent>
              </InsideTests>
              <InsideTests id="PracticeSection">
                <HereYouCan>
                  Here you can Practice the required Flashcards and Questions,
                  as well as your Custom ones. Once you complete written and
                  spoken practice for the required terms and questions, this
                  will automatically be registered on the system and you will be
                  able to access your next Study topic without having to
                  complete the Test. If you feel sufficiently confident, you can
                  skip this stage and proceed to the Topic Test.
                </HereYouCan>
                <InsidePracticeInner>
                  <CardsParent>
                    <Cards>
                      <Required>
                        <RequiredInner>
                          <CheckboxParent>
                            <Checkbox type="checkbox" onChange={handleChangeRequiredState} />
                            <Code>
                              <ContactUs>Required</ContactUs>
                            </Code>
                          </CheckboxParent>
                        </RequiredInner>
                        <RequiredTermsAre>
                          “Required” terms are a mandatory part of studies.
                        </RequiredTermsAre>
                      </Required>
                      <Optional>
                        <RequiredInner>
                          <CheckboxParent>
                            <Checkbox type="checkbox" onChange={handleChangeOptionalState} />
                            <Code>
                              <ContactUs>Optional</ContactUs>
                            </Code>
                          </CheckboxParent>
                        </RequiredInner>
                        <RequiredTermsAre>
                          “Custom” terms are added by the student and are not
                          mandatory
                        </RequiredTermsAre>
                      </Optional>
                    </Cards>
                    <Questions isVisible={IsSecondLvlVisible}>
                      <CheckboxContainer>
                        <Checkbox type="checkbox" onChange={handleChangeQuestionsState} />
                        <Code>
                          <Questions1>Questions</Questions1>
                        </Code>
                      </CheckboxContainer>
                      <QuestionsSectionCovers>
                        “Questions” section covers grammar-related tables from
                        last lesson
                      </QuestionsSectionCovers>
                    </Questions>
                    <Words isVisible={IsSecondLvlVisible}>
                      <FrameParent>
                        <CheckboxParent>
                          <Checkbox type="checkbox" onChange={handleChangeWordsState} />
                          <Code>
                            <Questions1>Words</Questions1>
                          </Code>
                        </CheckboxParent>
                        <QuestionsSectionCovers>
                          “Words” section covers the terms (vocabulary) from
                          last lesson
                        </QuestionsSectionCovers>
                      </FrameParent>
                      <WordsChild />
                      <FrameGroup isVisible={IsThirdLvlVisible}>
                        <FrameWrapper>
                          <CheckboxParent>
                            <Checkbox type="checkbox" onChange={handleChangeWritingState} />
                            <Code>
                              <Writing>Writing</Writing>
                            </Code>
                          </CheckboxParent>
                        </FrameWrapper>
                        <FrameWrapper>
                          <CheckboxParent>
                            <Checkbox type="checkbox" onChange={handleChangeSpeakingState} />
                            <Code>
                              <Writing>Speaking</Writing>
                            </Code>
                          </CheckboxParent>
                        </FrameWrapper>
                        <FrameWrapper>
                          <CheckboxParent>
                            <Checkbox type="checkbox" onChange={handleChangeMultipleChoiceState} />
                            <Code>
                              <Writing>Multiple Choice</Writing>
                            </Code>
                          </CheckboxParent>
                        </FrameWrapper>
                      </FrameGroup>
                    </Words>
                  </CardsParent>
                </InsidePracticeInner>
                <StateHoverIconno
                  isActive={IsButtonActive}
                  saveSession="Start Practice"
                  stateHoverIconnoJustifyContent="flex-start"
                  stateHoverIconnoWidth="unset"
                  stateHoverIconnoZIndex="unset"
                  stateHoverIconnoBackgroundColor="transparent"
                  stateHoverIconnoCursor="pointer"
                  stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                  stateHoverIconnoBorderC="var(--colors-darkerblue)"
                  stateHoverIconnoBorderS="solid"
                  stateHoverIconnoHeight="4.166666666666666vh"
                  stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                  stateHoverIconnoBoxSizing="border-box"
                  stateHoverIconnoPosition="unset"
                  stateHoverIconnoTop="unset"
                  stateHoverIconnoLeft="unset"
                  saveSessionColor="var(--colors-darkerblue)"
                  saveSessionDisplay="inline-block"
                  onButtonSecondaryClick={onButtonSecondary2Click}
                />
              </InsideTests>
              <InsideFlashcardsquestions id="FQSection" ref={FQScroll.siblingRef}>
                <HereAreYourActiveTermsAndParent>
                  <HereAreYourContainer>
                    <HereAreYour2>
                      Here are your active terms and questions.
                    </HereAreYour2>
                    <HereAreYour2>
                      Some are required before you can proceed to studying the
                      next topic, and you can add your own Custom terms and
                      questions as you deem appropriate: they will appear during
                      Practice (and will be added to the interval memorization
                      plan), but will not go into assessment Test. You can
                      Practice these or, if you feel sufficiently confident, you
                      can proceed straight to Test.
                    </HereAreYour2>
                  </HereAreYourContainer>
                  {
                    FCMain.length>0 && (FCIsOpen?
                    (
                      isFrontWin?
                      <div
                        style={{
                          width: "22.916666666666664vw",
                          height: "20.833333333333336vh",
                          position: "relative",
                          borderRadius: "16px",
                          backgroundColor: "var(--colors-white)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "2.083333333333333vh 0.0vw",
                          boxSizing: "border-box",
                          textAlign: "left",
                          fontSize: "1.5625vh",
                          color: "var(--color-gray-400)",
                          fontFamily: "Onest",
                        }}
                      >
                        <div
                          style={{
                            height: "1.5625vh",
                            margin: "0 !important",
                            position: "absolute",
                            top: "1.3020833333333335vh",
                            left: "0.6944444444444444vw",
                            overflow: "hidden",
                            flexShrink: "0",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.1388888888888889vw",
                          }}
                        >
                          <div style={{position: "relative", lineHeight: "2.604166666666667vh"}}>{FCWindowIndex+1}</div>
                          /
                          <div style={{position: "relative", lineHeight: "2.604166666666667vh"}}>{FCMain.length}</div>
                        </div>
                        <Butttonrotate24x24 onClick={RotateFCWin} style={{right:"2.59027778vw"}}>
                          <Layer1Icon alt="" src="/RotateFC.svg" />
                        </Butttonrotate24x24>
                        <Butttonrotate24x24 onClick={CloseFCWin}>
                          <Layer1Icon alt="" src="/mediumclose1.svg" />
                        </Butttonrotate24x24>
                        <div
                          style={{
                            position: "relative",
                            fontSize: "2.083333333333333vh",
                            lineHeight: "2.604166666666667vh",
                            color: "var(--colors-dark-1)",
                          }}
                        >
                          {FCMain[FCWindowIndex].frontContent}
                        </div>
                        <div
                          style={{
                            height: "5.208333333333334vh",
                            borderRadius: "12px",
                            backgroundColor: isHoveredFCSound?"var(--colors-darkerblue)":"transparent",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "0.5208333333333333vh 0.625vw",
                            boxSizing: "border-box",
                            borderColor: "var(--colors-darkerblue)",
                            borderStyle: "solid",
                            borderWidth: "0.13020833333333331vh",
                            cursor: "pointer",
                          }}
                          onMouseEnter={() => setIsHoveredFCSound(true)}
                          onMouseLeave={() => setIsHoveredFCSound(false)}
                          onClick={()=>alert("Sound!")}
                        >
                          <img
                            style={{width: "1.52777778vw", height: "2.7734375vh", position: "relative", filter: isHoveredFCSound?"var(--filters-btn-txt)":"var(--filters-darkerblue)"}}
                            loading="eager"
                            alt=""
                            src="/FCSound.svg"
                          />
                        </div>
                        <div
                          style={{
                            width: "14.166666666666666vw",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            padding: "0.0vh 1.3888888888888888vw",
                            boxSizing: "border-box",
                            gap: "0.2777777777777778vw",
                          }}
                        >
                          <button
                            style={{
                              cursor: "pointer",
                              border: "none",
                              padding: "0.26041666666666663vh 0.0vw",
                              backgroundColor: "var(--colors-grey-stroke)",
                              flex: "1",
                              borderRadius: "8px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "0.4166666666666667vw",
                            }}
                            onClick={PrevFCWin}
                          >
                            <div
                              style={{
                                position: "relative",
                                fontSize: "1.5625vh",
                                lineHeight: "2.604166666666667vh",
                                fontFamily: "Onest",
                                color: "var(--colors-dark-1)",
                                textAlign: "left",
                              }}
                            >
                              {`<`} Previous
                            </div>
                          </button>
                          <button
                            style={{
                              cursor: "pointer",
                              border: "none",
                              padding: "0.26041666666666663vh 0.0vw",
                              backgroundColor: "var(--colors-grey-stroke)",
                              flex: "1",
                              borderRadius: "8px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "0.4166666666666667vw",
                            }}
                            onClick={NextFCWin}
                          >
                            <div
                              style={{
                                position: "relative",
                                fontSize: "1.5625vh",
                                lineHeight: "2.604166666666667vh",
                                fontFamily: "Onest",
                                color: "var(--colors-dark-1)",
                                textAlign: "left",
                              }}
                            >
                              Next {`>`}
                            </div>
                          </button>
                        </div>
                      </div>
                      :
                      <div
                        style={{
                          width: "22.916666666666664vw",
                          height: "20.833333333333336vh",
                          position: "relative",
                          borderRadius: "16px",
                          backgroundColor: "var(--colors-white)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-between",
                          padding: "4.6875vh 1.1111111111111112vw 2.083333333333333vh",
                          boxSizing: "border-box",
                          textAlign: "center",
                          fontSize: "2.083333333333333vh",
                          color: "var(--colors-dark-1)",
                          fontFamily: "Onest",
                        }}
                      >
                        <Butttonrotate24x24 onClick={RotateFCWin} style={{right:"2.59027778vw"}}>
                          <Layer1Icon alt="" src="/RotateFC.svg" />
                        </Butttonrotate24x24>
                        <Butttonrotate24x24 onClick={CloseFCWin}>
                          <Layer1Icon alt="" src="/mediumclose1.svg" />
                        </Butttonrotate24x24>
                        <div style={{alignSelf: "stretch", position: "relative", lineHeight: "2.604166666666667vh"}}>
                          <p style={{margin: "0"}}>{FCMain[FCWindowIndex].backContent}</p>
                        </div>
                        <div
                          style={{
                            width: "11.38888888888889vw",
                            backgroundColor: "var(--colors-white)",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "0.2777777777777778vw",
                          }}
                        >
                          <button
                            style={{
                              cursor: "pointer",
                              border: "none",
                              padding: "0.26041666666666663vh 0.0vw",
                              backgroundColor: "var(--colors-grey-stroke)",
                              flex: "1",
                              borderRadius: "8px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "0.4166666666666667vw",
                            }}
                            onClick={PrevFCWin}
                          >
                            <div
                              style={{
                                position: "relative",
                                fontSize: "1.5625vh",
                                lineHeight: "2.604166666666667vh",
                                fontFamily: "Onest",
                                color: "var(--colors-dark-1)",
                                textAlign: "left",
                              }}
                            >
                              {`<`} Previous
                            </div>
                          </button>
                          <button
                            style={{
                              cursor: "pointer",
                              border: "none",
                              padding: "0.26041666666666663vh 0.0vw",
                              backgroundColor: "var(--colors-grey-stroke)",
                              flex: "1",
                              borderRadius: "8px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "0.4166666666666667vw",
                            }}
                            onClick={NextFCWin}
                          >
                            <div
                              style={{
                                position: "relative",
                                fontSize: "1.5625vh",
                                lineHeight: "2.604166666666667vh",
                                fontFamily: "Onest",
                                color: "var(--colors-dark-1)",
                                textAlign: "left",
                              }}
                            >
                              Next {`>`}
                            </div>
                          </button>
                        </div>
                        <div
                          style={{
                            height: "1.5625vh",
                            margin: "0 !important",
                            position: "absolute",
                            top: "1.3020833333333335vh",
                            left: "0.6944444444444444vw",
                            overflow: "hidden",
                            flexShrink: "0",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.1388888888888889vw",
                            textAlign: "left",
                            fontSize: "1.5625vh",
                            color: "var(--color-gray-400)",
                          }}
                        >
                          <div style={{position: "relative", lineHeight: "2.604166666666667vh"}}>{FCWindowIndex+1}</div>
                          /
                          <div style={{position: "relative", lineHeight: "2.604166666666667vh"}}>{FCMain.length}</div>
                        </div>
                      </div>
                    )
                    :
                    <AfterCloseNo
                      total={FCHasBeenOpened?String(FCWindowIndex+1)+" / "+String(FCMain.length):String(FCMain.length)+" Total"}
                      afterCloseNoBackgroundColor="var(--colors-white)"
                      flashArdsColor="var(--colors-dark-1)"
                      flashArdsMargin="0"
                      totalColor={FCHasBeenOpened?"var(--color-gray-400)":"var(--colors-dark-1)"}
                      buttonSecondaryCursor="pointer"
                      buttonSecondaryBackgroundColor="var(--colors-darkerblue)"
                      saveSessionDisplay="inline-block"
                      onButtonSecondary3Click={OpenFCWin}
                    />
                  )}
                </HereAreYourActiveTermsAndParent>
                <FrameParent1>
                  <FrameWrapper2>
                    <RequiredTermsParent onClick={ChangeHideRequiredTerms}>
                      <RequiredTerms>Required Terms</RequiredTerms>
                      <Wrapper>
                        <Div>0</Div>
                      </Wrapper>
                      <MediumdropdownarrowIcon
                        alt=""
                        src="/DropdownLocerArrow.svg"
                        IsRotated={isRequiredTermsRotated}
                      />
                    </RequiredTermsParent>
                  </FrameWrapper2>
                  <RequiredTerms1 isHidden={isRequiredTermsHidden}>
                    {rows.map((row, rowIndex) => (
                      <RequiredTermParent key={rowIndex}>
                        {row.map((card) => {
                          return (!flippedCards[card.id] ?
                            <RequiredTerm onClick={CreateOpenFCPopUp(card.id)}>
                              <Butttonrotate24x24 onClick={(e) => { e.stopPropagation(); toggleFlip(card.id); }}>
                                <Layer1Icon alt="" src="/RotateFC.svg" />
                              </Butttonrotate24x24>
                              <Significado>{card.frontContent}</Significado>
                            </RequiredTerm>
                            :
                            <RequiredTerm1 onClick={CreateOpenFCPopUp(card.id)}>
                              <Butttonrotate24x24 onClick={(e) => { e.stopPropagation(); toggleFlip(card.id); }}>
                                <Layer1Icon alt="" src="/RotateFC.svg" />
                              </Butttonrotate24x24>
                              <Significado>
                                <HereAreYour2>{card.backContent}</HereAreYour2>
                              </Significado>
                            </RequiredTerm1>
                        )})}
                      </RequiredTermParent>
                    ))}
                    {isFCVisible && (
                      isFront?
                      <MainFrame
                      Next={NextFCPopUp}
                      Prev={PrevFCPopUp}
                      Rotate={RotateFCPopUp}
                      Close={CloseFCPopUp}
                      index={currFC}
                      Data={flashcardsData}
                      />
                      :
                      <FrameContainer
                      Next={NextFCPopUp}
                      Prev={PrevFCPopUp}
                      Rotate={RotateFCPopUp}
                      Close={CloseFCPopUp}
                      index={currFC}
                      Data={flashcardsData}
                      />
                      )
                    }
                    {isFCVisible && <div style={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      backgroundColor: 'var(--color-black-350)', // 35% transparency
                      backdropFilter: 'blur(2px)', // Background blur effect
                      zIndex: 99999998, // Ensure it's below the sidebar but above everything else
                    }} />}
                    
                  </RequiredTerms1>
                  <FrameWrapper2>
                    <RequiredTermsParent onClick={ChangeHideGrammaticalTables}>
                      <RequiredTerms>Grammatical Table</RequiredTerms>
                      <Wrapper>
                        <Div>{InputData.HTMLs.length}</Div>
                      </Wrapper>
                      <MediumdropdownarrowIcon
                        alt=""
                        src="/DropdownLocerArrow.svg"
                        IsRotated={isGrammaticalTablesRotated}
                      />
                    </RequiredTermsParent>
                  </FrameWrapper2>
                  <Table1Parent isHidden={isGrammaticalTablesHidden}>
                    {
                      InputData.HTMLs.map((html, index)=>{
                        return (
                          <>
                            {parse(html)}
                          </>
                        )
                      })
                    }
                  </Table1Parent>
                  <FrameWrapper2>
                    <RequiredTermsParent onClick={ChangeHideCustomTerms}>
                      <RequiredTerms>Custom Terms</RequiredTerms>
                      <Wrapper>
                        <Div>{FCMain.length}</Div>
                      </Wrapper>
                      <MediumdropdownarrowIcon
                        alt=""
                        src="/DropdownLocerArrow.svg"
                        IsRotated={isCustomTermsRotated}
                      />
                    </RequiredTermsParent>
                  </FrameWrapper2>
                  <CustomLineParent isHidden={isCustomTermsHidden}>
                    <CustomLine>
                      {
                        IsCr?
                        <>
                          <InputsArea>
                            <StateDefaultEmptyYesChec1
                              text="Term"
                              stateDefaultEmptyYesChecBoxSizing="border-box"
                              stateDefaultEmptyYesChecOutline="none"
                              stateDefaultEmptyYesChecFontFamily="Onest"
                              stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                              stateDefaultEmptyYesChecFlexShrink="0"
                              stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                              stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
                              stateDefaultEmptyYesChecFlex="1"
                              stateDefaultEmptyYesChecFontWeight="unset"
                              stateDefaultEmptyYesChecPosition="unset"
                              stateDefaultEmptyYesChecTop="unset"
                              stateDefaultEmptyYesChecLeft="unset"
                              stateDefaultEmptyYesChecWidth="unset"
                              stateDefaultEmptyYesChecAlignSelf="unset"
                              stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                              stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                              stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                              stateDefaultEmptyYesChecBorderS="solid"
                              IsInput={true}
                            />
                            <StateDefaultEmptyYesChec1
                              text="Meaning"
                              stateDefaultEmptyYesChecBoxSizing="border-box"
                              stateDefaultEmptyYesChecOutline="none"
                              stateDefaultEmptyYesChecFontFamily="Onest"
                              stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                              stateDefaultEmptyYesChecFlexShrink="0"
                              stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                              stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
                              stateDefaultEmptyYesChecFlex="1"
                              stateDefaultEmptyYesChecFontWeight="unset"
                              stateDefaultEmptyYesChecPosition="unset"
                              stateDefaultEmptyYesChecTop="unset"
                              stateDefaultEmptyYesChecLeft="unset"
                              stateDefaultEmptyYesChecWidth="unset"
                              stateDefaultEmptyYesChecAlignSelf="unset"
                              stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                              stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                              stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                              stateDefaultEmptyYesChecBorderS="solid"
                              IsInput={true}
                            />
                          </InputsArea>
                          <ApplyDelete onClick={onApplyDeleteClick2}>
                            <HoverDisabled
                              smallCheck="/Checkmark.svg"
                              hoverDisabledMargin="unset"
                              hoverDisabledPosition="unset"
                              hoverDisabledTop="unset"
                              hoverDisabledRight="unset"
                              hoverDisabledFlexShrink="0"
                              hoverDisabledZIndex="unset"
                              hoverDisabledBackgroundColor="var(--colors-blue-1)"
                            />
                          </ApplyDelete>
                        </>
                        :
                        <StateHoverIconno
                          saveSession="Create new"
                          stateHoverIconnoJustifyContent="flex-start"
                          stateHoverIconnoWidth="unset"
                          stateHoverIconnoZIndex="unset"
                          stateHoverIconnoBackgroundColor="transparent"
                          stateHoverIconnoCursor="pointer"
                          stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                          stateHoverIconnoBorderC="var(--colors-darkerblue)"
                          stateHoverIconnoBorderS="solid"
                          stateHoverIconnoHeight="4.166666666666666vh"
                          stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                          stateHoverIconnoBoxSizing="border-box"
                          stateHoverIconnoPosition="unset"
                          stateHoverIconnoTop="unset"
                          stateHoverIconnoLeft="unset"
                          saveSessionColor="var(--colors-darkerblue)"
                          saveSessionDisplay="inline-block"
                          onButtonSecondaryClick={onButtonSecondary4Click}
                        />
                      }
                    </CustomLine>
                    <CardsArea>
                      {FCMain.map((FC)=>{
                        return (
                          <Editeditable
                            tutorCode={FC.frontContent}
                            back={FC.backContent}
                            itemId={"FCMain"+String(FC.id)}
                            handleRemoveItem={handleRemoveItem2}
                            handleEditItem={handleEditItem}
                            smallEdit="/smalledit@2x.png"
                            smallDelete="/smalldelete@2x.png"
                            editeditableAlignItems="center"
                            editeditableBackgroundColor="var(--colors-white)"
                            butttonEdit24x24Cursor="pointer"
                            butttonDelete40x28Cursor="pointer"
                          />
                        )
                      })}
                    </CardsArea>
                  </CustomLineParent>
                </FrameParent1>
              </InsideFlashcardsquestions>
              <ScrollHolder
                scrollHeight={FQScroll.scrollIndicatorHeight}
                scrollIndicatorTop={FQScroll.scrollIndicatorTop}
                ref={FQScroll.scrollRef}
                scrollHolderHeight="80.9vh"
                scrollHolderAlignItems="unset"
                scrollHolderPosition="absolute"
                scrollHolderTop="19vh"
                scrollHolderLeft="50vw"
                scrollHolderMargin="unset"
                scrollHolderRight="unset"
                scrollHolderFlexShrink="unset"
                scrollHolderZIndex="unset"
                scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                scrollBackgroundColor="var(--colors-purple-light)"
              />
            </TestFlashcards>
            <NewsVariants id="NewsSection">
              <div style={{display:(!isTitleDisplayed?"flex":"none")}}>
                <Property1NavigationPropert1
                  Categories={Cats}
                  showCategory={showCategory}
                  property1NavigationProperMargin="0 !important"
                  property1NavigationProperPosition="absolute"
                  property1NavigationProperTop="-1.0416666666666665vh"
                  property1NavigationProperLeft="0.5555555555555556vw"
                  property1NavigationProperZIndex="2"
                  chipBackgroundColor="var(--colors-blue-1)"
                  chipBoxSizing="border-box"
                  chipCursor="pointer"
                  allColor="var(--colors-dark-1)"
                  allDisplay="inline-block"
                  chipBoxSizing1="border-box"
                  chipCursor1="pointer"
                  chipBackgroundColor1="var(--colors-blue-1)"
                  allDisplay1="inline-block"
                  allColor1="var(--colors-dark-1)"
                  chipBoxSizing2="border-box"
                  chipCursor2="pointer"
                  chipBackgroundColor2="var(--colors-blue-1)"
                  allDisplay2="inline-block"
                  allColor2="var(--colors-dark-1)"
                  chipBoxSizing3="border-box"
                  chipCursor3="pointer"
                  chipBackgroundColor3="var(--colors-blue-1)"
                  allDisplay3="inline-block"
                  allColor3="var(--colors-dark-1)"
                  chipBoxSizing4="border-box"
                  chipCursor4="pointer"
                  chipBackgroundColor4="var(--colors-blue-1)"
                  allDisplay4="inline-block"
                  allColor4="var(--colors-dark-1)"
                  chipBoxSizing5="border-box"
                  chipCursor5="pointer"
                  chipBackgroundColor5="var(--colors-blue-1)"
                  allDisplay5="inline-block"
                  allColor5="var(--colors-dark-1)"
                  chipBoxSizing6="border-box"
                  chipCursor6="pointer"
                  chipBackgroundColor6="var(--colors-blue-1)"
                  allDisplay6="inline-block"
                  allColor6="var(--colors-dark-1)"
                  chipBoxSizing7="border-box"
                  chipCursor7="pointer"
                  chipBackgroundColor7="var(--colors-blue-1)"
                  allDisplay7="inline-block"
                  allColor7="var(--colors-dark-1)"
                  onChip3Click={onChip3Click}
                />
                <NewsImages isVisible={IsAllVisible}>
                  <ScrollHolder
                    scrollHeight={p1.scrollIndicatorHeight}
                    scrollIndicatorTop={p1.scrollIndicatorTop}
                    ref={p1.scrollRef}
                    scrollHolderHeight="76.171875vh"
                    scrollHolderAlignItems="flex-start"
                    scrollHolderPosition="absolute"
                    scrollHolderTop="0.5208333333333333vh"
                    scrollHolderLeft="unset"
                    scrollHolderMargin="0 !important"
                    scrollHolderRight="0.0vw"
                    scrollHolderFlexShrink="0"
                    scrollHolderZIndex="1"
                    scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                    scrollBackgroundColor="var(--colors-purple-light)"
                  />
                  <Articles ref={p1.siblingRef}>
                    {
                      InputData.News.map((Title, index)=>{
                        return (index<2?
                          <Property1Default14
                            prop={Title.datetime}
                            lang={Title.language}
                            title={Title.title}
                            text={Title.content?Title.content.slice(0, 100):""}
                            categories={Title.categories}
                            property1DefaultCursor="pointer"
                            frameDivBackgroundImage={"url('"+Title.image+"')"}
                            chipBackgroundColor="var(--colors-darkerblue)"
                            divColor="var(--colors-purple-dark)"
                            spainColor="var(--colors-purple-dark)"
                            cuidadoHaciendaEchaColor="var(--colors-dark-1)"
                            aunqueBizumCuentaColor="var(--colors-dark-1)"
                            onNewsCardLargeContainerClick={
                              GenShowTitle(index)
                            }
                          />
                          :
                          <Property1Default13
                            prop={Title.datetime}
                            lang={Title.language}
                            title={Title.title}
                            text={Title.content?Title.content.slice(0, 100):""}
                            categories={Title.categories}
                            property1DefaultCursor="pointer"
                            frameDivBackgroundImage={"url('"+Title.image+"')"}
                            chipBackgroundColor="var(--colors-darkerblue)"
                            divColor="var(--colors-purple-dark)"
                            spainColor="var(--colors-purple-dark)"
                            cuidadoHaciendaEchaColor="var(--colors-dark-1)"
                            aunqueBizumCuentaColor="var(--colors-dark-1)"
                            onNewsCardSmallContainerClick={
                              GenShowTitle(index)
                            }
                          />
                        )
                      })
                    }
                  </Articles>
                </NewsImages>
                <NewsList isVisible={!IsAllVisible}>
                  <Property1List
                    ref={p2.siblingRef}
                    News={DisplayedNews}
                    property1ListFlexShrink="0"
                    property1ListZIndex="0"
                    GenShowTitle={GenShowTitle}
                  />
                  <ScrollHolder
                    scrollHeight={p2.scrollIndicatorHeight}
                    scrollIndicatorTop={p2.scrollIndicatorTop}
                    ref={p2.scrollRef}
                    scrollHolderHeight="76.171875vh"
                    scrollHolderAlignItems="flex-start"
                    scrollHolderPosition="absolute"
                    scrollHolderTop="0.5208333333333333vh"
                    scrollHolderLeft="unset"
                    scrollHolderMargin="0 !important"
                    scrollHolderRight="0.0vw"
                    scrollHolderFlexShrink="0"
                    scrollHolderZIndex="1"
                    scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                    scrollBackgroundColor="var(--colors-purple-light)"
                  />
                </NewsList>
              </div>
              <NewsPersonalPage isVisible={isTitleDisplayed}>
                <Holder>
                  <Property1TextProperty2De
                    ref={p3.siblingRef}
                    graphicsHolder={DisplayedTitle.image}
                    graphicsHolderV={DisplayedTitle.video}
                    src={DisplayedTitle.source}
                    url={DisplayedTitle.link}
                    descr={DisplayedTitle.description}
                    largeBack="/largeback@2x.png"
                    publicationDate={DisplayedTitle.datetime}
                    language={DisplayedTitle.language}
                    title={DisplayedTitle.title}
                    text={DisplayedTitle.content?DisplayedTitle.content:""}
                    property1TextProperty2DeWidth="46.38888888888889vw"
                    publicationDateColor="var(--colors-dark-1)"
                    divColor="var(--colors-purple-dark)"
                    spainColor="var(--colors-purple-dark)"
                    cuidadoHaciendaEchaMargin="0"
                    cuidadoHaciendaEchaColor="var(--colors-dark-1)"
                    aunqueBizumCuentaColor="var(--colors-dark-1)"
                    aunqueCuentaConContainerColor="var(--colors-dark-1)"
                    sourceColor="var(--colors-dark-1)"
                    diariodemallorcaColor="var(--colors-dark-1)"
                    sourceLinkColor="var(--colors-dark-1)"
                    httpswwwdiariodemallorcaeColor="var(--colors-darkerblue)"
                    backButtonCursor="pointer"
                    backButtonPadding="0"
                    backButtonBackgroundColor="var(--colors-blue-1)"
                    newsColor="var(--colors-purple-dark)"
                    onBackButtonClick={onBackButtonClick}
                  />
                  <ScrollHolder
                    scrollHeight={p3.scrollIndicatorHeight}
                    scrollIndicatorTop={p3.scrollIndicatorTop}
                    ref={p3.scrollRef}
                    scrollHolderHeight="76.171875vh"
                    scrollHolderAlignItems="flex-start"
                    scrollHolderPosition="absolute"
                    scrollHolderTop="0.5208333333333333vh"
                    scrollHolderLeft="unset"
                    scrollHolderMargin="0 !important"
                    scrollHolderRight="0.0vw"
                    scrollHolderFlexShrink="0"
                    scrollHolderZIndex="1"
                    scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                    scrollBackgroundColor="var(--colors-purple-light)"
                  />
                </Holder>
                <Property1NavigationPropert
                  largeBack="/largeback@2x.png"
                  property1NavigationProperWidth="46.38888888888889vw"
                  property1NavigationProperPosition="absolute"
                  property1NavigationProperTop="-1.0416666666666665vh"
                  property1NavigationProperLeft="0.0vw"
                  property1NavigationProperFlexShrink="0"
                  property1NavigationProperPadding="0.0vh var(--padding-baseW)"
                  property1NavigationProperBoxSizing="border-box"
                  backButtonCursor="pointer"
                  backButtonPadding="0"
                  backButtonBackgroundColor="var(--colors-blue-1)"
                  newsColor="var(--colors-purple-dark)"
                  onBackButton1Click={onBackButton1Click}
                />
              </NewsPersonalPage>
            </NewsVariants>
          </Ls>
          <Rs>
            <Property1DefaultImage
              property1DefaultImageProp="/graphicsholder@2x.png"
              property1DefaultIconWidth="31.11111111111111vw"
              property1DefaultIconHeight="32.8125vh"
              property1DefaultIconPosition="relative"
              property1DefaultIconFlexShrink="0"
              property1DefaultIconTop="unset"
              property1DefaultIconLeft="unset"
            />
            <TabsChange>
              <Property1Secondary
                meaning="Flash Cards"
                property1SecondaryWidth="calc(100% - 1.1111111111111112vw)"
                property1SecondaryMargin="0 !important"
                property1SecondaryPosition="absolute"
                property1SecondaryTop="1.0416666666666665vh"
                property1SecondaryRight="0.5555555555555556vw"
                property1SecondaryLeft="0.5555555555555556vw"
                property1SecondaryZIndex="0"
                tabSecondaryBorderBottom="0.13020833333333331vh solid var(--color-gray-100)"
                tabSecondaryBoxSizing="border-box"
                tabSecondaryCursor="pointer"
                tabSecondaryBackgroundColor="transparent"
                meaningColor="var(--colors-dark-1)"
                meaningDisplay="inline-block"
                tabSecondaryBorderBottom1="0.13020833333333331vh solid var(--colors-grey-stroke)"
                tabSecondaryBoxSizing1="border-box"
                tabSecondaryCursor1="pointer"
                tabSecondaryBackgroundColor1="transparent"
                meaningDisplay1="inline-block"
                meaningColor1="var(--colors-dark-1)"
                tabSecondaryBorderBottom2="0.13020833333333331vh solid var(--colors-grey-stroke)"
                tabSecondaryBoxSizing2="border-box"
                tabSecondaryCursor2="pointer"
                tabSecondaryBackgroundColor2="transparent"
                meaningDisplay2="inline-block"
                meaningColor2="var(--colors-dark-1)"
                tabSecondaryBorderBottom3="0.13020833333333331vh solid var(--colors-grey-stroke)"
                tabSecondaryCursor3="pointer"
                tabSecondaryBackgroundColor3="transparent"
                meaningDisplay3="inline-block"
                meaningColor3="var(--colors-dark-1)"
                RSSec={RSSec}
                MeaningB={MeaningB}
                GrammarB={GrammarB}
                VideoB={VideoB}
                FlashcardsB={FlashcardsB}
                TranslateB={TranslateB}
                hv={InputData.hv}
              />
              <TabMeaning id="MeaningSection">
                <HolaParent>
                  <Hola id="MeaningWord"></Hola>
                  <Property1Default11
                    largeVolumeEnable="/Audio.svg"
                    property1DefaultCursor="pointer"
                    property1DefaultBorderW="none"
                    property1DefaultBorderC="none"
                    property1DefaultBorderS="none"
                    property1DefaultPadding="0"
                    property1DefaultBackgroundColor="var(--colors-blue-1)"
                    onPlayAudioClick={(e)=>{var a=document.getElementById("MeaningWord");a && AudioB(e,a.innerHTML)}}
                  />
                </HolaParent>
                <HolaContainer>
                  <HereAreYour id="MeaningMean" ref={MeanScroll.siblingRef}>
                  </HereAreYour>
                  <ScrollHolder
                    scrollHeight={MeanScroll.scrollIndicatorHeight}
                    scrollIndicatorTop={MeanScroll.scrollIndicatorTop}
                    ref={MeanScroll.scrollRef}
                    scrollHolderHeight="38vh"
                    scrollHolderAlignItems="unset"
                    scrollHolderPosition="absolute"
                    scrollHolderTop="0"
                    scrollHolderLeft="28vw"
                    scrollHolderMargin="0"
                    scrollHolderRight="unset"
                    scrollHolderFlexShrink="unset"
                    scrollHolderZIndex="unset"
                    scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                    scrollBackgroundColor="var(--colors-purple-light)"
                  />
                </HolaContainer>
              </TabMeaning>
              <TabMeaning style={{display: "none"}} id="TranslateSection">
                <HolaParent>
                  <Hola id="TranslateWords"></Hola>
                  <Property1Default11
                    largeVolumeEnable="/Audio.svg"
                    property1DefaultCursor="pointer"
                    property1DefaultBorderW="none"
                    property1DefaultBorderC="none"
                    property1DefaultBorderS="none"
                    property1DefaultPadding="0"
                    property1DefaultBackgroundColor="var(--colors-blue-1)"
                    onPlayAudioClick={(e)=>{var a=document.getElementById("TranslateWords");a && AudioB(e,a.innerHTML)}}
                  />
                </HolaParent>
                <HolaContainer>
                  <HereAreYour id="TranslateMean" ref={TScroll.siblingRef}>
                  </HereAreYour>
                  <ScrollHolder
                    scrollHeight={TScroll.scrollIndicatorHeight}
                    scrollIndicatorTop={TScroll.scrollIndicatorTop}
                    ref={TScroll.scrollRef}
                    scrollHolderHeight="38vh"
                    scrollHolderAlignItems="unset"
                    scrollHolderPosition="absolute"
                    scrollHolderTop="0"
                    scrollHolderLeft="28vw"
                    scrollHolderMargin="unset"
                    scrollHolderRight="unset"
                    scrollHolderFlexShrink="unset"
                    scrollHolderZIndex="unset"
                    scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                    scrollBackgroundColor="var(--colors-purple-light)"
                  />
                </HolaContainer>
              </TabMeaning>
              <TabGrammar id="GrammarSection">
                <Property1Variant2
                  largeVolumeEnable="/Audio.svg"
                  property1Variant2Height="45.83333333333333vh"
                  property1Variant2Position="absolute"
                  property1Variant2Top="1.953125vh"
                  property1Variant2Left="0.0vw"
                  property1Variant2Overflow="hidden"
                  holaMargin="0"
                  holaColor="var(--colors-dark-1)"
                  playAudioCursor="pointer"
                  playAudioBorderW="none"
                  playAudioBorderC="none"
                  playAudioBorderS="none"
                  playAudioPadding="0"
                  playAudioBackgroundColor="var(--colors-blue-1)"
                  holaContainerColor="var(--colors-dark-1)"
                  onPlayAudio1Click={(e)=>{var a=document.getElementById("GrammarWord");a && AudioB(e,a.innerHTML)}}
                  ScrollObj={GramScroll}
                />
                <ScrollHolder
                  scrollHeight={GramScroll.scrollIndicatorHeight}
                  scrollIndicatorTop={GramScroll.scrollIndicatorTop}
                  ref={GramScroll.scrollRef}
                  scrollHolderHeight="38vh"
                  scrollHolderAlignItems="unset"
                  scrollHolderPosition="absolute"
                  scrollHolderTop="9.15vh"
                  scrollHolderLeft="28vw"
                  scrollHolderMargin="unset"
                  scrollHolderRight="unset"
                  scrollHolderFlexShrink="unset"
                  scrollHolderZIndex="unset"
                  scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                  scrollBackgroundColor="var(--colors-purple-light)"
                />
              </TabGrammar>
              {(InputData.hv===false) && <TabVideo id="VideoSection">
                <WidgetContainer>
                  <VideoSection id="Video">

                  </VideoSection>
                </WidgetContainer>
              </TabVideo>}
              <TabFlashcards id="FlashcardSection">
                <UnderlinedWordsAre>
                  {t("RSFCInfo")}
                </UnderlinedWordsAre>
                <CustomLineGroup>
                  <CustomLine1>
                    <InputsArea>
                      <StateDefaultEmptyYesChec1
                        text="term"
                        stateDefaultEmptyYesChecBoxSizing="border-box"
                        stateDefaultEmptyYesChecOutline="none"
                        stateDefaultEmptyYesChecFontFamily="Onest"
                        stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                        stateDefaultEmptyYesChecFlexShrink="0"
                        stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                        stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
                        stateDefaultEmptyYesChecFlex="1"
                        stateDefaultEmptyYesChecFontWeight="unset"
                        stateDefaultEmptyYesChecPosition="unset"
                        stateDefaultEmptyYesChecTop="unset"
                        stateDefaultEmptyYesChecLeft="unset"
                        stateDefaultEmptyYesChecWidth="unset"
                        stateDefaultEmptyYesChecAlignSelf="unset"
                        stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                        stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                        stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                        stateDefaultEmptyYesChecBorderS="solid"
                        IsInput={true}
                      />
                      <StateDefaultEmptyYesChec1
                        text="meaning"
                        stateDefaultEmptyYesChecBoxSizing="border-box"
                        stateDefaultEmptyYesChecOutline="none"
                        stateDefaultEmptyYesChecFontFamily="Onest"
                        stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                        stateDefaultEmptyYesChecFlexShrink="0"
                        stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                        stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
                        stateDefaultEmptyYesChecFlex="1"
                        stateDefaultEmptyYesChecFontWeight="unset"
                        stateDefaultEmptyYesChecPosition="unset"
                        stateDefaultEmptyYesChecTop="unset"
                        stateDefaultEmptyYesChecLeft="unset"
                        stateDefaultEmptyYesChecWidth="unset"
                        stateDefaultEmptyYesChecAlignSelf="unset"
                        stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                        stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                        stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                        stateDefaultEmptyYesChecBorderS="solid"
                        IsInput={true}
                      />
                    </InputsArea>
                    <ApplyDelete onClick={onApplyDeleteClick}>
                      <HoverDisabled
                        smallCheck="/Checkmark.svg"
                        hoverDisabledMargin="unset"
                        hoverDisabledPosition="unset"
                        hoverDisabledTop="unset"
                        hoverDisabledRight="unset"
                        hoverDisabledFlexShrink="0"
                        hoverDisabledZIndex="unset"
                        hoverDisabledBackgroundColor="var(--colors-blue-1)"
                      />
                    </ApplyDelete>
                  </CustomLine1>
                  <FlashCardSmallParent ref={RSFCScroll.siblingRef}>
                    {items.map((itemId, _) => (
                      <Editdefault
                        key={itemId}
                        word={FCMain.find(item => item.id === itemId)?.frontContent}
                        mean={FCMain.find(item => item.id === itemId)?.backContent}
                        smallDelete="/DeleteFC&Acc.svg"
                        editdefaultBorderBottom="0.13020833333333331vh solid var(--colors-grey-stroke)"
                        tutorCodeColor="var(--colors-dark-1)"
                        tutorCodeColor1="var(--colors-dark-1)"
                        butttonDelete40x28Cursor="pointer"
                        butttonDelete40x28BorderW="none"
                        butttonDelete40x28BorderC="none"
                        butttonDelete40x28BorderS="none"
                        butttonDelete40x28BackgroundColor="transparent"
                        onButttonDelete40x283Click={() => handleRemoveItem(itemId)}
                      />
                    ))}
                  </FlashCardSmallParent>
                  <ScrollHolder
                    scrollHeight={RSFCScroll.scrollIndicatorHeight}
                    scrollIndicatorTop={RSFCScroll.scrollIndicatorTop}
                    ref={RSFCScroll.scrollRef}
                    scrollHolderHeight="27vh"
                    scrollHolderAlignItems="unset"
                    scrollHolderPosition="absolute"
                    scrollHolderTop="27vh"
                    scrollHolderLeft="30vw"
                    scrollHolderMargin="unset"
                    scrollHolderRight="unset"
                    scrollHolderFlexShrink="unset"
                    scrollHolderZIndex="unset"
                    scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                    scrollBackgroundColor="var(--colors-purple-light)"
                  />
                </CustomLineGroup>
              </TabFlashcards>
            </TabsChange>
          </Rs>
        </Page1Study>
        <FormContainer4
        ScrollObj={MLAScroll}
        ScrollObjTL={TutorListScroll}
        ScrollObjMA={AlertsScroll}
        ScrollObjMAH={AHistScroll}
        TutPageScroll={TutPageScroll}
        ActivitiesV={InputData.Acts}
        />
        {false && <Page3TeachParent id="TPSection">
          <TabsFixed>
            <Tabnavigation>
              <StateDefaultNotificationno
                smallFlashCards="/Teach.svg"
                text="Tutor"
                stateDefaultNotificationnCursor="pointer"
                stateDefaultNotificationnBorderW="none"
                stateDefaultNotificationnBorderC="none"
                stateDefaultNotificationnBorderS="none"
                stateDefaultNotificationnBackgroundColor="transparent"
                stateDefaultNotificationnWidth="max-content"
                stateDefaultNotificationnFlexShrink="0"
                stateDefaultNotificationnBoxSizing="border-box"
                stateDefaultNotificationnBorderBottom="unset"
                smallFlashCardsIconOverflow="unset"
                smallFlashCardsIconFlexShrink="unset"
                smallFlashCardsIconOpacity="unset"
                flashCardsDisplay="inline-block"
                flashCardsColor="var(--colors-dark-1)"
                HasClass="selected"
                onTabPrimaryB3Click={onTabPrimaryB6Click}
              />
              <StateDefaultNotificationno
                smallFlashCards="/Parent.svg"
                text="Parent"
                stateDefaultNotificationnCursor="pointer"
                stateDefaultNotificationnBorderW="none"
                stateDefaultNotificationnBorderC="none"
                stateDefaultNotificationnBorderS="none"
                stateDefaultNotificationnBackgroundColor="transparent"
                stateDefaultNotificationnWidth="max-content"
                stateDefaultNotificationnFlexShrink="0"
                stateDefaultNotificationnBoxSizing="border-box"
                stateDefaultNotificationnBorderBottom="unset"
                smallFlashCardsIconOverflow="hidden"
                smallFlashCardsIconFlexShrink="0"
                smallFlashCardsIconOpacity="unset"
                flashCardsDisplay="inline-block"
                flashCardsColor="var(--colors-dark-1)"
                onTabPrimaryB3Click={onTabPrimaryB7Click}
              />
            </Tabnavigation>
          </TabsFixed>
          <FormContainer2
            Code="103548A3D6337M78HTPY"
            Students={[
              {
                Name:"Alexander Vachovsky",
                Nick:"",
                tableData: [
                  {
                    Language: "English UK",
                    SetUpBy: "Daniel Craig",
                    Target: "",
                    Deadline: "06.12.2023"
                  },
                  {
                    Language: "English UK",
                    SetUpBy: "Olga_Student",
                    Target: "Active English US use at high-begginer level. Main target for fast education progress in nearest time.",
                    Deadline: "11.12.2023"
                  }
                ]
              },
              {
                Name:"Roman Podgornyi",
                Nick:"",
                tableData: [
                  {
                    Language: "Chinese Cantonese",
                    SetUpBy: "Daniel Craig",
                    Target: "",
                    Deadline: "13.12.2023"
                  }
                ]
              },
              {
                Name:"Polina Shevchenko",
                Nick:"Poli",
                tableData: [
                  {
                    Language: "English UK",
                    SetUpBy: "Daniel Craig",
                    Target: "",
                    Deadline: "12.12.2023"
                  }
                ]
              }
            ]}
            languageData={[
              {
                id:0,
                language:"English UK",
                level:"Conversational",
                status:"1"
              },
              {
                id:1,
                language:"Chinese Cantonese",
                level:"Conversational",
                status:"1"
              }
            ]}
            ref={TutorScroll.siblingRef}
            rec={TutorScroll.recalculateScrollIndicator}
          />
          <ScrollHolder
            scrollHeight={TutorScroll.scrollIndicatorHeight}
            scrollIndicatorTop={TutorScroll.scrollIndicatorTop}
            ref={TutorScroll.scrollRef}
            scrollHolderHeight="84.5vh"
            scrollHolderAlignItems="unset"
            scrollHolderPosition="absolute"
            scrollHolderTop="14.5vh"
            scrollHolderLeft="99.5vw"
            scrollHolderMargin="unset"
            scrollHolderRight="unset"
            scrollHolderFlexShrink="unset"
            scrollHolderZIndex="unset"
            scrollHolderBackgroundColor="var(--colors-grey-stroke)"
            scrollBackgroundColor="var(--colors-purple-light)"
            hidden={parentPVis}
          />
          <ContainerCardFormFilter2
          Students={[
            {
              Name:"Danie Shtein",
              rowData:[
                {
                  language:"English US",
                  activity:"Active English US use at high-begginer level.",
                  date:"01.12.2023",
                  time:"14:25:36",
                  tutor:"Danie Shtein",
                  earned:4.6,
                  total:25.38,
                },
                {
                  language:"English US",
                  activity:"Active English US use at high-begginer level.",
                  date:"01.12.2023",
                  time:"14:25:36",
                  tutor:"Danie Shtein",
                  earned:4.6,
                  total:25.38,
                },
                {
                  language:"English UK",
                  activity:"",
                  date:"",
                  time:"",
                  tutor:"Danie Shtein",
                  earned:0,
                  total:0,
                }
              ]
            },
            {
              Name:"Brandon Shtein",
              rowData:[
                {
                  language:"English US",
                  activity:"Active English US use at high-begginer level.",
                  date:"01.12.2023",
                  time:"14:25:36",
                  tutor:"Danie Shtein",
                  earned:4.6,
                  total:25.38,
                },
                {
                  language:"English US",
                  activity:"Active English US use at high-begginer level.",
                  date:"01.12.2023",
                  time:"14:25:36",
                  tutor:"Danie Shtein",
                  earned:4.6,
                  total:25.38,
                }
              ]
            },
            {
              Name:"Veronika Shtein",
              rowData:[
                {
                  language:"English US",
                  activity:"Active English US use at high-begginer level.",
                  date:"01.12.2023",
                  time:"14:25:36",
                  tutor:"Danie Shtein",
                  earned:4.6,
                  total:25.38,
                },
                {
                  language:"English US",
                  activity:"",
                  date:"",
                  time:"",
                  tutor:"Danie Shtein",
                  earned:0,
                  total:0,
                }
              ]
            }
          ]}
          ref={ParentScroll.siblingRef}
          />
          <ScrollHolder
            scrollHeight={ParentScroll.scrollIndicatorHeight}
            scrollIndicatorTop={ParentScroll.scrollIndicatorTop}
            ref={ParentScroll.scrollRef}
            scrollHolderHeight="84.5vh"
            scrollHolderAlignItems="unset"
            scrollHolderPosition="absolute"
            scrollHolderTop="15vh"
            scrollHolderLeft="98vw"
            scrollHolderMargin="unset"
            scrollHolderRight="unset"
            scrollHolderFlexShrink="unset"
            scrollHolderZIndex="unset"
            scrollHolderBackgroundColor="var(--colors-grey-stroke)"
            scrollBackgroundColor="var(--colors-purple-light)"
            hidden={!parentPVis}
          />
        </Page3TeachParent>}
        <Page4Profile id="ProfileSection">
          <ContactForm>
            <ContactUs>Contact Us</ContactUs>
            <Property1Default6
              property1DefaultWidth="unset"
              property1DefaultJustifyContent="space-between"
              property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW) 0.52083333333vh"
              property1DefaultGap="unset"
              property1DefaultPosition="relative"
              property1DefaultTop="unset"
              property1DefaultLeft="unset"
              property1DefaultBackgroundColor="transparent"
              property1DefaultFontFamily="Onest"
              property1DefaultFontWeight="500"
              property1DefaultFontSize="1.8229166666666667vh"
              property1DefaultColor="var(--colors-purple-light)"
              property1DefaultAlignSelf="stretch"
              options={["General","Refunds","Technical","Privacy"]}
              value={"General"}
              id="msgtype"
            />
            <StateDefaultEmptyYesChec1
              text="Email"
              stateDefaultEmptyYesChecBoxSizing="border-box"
              stateDefaultEmptyYesChecOutline="none"
              stateDefaultEmptyYesChecFontFamily="Onest"
              stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
              stateDefaultEmptyYesChecFlexShrink="unset"
              stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
              stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
              stateDefaultEmptyYesChecFlex="unset"
              stateDefaultEmptyYesChecFontWeight="unset"
              stateDefaultEmptyYesChecPosition="unset"
              stateDefaultEmptyYesChecTop="unset"
              stateDefaultEmptyYesChecLeft="unset"
              stateDefaultEmptyYesChecWidth="unset"
              stateDefaultEmptyYesChecAlignSelf="stretch"
              stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
              stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
              stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
              stateDefaultEmptyYesChecBorderS="solid"
              IsInput={true}
            />
            <StateemptyScrollno
              stateemptyScrollnoBoxSizing="border-box"
              stateemptyScrollnoFontFamily="Onest"
              stateemptyScrollnoFontSize="1.8229166666666667vh"
              stateemptyScrollnoOutline="none"
              stateemptyScrollnoColor="var(--colors-purple-light)"
              stateemptyScrollnoFlex="unset"
              stateemptyScrollnoHeight="26.041666666666668vh"
              stateemptyScrollnoFlexShrink="0"
              stateemptyScrollnoBackgroundColor="var(--colors-white)"
              stateemptyScrollnoBorderW="0.130208333vh 0.0694444444vw"
              stateemptyScrollnoBorderC="var(--colors-grey-stroke)"
              stateemptyScrollnoBorderS="solid"
              id="msg"
            />
            <StateHoverIconno
              saveSession="Send Message"
              stateHoverIconnoJustifyContent="flex-start"
              stateHoverIconnoWidth="unset"
              stateHoverIconnoZIndex="unset"
              stateHoverIconnoBackgroundColor="transparent"
              stateHoverIconnoCursor="pointer"
              stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
              stateHoverIconnoBorderC="var(--colors-darkerblue)"
              stateHoverIconnoBorderS="solid"
              stateHoverIconnoHeight="4.166666666666666vh"
              stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
              stateHoverIconnoBoxSizing="border-box"
              stateHoverIconnoPosition="unset"
              stateHoverIconnoTop="unset"
              stateHoverIconnoLeft="unset"
              saveSessionColor="var(--colors-darkerblue)"
              saveSessionDisplay="inline-block"
              onButtonSecondaryClick={onButtonSecondary36Click}
            />
          </ContactForm>
          <Container1>
            <Fields>
              <Email>
                <Email1>Email</Email1>
                <Guest7fzlmuyn1700651105>
                  {InputData.ProfileData.Email}
                </Guest7fzlmuyn1700651105>
              </Email>
              <FieldsChild />
              <Email>
                <Email1>Password</Email1>
                <TextfieldsmallParent>
                  <StateDefaultEmptyYesChec
                    text="Password"
                    stateDefaultEmptyYesChecBoxSizing="border-box"
                    stateDefaultEmptyYesChecPosition="unset"
                    stateDefaultEmptyYesChecGap="unset"
                    stateDefaultEmptyYesChecOutline="none"
                    stateDefaultEmptyYesChecFontFamily="Onest"
                    stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                    stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                    stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
                    stateDefaultEmptyYesChecAlignSelf="unset"
                    stateDefaultEmptyYesChecWidth="unset"
                    stateDefaultEmptyYesChecHeight="unset"
                    stateDefaultEmptyYesChecFontWeight="500"
                    stateDefaultEmptyYesChecFlex="1"
                    stateDefaultEmptyYesChecZIndex="unset"
                    stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                    stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                    stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                    stateDefaultEmptyYesChecBorderS="solid"
                    stateDefaultEmptyTextWidth="98%"
                    type="password"
                    id="pwd1"
                    onCheckClick={()=>{
                      alert('Password is fine!')
                      return true
                    }}
                  />
                  <StateDefaultEmptyYesChec
                    text="Repeat Password"
                    stateDefaultEmptyYesChecBoxSizing="border-box"
                    stateDefaultEmptyYesChecPosition="unset"
                    stateDefaultEmptyYesChecGap="unset"
                    stateDefaultEmptyYesChecOutline="none"
                    stateDefaultEmptyYesChecFontFamily="Onest"
                    stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                    stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                    stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
                    stateDefaultEmptyYesChecAlignSelf="unset"
                    stateDefaultEmptyYesChecWidth="unset"
                    stateDefaultEmptyYesChecHeight="unset"
                    stateDefaultEmptyYesChecFontWeight="500"
                    stateDefaultEmptyYesChecFlex="1"
                    stateDefaultEmptyYesChecZIndex="unset"
                    stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                    stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                    stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                    stateDefaultEmptyYesChecBorderS="solid"
                    stateDefaultEmptyTextWidth="98%"
                    type="password"
                    id="pwd2"
                    onCheckClick={()=>{
                      const pwd1N = document.getElementById('pwd1') as HTMLInputElement;
                      const pwd2N = document.getElementById('pwd2') as HTMLInputElement;
                      if (pwd1N && pwd1N.value !== "") {
                        // Validate passwords
                        if (pwd1N.value !== pwd2N.value) {
                          alert('Passwords do not match!');
                          return false;
                        } else {
                          alert('Passwords match!');
                          return true;
                        }
                      }
                      return false;
                    }}
                  />
                </TextfieldsmallParent>
              </Email>
              <FieldsChild />
              <Email>
                <Email1>Time Zone</Email1>
                <Utc0200Parent>
                  <Russian>{TZ}</Russian>
                  <Property1Default6
                    property1DefaultWidth="9.791666666666666vw"
                    property1DefaultJustifyContent="space-between"
                    property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                    property1DefaultGap="unset"
                    property1DefaultPosition="absolute"
                    property1DefaultTop="0.5208333333333333vh"
                    property1DefaultLeft="25.694444444444443vw"
                    property1DefaultBackgroundColor="transparent"
                    property1DefaultFontFamily="Onest"
                    property1DefaultFontWeight="500"
                    property1DefaultFontSize="1.8229166666666667vh"
                    property1DefaultColor="var(--colors-purple-light)"
                    property1DefaultAlignSelf="unset"
                    options={utcOffsets}
                    value={TZ}
                    id="tzSelect"
                  />
                </Utc0200Parent>
              </Email>
              <FieldsChild />
              <Email>
                <Email1>Default Language</Email1>
                <Utc0200Parent>
                  <Russian>{ProfLang}</Russian>
                  <Property1Default6
                    property1DefaultWidth="9.791666666666666vw"
                    property1DefaultJustifyContent="space-between"
                    property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                    property1DefaultGap="unset"
                    property1DefaultPosition="absolute"
                    property1DefaultTop="0.5208333333333333vh"
                    property1DefaultLeft="25.694444444444443vw"
                    property1DefaultBackgroundColor="transparent"
                    property1DefaultFontFamily="Onest"
                    property1DefaultFontWeight="500"
                    property1DefaultFontSize="1.8229166666666667vh"
                    property1DefaultColor="var(--colors-purple-light)"
                    property1DefaultAlignSelf="unset"
                    options={InputData.AllLangs}
                    value={CurrNL}
                    id="NatLangSel"
                  />
                </Utc0200Parent>
              </Email>
              <FieldsChild />
              <Email>
                <Email1>Default Currency</Email1>
                <Utc0200Parent>
                  <Russian>{Curr}</Russian>
                  <Property1Default6
                    property1DefaultWidth="9.791666666666666vw"
                    property1DefaultJustifyContent="space-between"
                    property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                    property1DefaultGap="unset"
                    property1DefaultPosition="absolute"
                    property1DefaultTop="0.5208333333333333vh"
                    property1DefaultLeft="25.694444444444443vw"
                    property1DefaultBackgroundColor="transparent"
                    property1DefaultFontFamily="Onest"
                    property1DefaultFontWeight="500"
                    property1DefaultFontSize="1.8229166666666667vh"
                    property1DefaultColor="var(--colors-purple-light)"
                    property1DefaultAlignSelf="unset"
                    options={["USD","EUR"]}
                    value={Curr}
                    id="Curr"
                  />
                </Utc0200Parent>
              </Email>
              {false && <><FieldsChild />
              <Email>
                <Email1>Parent Code</Email1>
                <TextfieldsmallWrapper>
                  <StateDefaultEmptyYesChec
                    text="Parent Code"
                    stateDefaultEmptyYesChecBoxSizing="border-box"
                    stateDefaultEmptyYesChecPosition="unset"
                    stateDefaultEmptyYesChecGap="unset"
                    stateDefaultEmptyYesChecOutline="none"
                    stateDefaultEmptyYesChecFontFamily="Onest"
                    stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                    stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                    stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
                    stateDefaultEmptyYesChecAlignSelf="unset"
                    stateDefaultEmptyYesChecWidth="unset"
                    stateDefaultEmptyYesChecHeight="unset"
                    stateDefaultEmptyYesChecFontWeight="500"
                    stateDefaultEmptyYesChecFlex="1"
                    stateDefaultEmptyYesChecZIndex="unset"
                    stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                    stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                    stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                    stateDefaultEmptyYesChecBorderS="solid"
                    stateDefaultEmptyTextWidth="99%"
                  />
                </TextfieldsmallWrapper>
              </Email>
              <FieldsChild />
              <Email>
                <Email1>Display Name</Email1>
                <YourDisplayNameWillBeVisiParent>
                  <YourDisplayName>
                    Your Display Name will be visible to others.
                  </YourDisplayName>
                  <StateDefaultEmptyYesChec
                    text="Display Name"
                    stateDefaultEmptyYesChecBoxSizing="border-box"
                    stateDefaultEmptyYesChecPosition="unset"
                    stateDefaultEmptyYesChecGap="unset"
                    stateDefaultEmptyYesChecOutline="none"
                    stateDefaultEmptyYesChecFontFamily="Onest"
                    stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
                    stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
                    stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
                    stateDefaultEmptyYesChecAlignSelf="unset"
                    stateDefaultEmptyYesChecWidth="unset"
                    stateDefaultEmptyYesChecHeight="unset"
                    stateDefaultEmptyYesChecFontWeight="unset"
                    stateDefaultEmptyYesChecFlex="1"
                    stateDefaultEmptyYesChecZIndex="1"
                    stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
                    stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
                    stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
                    stateDefaultEmptyYesChecBorderS="solid"
                    stateDefaultEmptyTextWidth="99%"
                  />
                </YourDisplayNameWillBeVisiParent>
              </Email></>}
            </Fields>
            <FrameParent>
              <StateHoverIconno
                saveSession="Save Changes"
                stateHoverIconnoJustifyContent="flex-start"
                stateHoverIconnoWidth="unset"
                stateHoverIconnoZIndex="unset"
                stateHoverIconnoBackgroundColor="transparent"
                stateHoverIconnoCursor="pointer"
                stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                stateHoverIconnoBorderC="var(--colors-darkerblue)"
                stateHoverIconnoBorderS="solid"
                stateHoverIconnoHeight="4.166666666666666vh"
                stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                stateHoverIconnoBoxSizing="border-box"
                stateHoverIconnoPosition="unset"
                stateHoverIconnoTop="unset"
                stateHoverIconnoLeft="unset"
                saveSessionColor="var(--colors-darkerblue)"
                saveSessionDisplay="inline-block"
                onButtonSecondaryClick={()=>{
                  const pwd1N = document.getElementById('pwd1') as HTMLInputElement;
                  const pwd2N = document.getElementById('pwd2') as HTMLInputElement;
                  var defaultLanguage = document.getElementById("NatLangSel") as HTMLSelectElement;
                  if (!defaultLanguage || !defaultLanguage.value) {
                    alert("Default Language cannot be empty")
                    return
                  }
                  const defaultCurrency = document.getElementById('Curr') as HTMLSelectElement;
                  //let pc = document.getElementById('ParentC').value;
                  //let dn = document.getElementById('Prof-dn').value;
                  let tz = document.getElementById('tzSelect') as HTMLSelectElement;
                  //if (document.getElementById('ParentC').disabled) {
                  //  pc=""
                  //}
                  let dataToSend = {}
                  // Prepare data to send
                  if (pwd1N && pwd1N.value !== "") {
                    // Validate passwords
                    if (pwd1N.value !== pwd2N.value) {
                      alert('Passwords do not match!');
                      return;
                    }
                    dataToSend = {
                      //email: email,
                      password: pwd1N.value,
                      confirmPassword: pwd2N.value,
                      language: defaultLanguage.value,
                      currency: defaultCurrency.value,
                      pc: "",
                      dn: "",
                      tz: tz.value
                    };
                  } else {
                    dataToSend = {
                      //email: email,
                      language: defaultLanguage.value,
                      currency: defaultCurrency.value,
                      pc: "",
                      dn: "",
                      tz: tz.value
                    };
                  }

                  
                  fetch('/ChangeProfile', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body:JSON.stringify(dataToSend)
                  })
                  .then(response => response.json())
                  .then(data => {
                    defaultLanguage && setCurrNL(defaultLanguage.value)
                    setNLCode(data.code)
                    i18n.changeLanguage(data.NLLocal);
                    setProfLang(defaultLanguage.value)
                    setTZ(tz.value)
                    setCurr(defaultCurrency.value)
                    pwd1N.value=""
                    pwd2N.value=""
                  })
              }}
              />
              <Buttonsecondary
                disabled={true}
                onClick={onButtonSecondary38Click}
              >
                <SmalldeleteIcon alt="" src="/DeleteFC&Acc.svg" />
                <SaveSession>Delete Account</SaveSession>
              </Buttonsecondary>
            </FrameParent>
          </Container1>
        </Page4Profile>
        <BalanceContainer
          darkMode={InputData.darkMode}
          iconCode="/Checkmark.svg"
          propColor="var(--colors-dark-1)"
          propBorderTop="0.13020833333333331vh solid var(--colors-grey-stroke)"
          propColor1="var(--colors-dark-1)"
          propColor15="var(--colors-dark-1)"
        />
      </BlocksOfPages>
    </LayoutBlocksLightRoot>
  );
};

export default LayoutBlocksLight;
